import React from "react";
import Clock from "../../../assets/SVG/clock";
import Badge from "../../../assets/SVG/badge";

const Sidebar = () => {
  return (
    <div className="bg-primary-pink min-h-screen">
      <div className="border-b border-primary-purple hover:bg-primary-purple p-5">
        <Clock className="w-[28px] h-[28px] cursor-pointer" />
      </div>
      <div className="border-b border-primary-purple hover:bg-primary-purple p-5">
        <Badge className="w-[27px] h-[37px] cursor-pointer" />
      </div>
    </div>
  );
};

export default Sidebar;
