export const getDaysOfWeekBetweenDates = (enteredStartDate, enteredEndDate) => {
  const startDate = new Date(enteredStartDate);
  const endDate = new Date(enteredEndDate);

  endDate.setDate(endDate.getDate() + 1);

  const daysOfWeek = [];

  let i = 0;

  while (i < 7 && startDate < endDate) {
    daysOfWeek.push(startDate.getDay());
    startDate.setDate(startDate.getDate() + 1);
    i++;
  }

  let daysOptions = {};
  let weekdays = [];

  for (let i = 0; i < daysOfWeek.length; i++) {
    if (daysOfWeek[i] === 0) {
      daysOptions["Sunday"] = {
        weekDay: 0,
        selected: false,
        time: "00:00",
      };
      weekdays.push("Sunday");
    } else if (daysOfWeek[i] === 1) {
      daysOptions["Monday"] = {
        weekDay: 1,
        selected: false,
        time: "00:00",
      };
      weekdays.push("Monday");
    } else if (daysOfWeek[i] === 2) {
      daysOptions["Tuesday"] = {
        weekDay: 2,
        selected: false,
        time: "00:00",
      };
      weekdays.push("Tuesday");
    } else if (daysOfWeek[i] === 3) {
      daysOptions["Wednesday"] = {
        weekDay: 3,
        selected: false,
        time: "00:00",
      };
      weekdays.push("Wednesday");
    } else if (daysOfWeek[i] === 4) {
      daysOptions["Thursday"] = {
        weekDay: 4,
        selected: false,
        time: "00:00",
      };
      weekdays.push("Thursday");
    } else if (daysOfWeek[i] === 5) {
      daysOptions["Friday"] = {
        weekDay: 5,
        selected: false,
        time: "00:00",
      };
      weekdays.push("Friday");
    } else if (daysOfWeek[i] === 6) {
      daysOptions["Saturday"] = {
        weekDay: 6,
        selected: false,
        time: "00:00",
      };
      weekdays.push("Saturday");
    }
  }

  return { daysOptions, weekdays };
};
