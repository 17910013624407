import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";

import "./dateRangeStyles.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

//{ domNode, DateSelectHandler, id } // parameter needed in future

const MyDateRangePicker = ({
  slotDetails,
  setSlotDetails,
  setSelectedCourse,
  selectedCourse,
}) => {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const changeHandler = (item) => {
    console.log(item.selection);
    setState([item.selection]);
    //DateSelectHandler(item.selection, id);
    setSlotDetails({ ...slotDetails, date: item.selection.startDate });
    setSelectedCourse({ ...selectedCourse, date: item.selection.startDate });
  };

  return (
    <div>
      <DateRangePicker
        editableDateInputs={true}
        onChange={changeHandler}
        moveRangeOnFirstSelection={false}
        ranges={state}
      />
    </div>
  );
};

export default MyDateRangePicker;
