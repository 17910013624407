import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import { BACKEND_BASE_URL } from "../../../helpers/url/url";
import axios from "../../../helpers/axios/axios";
import LoadingSpinner from "../../../components/Spinners/LoadingSpinner/LoadingSpinner";

const ActivePricingPlan = () => {
  const [isLoading, setIsLoading] = useState({ id: "" });
  const [planInfo, setPlanInfo] = useState({});

  const getWalletDetailsAPI = async () => {
    setIsLoading({ id: "main-page" });
    try {
      const getWalletDetailsResponse = await axios.get(
        `${BACKEND_BASE_URL}/api/students/getWalletDetails`
      );
      if (getWalletDetailsResponse?.status === 200) {
        console.log(getWalletDetailsResponse);
        setPlanInfo({
          paymentMode: getWalletDetailsResponse?.data?.wallet?.paymentMode,
          credits: getWalletDetailsResponse?.data?.wallet?.credits,
        });
        setIsLoading({ id: null });
      }
    } catch (err) {
      console.log(err);
      setIsLoading({ id: null });
    }
  };

  useEffect(() => {
    getWalletDetailsAPI();
  }, []);

  const switchPaymentModeAPI = async () => {
    setIsLoading({ id: "main-page" });
    try {
      setIsLoading({ id: "switch-payment" });
      const switchPaymentModeResponse = await axios.patch(
        `${BACKEND_BASE_URL}/api/payment/switchPaymentMode`,
        {
          paymentMode:
            planInfo?.paymentMode === "prepaid" ? "postpaid" : "prepaid",
        }
      );
      if (switchPaymentModeResponse?.status === 204) {
        getWalletDetailsAPI();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {isLoading.id === "main-page" && <LoadingSpinner />}
      {isLoading.id !== "main-page" && (
        <div className="max-w-screen p-5 lg:p-10 2xl:p-20">
          <div>
            <h1 className="text-center lg:text-left text-2xl xl:text-3xl text-secondary-blue font-bold xl:leading-[40px]">
              Pricing Plan
            </h1>
            <h3 className="text-center lg:text-left text-base xl:text-2xl text-secondary-blue font-normal xl:leading-[40px]">
              Upgrade your plan to Prepaid to avail exciting offers!
            </h3>
          </div>
          <div className="mt-10 max-w-2xl bg-secondary-blue rounded-xl p-5 sm:p-10 flex flex-col items-center justify-center">
            <p className="text-center 2xl:mx-20 mb-10 text-xl xl:text-3xl text-white font-semibold 2xl:leading-[48px]">
              You’re current pricing plan is{" "}
              <span className="capitalize text-secondary-orange">
                {planInfo?.paymentMode ? planInfo?.paymentMode : "Not Found"}
              </span>{" "}
              plan
            </p>
            <div className="flex flex-col space-y-3 w-[100%] sm:w-[65%] bg-white rounded-2xl px-5 pt-5 pb-7">
              <p className="text-sm text-secondary-blue font-semibold">
                Pricing Plan
              </p>
              <div className="flex items-center space-x-3">
                <p className="capitalize text-xl text-secondary-blue font-semibold">
                  {planInfo?.paymentMode ? planInfo?.paymentMode : "Not Found"}
                </p>
                <span
                  onClick={switchPaymentModeAPI}
                  className="text-base text-primary-purple underline cursor-pointer font-semibold"
                >
                  Change to{" "}
                  {planInfo?.paymentMode === "prepaid" ? "postpaid" : "prepaid"}
                </span>
              </div>
              <div>
                <div className="mb-2 text-sm font-semibold text-secondary-blue flex items-center justify-between">
                  <p>Classes completed</p>
                  <p>02/10</p>
                </div>
                <div className="bg-primary-pink w-full h-[30px] rounded-2xl"></div>
              </div>
              <div>
                <p className="my-2 text-sm text-secondary-blue font-semibold">
                  Available Balance
                </p>
                <div className="mb-5 flex items-center space-x-2">
                  <p className="text-lg font-semibold text-secondary-blue">
                    {planInfo?.credits ? `$${planInfo?.credits}` : "00.00"}
                  </p>
                  <span className="text-sm font-semibold underline text-primary-green">
                    Recharge now
                  </span>
                </div>
              </div>
              <Button
                innerText="Recharge Plan"
                type="button"
                extraClass="py-1 text-lg text-white font-normal bg-primary-green"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivePricingPlan;
