import React from "react";

const CheckboxTick = ({ className, fill, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        fill={fill ? fill : "#DDC2FF"}
        fillRule="evenodd"
        d="M8.606 16.388l.848.849L19.636 7.054 17.94 5.357l-8.485 8.485-3.394-3.394-1.697 1.697 4.243 4.243zM0 2.388A2.394 2.394 0 012.389 0H21.61A2.394 2.394 0 0124 2.389V21.61A2.394 2.394 0 0121.611 24H2.39A2.394 2.394 0 010 21.611V2.39z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default CheckboxTick;
