import React from "react";

const Input = ({
  placeholder,
  type,
  name,
  id,
  error,
  showIndividualError,
  extraClass,
  ...rest
}) => {
  return (
    <div>
      <input
        className={`${extraClass} text-[10px] sm:text-base w-full h-[35px] xl:w-[350px] lg:h-[38px] xl:h-[42px] px-5 py-1.5 rounded-lg placeholder:text-[10px] sm:placeholder:text-base focus:outline-none`}
        type={type}
        placeholder={placeholder}
        name={name}
        id={id}
        {...rest}
      />
      <div>
        {showIndividualError && error ? (
          <span className="pl-1 text-xs sm:text-sm font-normal  text-primary-red">
            {error}
          </span>
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
};

export default Input;
