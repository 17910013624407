import React from "react";

const ProfessionalProjects = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 73 58"
    >
      <path
        fill="#FFE8F5"
        d="M64.754 6.603h-32.69C31.491 2.853 28.231 0 24.318 0H8.584A7.828 7.828 0 00.758 7.827v3.587c0 .489.081.978.163 1.467-.081.407-.163.896-.163 1.386v35.789c0 4.24 3.424 7.745 7.745 7.745h56.252c4.239 0 7.744-3.425 7.744-7.745v-35.79c0-4.239-3.505-7.663-7.744-7.663zm1.794 7.664v35.789a1.81 1.81 0 01-1.794 1.793H8.503a1.81 1.81 0 01-1.794-1.793v-35.79a1.81 1.81 0 011.794-1.793h56.251c.979.082 1.794.815 1.794 1.794z"
      ></path>
      <path
        fill="#FFE8F5"
        d="M61.086 16.957l-12.473 2.609.734 2.772c-11.821-5.463-15.245-3.832-16.223-2.527l-.408.244-8.56.734.407-1.386-14.592-3.016-4.729-1.06v24.212h4.729v.081h8.968l.08-.57 13.942 7.989c.244.163 2.038 1.304 4.076 1.304.57 0 1.141-.081 1.712-.244h.245c.244 0 .489-.082.652-.163.897.08 2.364 0 3.424-.734.49-.245.816-.734 1.141-1.223 1.305.082 3.017-.489 4.402-2.364.734-.08 1.957-.407 2.772-1.304.734-.734 1.141-1.794 1.06-3.016l1.55-.734.244.897h13.37V15.407l-6.523 1.55zM49.51 40.762c-.244.244-.734.489-1.141.489l-9.13-8.723c-.572-.571-1.387-.49-1.958.08-.57.572-.489 1.387.082 1.958l8.641 8.397c-.733.733-1.386.733-1.793.652L37.2 36.93c-.571-.571-1.386-.571-1.957.08-.571.572-.49 1.387.081 1.958l6.522 6.195-.163.164c-.652.489-1.957.244-2.364.244l-4.158-3.995c-.57-.57-1.385-.489-1.956.081-.571.572-.49 1.386.081 1.957l2.201 2.12c-.57-.245-1.06-.49-1.06-.49l-14.674-8.233 3.669-12.717 5.706-.571c-3.016 2.609-3.831 4.158-2.364 6.522.652 1.141 1.55 1.712 2.772 1.957 2.446.489 5.136-1.386 6.033-2.038l2.446-.49 11.658 10.191c.081.081.081.57-.163.897zm1.712-3.587L38.993 26.169l-4.484.896-.326.327c-.897.733-2.853 1.875-4.076 1.712-.489-.081-.733-.244-.897-.652-.57-.897-.489-1.223 4.81-5.707l1.386-1.304s2.772-1.305 13.207 3.668l1.549.571.081-.163 3.098 10.762-2.12.896z"
      ></path>
    </svg>
  );
};

export default ProfessionalProjects;
