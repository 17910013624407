import React from "react";
import TdData from "./components/TdData";
import TdIcon from "./components/TdIcon";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toastNotification } from "../../helpers/functions/toast-notification/toast-notification";

const Table = ({
  headersData,
  rowsData,
  columnsWidth,
  tableWidth,
  cellClickHandler,
}) => {
  return (
    <div
      style={{ width: tableWidth }}
      className="border border-secondary-blue rounded-lg"
    >
      <table className="border-collapse">
        <thead>
          <tr>
            {headersData.map((header, index) => (
              <th
                style={{ width: columnsWidth[index] }}
                className={`${header.extraClass} py-3 px-5 ${
                  index !== headersData.length - 1 &&
                  "border-r-[0.5px] border-secondary-blue"
                }`}
                key={header.accessor}
              >
                {header.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rowsData.map((row, index) => (
            <tr key={index}>
              {row.map((cell, index) => {
                if (cell.type === "icon") {
                  return (
                    <CopyToClipboard
                      text={cell?.copyToClipboard?.text}
                      onCopy={() => {
                        if (cell?.copyToClipboard?.isAllowed) {
                          toastNotification("success", "Class link copied");
                        }
                      }}
                      key={cell.id}
                    >
                      <TdIcon
                        style={{ width: columnsWidth[index] }}
                        extraClass={`${
                          index !== row.length - 1
                            ? "border-r-[0.5px] border-t-[0.5px] border-secondary-blue"
                            : "border-t-[0.5px] border-secondary-blue"
                        }`}
                        Icon={cell.cellValue}
                        onClick={() => {
                          if (cell.isActionModal) {
                            cellClickHandler(cell.isActionModal, cell.cb());
                          }
                        }}
                      />
                    </CopyToClipboard>
                  );
                } else {
                  return (
                    <TdData
                      style={{ width: columnsWidth[index] }}
                      extraClass={`${cell.extraClass} ${
                        index !== row.length - 1
                          ? "border-r-[0.5px] border-t-[0.5px] border-secondary-blue"
                          : "border-t-[0.5px] border-secondary-blue"
                      }`}
                      value={cell.cellValue}
                      key={cell.id}
                    />
                  );
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
