import React from "react";

const Tooltip = () => {
  return (
    <div className="">
      <div className="-mb-[2px] ml-7">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="10"
          fill="none"
          viewBox="0 0 23 10"
        >
          <path
            fill="#ffffff"
            stroke="#DDC2FF"
            d="M1.5 8.5L11.982.638 21.348 8.5H1.5z"
          ></path>
          <path stroke="#ffffff" strokeWidth="2" d="M3 9h17"></path>
          <path
            fill="#fff"
            d="M2.5 8.5L3 8v1H2l.5-.5zM20 8l.5.5.5.5h-1V8z"
          ></path>
        </svg>
      </div>
      <div className="border border-primary-purple w-[150px] 2xl:w-[180px] p-3">
        <p className="text-[0.7rem] 2xl:text-sm font-normal text-secondary-blue-lt-lt">
          Link will be active 5 mins before the class
        </p>
      </div>
    </div>
  );
};

export default Tooltip;
