import React from "react";

const Badge = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 27 38"
      {...rest}
    >
      <path
        fill="#434484"
        fillRule="evenodd"
        d="M26.502 13.025C26.502 5.832 20.569 0 13.25 0S0 5.832 0 13.025C0 20.22 5.933 26.05 13.25 26.05c7.32 0 13.252-5.83 13.252-13.025zm-24.735 0c0-6.234 5.142-11.288 11.484-11.288s11.484 5.054 11.484 11.288c0 6.235-5.141 11.29-11.484 11.29S1.767 19.26 1.767 13.024zM20.538 31.858a.859.859 0 00-.184-1.215.893.893 0 00-1.236.181l-3.623 4.81a.859.859 0 00.184 1.214.894.894 0 001.236-.18l3.623-4.81z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#434484"
        fillRule="evenodd"
        d="M25.805 32.777a.88.88 0 00.964-.782.872.872 0 00-.795-.947l-6.06-.571a.88.88 0 00-.965.781.873.873 0 00.796.947l6.06.572z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#434484"
        fillRule="evenodd"
        d="M14.97 35.745a.89.89 0 001.167.44.862.862 0 00.448-1.146l-5.331-10.524a.89.89 0 00-1.166-.44.863.863 0 00-.448 1.146l5.33 10.524zm6.821-13.585a.89.89 0 00-1.166-.44.862.862 0 00-.448 1.146l4.478 8.64a.89.89 0 001.166.44.862.862 0 00.448-1.146l-4.478-8.64zM7.975 29.842a.893.893 0 00-1.235-.181.859.859 0 00-.185 1.215l3.624 4.809a.894.894 0 001.236.18.859.859 0 00.184-1.214l-3.624-4.809z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#434484"
        fillRule="evenodd"
        d="M1.12 30.066a.872.872 0 00-.795.947.88.88 0 00.964.781l6.06-.571a.872.872 0 00.796-.947.88.88 0 00-.964-.782l-6.06.572z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#434484"
        fillRule="evenodd"
        d="M10.632 34.68a.862.862 0 00.447 1.147.89.89 0 001.167-.44l1.614-3.537a.862.862 0 00-.448-1.146.89.89 0 00-1.166.44l-1.614 3.537zm-4.313-11.8a.862.862 0 00-.448-1.146.89.89 0 00-1.166.44L.948 30.443a.862.862 0 00.448 1.146.89.89 0 001.166-.44l3.757-8.27zM7.146 10.256a.894.894 0 00-1.249.016.858.858 0 00.016 1.228l3.36 3.22a.894.894 0 001.25-.016.858.858 0 00-.016-1.228l-3.36-3.22z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#434484"
        fillRule="evenodd"
        d="M11.301 11.075a.87.87 0 00.747-.984.88.88 0 00-1-.734l-4.645.663a.87.87 0 00-.747.984.881.881 0 001.001.735l4.644-.664zM10.761 14.246a.87.87 0 00-.721-1.003.882.882 0 00-1.02.71l-.793 4.546a.87.87 0 00.72 1.003.882.882 0 001.021-.71l.793-4.546z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#434484"
        fillRule="evenodd"
        d="M8.685 17.878a.86.86 0 00-.37 1.172.89.89 0 001.193.365l4.153-2.147a.861.861 0 00.371-1.173.891.891 0 00-1.193-.364l-4.154 2.147zM16.535 18.793c.082.472.539.79 1.02.709a.87.87 0 00.72-1.003l-.792-4.547a.882.882 0 00-1.02-.709.87.87 0 00-.722 1.003l.794 4.546z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#434484"
        fillRule="evenodd"
        d="M13.662 15.73a.891.891 0 00-1.193.365.861.861 0 00.37 1.173l4.155 2.147a.89.89 0 001.193-.365.86.86 0 00-.371-1.172l-4.154-2.147zM19.845 11.739a.881.881 0 001.001-.735.871.871 0 00-.747-.984l-4.644-.663a.88.88 0 00-1.001.734.871.871 0 00.747.985l4.644.663z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#434484"
        fillRule="evenodd"
        d="M15.995 13.476a.858.858 0 00-.016 1.228c.34.344.9.35 1.249.016l3.36-3.22a.858.858 0 00.016-1.228.894.894 0 00-1.249-.016l-3.36 3.22zM14.536 10.6a.89.89 0 001.183.395.862.862 0 00.401-1.163l-2.077-4.137a.89.89 0 00-1.183-.394.861.861 0 00-.401 1.163l2.077 4.136z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#434484"
        fillRule="evenodd"
        d="M14.044 6.462a.862.862 0 00-.402-1.163.89.89 0 00-1.183.394L10.383 9.83a.862.862 0 00.4 1.163.89.89 0 001.184-.395l2.077-4.136z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Badge;
