import React from "react";

const OurInstuctors = ({
  topBannerHeading,
  topBannerVideo,
  skillHeading,
  instructorImage,
  instructorName,
  instructorDescriptionHeading,
  instructorDescription,
  WeTeachCreativeAnalyticalSkills,
  ProductiveOutcomes,
  TransformCard,
  InstructorPageAnimation,
}) => {
  return (
    <div className="max-w-screen">
      <div className="w-full bg-primary-purple flex flex-col space-y-10 lg:space-y-0 lg:flex-row items-center justify-center lg:space-x-32 px-5 lg:px-10 pt-10 pb-16">
        <h2 className="max-w-md text-2xl xl:text-4xl text-center lg:text-left text-secondary-blue leading-[32px] xl:leading-[48px] font-extrabold">
          {topBannerHeading}
        </h2>

        <video
          className="object-cover w-[370px] h-[208px] rounded-lg lg:rounded-none lg:w-[450px] lg:h-[255px] xl:w-[600px] xl:h-[340px]"
          loop
          muted
          autoPlay
        >
          <source src={topBannerVideo} type="video/mp4" />
        </video>
      </div>
      <div className="my-10 lg:mx-10 xl:mx-20">
        <h2 className="text-2xl lg:text-4xl text-center lg:text-left text-secondary-blue font-[750] mb-10">
          Meet our <span className="text-primary-green">{skillHeading}</span>
        </h2>
        <div className="flex flex-col space-y-5 lg:space-y-0 items-center lg:items-start lg:flex-row lg:space-x-20 xl:space-x-32">
          <div className="text-center w-[300px] xl:w-[330px] h-[403px] border-2 border-[#E47650] rounded-[20px] p-5">
            <img
              className="w-[320px] h-[300px] rounded-lg"
              src={instructorImage}
              alt=""
            />
            <p className="text-2xl text-black font-normal mt-5">
              {instructorName}
            </p>
          </div>
          <div className="max-w-xl mx-5">
            <h3 className="lg:text-xl font-extrabold text-secondary-blue leading-[30p] mb-3">
              {instructorDescriptionHeading}
            </h3>
            <p className="text-sm lg:text-lg font-normal text-secondary-blue xl:leading-[28px]">
              {instructorDescription}
            </p>
          </div>
        </div>
      </div>
      {WeTeachCreativeAnalyticalSkills && WeTeachCreativeAnalyticalSkills}
      {ProductiveOutcomes && ProductiveOutcomes}
      {InstructorPageAnimation && (
        <div className="lg:mx-20 xl:mx-64 mt-48 pb-20">
          {InstructorPageAnimation}
        </div>
      )}
      {TransformCard && TransformCard}
    </div>
  );
};

export default OurInstuctors;
