import React, { useState } from "react";
import DropdownArrow from "../../assets/SVG/dropdown-arrow";
import { useClickOutside } from "../../CustomHook/use-click-outside";

const Dropdown = ({
  menuList,
  arrowStyle,
  dropdownStyle,
  defaultValue,
  dropdownMenuStyle,
  dropdownHandler,
}) => {
  const [selectedValue, setSelectedValue] = useState({ ...defaultValue });
  const [openDropdown, setOpenDropdown] = useState(false);

  let domNode = useClickOutside(() => {
    setOpenDropdown(false);
  });

  return (
    <div className="relative">
      <div className={dropdownStyle}>
        <h3 className="mr-2 text-base font-semibold text-primary-blue">
          {selectedValue.name}
        </h3>
        <DropdownArrow
          className={arrowStyle}
          onClick={() => setOpenDropdown(true)}
        />
      </div>
      <div
        ref={domNode}
        className={`${dropdownMenuStyle} absolute z-10`}
        style={{ boxShadow: "0px 4px 24px rgba(58, 27, 163, 0.08)" }}
      >
        {openDropdown &&
          menuList.length > 0 &&
          menuList.map((option, index) => (
            <p
              className={`cursor-pointer text-base font-medium text-primary-blue p-2 ${
                index !== 0 &&
                index !== menuList.length &&
                "border-t border-border-gray"
              }`}
              onClick={() => {
                setSelectedValue(option);
                if (dropdownHandler) {
                  dropdownHandler(option);
                }
                setOpenDropdown(false);
              }}
              key={option.id}
            >
              {option.name}
            </p>
          ))}
      </div>
    </div>
  );
};

export default Dropdown;
