import React, { useState } from "react";
import Avatar from "../../../assets/images/avatar.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import DropdownArrow from "../../../assets/SVG/dropdown-arrow";
import Hamburger from "../../../assets/SVG/hamburger";
import Cross from "../../../assets/SVG/cross";
import Logo from "../../../assets/images/logo.png";
import Laptop from "../../../assets/SVG/laptop";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authActions } from "../../../redux/features/auth-slice";
//import Settings from "../../../../assets/SVG/settings";
//import NotificationCard from "../NotificationCard/NotificationCard";
//import SearchBar from "../../../../components/SearchBar/SearchBar";

const Header = () => {
  const navigate = useNavigate();
  const [isOpenDropdown, setIsOpenDropdown] = useState({ open: false });
  const [isOpenDropDownMobile, setIsOpenDropdownMobile] = useState({
    open: true,
  });
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const [openProfileDropdown, setOpenProfileDropdown] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  //console.log(openProfileDropdown, "openProfileDropdown");

  return (
    <div className="relative max-w-screen bg-primary-pink flex items-center justify-between p-5 lg:pl-0 sm:py-5 shadow-sm">
      <div className="flex items-center space-x-5">
        <div className="lg:hidden">
          <Hamburger
            className="w-[35px] h-[24px]"
            onClick={() => setIsHamburgerOpen(true)}
          />
          {isHamburgerOpen && (
            <div
              style={{
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              }}
              className="flex flex-col space-y-3 absolute z-10 top-0 left-0 rounded-2xl w-[75%] h-screen bg-white py-3 px-7"
            >
              <div>
                <Cross
                  onClick={() => {
                    setIsHamburgerOpen(false);
                    setIsOpenDropdownMobile({ open: true });
                  }}
                />
                <div className="flex items-center space-x-2 mt-8">
                  <DropdownArrow
                    onClick={() => {
                      setIsOpenDropdownMobile((prevState) => {
                        return { open: !prevState.open };
                      });
                    }}
                    className="w-3 h-5 cursor-pointer"
                  />
                  <span className="text-base text-primary-blue font-medium">
                    Explore Skills
                  </span>
                </div>
                {isOpenDropDownMobile.open && (
                  <div className="flex flex-col">
                    <Link
                      to="/explore-skills/chess"
                      onClick={() => setIsHamburgerOpen(false)}
                      className="text-sm text-secondary-blue-lt block cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
                    >
                      Chess
                    </Link>
                    <Link
                      to="/explore-skills/web-design"
                      onClick={() => setIsHamburgerOpen(false)}
                      className="text-sm text-secondary-blue-lt cursor-pointer block hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
                    >
                      Web Design
                    </Link>
                    <Link
                      to="/explore-skills/coding"
                      onClick={() => setIsHamburgerOpen(false)}
                      className="text-sm text-secondary-blue-lt cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
                    >
                      Coding
                    </Link>
                    <Link
                      to="/explore-skills/graphic-design"
                      onClick={() => setIsHamburgerOpen(false)}
                      className="text-sm text-secondary-blue-lt cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
                    >
                      Graphic Design
                    </Link>
                    <Link
                      to="/explore-skills/video-editing"
                      onClick={() => setIsHamburgerOpen(false)}
                      className="text-sm text-secondary-blue-lt cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
                    >
                      Video Editing
                    </Link>
                    <Link
                      to="/explore-skills/3D-modelling"
                      onClick={() => setIsHamburgerOpen(false)}
                      className="text-sm text-secondary-blue-lt cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
                    >
                      3D Modelling
                    </Link>
                  </div>
                )}
              </div>

              <Link
                to="/summer-bootcamp"
                className="text-base text-secondary-blue font-normal"
                onClick={() => setIsHamburgerOpen(false)}
              >
                Summer Bootcamp 22
              </Link>
              <Link
                to="/pricing/options"
                className="text-base text-secondary-blue font-normal"
                onClick={() => setIsHamburgerOpen(false)}
              >
                Pricing Plans
              </Link>
              <Link
                to="/creative-champs"
                className="text-base text-secondary-blue font-normal"
                onClick={() => setIsHamburgerOpen(false)}
              >
                Creative Champs
              </Link>
              <Link
                to="/our-clients"
                className="text-base text-secondary-blue font-normal"
                onClick={() => setIsHamburgerOpen(false)}
              >
                Our Clients
              </Link>
              <Link
                to="/about-us"
                className="text-base text-secondary-blue font-normal"
                onClick={() => setIsHamburgerOpen(false)}
              >
                About Us
              </Link>
              <Link
                to="/contact-us"
                className="text-base text-secondary-blue font-normal"
                onClick={() => setIsHamburgerOpen(false)}
              >
                Contact Us
              </Link>
              {/* <span
                className="text-base text-secondary-blue font-normal"
                onClick={() => setIsHamburgerOpen(false)}
              >
                Settings
              </span>
              <span
                className="text-base text-secondary-blue font-normal"
                onClick={() => setIsHamburgerOpen(false)}
              >
                Logout
              </span> */}
            </div>
          )}
        </div>
        <div
          onClick={() => navigate("/")}
          className="lg:flex lg:flex-col sm:items-center cursor-pointer"
        >
          <img
            src={Logo}
            alt="Krafternoon"
            className="w-[53px] h-[43px] lg:w-[73px] lg:h-[63px]"
          />
          <p className="hidden lg:inline font-bold text-lg -mt-1">
            KRAFTERNOON
          </p>
          <p className="hidden lg:inline font-normal text-sm -mt-2">
            KRAFTING SKILLS
          </p>
        </div>

        <div className="bg-transparent z-20 relative">
          <div className="hidden lg:inline-flex items-center space-x-2">
            <span className="text-sm 2xl:text-lg text-primary-blue font-normal ">
              Explore Skills
            </span>
            <DropdownArrow
              onClick={() => {
                setIsOpenDropdown((prevState) => {
                  return { open: !prevState.open };
                });
              }}
              className="w-3 h-5 cursor-pointer"
            />
          </div>
          {isOpenDropdown.open && (
            <div className="py-2 w-40 xl:w-44 hidden sm:flex sm:flex-col absolute xl:text-lg text-secondary-blue font-normal bg-white shadow-md rounded-md">
              <Link
                to="/explore-skills/chess"
                onClick={() => setIsOpenDropdown(false)}
                className="cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
              >
                Chess
              </Link>
              <Link
                to="/explore-skills/web-design"
                onClick={() => setIsOpenDropdown(false)}
                className="cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
              >
                Web Design
              </Link>
              <Link
                to="/explore-skills/coding"
                onClick={() => setIsOpenDropdown(false)}
                className="cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
              >
                Coding
              </Link>
              <Link
                to="/explore-skills/graphic-design"
                onClick={() => setIsOpenDropdown(false)}
                className="cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
              >
                Graphic Design
              </Link>
              <Link
                to="/explore-skills/video-editing"
                onClick={() => setIsOpenDropdown(false)}
                className="cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
              >
                Video Editing
              </Link>
              <Link
                to="/explore-skills/3D-modelling"
                onClick={() => setIsOpenDropdown(false)}
                className="cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
              >
                3D Modelling
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center space-x-4 xl:space-x-6 2xl:space-x-12">
        <div className="hidden lg:inline-flex items-center space-x-4 xl:space-x-6 2xl:space-x-12">
          <NavLink
            to="/summer-bootcamp"
            className={({ isActive }) =>
              isActive
                ? "text-primary-blue text-xs xl:text-base 2xl:text-xl font-semibold "
                : "text-xs xl:text-sm 2xl:text-lg font-normal  text-secondary-blue-lt"
            }
          >
            Summer Bootcamp 22
          </NavLink>

          <NavLink
            to="/pricing/options"
            className={({ isActive }) =>
              isActive
                ? "text-primary-blue text-xs xl:text-base 2xl:text-xl font-semibold "
                : "text-xs xl:text-sm 2xl:text-lg font-normal  text-secondary-blue-lt"
            }
          >
            Pricing
          </NavLink>
          <NavLink
            to="/creative-champs"
            className={({ isActive }) =>
              isActive
                ? "text-primary-blue text-xs xl:text-base 2xl:text-xl font-semibold "
                : "text-xs xl:text-sm 2xl:text-lg font-normal  text-secondary-blue-lt"
            }
          >
            Creative Champs
          </NavLink>
          <NavLink
            to="/about-us"
            className={({ isActive }) =>
              isActive
                ? "text-primary-blue text-xs xl:text-base 2xl:text-xl font-semibold "
                : "text-xs xl:text-sm 2xl:text-lg font-normal  text-secondary-blue-lt"
            }
          >
            About Us
          </NavLink>
          <NavLink
            to="/contact-us"
            className={({ isActive }) =>
              isActive
                ? "text-primary-blue text-xs xl:text-base 2xl:text-xl font-semibold "
                : "text-xs xl:text-sm 2xl:text-lg font-normal  text-secondary-blue-lt"
            }
          >
            Contact Us
          </NavLink>
          <NavLink
            to="/my-learning/all-courses"
            className={
              location.pathname.match("my-learning")
                ? "text-primary-blue text-xs xl:text-base 2xl:text-xl font-semibold flex items-center"
                : "text-xs xl:text-sm 2xl:text-lg font-normal  text-secondary-blue-lt flex items-center"
            }
          >
            <Laptop className="w-[22px] h-[11px] xl:w-[32px] xl:h-[21px] mr-2" />
            My Learning
          </NavLink>
        </div>
        <div
          className="relative"
          onMouseOver={() => setOpenProfileDropdown(true)}
          onMouseOut={() => setOpenProfileDropdown(false)}
          onClick={() => setOpenProfileDropdown(true)}
        >
          <img
            src={Avatar}
            alt="avatar"
            className="cursor-pointer w-[48px] h-[48px]"
          />
          {openProfileDropdown && (
            <div
              style={{ boxShadow: "-3px 30px 48px rgba(67, 68, 132, 0.25)" }}
              className="z-40 absolute right-0 sm:right-5 py-2 w-40 xl:w-44 flex flex-col xl:text-lg text-secondary-blue font-normal bg-white shadow-md rounded-md"
            >
              <Link
                to="/my-profile"
                onClick={() => setOpenProfileDropdown(false)}
                className="cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
              >
                Profile
              </Link>
              {/* <Link
                to="/my-courses"
                onClick={() => setOpenProfileDropdown(false)}
                className="cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
              >
                My Courses
              </Link> */}
              <Link
                to="/my-pricing-plan"
                onClick={() => setOpenProfileDropdown(false)}
                className="cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
              >
                Pricing Plan
              </Link>
              <Link
                to="/my-wallet"
                onClick={() => setOpenProfileDropdown(false)}
                className="cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
              >
                Wallet
              </Link>
              <Link
                to="/settings"
                onClick={() => setOpenProfileDropdown(false)}
                className="cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
              >
                Settings
              </Link>
              <span
                onClick={() => {
                  setIsOpenDropdown(false);
                  dispatch(authActions.logout());
                  navigate("/");
                }}
                className="cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
              >
                Logout
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
