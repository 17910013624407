import React from "react";
import { useParams } from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import AddBatch from "./Pages/AddBatch/AddBatch";
import AddCourse from "./Pages/AddCourse/AddCourse";
import AllCourse from "./Pages/AddCourse/components/AllCourse/AllCourse";
import AdminDashboard from "./Pages/AdminDashboard/AdminDashboard";
import AdPosts from "./Pages/AdPosts/AdPosts";
import AllAdPosts from "./Pages/AdPosts/components/AllAdPosts/AllAdPosts";
import EditAdPost from "./Pages/AdPosts/components/EditAdPost/EditAdPost";
import AssignClass from "./Pages/AssignClass/AssignClass";
import CreateDiscountCoupons from "./Pages/CreateDiscountCoupons/CreateDiscountCoupons";
import CreatePlan from "./Pages/CreatePlan/CreatePlan";

const Admin = () => {
  const { type } = useParams();
  return (
    <div className="max-w-screen flex space-x-10">
      <Sidebar
        heading="ADMIN VIEW"
        menuList={[
          { id: "dashboard", name: "Dashboard", url: "/admin/dashboard" },
          {
            id: "assign-class",
            name: "Assign Class",
            url: "/admin/assign-class",
          },
          { id: "add-course", name: "Add Course", url: "/admin/add-course" },
          { id: "add-batch", name: "Add Batch", url: "/admin/add-batch" },
          { id: "ad-posts", name: "Ad Posts", url: "/admin/ad-posts" },
          { id: "create-plan", name: "Create Plan", url: "/admin/create-plan" },
          {
            id: "create-discount-coupons",
            name: "Create Coupons",
            url: "/admin/create-discount-coupons",
          },
          { id: "requests", name: "Requests", url: "/admin/requests" },
          { id: "data-loader", name: "Data Loader", url: "/admin/data-loader" },
        ]}
      />
      {type === "dashboard" && <AdminDashboard />}
      {type === "assign-class" && <AssignClass />}
      {type === "add-course" && <AddCourse />}
      {type === "add-batch" && <AddBatch />}
      {type === "all-courses" && <AllCourse />}
      {type === "ad-posts" && <AdPosts />}
      {type === "all-ad-posts" && <AllAdPosts />}
      {type === "edit-ad-post" && <EditAdPost />}
      {type === "create-plan" && <CreatePlan />}
      {type === "create-discount-coupons" && <CreateDiscountCoupons />}
    </div>
  );
};

export default Admin;
