import React, { useState } from "react";
import CircleSpinner from "../Spinners/CircleSpinner/CircleSpinner";
import Search from "../../assets/SVG/search";
import Cancel from "../../assets/SVG/cancel";

const SearchableDropdown = ({
  menuList,
  searchbarStyle,
  placeholder,
  searchMenuStyle,
  searchHandler,
}) => {
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const handleInputChange = (e) => {
    const searchWord = e.target.value;
    setSearchText(searchWord);
    setIsLoading(true);
    const newFilteredList = menuList.filter((value) => {
      return value.name.toLowerCase().includes(searchWord.toLowerCase());
    });

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilteredList);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  //   console.log(menuList, "menuList");
  //   console.log(filteredData, "filteredData");

  const clearInput = () => {
    setFilteredData([]);
    setSearchText("");
  };

  return (
    <div>
      <div className={searchbarStyle}>
        <div className="flex grow-0 items-center space-x-3">
          <Search />
          <input
            onKeyPress={(e) => setSearchText(e.target.value)}
            onChange={(e) => handleInputChange(e)}
            value={searchText ? searchText : ""}
            type="text"
            placeholder={placeholder ? placeholder : "Search"}
            className="focus:outline-none bg-transparent placeholder:text-secondary-blue-lt placeholder:text-base placeholder:font-medium"
          />
        </div>
        {isLoading && (
          <CircleSpinner
            style={{ borderTop: "4px rgba(67, 68, 132, 0.5) solid" }}
          />
        )}
        {searchText && !isLoading && (
          <Cancel
            onClick={() => {
              clearInput();
              searchHandler("");
            }}
            className="text-ternary-blue hover:text-primary-blue h-6 w-6 cursor-pointer "
          />
        )}
      </div>
      {filteredData.length > 0 && !isLoading && (
        <ul className={searchMenuStyle}>
          {filteredData.slice(0, 3).map((value, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setSearchText(value.name);
                  searchHandler(value);
                  setFilteredData([]);
                }}
                className="cursor-pointer hover:bg-ternary-blue-ltr-ltr py-3 px-5"
              >
                <p className="text-base text-primary-blue font-medium">
                  {value.name}
                </p>
              </div>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default SearchableDropdown;
