import React from "react";
import classes from "./fileUploader.module.css";

const FileUploader = ({ label, files, setFiles, uploadFilesAPI }) => {
  // let FILE_SIZE;

  const handleFileChange = (e) => {
    let allFiles = e.target.files;
    console.log(allFiles);
    let filesArr = Array.prototype.slice.call(allFiles);
    setFiles([...files, ...filesArr]);

    if (allFiles.length > 0) {
      for (let i = 0; i < allFiles.length; i++) {
        uploadFilesAPI(allFiles[i]);
      }

      console.log("true");
    } else {
      console.log("false");
    }
    // FILE_SIZE = Math.ceil(e.target.files[0].size / (1026 * 1026));
    // if (FILE_SIZE > 4) {
    //   setFileName(
    //     "File too big. Upload files of size less than or equal to 4MB"
    //   );
    //   selectFile(null);
    // } else {
    //   setFileName(e.target.files[0].name);
    //   selectFile(e.target.files[0]);
    // }
    // console.log("FILE_SIZE in MB", FILE_SIZE);
  };

  return (
    <div>
      <input
        className={classes.custom}
        type="file"
        multiple
        name="dashboardData"
        id="dashboardData"
        onChange={handleFileChange}
      />
      {label && label}
    </div>
  );
};

export default FileUploader;
