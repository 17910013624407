import React from "react";
import CircularTickBlue from "../../assets/SVG/circular-tick-blue";
import { v4 as uuidv4 } from "uuid";

const TaskTracker = ({ taskTracker, length, taskLabels }) => {
  const buildTaskCircles = () => {
    let taskCircles = [];

    for (let i = 0; i < 2 * length - 1; i++) {
      if (i % 2 === 0) {
        if (taskLabels?.length === 0) {
          taskCircles.push(
            <span
              key={uuidv4()}
              className={
                taskTracker >= i / 2 + 1
                  ? "flex flex-col items-center justify-center bg-ternary-pink border-2 border-ternary-pink rounded-full w-[60px] h-[60px] sm:w-[80px] sm:h-[80px] sm:text-2xl font-semibold text-secondary-blue"
                  : "flex flex-col items-center justify-center bg-transparent border-2 border-ternary-pink rounded-full w-[60px] h-[60px] sm:w-[80px] sm:h-[80px] sm:text-2xl font-semibold text-primary-pink"
              }
            >
              {i / 2 + 1 >= taskTracker ? (
                `0${i / 2 + 1}`
              ) : (
                <CircularTickBlue className="w-[30px] h-[30px] " />
              )}
            </span>
          );
        } else {
          taskCircles.push(
            <div
              key={uuidv4()}
              className={`flex items-center ${
                taskLabels && "space-x-3 mr-1 2xl:mr-3"
              }`}
            >
              <span
                className={
                  taskTracker >= i / 2 + 1
                    ? "flex flex-col items-center justify-center bg-ternary-pink border-2 border-ternary-pink rounded-full w-[30px] h-[30px] sm:w-[50px] sm:h-[50px] 2xl:w-[80px] 2xl:h-[80px] text-xs sm:text-base 2xl:text-2xl font-semibold text-secondary-blue"
                    : "flex flex-col items-center justify-center bg-transparent border-2 border-ternary-pink rounded-full w-[30px] h-[30px] sm:w-[50px] sm:h-[50px] 2xl:w-[80px] 2xl:h-[80px] text-xs sm:text-base 2xl:text-2xl font-semibold text-primary-pink"
                }
              >
                {i / 2 + 1 >= taskTracker ? (
                  `0${i / 2 + 1}`
                ) : (
                  <CircularTickBlue className="w-[30px] h-[30px] " />
                )}
              </span>
              <p className="text-secondary-blue text-xs sm:text-sm 2xl:text-base font-bold max-w-max">
                {taskLabels && taskLabels[i / 2]}
              </p>
            </div>
          );
        }
      } else {
        taskCircles.push(
          <div
            key={uuidv4()}
            className="bg-primary-purple w-[50px] sm:w-[150px] lg:w-[216px] h-[1px]"
          ></div>
        );
      }
    }

    return taskCircles;
  };
  return (
    <div className="flex items-center justify-between lg:justify-start">
      {buildTaskCircles()}
    </div>
  );
};

export default TaskTracker;
