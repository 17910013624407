import React, { useState } from "react";

import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { authActions } from "../../../redux/features/auth-slice";
import { BACKEND_BASE_URL, FALLBACK_URL } from "../../../helpers/url/url";
import axios from "../../../helpers/axios/axios";
import Button from "../../Button/Button";
import Input from "../../Input/Input";
import Google from "../../../assets/SVG/google";

const Signin = () => {
  const [showIndividualError, setShowIndividualError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ isError: false, message: "" });
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  console.log(auth, "auth");

  const navigate = useNavigate();
  let errorLength;

  function checkErrors() {
    if (errorLength > 0) {
      setShowIndividualError(true);
    }
  }

  const signInAPI = async (email, password) => {
    setIsLoading(true);
    setError({ isError: false, message: "" });
    try {
      const signInRespone = await axios.post(
        `${BACKEND_BASE_URL}/api/sign-in/student`,
        { email: email, password: password }
      );
      setIsLoading(false);
      console.log(signInRespone, "signInRespone");
      if (signInRespone?.data?.success) {
        const token = signInRespone.data.token;
        localStorage.setItem("token", token);
        dispatch(authActions.login({ token: token }));
        navigate("/dashboard");
      }
    } catch (err) {
      console.log(err);
      if (err.response.status >= 400 && err.response.status < 500) {
        setError({
          isError: true,
          message: "Invalid Credentials! Please try again.",
        });
      } else {
        setError({
          isError: true,
          message: "Oops something went wrong! Please try again.",
        });
      }
      setIsLoading(false);
    }
  };
  return (
    <div className="max-w-screen flex flex-col md:items-center p-5 md:p-0">
      <h1 className="md:mx-10 md:text-center md:mt-12 md:text-lg text-2xl xl:text-4xl font-semibold text-black xl:leading-[50px]">
        <b>Sign up</b> now and avail instant <b>20%</b> Discount
      </h1>
      {error.isError && (
        <p className="text-primary-red font-semibold">{error.message}</p>
      )}
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("Invalid email address")
            .required("Email is Required"),
          password: Yup.string()
            .required("Password is required")
            .min(8, "Password must contain atleast 8 characters"),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const { email, password } = values;
          //console.log(email, "email", password, "password");
          signInAPI(email, password);
          setSubmitting(false);
          resetForm({ email: "", password: "" });
        }}
      >
        {(formik) => {
          errorLength = Object.keys(formik.errors).length;
          return (
            <form onSubmit={formik.handleSubmit}>
              <div className="mt-5 xl:mt-10 mb-5 md:mx-10 xl:mx-0">
                <p className="mb-1 xl:mb-3 text-base xl:text-xl text-primary-blue leading-[27px] font-normal">
                  Email address
                </p>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Email registered email id"
                  autoComplete="email"
                  error={formik.errors.email}
                  showIndividualError={showIndividualError}
                  extraClass={
                    formik.touched.email && formik.errors.email
                      ? "text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-primary-red"
                      : "text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-ternary-blue"
                  }
                  {...formik.getFieldProps("email")}
                />
              </div>

              <div className="mt-5 mb-5 md:mx-10 xl:mx-0">
                <p className="mb-1 xl:mb-3 text-base xl:text-xl text-primary-blue  leading-[27px] font-normal">
                  Password
                </p>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter your password"
                  error={formik.errors.password}
                  showIndividualError={showIndividualError}
                  extraClass={
                    formik.touched.password && formik.errors.password
                      ? "text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-primary-red"
                      : "text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-ternary-blue"
                  }
                  {...formik.getFieldProps("password")}
                />
              </div>

              <div className="md:mt-12 w-full md:w-[80%] xl:w-[350px] flex flex-col space-y-3 xl:space-y-7 md:mx-10 xl:mx-0">
                <Button
                  onClick={() => checkErrors()}
                  innerText="Log in"
                  type="submit"
                  extraClass={`text-center h-[35px] xl:h-[45px] text-sm sm:text-base xl:text-lg text-white font-medium  rounded-full bg-primary-green ${
                    isLoading && "opacity-20"
                  }`}
                  isLoading={isLoading}
                  disabled={isLoading}
                />

                <Button
                  onClick={() =>
                    window.location.replace(
                      `${BACKEND_BASE_URL}/api/oauth/google?fallbackURL=${FALLBACK_URL}`
                    )
                  }
                  Icon={Google}
                  innerText="Sign in with Google"
                  type="button"
                  extraClass="text-center h-[35px] xl:h-[45px] text-sm sm:text-base xl:text-lg text-primary-green font-medium  rounded-full bg-white border border-primary-green"
                />
              </div>
            </form>
          );
        }}
      </Formik>
      <div>
        <p className="mt-3 text-sm xl:text-lg leading-[28px] text-secondary-blue  font-light">
          Don't have an account?&nbsp;
          <span
            onClick={() => navigate("/signup")}
            className="font-bold cursor-pointer hover:underline"
          >
            Create Account
          </span>
        </p>
        <p
          onClick={() => {
            navigate("/summer-bootcamp");
          }}
          className="text-center mb-5 sm:mb-16 text-sm xl:text-lg text-primary-red font-bold cursor-pointer hover:underline"
        >
          Explore Camps
        </p>
      </div>
    </div>
  );
};

export default Signin;
