import React, { useState } from "react";
import Button from "../../../components/Button/Button";
import { BACKEND_BASE_URL } from "../../../helpers/url/url";
import axios from "../../../helpers/axios/axios";
import { useNavigate } from "react-router-dom";

const BookingSummary = ({ selectedCourse }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  console.log(selectedCourse, "selectedCourse");

  const bookClassAPI = async () => {
    setIsLoading(true);
    try {
      const bookClassResponse = await axios.post(
        `${BACKEND_BASE_URL}/api/payment/bookAClass`,
        {
          courseId: selectedCourse?.courseId,
          requestedDateTime: selectedCourse?.requestedDateTime,
          classOriginTimeZone: "PST",
          frequency: "weekly",
          frequencyTimeline: [
            {
              weekDay: 1,
              time: "01:15",
            },
            {
              weekDay: 3,
              time: "19:30",
            },
            {
              weekDay: 5,
              time: "18:30",
            },
          ],
          timezone: "PST",
        }
      );
      console.log(bookClassResponse, "bookClassResponse");
      if (
        bookClassResponse.status === 202 ||
        bookClassResponse?.data?.success
      ) {
        navigate("/student/enroll/success");
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      navigate("/student/enroll/failure");
    }
  };
  return (
    <div>
      <div className="relative flex flex-col items-center lg:items-start lg:flex-row justify-between bg-secondary-blue w-[100%]  rounded-xl px-5 lg:px-10">
        <div className="sm:w-[40%] sm:text-center lg:text-left lg:max-w-md mt-20">
          <div className="text-white text-base sm:text-xl 2xl:text-[32px] font-semibold 2xl:leading-[48px]">
            <p>You’re just one step away from having fun learning with </p>
            <p className="text-primary-yellow">Krafternoon</p>
          </div>
          <img
            src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/Payment/dizzy-online-shopping_1_7TbAsU_XC.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654950055889"
            alt=""
            className="hidden lg:inline-flex absolute -bottom-2 w-[40%] 3xl:w-1/5"
          />
        </div>
        <div className="w-full sm:w-[70%] md:w-[55%] xl:w-[656px] bg-white border boder-primary-pink rounded-lg py-5 px-5 2xl:px-10 my-20">
          <div>
            <div className="mb-2 flex items-center justify-between">
              <h4 className="text-xs sm:text-sm xl:text-base font-medium leading-[24px] text-secondary-blue">
                Pricing plan
              </h4>
              <h4 className="text-xs sm:text-sm xl:text-base font-medium leading-[24px] text-secondary-blue">
                Wallet Balance
              </h4>
            </div>
            <div className="mb-2 flex items-center justify-between">
              <p className="text-xs sm:text-sm xl:text-lg font-medium leading-[24px] text-secondary-blue">
                {selectedCourse?.walletDetails?.paymentMode === "postpaid"
                  ? "Postpaid"
                  : "Prepaid"}
              </p>
              <div className="text-xs sm:text-sm xl:text-lg font-medium leading-[24px] text-secondary-blue">
                {/* {selectedCourse?.paymentMode === "prepaid" && (
                  <strike className="xl:text-lg text-primary-purple font-medium mr-2">
                    $150.00
                  </strike>
                )}
                {selectedCourse?.paymentMode === "postpaid"
                  ? "$15/hr"
                  : `$${selectedCourse?.price - 20}`} */}
                ${selectedCourse?.walletDetails?.credits}
              </div>
            </div>
            {/* {selectedCourse?.paymentMode === "postpaid" && (
              <div className="mb-7 flex items-center justify-between text-base xl:text-lg font-medium leading-[24px] text-secondary-blue">
                <p className="">Credit Limit</p>
                <p>100$</p>
              </div>
            )} */}
          </div>

          <div>
            <div className="mb-2 flex items-center justify-between">
              <h4 className="text-xs sm:text-sm xl:text-base font-medium leading-[24px] text-secondary-blue">
                Skill
              </h4>
              <h4 className="text-xs sm:text-sm xl:text-base font-medium leading-[24px] text-secondary-blue">
                Date
              </h4>
            </div>
            <div className="mb-2 flex items-center justify-between">
              <p className="text-xs sm:text-sm xl:text-lg font-medium leading-[24px] text-secondary-blue">
                {selectedCourse?.courseName
                  ? selectedCourse?.courseName
                  : selectedCourse?.title
                  ? selectedCourse?.title
                  : "Not Found"}
                <span
                  className="text-primary-purple text-sm cursor-pointer hover:text-secondary-blue underline ml-3"
                  onClick={() => navigate("/student/dashboard")}
                >
                  Change
                </span>
              </p>
              <div className="text-xs sm:text-sm xl:text-lg font-medium leading-[24px] text-secondary-blue">
                {/* {selectedCourse?.paymentMode === "prepaid" && (
                  <strike className="xl:text-lg text-primary-purple font-medium mr-2">
                    $150.00
                  </strike>
                )}
                {selectedCourse?.paymentMode === "postpaid"
                  ? "$15/hr"
                  : `$${selectedCourse?.price - 20}`} */}
                {selectedCourse?.date?.toString().slice(0, 10)}{" "}
                {selectedCourse?.time}
              </div>
            </div>
            {/* {selectedCourse?.paymentMode === "postpaid" && (
              <div className="mb-7 flex items-center justify-between text-base xl:text-lg font-medium leading-[24px] text-secondary-blue">
                <p className="">Credit Limit</p>
                <p>100$</p>
              </div>
            )} */}
          </div>

          {/* <div className="bg-primary-pink w-full h-[1px] my-5"></div>
          <div className="mb-3 flex items-center justify-between">
            <p className="text-base xl:text-2xl font-medium leading-[24px] text-secondary-blue">
              TOTAL
            </p>
            <p className="text-base xl:text-2xl font-medium leading-[24px] text-secondary-blue">
              ${selectedCourse?.courseAmount}
            </p>
          </div> */}
          <div className="bg-primary-pink w-full h-[1px] my-5"></div>
          <div className="flex flex-col space-y-3 lg:space-y-0 lg:flex-row md:items-center md:justify-between">
            <div className="mb-5 md:mb-0">
              <p className="my-1 text-base text-secondary-blue font-normal">
                Available Wallet Balance: $
                {selectedCourse?.walletDetails?.credits}
              </p>
              <p className="my-1 text-base text-secondary-blue font-normal">
                Available Credit Limit: $
                {selectedCourse?.walletDetails?.creditLimit}
              </p>
            </div>
            <div className="w-full lg:max-w-max flex flex-col space-y-3 md:space-y-0 md:flex-row items-center md:space-x-3">
              <Button
                isLoading={isLoading}
                disabled={isLoading}
                innerText="Book now!"
                onClick={bookClassAPI}
                type="button"
                extraClass="h-[40px] w-full lg:max-w-max text-xs xl:text-sm text-white font-normal rounded-full bg-primary-green"
              />
            </div>
          </div>
        </div>
        <img
          src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/Payment/dizzy-online-shopping_1_7TbAsU_XC.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654950055889"
          alt=""
          className="sm:w-[60%] lg:hidden"
        />
      </div>
    </div>
  );
};

export default BookingSummary;
