import React from "react";

const Patience = ({ className }) => {
  return (
    <svg
      className={className ? className : "h-5 w-5"}
      viewBox="0 0 69 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.1015 33.4659H37.382C36.518 33.4659 35.8181 32.7637 35.8181 31.897V22.1468C35.8181 21.28 36.518 20.5779 37.382 20.5779C38.2455 20.5779 38.9459 21.2801 38.9459 22.1468V30.3281H47.1015C47.9649 30.3281 48.6653 31.0308 48.6653 31.897C48.6653 32.7638 47.9649 33.4659 47.1015 33.4659ZM37.7944 11.8188C26.7588 11.8188 17.7805 20.8256 17.7805 31.897C17.7805 42.9684 26.7588 51.9752 37.7944 51.9752C48.8299 51.9752 57.8083 42.9684 57.8083 31.897C57.8083 20.8256 48.8305 11.8188 37.7944 11.8188ZM25.9282 76.8772H53.6464V60.3782C53.6464 60.0104 53.7754 59.6543 54.0109 59.3719C60.8415 51.1767 65.6119 39.2894 65.6119 30.4634C65.6119 15.652 52.6518 3.13811 37.3107 3.13811C22.2906 3.13811 10.0711 15.3961 10.0711 30.4634C10.0711 30.7453 10.0772 31.0461 10.0895 31.4112C10.1023 31.7818 9.98346 32.139 9.76356 32.4242L3.43704 43.5994C3.02458 44.3283 3.02904 45.1962 3.44988 45.9207C3.87016 46.6451 4.61975 47.0777 5.45527 47.0777H8.51229C9.28756 47.0777 9.94557 47.647 10.0589 48.4167L11.6692 59.3178C11.6809 59.3982 11.6865 59.4791 11.6859 59.5606C11.6781 60.5541 12.0778 61.4694 12.8117 62.1375C13.5384 62.7989 14.475 63.1098 15.4495 63.0144L24.1382 61.7457C24.5881 61.6798 25.0435 61.8138 25.3868 62.1119C25.73 62.4099 25.9276 62.843 25.9276 63.2985L25.9282 76.8772ZM55.211 80.0151H24.365C23.501 80.0151 22.8012 79.3129 22.8012 78.4462V65.1116L15.8684 66.1241C15.8477 66.1268 15.8271 66.1296 15.807 66.1319C13.9512 66.3272 12.0937 65.7194 10.7111 64.4608C9.361 63.2318 8.5813 61.4904 8.56001 59.6653L7.1641 50.2154H5.45677C3.49538 50.2154 1.73504 49.1995 0.747707 47.4988C-0.239085 45.7982 -0.249702 43.761 0.71924 42.0496L6.95145 31.0402C6.94699 30.8359 6.94475 30.6467 6.94475 30.4631C6.94475 13.6655 20.5674 0 37.3121 0C45.3561 0 53.3652 3.2422 59.2878 8.89516C65.3838 14.7144 68.7411 22.3743 68.7411 30.4636C68.7411 39.8009 63.8405 52.2493 56.7756 60.9424V78.4458C56.7756 79.3126 56.0757 80.0147 55.2117 80.0147L55.211 80.0151Z"
        fill="#FFE8F5"
      />
    </svg>
  );
};

export default Patience;
