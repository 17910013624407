import React from "react";

const Instagram = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 26 25"
      {...rest}
    >
      <path
        fill="#434484"
        d="M18.968 0H7.766A6.907 6.907 0 00.867 6.899v11.202A6.907 6.907 0 007.767 25h11.2c3.805 0 6.9-3.095 6.9-6.9V6.9A6.907 6.907 0 0018.967 0zm4.681 18.1c0 2.582-2.1 4.682-4.681 4.682H7.766c-2.58 0-4.68-2.1-4.68-4.681V6.899c0-2.58 2.1-4.68 4.68-4.68h11.202c2.581 0 4.68 2.1 4.68 4.68v11.202h.001z"
      ></path>
      <path
        fill="#434484"
        d="M13.367 6.058A6.45 6.45 0 006.925 12.5a6.449 6.449 0 006.442 6.442A6.449 6.449 0 0019.81 12.5a6.449 6.449 0 00-6.442-6.442zm0 10.666A4.228 4.228 0 019.143 12.5a4.228 4.228 0 014.224-4.224 4.228 4.228 0 014.224 4.224 4.228 4.228 0 01-4.224 4.224zM20.08 4.178c-.427 0-.847.173-1.149.476a1.635 1.635 0 00-.477 1.15c0 .428.174.848.477 1.15a1.64 1.64 0 001.15.477c.428 0 .846-.175 1.15-.476.303-.303.476-.723.476-1.15 0-.43-.173-.85-.476-1.151a1.634 1.634 0 00-1.15-.476z"
      ></path>
    </svg>
  );
};

export default Instagram;
