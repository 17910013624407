import React from "react";

const CircularTickBlue = ({ className, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-4 w-4"}
      fill="none"
      viewBox="0 0 15 15"
    >
      <path
        fill={fill ? fill : "#434484"}
        fillRule="evenodd"
        d="M7.44 0a7.439 7.439 0 110 14.878A7.439 7.439 0 017.44 0zM3.755 7.536a.741.741 0 011.1-.994l1.853 2.047 4.004-4.136a.742.742 0 111.064 1.035l-4.554 4.704a.74.74 0 01-1.08-.02L3.754 7.535z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default CircularTickBlue;
