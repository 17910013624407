import React from "react";

const Edit = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 26 26"
      {...rest}
    >
      <path
        fill="#434484"
        d="M22.69 0a.625.625 0 00-.438.184l-.686.687-1.547 1.546.434.434 2.135 2.136.434.433 1.547-1.546.686-.686a.616.616 0 00.006-.874L23.126.18A.607.607 0 0022.69 0zm-3.43 3.176L9.05 13.386l3.003 3.004 10.21-10.21-3.002-3.004zM4.45 4.715A4.439 4.439 0 000 9.164V20.99a4.44 4.44 0 004.449 4.448h11.826a4.44 4.44 0 004.45-4.448v-9.205l-2.345 2.345v6.584a2.375 2.375 0 01-2.38 2.38H4.725a2.375 2.375 0 01-2.38-2.38V9.439a2.375 2.375 0 012.38-2.38h6.584l2.344-2.344H4.45zm3.883 9.39l-.15.539-.67 2.41-.187.672c-.083.299.09.472.389.39l.672-.188 2.41-.67.539-.15-2.89-2.889-.113-.114z"
      ></path>
    </svg>
  );
};

export default Edit;
