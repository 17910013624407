import React from "react";

const WeTeachCreativeAnalyticalSkills = ({
  firstPart,
  secondPart,
  thirdPart,
  descriptionOne,
  descriptionTwo,
  descriptionThree,
}) => {
  return (
    <div className="flex flex-col lg:flex-row items-center lg:justify-between m-5 lg:mx-5 xl:mx-20 lg:mb-40">
      <div className="lg:w-1/2 order-2 lg:order-none">
        <img
          src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/tr:w-2236/brain_ydZvGrC2I.png?ik-sdk-version=javascript-1.4.3&updatedAt=1651652976254"
          alt=""
          className="w-[350px] h-[300px] sm:w-[400px] sm:h-[400px] xl:w-[559px] xl:h-[559px]"
        />
      </div>
      <div className="sm:w-[80%] lg:w-1/2 order-1 lg:order-none">
        <div className="text-xl xl:text-3xl text-center lg:text-left font-[750] xl:leading-10 mb-7 lg:mb-2 xl:mb-4">
          <p className="text-secondary-blue">
            {firstPart ? firstPart : "We build"}
            <span className="mx-1 lg:mx-1 xl:mx-3 text-primary-green">
              {secondPart ? secondPart : `"CREATIVE & ANALYTICAL"`}
            </span>
            {thirdPart ? thirdPart : "approach"}
          </p>
        </div>
        {descriptionOne && (
          <p className="text-base xl:text-xl text-secondary-blue font-normal  mb-3">
            {descriptionOne}
          </p>
        )}
        {descriptionTwo && (
          <p className="hidden lg:inline-flex text-base xl:text-xl text-secondary-blue font-normal  mb-3">
            {descriptionTwo}
          </p>
        )}
        {descriptionThree && (
          <p className="hidden lg:inline-flex text-base xl:text-xl text-secondary-blue font-normal ">
            {descriptionThree}
          </p>
        )}
      </div>
      <div className="mb-20 lg:mb-0 inline-flex flex-col space-y-3 lg:hidden sm:w-[80%] order-3 lg:order-none">
        {descriptionTwo && (
          <p className="text-base xl:text-2xl text-secondary-blue font-normal ">
            {descriptionTwo}
          </p>
        )}
        {descriptionThree && (
          <p className="text-base xl:text-2xl text-secondary-blue font-normal ">
            {descriptionThree}
          </p>
        )}
      </div>
    </div>
  );
};

export default WeTeachCreativeAnalyticalSkills;
