import axios from "axios";
import React, { useEffect, useState } from "react";
import CircularCross from "../../../assets/SVG/circular-cross";
import Button from "../../../components/Button/Button";
import Dropdown from "../../../components/Dropdown/Dropdown";
import SearchableDropdown from "../../../components/SearchableDropdown/SearchableDropdown";
import { toastNotification } from "../../../helpers/functions/toast-notification/toast-notification";
import { BACKEND_BASE_URL } from "../../../helpers/url/url";

const AddBatch = () => {
  const [batchDetails, setBatchDetails] = useState({
    batchName: "",
    studentsList: [],
  });
  const [assignTeacherData, setAssignTeacherData] = useState({
    assign: "single",
    batchName: "",
    teacherEmail: "",
    studentEmail: "",
    pricePerHour: "",
    courseId: "",
  });
  const [isLoading, setIsLoading] = useState({ id: "", isLoading: false });
  const [allStudents, setAllStudents] = useState([]);
  const [allTeachers, setAllTeachers] = useState([]);
  const [allBatches, setAllBatches] = useState([]);
  const [allCourses, setAllCourses] = useState([]);

  useEffect(() => {
    const getAllStudentsAPI = async () => {
      try {
        const allStudentsResponse = await axios.get(
          `${BACKEND_BASE_URL}/admin/adminRoles/students`
        );
        let ALL_STUDENTS = [];
        allStudentsResponse?.data?.students.map((student) =>
          ALL_STUDENTS.push({
            id: student._id,
            name: student.name,
            email: student.email,
          })
        );
        setAllStudents([...ALL_STUDENTS]);
      } catch (err) {
        console.log(err);
      }
    };
    getAllStudentsAPI();
  }, []);

  useEffect(() => {
    const getAllBatchesAPI = async () => {
      try {
        const allBatchesResponse = await axios.get(
          `${BACKEND_BASE_URL}/admin/adminRoles/getBatches`
        );
        //console.log(allBatchesResponse, "allBatchesResponse");
        let ALL_BATCHES = [];
        if (allBatchesResponse.status === 200) {
          allBatchesResponse?.data?.batches.map((batch) =>
            ALL_BATCHES.push({ id: batch._id, name: batch.batchName })
          );
          setAllBatches([...ALL_BATCHES]);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getAllBatchesAPI();
  }, []);

  useEffect(() => {
    const getAllTeachersAPI = async () => {
      try {
        const allTeachersResponse = await axios.get(
          `${BACKEND_BASE_URL}/admin/adminRoles/teachers`
        );
        let ALL_TEACHERS = [];
        allTeachersResponse?.data?.teachers.map((teacher) =>
          ALL_TEACHERS.push({
            id: teacher._id,
            name: teacher.name,
            email: teacher.email,
          })
        );
        setAllTeachers([...ALL_TEACHERS]);
      } catch (err) {
        console.log(err);
      }
    };
    getAllTeachersAPI();
  }, []);

  useEffect(() => {
    const getAllCoursesAPI = async () => {
      try {
        const allCoursesResponse = await axios.get(
          `${BACKEND_BASE_URL}/admin/adminRoles/getCourses`
        );
        //console.log(allCoursesResponse.data.courses, "allCoursesResponse");
        let ALL_COURSES = [];
        allCoursesResponse?.data?.courses.map((course) =>
          ALL_COURSES.push({
            id: course._id,
            name: course.courseName,
          })
        );
        setAllCourses([...ALL_COURSES]);
      } catch (err) {
        console.log(err);
      }
    };
    getAllCoursesAPI();
  }, []);

  const createBatchAPI = async () => {
    let selectedStudents = [];
    batchDetails?.studentsList?.map((student) =>
      selectedStudents.push({
        name: student.name,
        email: student.email,
      })
    );

    setIsLoading({ id: "create-batch", isLoading: true });
    try {
      const creatBatchResponse = await axios.post(
        `${BACKEND_BASE_URL}/admin/adminRoles/makeBatch`,
        { batchName: batchDetails.batchName, studentsArr: selectedStudents }
      );
      //console.log(creatBatchResponse, "creatBatchResponse");
      if (creatBatchResponse.status === 200) {
        toastNotification("success", "Batch created successfully!");
        setIsLoading({ id: null, isLoading: false });
        setBatchDetails({ batchName: "", studentsList: [] });
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
      toastNotification("error", "Batch cannot be created. Please try again!");
      setIsLoading({ id: null, isLoading: false });
      setBatchDetails({ batchName: "", studentsList: [] });
      window.location.reload();
    }
  };

  const assignTeacherAPI = async () => {
    setIsLoading({ id: "assign-teacher", isLoading: true });
    if (assignTeacherData.assign === "batch") {
      try {
        const assignTeacherResponse = await axios.post(
          `${BACKEND_BASE_URL}/admin/teacherActions/assignTeacherToBatch`,
          {
            batchName: assignTeacherData.batchName,
            teacherEmail: assignTeacherData.teacherEmail,
            courseId: assignTeacherData.courseId,
            pricePerHour: assignTeacherData.pricePerHour,
          }
        );
        //console.log(assignTeacherResponse, "assignTeacherResponse");
        if (assignTeacherResponse.status === 200) {
          toastNotification(
            "success",
            "Teacher has been assigned successfully to batch!"
          );
          setIsLoading({ id: null, isLoading: false });
          window.location.reload();
          setAssignTeacherData({
            assign: "single",
            batchName: "",
            teacherEmail: "",
            studentEmail: "",
            pricePerHour: "",
            courseId: "",
          });
        }
      } catch (err) {
        //console.log(err);
        toastNotification(
          "error",
          "Assigning teacher to batch failed. Please try again!"
        );
        setIsLoading({ id: null, isLoading: false });
      }
    } else if (assignTeacherData.assign === "single") {
      try {
        const assignTeacherResponse = await axios.post(
          `${BACKEND_BASE_URL}/admin/teacherActions/assignTeacherSingle`,
          {
            studentEmail: assignTeacherData.studentEmail,
            teacherEmail: assignTeacherData.teacherEmail,
            courseId: assignTeacherData.courseId,
            pricePerHour: assignTeacherData.pricePerHour,
          }
        );
        //console.log(assignTeacherResponse, "assignTeacherResponse");
        if (assignTeacherResponse.status === 200) {
          toastNotification(
            "success",
            "Teacher has been assigned successfully to the student!"
          );
          setIsLoading({ id: null, isLoading: false });
          window.location.reload();
          setAssignTeacherData({
            assign: "single",
            batchName: "",
            teacherEmail: "",
            studentEmail: "",
            pricePerHour: "",
            courseId: "",
          });
        }
      } catch (err) {
        //console.log(err);
        toastNotification(
          "error",
          "Assigning teacher to student failed. Please try again!"
        );
        setIsLoading({ id: null, isLoading: false });
      }
    }
  };

  const deleteSelectedStudentHandler = (id) => {
    const remainingStudents = batchDetails?.studentsList.filter(
      (student) => student.id !== id
    );
    setBatchDetails({ ...batchDetails, studentsList: remainingStudents });
  };

  return (
    <div className="flex justify-between w-full pr-20">
      <div>
        <div className="bg-ternary-blue-ltr mt-10 rounded-lg pb-20">
          <h3 className="text-secondary-blue text-2xl font-extrabold p-5">
            Add Batch
          </h3>
          <div className="border-t border-secondary-blue-lt p-5">
            <div className="flex items-center space-x-5">
              <p className="text-lg font-semibold text-primary-blue">
                Batch Name
              </p>
              <input
                type="text"
                placeholder="Enter Batch Name"
                className="bg-transparent w-[50%] border border-secondary-blue py-1 px-2 text-lg text-secondary-blue placeholder:text-secondary-blue-lt focus:outline-none"
                value={batchDetails.batchName}
                onChange={(e) =>
                  setBatchDetails({
                    ...batchDetails,
                    batchName: e.target.value,
                  })
                }
              />
            </div>
            <div className="flex items-center space-x-5 my-10">
              <p className="text-lg font-semibold text-primary-blue">
                Student's Name
              </p>
              <SearchableDropdown
                menuList={[...allStudents]}
                searchbarStyle="w-[300px] h-[40px] border border-primary-blue flex items-center justify-between px-3 text-lg text-primary-blue font-normal "
                searchMenuStyle="w-[300px] bg-white absolute z-10 shadow-lg rounded-md"
                searchHandler={(option) =>
                  setBatchDetails({
                    ...batchDetails,
                    studentsList: [...batchDetails.studentsList, option],
                  })
                }
                placeholder="Enter Student's Name"
              />
            </div>
            {batchDetails?.studentsList.length > 0 && (
              <div className="border border-secondary-blue rounded-lg my-10 p-3 flex justify-between">
                <div className="flex flex-wrap items-center">
                  {batchDetails?.studentsList.map((student) => (
                    <div
                      key={student.id}
                      className="mr-5 flex items-center space-x-2 max-w-max bg-ternary-blue-lt p-2 rounded mb-5"
                    >
                      <p className="text-black text-sm">{student.name}</p>
                      <CircularCross
                        onClick={() => deleteSelectedStudentHandler(student.id)}
                        className="w-[13px] h-[13px] cursor-pointer"
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            <Button
              onClick={createBatchAPI}
              isLoading={isLoading.id === "create-batch" && isLoading.isLoading}
              disabled={isLoading.id !== null && isLoading.isLoading}
              innerText="Create Batch"
              type="button"
              extraClass="py-1 px-5 max-w-max rounded-full bg-primary-green"
              innerTextStyle="text-xs md:text-base xl:text-lg font-normal text-white"
            />
          </div>
        </div>
      </div>

      <div>
        <div className="bg-ternary-blue-ltr mt-10 rounded-lg pb-20">
          <h3 className="text-secondary-blue text-2xl font-extrabold p-5">
            Assign Teacher
          </h3>
          <div className="border-t border-secondary-blue-lt p-5">
            <div className="flex items-center space-x-5 mb-10">
              <p className="text-xl text-primary-blue font-normal">Assign</p>
              <Dropdown
                menuList={[
                  { id: "single", name: "Single" },
                  { id: "batch", name: "Batch" },
                ]}
                defaultValue={{ id: "single", name: "Single" }}
                dropdownStyle="w-[142px] h-[40px] border border-primary-blue flex items-center justify-around text-lg text-primary-blue font-normal"
                arrowStyle="w-[20px] h-[15px] cursor-pointer"
                dropdownMenuStyle="w-[142px] bg-white"
                dropdownHandler={(option) =>
                  setAssignTeacherData({
                    ...assignTeacherData,
                    assign: option.id,
                  })
                }
              />
            </div>
            {assignTeacherData.assign === "batch" && (
              <div className="flex items-center space-x-5 mb-10">
                <p className="text-lg font-semibold text-primary-blue">
                  Batch Name
                </p>
                <SearchableDropdown
                  menuList={[...allBatches]}
                  searchbarStyle="w-[300px] h-[40px] border border-primary-blue flex items-center justify-between px-3 text-lg text-primary-blue font-normal "
                  searchMenuStyle="w-[300px] bg-white absolute z-10 shadow-lg rounded-md"
                  searchHandler={(option) =>
                    setAssignTeacherData({
                      ...assignTeacherData,
                      batchName: option.name,
                    })
                  }
                  placeholder="Enter Batch Name"
                />
              </div>
            )}
            <div className="flex items-center space-x-5">
              <p className="text-lg text-primary-blue font-normal">
                Course Name
              </p>
              <SearchableDropdown
                menuList={[...allCourses]}
                searchbarStyle="w-[300px] h-[40px] border border-primary-blue flex items-center justify-between px-3 text-lg text-primary-blue font-normal "
                searchMenuStyle="w-[300px] bg-white absolute z-10 shadow-lg rounded-md"
                searchHandler={(option) =>
                  setAssignTeacherData({
                    ...assignTeacherData,
                    courseId: option.id,
                  })
                }
                placeholder="Enter Course Name"
              />
            </div>

            {assignTeacherData.assign === "single" && (
              <div className="flex items-center space-x-5 my-10">
                <p className="text-lg text-primary-blue font-normal">
                  Select Student
                </p>
                <SearchableDropdown
                  menuList={[...allStudents]}
                  searchbarStyle="w-[300px] h-[40px] border border-primary-blue flex items-center justify-between px-3 text-lg text-primary-blue font-normal "
                  searchMenuStyle="w-[300px] bg-white absolute z-10 shadow-lg rounded-md"
                  searchHandler={(option) =>
                    setAssignTeacherData({
                      ...assignTeacherData,
                      studentEmail: option.email,
                    })
                  }
                  placeholder="Enter Student's Name"
                />
              </div>
            )}

            <div className="flex items-center space-x-5 my-10">
              <p className="text-lg text-primary-blue font-normal">
                Assign Teacher
              </p>
              <SearchableDropdown
                menuList={[...allTeachers]}
                searchbarStyle="w-[300px] h-[40px] border border-primary-blue flex items-center justify-between px-3 text-lg text-primary-blue font-normal "
                searchMenuStyle="w-[300px] bg-white absolute z-10 shadow-lg rounded-md"
                searchHandler={(option) =>
                  setAssignTeacherData({
                    ...assignTeacherData,
                    teacherEmail: option.email,
                  })
                }
                placeholder="Enter Teacher's Name"
              />
            </div>
            <div className="flex items-center space-x-5 my-10">
              <p className="text-lg text-primary-blue font-normal">Price</p>
              <input
                type="text"
                placeholder="$00:00"
                className="w-[100px] text-secondary-blue focus:outline-none border-b border-primary-blue bg-transparent text-center placeholder:text-secondary-blue-lt text-lg"
                value={assignTeacherData.pricePerHour}
                onChange={(e) =>
                  setAssignTeacherData({
                    ...assignTeacherData,
                    pricePerHour: e.target.value,
                  })
                }
              />
            </div>
            <Button
              onClick={assignTeacherAPI}
              isLoading={
                isLoading.id === "assign-teacher" && isLoading.isLoading
              }
              disabled={isLoading.id !== null && isLoading.isLoading}
              innerText="Assign Teacher"
              type="button"
              extraClass="py-1 px-5 max-w-max rounded-full bg-primary-green"
              innerTextStyle="text-xs md:text-base xl:text-lg font-normal text-white"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBatch;
