import React from "react";
import { useParams } from "react-router-dom";
import GraphView from "../../assets/SVG/graph-view";
import LiveClasses from "../../assets/SVG/live-classes";
import PersonalizedCourses from "../../assets/SVG/personalized-courses";
import MostInDemandFacilities from "../../components/MostInDemandFacilities/MostInDemandFacilities";
import OurInstuctors from "../../components/OurInstructors/OurInstuctors";
import WeTeachCreativeAnalyticalSkills from "../../components/WeTeachCreativeAnalyticalSkills/WeTeachCreativeAnalyticalSkills";
import Patience from "../../assets/SVG/patience";
import TransformCard from "../../components/Card/TransformCard/TransformCard";
import DesktopPaint from "../../assets/SVG/desktop-paint";
import Business from "../../assets/SVG/business";
import PeopleLife from "../../assets/SVG/people-life";
import InstructorPageAnimation from "../../components/InstructorPageAnimation/InstructorPageAnimation";
import HorseTarget from "../../assets/images/horse-target.png";
import LaptopDesign from "../../assets/images/laptop-design.png";
import VideoEditing from "../../assets/images/video-editing.png";
import Coding from "../../assets/images/coding.png";
import ComingSoon from "../ComingSoon/ComingSoon";

const Instructor = () => {
  const { skillType } = useParams();

  return (
    <div>
      {skillType === "3D-modelling" && <ComingSoon />}
      {skillType === "chess" && (
        <OurInstuctors
          topBannerHeading='"Our champs look forward to each class & indulge into the world of Creativity & Analytical thinking"'
          topBannerVideo="https://ik.imagekit.io/b3i1ietvz/Krafternoon/ExploreSkills/chess_video_9wvPVtwni.mp4?ik-sdk-version=javascript-1.4.3&updatedAt=1654162542734"
          skillHeading="Chess Futuristic"
          instructorImage="https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/hero_image_qZ404pE8u.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652979477740"
          instructorName="Devarsh Srivastava"
          instructorDescriptionHeading='“Chess is a game which will not only make you mentally strong but also help one overcome real-life problems"'
          instructorDescription="When I was barely 5 year old, I got exposure of game by my grandfather and mother. With time I developed an inclination towards the game and enrolled myself to play professionally. The game has definitely impacted my personality, shaping me to be creative & empowering analytical approach. This made me think about other kids out there and planned to give them a platform- Krafternoon"
          WeTeachCreativeAnalyticalSkills={
            <WeTeachCreativeAnalyticalSkills
              firstPart="We teach"
              secondPart="Chess for Creativity & Analytical mind"
              thirdPart="Skills"
              descriptionOne="The game of chess is intensely fascinating game in the history of world which engages people of all age groups and culture."
              descriptionTwo="Mastering higher cognitive aspect of an individual, it also helps one to build up personality. Higher concentration level, patience, perseverance, & polished decision making skill. We at Krafternoon believe to shape creative & analytical power of an individual via Chess in simple & fun way by our futuristic."
            />
          }
          ProductiveOutcomes={
            <div className="relative">
              <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-2 mx-auto text-center text-base md:text-lg xl:text-2xl font-[750] bg-white w-[65%] sm:w-[50%] xl:w-[40%] 2xl:w-[35%]">
                <div className="sm:flex items-center justify-center space-x-2">
                  <p className="text-primary-green">Productive Outcomes</p>
                  <p className="text-secondary-blue">of Learning</p>
                  <p className="text-primary-green">Chess</p>
                </div>
              </div>
              <MostInDemandFacilities
                menuList={[
                  {
                    id: "analytical-thinking",
                    title: "Analytical Thinking",
                    Icon: PersonalizedCourses,
                  },
                  {
                    id: "working-skills",
                    title: "Working Skills",
                    Icon: LiveClasses,
                  },
                  {
                    id: "design-thinking-approach",
                    title: "Expose to Design Thinking approach",
                    Icon: GraphView,
                  },
                  {
                    id: "patience-perseverance",
                    title: "Patience & Perseverance",
                    Icon: Patience,
                  },
                ]}
              />
            </div>
          }
          TransformCard={
            <TransformCard
              mainHeading="Exposure to learn with current industry experts"
              subHeading="Kids around the world are learning new skills and excelling in their field. Take a step towards an innovative future."
              buttonInnerText="Experience it for FREE"
            />
          }
          InstructorPageAnimation={
            <InstructorPageAnimation
              mainHeading="Krafternoon targets"
              mainImage={{
                imageUrl: HorseTarget,
                className: "w-[210px] h-[194px] absolute -bottom-10 -left-12",
              }}
              firstOption={{
                title: "Puzzle rush games, blindfold puzzle",
                imageUrl:
                  "https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/68747470733a2f2f692e696d6775722e636f6d2f6543656f38477a2e676966__1__Ykze5uvet.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1652992813913",
              }}
              secondOption={{
                title: "Personal Feedback",
                imageUrl:
                  "https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/74pZ__1__2ITUixHez.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1652991376929",
              }}
              thirdOption={{
                title: "Creativity and Analytical Sessions",
                imageUrl:
                  "https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/QA__1__PwDcbvatO.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1652991373661",
              }}
              fourthOption={{
                title: "Take away from home",
                imageUrl:
                  "https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/200__1__TptWq9L3Ud.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1652991375416",
              }}
              fifthOption={{
                title: "Live interaction sessions with futuristic",
                imageUrl:
                  "https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/200__1___1__thME9ZC5ya.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1652991376114",
              }}
            />
          }
        />
      )}

      {skillType === "web-design" && (
        <OurInstuctors
          topBannerHeading='"Creativity is the root of innovation. We at Krafternoon leverage potential of lil champs to design innovative solutions for future"'
          topBannerVideo="https://ik.imagekit.io/b3i1ietvz/Krafternoon/ExploreSkills/GD_video_8ptRyx282.mp4?ik-sdk-version=javascript-1.4.3&updatedAt=1654162541861"
          skillHeading=" UI/UX Futuristic"
          instructorImage="https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/IMG_20210629_133605_383_2__1__Apy71QZAq.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652979476642"
          instructorName="Ashwita Midivelli"
          instructorDescriptionHeading='"I enjoy the whole process of designing creative yet humane solutions which smoothen the life of people"'
          instructorDescription="For me designing came naturally as the seeds of creativity was sown early in my life. Inclining towards my passion, I took it towards my professional front and worked towards designing a human-centric aspect. With Krafternoon I want to give opportunity to lil ones to explore creative zone"
          WeTeachCreativeAnalyticalSkills={
            <WeTeachCreativeAnalyticalSkills
              firstPart="We teach"
              secondPart="UI/UX for Creativity & Analytical mind"
              thirdPart="Skills"
              descriptionOne="The world of designing is not new. It's only the change of perspective in observing it. Right from the physical world to biological right to to the human civilization to present era is all about designing."
              descriptionTwo="Mastering designing aspect helps one to build personality. As one increase power of creativity, innovation, concentration level, & polished communication skill. We at Krafternoon believe to shape creative & analytical power of an individual via UI/UX in simple & fun way by our futuristic."
            />
          }
          ProductiveOutcomes={
            <div className="relative">
              <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-2 mx-auto text-center text-base md:text-lg xl:text-2xl font-[750] bg-white w-[65%] sm:w-[60%] lg:w-[45%] 2xl:w-[40%]">
                <div className="sm:flex items-center justify-center space-x-2">
                  <p className="text-primary-green">Productive Outcomes</p>
                  <p className="text-secondary-blue">of Learning</p>
                  <p className="text-primary-green">UI/UX Design</p>
                </div>
              </div>
              <MostInDemandFacilities
                menuList={[
                  {
                    id: "better-designer",
                    title: "An opportunity to become a better designer",
                    Icon: DesktopPaint,
                  },
                  {
                    id: "people-lives-better",
                    title: "Make people's lives better",
                    Icon: PeopleLife,
                  },
                  {
                    id: "better-business",
                    title: "Good design makes better business",
                    Icon: Business,
                  },
                  {
                    id: "logice-creativity",
                    title: "Combination of Logic & Creativity",
                    Icon: Patience,
                  },
                ]}
              />
            </div>
          }
          TransformCard={
            <TransformCard
              mainHeading="Exposure to learn with current industry experts"
              subHeading="Kids around the world are learning new skills and excelling in their field. Take a step towards an innovative future."
              buttonInnerText="Experience it for FREE"
            />
          }
          InstructorPageAnimation={
            <InstructorPageAnimation
              mainHeading="What can you expect in the class?"
              mainImage={{
                imageUrl: LaptopDesign,
                className: "w-[230px] h-[194px] absolute -bottom-10 -left-12",
              }}
              firstOption={{
                title: "Design Resources, Games",
                imageUrl:
                  "https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/images_qMCxMZejv.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652992813643",
              }}
              secondOption={{
                title: "Personal Feedback",
                imageUrl:
                  "https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/74pZ__1__2ITUixHez.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1652991376929",
              }}
              thirdOption={{
                title: "Portfolio Creation",
                imageUrl:
                  "https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/72c0b2_48ae3a329f1d4ba7b1aa5daa81fd6a85_mv2_a3qExQIz6.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1652991378878",
              }}
              fourthOption={{
                title: "Capstone Projects",
                imageUrl:
                  "https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/df392fb90619818047bf4f09e0adbc36_LU0DT4_Rn.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1652991373881",
              }}
              fifthOption={{
                title: "Weekly Design Tasks",
                imageUrl:
                  "https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/91ff38_403c2b2b8ce04bc1bd0f616206c2e2f3_mv2_Eu7HS2Ulp.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1652991376238",
              }}
            />
          }
        />
      )}

      {(skillType === "graphic-design" || skillType === "video-editing") && (
        <OurInstuctors
          topBannerHeading='"It’s through mistakes that you actually can grow. You have to get bad in order to get good."'
          topBannerVideo="https://ik.imagekit.io/b3i1ietvz/Krafternoon/ExploreSkills/VE_video_b9D7RwUwk.mp4?ik-sdk-version=javascript-1.4.3&updatedAt=1654162547714"
          skillHeading="Graphic design & Video Editing Futuristic"
          instructorImage="https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/bhavik_jbliuib3a.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654151690717"
          instructorName="Bhavik Telisara"
          instructorDescriptionHeading='“I get fascinated by the creative process and how a creative person can get in the domain of Filmmaking and Graphic Designing."'
          instructorDescription="I am a filmmaker & a Graphic designer and have worked with various companies creating advertisements, promotional videos, digital posters, mascots and logos. I have also led a team of 20 people and created videos for fests for 2 years and captured some great interviews. I started learning film making and Graphic designing 5 years ago and have been continuing it till date. Looking forward to make even more quality content and also teaching students the things I learnt from my experience over the years."
          WeTeachCreativeAnalyticalSkills={
            <WeTeachCreativeAnalyticalSkills
              firstPart="We teach"
              secondPart="Graphic Design & Video Editing for Creativity & Analytical mind"
              thirdPart="Skills"
              descriptionOne="The world of designing is not new. It's only the change of perspective in observing it. Right from the physical world to biological right to to the human civilization to present era is all about designing."
              descriptionTwo="Mastering designing aspect helps one to build personality. As one increase power of creativity, innovation, concentration level, & polished communication skill. We at Krafternoon believe to shape creative & analytical power of an individual via UI/UX in simple & fun way by our futuristic."
            />
          }
          ProductiveOutcomes={
            <div className="relative">
              <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-2 mx-auto text-center text-base md:text-lg xl:text-2xl font-[750] bg-white w-[65%] sm:w-[76%] lg:w-[60%] 2xl:w-[50%]">
                <div className="sm:flex items-center justify-center space-x-2">
                  <p className="text-primary-green">Productive Outcomes</p>
                  <p className="text-secondary-blue">of Learning</p>
                  <p className="text-primary-green">
                    Graphic Design & Video Editing
                  </p>
                </div>
              </div>
              <MostInDemandFacilities
                menuList={[
                  {
                    id: "better-designer",
                    title: "An opportunity to become a better designer",
                    Icon: DesktopPaint,
                  },
                  {
                    id: "people-lives-better",
                    title: "Make people's lives better",
                    Icon: PeopleLife,
                  },
                  {
                    id: "better-business",
                    title: "Good design makes better business",
                    Icon: Business,
                  },
                  {
                    id: "logice-creativity",
                    title: "Combination of Logic & Creativity",
                    Icon: Patience,
                  },
                ]}
              />
            </div>
          }
          TransformCard={
            <TransformCard
              mainHeading="Exposure to learn with current industry experts"
              subHeading="Kids around the world are learning new skills and excelling in their field. Take a step towards an innovative future."
              buttonInnerText="Experience it for FREE"
            />
          }
          InstructorPageAnimation={
            <InstructorPageAnimation
              mainHeading="What can you expect in the class?"
              mainImage={{
                imageUrl: VideoEditing,
                className: "w-[230px] h-[194px] absolute -bottom-10 -left-12",
              }}
              firstOption={{
                title: "Look into Commercial Projects",
                imageUrl:
                  "https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/093f1861fc92b3a0ba497ece177b27e6_LdKN9RXBc.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1654152856613",
              }}
              secondOption={{
                title: "Personal Feedback",
                imageUrl:
                  "https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/74pZ__1__2ITUixHez.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1652991376929",
              }}
              thirdOption={{
                title: "Industry standard videos",
                imageUrl:
                  "https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/433d83f7e481f35245f8c6bb7c7591d8_P5F04Xdyj.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1654152856162",
              }}
              fourthOption={{
                title: "Capstone Projects",
                imageUrl:
                  "https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/df392fb90619818047bf4f09e0adbc36_LU0DT4_Rn.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1652991373881",
              }}
              fifthOption={{
                title: "Weekly Design Tasks",
                imageUrl:
                  "https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/72c0b2_48ae3a329f1d4ba7b1aa5daa81fd6a85_mv2_a3qExQIz6.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1652991378878",
              }}
            />
          }
        />
      )}

      {skillType === "coding" && (
        <OurInstuctors
          topBannerHeading='"Everyone should know how to program a computer, because it teaches you how to think."'
          topBannerVideo="https://ik.imagekit.io/b3i1ietvz/Krafternoon/ExploreSkills/coding_video_zDtosW2-c.mp4?ik-sdk-version=javascript-1.4.3&updatedAt=1654162552767"
          skillHeading="Developer Futuristic"
          instructorImage="https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/final_wXason2i7.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1654156757262"
          instructorName="Aditya Agarwal"
          instructorDescriptionHeading={`"I enjoy the process of solving complex problems through programming. Programming allows us to make a positive difference in people's live"`}
          instructorDescription="I started coding when I was 14 and since then have constantly tried to expand my knowledge in this domain. I develop powerful and clean web applications. I have collaborated with freelancers, worked for start-ups, and developed several personal projects."
          WeTeachCreativeAnalyticalSkills={
            <WeTeachCreativeAnalyticalSkills
              firstPart="We teach"
              secondPart="Coding for Creativity & Analytical mind"
              thirdPart="Skills"
              descriptionOne="Coding uses so much creativity. When we code we can build our own computer games, animation movies or interactive digital artwork."
              descriptionTwo="Coding is one of the most in demand skill currently. Brilliant programmers have an opportunity to work in big MNCs link Google, Apple, Amazon, etc. This involves creating our own designs, including graphics, animations, storytelling, and building our own imaginary worlds and characters."
            />
          }
          ProductiveOutcomes={
            <div className="relative">
              <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-2 mx-auto text-center text-base md:text-lg xl:text-2xl font-[750] bg-white w-[65%] sm:w-[70%] md:w-[70%] lg:w-[55%] 2xl:w-[45%]">
                <div className="sm:flex items-center justify-center space-x-2">
                  <p className="text-primary-green">Productive Outcomes</p>
                  <p className="text-secondary-blue">of Learning</p>
                  <p className="text-primary-green">Coding & Development</p>
                </div>
              </div>
              <MostInDemandFacilities
                menuList={[
                  {
                    id: "better-designer",
                    title: "An opportunity to become a better designer",
                    Icon: DesktopPaint,
                  },
                  {
                    id: "people-lives-better",
                    title: "Make people's lives better",
                    Icon: PeopleLife,
                  },
                  {
                    id: "better-business",
                    title: "Good design makes better business",
                    Icon: Business,
                  },
                  {
                    id: "logice-creativity",
                    title: "Combination of Logic & Creativity",
                    Icon: Patience,
                  },
                ]}
              />
            </div>
          }
          TransformCard={
            <TransformCard
              mainHeading="Exposure to learn with current industry experts"
              subHeading="Kids around the world are learning new skills and excelling in their field. Take a step towards an innovative future."
              buttonInnerText="Experience it for FREE"
            />
          }
          InstructorPageAnimation={
            <InstructorPageAnimation
              mainHeading="What can you expect in the class?"
              mainImage={{
                imageUrl: Coding,
                className: "w-[230px] h-[194px] absolute -bottom-10 -left-12",
              }}
              firstOption={{
                title: "Challenging Problems",
                imageUrl:
                  "https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/coding_KoWErHJax.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1654152856190",
              }}
              secondOption={{
                title: "Personal Feedback",
                imageUrl:
                  "https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/74pZ__1__2ITUixHez.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1652991376929",
              }}
              thirdOption={{
                title: "End Project",
                imageUrl:
                  "https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/72c0b2_48ae3a329f1d4ba7b1aa5daa81fd6a85_mv2_a3qExQIz6.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1652991378878",
              }}
              fourthOption={{
                title: "Capstone Projects",
                imageUrl:
                  "https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/df392fb90619818047bf4f09e0adbc36_LU0DT4_Rn.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1652991373881",
              }}
              fifthOption={{
                title: "Weekly Coding Tasks",
                imageUrl:
                  "https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/UMSgb_ebiJRBsMV.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1654152855623",
              }}
            />
          }
        />
      )}
    </div>
  );
};

export default Instructor;
