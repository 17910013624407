import React, { useEffect, useState } from "react";
import InvertedCommas from "../../assets/SVG/inverted-commas";
import Paintbrush from "../../assets/SVG/paintbrush";
import TransformCard from "../../components/Card/TransformCard/TransformCard";
import TaskTracker from "../../components/TaskTracker/TaskTracker";
import BootcampSignIn from "./components/BootcampSignIn";
import { useNavigate, useParams } from "react-router-dom";
import BootcampSignUp from "./components/BootcampSignUp";
import BootcampPayment from "./components/BootcampPayment";
import BootcampBatchDetails from "./components/BootcampBatchDetails";
import BootcampVerifyOtp from "./components/BootcampVerifyOtp";
import { useSelector } from "react-redux";

const BootcampRegister = () => {
  const auth = useSelector((state) => state.auth);
  const bootcamp = useSelector((state) => state.bootcamp);
  console.log(auth, "auth");
  console.log(bootcamp, "bootcamp");

  const [taskTracker, setTaskTracker] = useState(1);
  const [batchDetails, setBatchDetails] = useState({
    checkoutId: null,
    planId: null,
    batchStartDate: "2022-06-12T18:30:00.000+00:00",
    batchEndDate: "2022-06-23T18:30:00.000+00:00",
    timeslot: { from: "09:30", to: "12:30" },
    batchId: "13-24",
    slotId: "09:30-12:30",
    courseName: bootcamp.courseName,
    courseAmount: bootcamp.courseAmount,
    courseId: bootcamp.courseId,
    courseAmountAfterCoupon: null,
  });
  const { type } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (type === "signin" || type === "signup") {
      setTaskTracker(1);
    } else if (type === "batch-type") {
      setTaskTracker(2);
    } else if (type === "payment") {
      setTaskTracker(3);
    }
  }, [type]);

  useEffect(() => {
    if (!auth.isLoggedIn && (type === "batch-type" || type === "payment")) {
      navigate("/summer-bootcamp");
    }
  }, [auth.isLoggedIn, navigate, type]);

  return (
    <div className="max-w-screen">
      <div className="bg-primary-background-blue pt-10 pb-20 w-full flex flex-col items-center">
        <div className="flex items-center justify-center">
          <h1 className="-mr-6 md:-mr-12 text-white text-2xl md:text-4xl 2xl:text-5xl leading-[54px] font-extrabold ">
            Summer Bootcamp'22
          </h1>
          <Paintbrush className="w-[57px] h-[33px] md:w-[122px] md:h-[70px]" />
        </div>
        <div className="mb-10 mt-36">
          <TaskTracker taskTracker={taskTracker} length={3} />
        </div>

        {(type === "signup" || type === "signin" || type === "verify-otp") && (
          <div className="relative mt-20 mb-32 py-10 w-[85%] lg:w-[90%] xl:w-[80%] flex flex-col items-center lg:items-start lg:justify-around lg:flex-row border boder-primary-pink rounded-[30px] px-5 sm:px-10">
            <h3 className="lg:hidden text-white text-base sm:text-xl text-center font-medium ">
              You’re almost there from having fun learning this summer
            </h3>
            <div className="mt-10 order-2 lg:order-none rounded-xl hidden lg:flex flex-col items-center justify-between w-full xl:w-1/2">
              <h3 className=" text-white leading-[34px] w-[80%] text-2xl text-center font-medium ">
                You’re almost there from having fun learning this summer
              </h3>
              <img
                className="w-[473px] h-[266px] absolute -bottom-6"
                src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/dizzy-woman-rollerblading-with-phone_1_alxn0qcUS.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654027574305"
                alt=""
              />
            </div>

            {type === "signin" && <BootcampSignIn />}
            {type === "signup" && <BootcampSignUp />}
            {type === "verify-otp" && <BootcampVerifyOtp />}
          </div>
        )}

        {auth.isLoggedIn && type === "batch-type" && (
          <BootcampBatchDetails
            batchDetails={batchDetails}
            setBatchDetails={setBatchDetails}
          />
        )}
        {auth.isLoggedIn && type === "payment" && (
          <BootcampPayment
            batchDetails={batchDetails}
            setBatchDetails={setBatchDetails}
          />
        )}
      </div>

      <div className="w-full flex  justify-center space-x-5 xl:space-x-10 pt-20 px-5 xl:px-20">
        <div className="relative lg:w-1/3  border border-primary-purple rounded-[24px] py-5 xl:py-10 px-5">
          <div className="absolute -top-6 left-6">
            <InvertedCommas className="h-10 w-10" />
          </div>
          <p className="text-xs xl:text-sm 2xl:text-base text-secondary-blue">
            Instructor makes the game learning super fun and exciting! He comes
            up with new challenges with every session keeping me interested.
            Also he keeps the kids super positive throughout the process which
            makes it an enjoyable experience for my age kids.
          </p>
        </div>
        <div className="hidden sm:inline-flex relative lg:w-1/3 border border-primary-purple rounded-[24px] py-5 xl:py-10 px-5">
          <div className="absolute -top-6 left-6">
            <InvertedCommas className="h-10 w-10" />
          </div>
          <p className="text-xs xl:text-sm 2xl:text-base text-secondary-blue">
            My kids have been learning chess from Devarsh since the past 4
            months.They have made a lot of progress.Devarsh is very patient with
            the kids.He is a lot of fun to learn with.He also hosts tournaments
            on a bi weekly basis which helps build kid’s confidence.
          </p>
        </div>
        <div className="hidden lg:inline-flex relative lg:w-1/3 border border-primary-purple rounded-[24px] py-5 xl:py-10 px-5">
          <div className="absolute -top-6 left-6">
            <InvertedCommas className="h-10 w-10" />
          </div>
          <p className="text-xs xl:text-sm 2xl:text-base text-secondary-blue">
            My son is learning chess from last 4 months and the improvements in
            his thinking ability and reasoning skills are evident. He eagerly
            waits for the class every week. A big thanks to Devarsh for adopting
            innovative techniques for Chess training and for systematically
            identifying his weak points in game play to provide the necessary
            help.
          </p>
        </div>
      </div>
      <TransformCard
        mainHeading="Exposure to learn with current industry experts"
        subHeading="Kids around the world are learning new skills and excelling in their field. Take a step towards an innovative future."
        buttonInnerText="Experience it for FREE"
      />
    </div>
  );
};

export default BootcampRegister;
