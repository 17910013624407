import React from "react";
import QuestionCard from "./components/QuestionCard/QuestionCard";
import pricingPlan from "../../assets/images/pricing.png";

const FrequentlyAskedQuestions = () => {
  return (
    <div className="max-w-screen">
      <h1 className="my-10 xl:my-20 text-center text-xl xl:text-3xl text-primary-background-blue font-bold">
        Frequently asked questions
      </h1>
      <div className="flex flex-col items-center space-y-5 xl:space-y-10 mb-20">
        <QuestionCard
          question="Who are we?"
          answer={
            <div className="text-xs xl:text-lg text-secondary-blue font-normal">
              <p>
                Krafternoon is a skill development platform which offers
                customised courses to work on real world projects under the
                guidance of our industry experts to improve the creative and
                analytical thinking of kids.
              </p>
            </div>
          }
        />
        <QuestionCard
          question="What skills do we offer?"
          answer={
            <div className="text-xs xl:text-lg text-secondary-blue font-normal">
              <p>
                Krafteroon provides a wide range of personalised tailor-made
                courses that enhance a child's creative and analytical thinking
                skills.
              </p>
              <p className="my-2">
                We provide the following skill development courses:
              </p>
              <div>
                <p>UI UX Design</p>
                <p>Chess</p>
                <p>Video Editing</p>
                <p>3D Modelling</p>
                <p>Web Development</p>
                <p>Graphic Design</p>
                <p>Artificial Intelligence</p>
              </div>
            </div>
          }
        />
        <QuestionCard
          question="Can we switch between skills??"
          answer={
            <div className="text-xs xl:text-lg text-secondary-blue font-normal">
              <p>
                Krafternoon provides a unique feature where if your kid wants to
                change the course they are learning, they can immediately switch
                to another course hassle free without any additional charges.
              </p>
            </div>
          }
        />
        <QuestionCard
          question="How are kids evaluated?"
          answer={
            <div className="text-xs xl:text-lg text-secondary-blue font-normal">
              <p>
                We at Krafternoon understand that every child is unique and
                every child has a different set of skills that need to be
                enhanced with the help of industry experts. Krafternoon believes
                in comparing a child’s progress only with themselves and no one
                else. Here, we use a system called CA (Creative and Analytical)
                analysis where your child’s progress today will be compared with
                their progress yesterday and we will focus on their Creative and
                Analytical thinking power.
              </p>
            </div>
          }
        />
        <QuestionCard
          question="What are Creative & Analytical skills?"
          answer={
            <div className="text-xs xl:text-lg text-secondary-blue font-normal">
              <p>
                It is well researched that Creative and Analytical thinking
                skills lead to higher thinking patterns and help individuals
                work efficiently. As to utilise the power of these skills, it is
                best to begin at an early stage. Being unaware of the skill sets
                which contribute towards enhancing creativity and analytical
                power, can create a point of hindrance. So, to leverage the
                current learning process we at Krafternoon have designed a
                platform with a human-centric approach with real world
                applications.
              </p>
            </div>
          }
        />
        <QuestionCard
          question="What Pricing plans are available?"
          answer={
            <div className="text-xs xl:text-lg text-secondary-blue font-normal">
              <p>
                We provide two, very flexible, pricing plans as given below:
              </p>
              <img src={pricingPlan} alt="" className=" mt-5" />
            </div>
          }
        />
        <QuestionCard
          question="How are classes scheduled?"
          answer={
            <div className="text-xs xl:text-lg text-secondary-blue font-normal">
              <p>
                At Krafternoon, we believe in personalised learning to cater to
                the needs of each child. We take 1:1 mentoring sessions at
                flexible hours for each of the students.
              </p>
            </div>
          }
        />
        <QuestionCard
          question="Who can join Krafternoon?"
          answer={
            <div className="text-xs xl:text-lg text-secondary-blue font-normal">
              <p>
                Krafternoon classes are open to learners between ages 4 - 16,
                with each class specifying the age range for which that course
                is designed. Krafternoon works for all kids, no matter their
                schooling type, from brick and mortar to homeschool, and
                everything in between.
              </p>
            </div>
          }
        />
        <QuestionCard
          question="What kind of technology do the kids need?"
          answer={
            <div className="text-xs xl:text-lg text-secondary-blue font-normal">
              <p>
                We encourage children to join us using the device of their
                choice.Krafteroon works on all major web browsers, and we use
                Zoom and Google Meet for live video meetings. You’ll need a
                working webcam and microphone, and a solid internet connection.
              </p>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;
