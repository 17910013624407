import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Tick from "../../../assets/SVG/tick";
import Button from "../../Button/Button";

const PaymentCard = ({
  heading,
  description,
  bestSeller,
  price,
  priceDescription,
  optionsList,
  onClick,
}) => {
  //const auth = useSelector((state) => state.auth);
  //console.log(auth);

  // const navigate = useNavigate();
  // // const startLearningHandler = () => {
  // //   localStorage.setItem("redirectUrl", "/pricing/pricing-plan");
  // //   localStorage.setItem("planType", heading);
  // //   if (auth.isLoggedIn) {
  // //     navigate("/pricing/pricing-plan");
  // //   } else {
  // //     navigate("/signin");
  // //   }
  // // };

  return (
    <div
      className={
        bestSeller
          ? "relative flex flex-col items-center bg-white h-[450px] sm:h-[550px] xl:h-[610px] w-[300px] lg:w-[400px] xl:w-[465px]"
          : "relative flex flex-col items-center bg-white border border-secondary-blue rounded-lg xl:rounded-tr-lg xl:rounded-b-lg h-[450px] sm:h-[550px] xl:h-[610px] w-[300px] lg:w-[400px] xl:w-[465px]"
      }
    >
      <div
        className={
          bestSeller
            ? " text-center w-full py-4 bg-secondary-blue rounded-lg rounded-bl-none rounded-br-none"
            : " text-center w-full py-4 bg-white rounded-lg xl:rounded-r-lg xl:rounded-bl-lg"
        }
      >
        {bestSeller && (
          <span className="absolute left-0 top-8 xl:top-8  bg-white xl:w-[90px] p-1 xl:p-2 text-xs xl:text-base text-primary-blue rounded-r-md">
            Bestseller
          </span>
        )}
        <h2
          className={
            bestSeller
              ? "mb-1 xl:mb-2  text-xl xl:text-4xl font-normal text-white"
              : "mb-1 xl:mb-2  text-xl xl:text-4xl font-normal text-secondary-blue"
          }
        >
          {heading}
        </h2>
        <p
          className={
            bestSeller
              ? " text-xs xl:text-lg font-normal text-white"
              : " text-xs xl:text-lg font-normal text-secondary-blue"
          }
        >
          {description}
        </p>
      </div>
      <div
        className={
          bestSeller
            ? "border-l border-r sm:border-r-0 border-b rounded-b-lg h-full border-secondary-blue w-full flex flex-col items-center p-5 xl:py-7"
            : "w-full flex flex-col items-center p-5 xl:py-7"
        }
      >
        <div>
          <div className="mb-2 flex items-center space-x-1">
            <p className=" text-3xl xl:text-5xl text-primary-blue">
              {bestSeller ? `$${price - 20}` : `$${price}`}
            </p>
            {bestSeller && (
              <strike className="mt-1 xl:mt-3 text-secondary-blue-ltr-ltr  text-lg xl:text-2xl">
                ${price}
              </strike>
            )}
            {!bestSeller && (
              <p className="text-primary-blue  text-3xl xl:mt-2.5">/hr</p>
            )}
          </div>
          <p className=" text-md xl:text-lg text-secondary-blue-ltr">
            {priceDescription}
          </p>
        </div>
        <div className="bg-primary-purple h-[1px] w-[85%]"></div>
        <div className="max-w-max pt-5 xl:pt-8">
          <div className="flex flex-col space-y-1 sm:space-y-3">
            {optionsList.length > 0 &&
              optionsList.map((option) => (
                <div
                  className="flex items-center space-x-2  text-sm sm:text-base xl:text-lg text-secondary-blue"
                  key={option.id}
                >
                  <Tick />
                  <span>{option.title}</span>
                </div>
              ))}
          </div>
        </div>
        <Button
          onClick={onClick}
          innerText="Start Learning"
          type="button"
          extraClass="py-2 absolute bottom-8 w-[70%] text-base xl:text-lg text-white font-normal  rounded-full bg-primary-green"
        />
        <div />
      </div>
    </div>
  );
};

export default PaymentCard;
