import React, { useState } from "react";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import Google from "../../assets/SVG/google";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { BACKEND_BASE_URL, FALLBACK_URL } from "../../helpers/url/url";
import axios from "../../helpers/axios/axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { authActions } from "../../redux/features/auth-slice";

const Signin = () => {
  const [showIndividualError, setShowIndividualError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ isError: false, message: "" });

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  console.log(auth, "auth");

  const navigate = useNavigate();
  let errorLength;

  function checkErrors() {
    if (errorLength > 0) {
      setShowIndividualError(true);
    }
  }

  const signInAPI = async (email, password) => {
    setIsLoading(true);
    setError({ isError: false, message: "" });
    try {
      const signInRespone = await axios.post(
        `${BACKEND_BASE_URL}/api/sign-in/student`,
        { email: email, password: password }
      );
      setIsLoading(false);
      console.log(signInRespone, "signInRespone");
      if (signInRespone?.data?.success) {
        const token = signInRespone.data.token;
        localStorage.setItem("token", token);
        dispatch(authActions.login({ token: token }));
        navigate("/dashboard");
      }
    } catch (err) {
      console.log(err);
      if (err.response.status >= 400 && err.response.status < 500) {
        setError({
          isError: true,
          message: "Invalid Credentials! Please try again.",
        });
      } else {
        setError({
          isError: true,
          message: "Oops something went wrong! Please try again.",
        });
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-screen">
      <div className="w-full flex flex-col xl:flex-row">
        <div className="order-2 xl:order-none rounded-xl hidden sm:inline-flex flex-col items-center bg-ternary-blue-ltr w-full xl:w-1/2">
          <h1 className="mt-10 w-[80%] text-2xl text-center xl:text-left xl:text-4xl xl:leading-[48px] font-semibold  text-secondary-blue">
            Sow the seeds of{" "}
            <i className="font-extrabold">Creativity & Analytical</i> approach
            with <i className="font-extrabold">Krafternoon</i>
          </h1>
          <img
            className="w-[700px] h-[410px]"
            src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/AuthPage/dizzy-girl-sitting-in-a-chair-with-a-notebook-and-watching-a-video-lecture_1__1__1FeWv7lnG.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652080713866"
            alt=""
          />
        </div>

        <div className="w-full xl:w-1/2 flex flex-col items-center">
          <h1 className="mt-10 text-2xl xl:text-4xl  font-extrabold text-secondary-blue xl:leading-[62px]">
            Welcome back!
          </h1>
          {error.isError && (
            <p className="text-primary-red font-semibold">{error.message}</p>
          )}
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email("Invalid email address")
                .required("Email is Required"),
              password: Yup.string()
                .required("Password is required")
                .min(8, "Password must contain atleast 8 characters"),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              const { email, password } = values;
              //console.log(email, "email", password, "password");
              signInAPI(email, password);
              setSubmitting(false);
              resetForm({ email: "", password: "" });
            }}
          >
            {(formik) => {
              errorLength = Object.keys(formik.errors).length;
              return (
                <form onSubmit={formik.handleSubmit}>
                  <div className="mt-10 mb-5">
                    <p className="mb-1 sm:mb-3 text-base sm:text-xl text-primary-blue  leading-[27px] font-normal">
                      Email address
                    </p>
                    <Input
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Email registered email id"
                      autoComplete="email"
                      error={formik.errors.email}
                      showIndividualError={showIndividualError}
                      extraClass={
                        formik.touched.email && formik.errors.email
                          ? "text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-primary-red"
                          : "text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-ternary-blue"
                      }
                      {...formik.getFieldProps("email")}
                    />
                  </div>

                  <div>
                    <p className="mb-1 sm:mb-3 text-base sm:text-xl text-primary-blue  leading-[27px] font-normal">
                      Password
                    </p>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Enter your password"
                      error={formik.errors.password}
                      showIndividualError={showIndividualError}
                      extraClass={
                        formik.touched.password && formik.errors.password
                          ? "text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-primary-red"
                          : "text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-ternary-blue"
                      }
                      {...formik.getFieldProps("password")}
                    />
                  </div>

                  <div className="mt-12 w-full sm:w-[350px] flex flex-col space-y-7">
                    <Button
                      onClick={() => checkErrors()}
                      innerText="Log in"
                      type="submit"
                      extraClass={`text-center h-[45px] text-sm sm:text-base xl:text-lg text-white font-medium  rounded-full bg-primary-green ${
                        isLoading && "opacity-20"
                      }`}
                      isLoading={isLoading}
                      disabled={isLoading}
                    />

                    <Button
                      onClick={() =>
                        window.location.replace(
                          `${BACKEND_BASE_URL}/api/oauth/google?fallbackURL=${FALLBACK_URL}`
                        )
                      }
                      Icon={Google}
                      innerText="Sign in with Google"
                      type="button"
                      extraClass="text-center h-[45px] text-sm sm:text-base xl:text-lg text-primary-green font-medium  rounded-full bg-white border border-primary-green"
                    />
                  </div>
                </form>
              );
            }}
          </Formik>
          <div>
            <p className="mt-3 mb-5 sm:mb-16 text-sm sm:text-lg leading-[28px] text-secondary-blue  font-light">
              Don't have an account?&nbsp;
              <span
                onClick={() => navigate("/signup")}
                className="font-bold cursor-pointer hover:underline"
              >
                Create Account
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
