import axios from "axios";

let token;

const axiosInstance = axios.create({
  headers: {
    Authorization: token ? `${token}` : "",
  },
});

axiosInstance.interceptors.request.use((req) => {
  //const user = window.localStorage.getItem("user");
  token = localStorage.getItem("token");
  //console.log("token from axios", token);
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
});

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    console.log(error);
    const status = error.response ? error.response.status : 500;
    if (status && status === 401) {
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
