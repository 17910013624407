import React from "react";
import CircleSpinner from "../Spinners/CircleSpinner/CircleSpinner";
const Button = ({
  innerText,
  type,
  onClick,
  onKeyPress,
  disabled,
  extraClass,
  Icon,
  innerTextStyle,
  isLoading,
  ...rest
}) => {
  return (
    <div className={`flex items-center space-x-2 ${extraClass}`}>
      <button
        onClick={onClick}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            onClick();
            console.log("Enter");
          }
        }}
        type={type}
        {...rest}
        disabled={disabled}
        className={`w-full disabled:opacity-80 flex items-center justify-center space-x-3 py-1 px-4 font-[750]`}
      >
        {Icon && <Icon className="h-5 w-5 xl:h-7 xl:w-7" />}
        <span className={innerTextStyle}>{innerText}</span>
        {isLoading && (
          <span>
            <CircleSpinner />
          </span>
        )}
      </button>
    </div>
  );
};

export default Button;
