import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CheckboxTick from "../../../assets/SVG/checkbox-tick";
import CircularCrossBlue from "../../../assets/SVG/circular-cross-blue";
import CircularTickBlue from "../../../assets/SVG/circular-tick-blue";
import Button from "../../../components/Button/Button";
import { BACKEND_BASE_URL } from "../../../helpers/url/url";
import axios from "../../../helpers/axios/axios";
import LoadingSpinner from "../../../components/Spinners/LoadingSpinner/LoadingSpinner";

const Summary = ({ selectedPlan }) => {
  const [promocode, setPromocode] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isPromocodeApplied, setPromocodeApplied] = useState(null);
  const [showIndividualError, setShowIndividualError] = useState(false);
  const [isLoading, setIsLoading] = useState({ id: null });
  const [checkoutId, setCheckoutId] = useState(null);

  console.log(selectedPlan, "selectedPlan");
  console.log(checkoutId, "checkoutId");

  const applyRemoveCouponCodeAPI = async (applied) => {
    setIsLoading({ id: "validate-coupon" });
    try {
      const applyCouponResponse = await axios.post(
        `${BACKEND_BASE_URL}/api/payment/applyCouponCode`,
        {
          checkoutId: selectedPlan.checkoutId,
          couponCode: promocode,
          applied: applied,
        }
      );
      if (applyCouponResponse?.status === 200) {
        if (applied === false) {
          setPromocode("");
          setPromocodeApplied(null);
        } else {
          setPromocodeApplied(true);
        }

        // setSelectedPlan({
        //   ...selectedPlan,
        //   courseAmountAfterCoupon: applyCouponResponse?.data?.finalAmount,
        // });
      } else if (applyCouponResponse?.status === 404) {
        setPromocodeApplied(false);
        setPromocode("");
      }
      console.log("applyCouponResponse", applyCouponResponse);
      setIsLoading({ id: null });
    } catch (err) {
      console.error(err);
      setPromocodeApplied(false);
      setIsLoading({ id: null });
    }
  };

  const checkoutPlanAPI = async () => {
    if (isChecked) {
      setShowIndividualError(false);
      setIsLoading({ id: "checkout-safely" });
      try {
        const checkoutPlanResponse = await axios.post(
          `${BACKEND_BASE_URL}/api/payment/checkout`,
          {
            checkoutId: selectedPlan.checkoutId,
          }
        );
        console.log("checkoutPlanResponse", checkoutPlanResponse);
        if (checkoutPlanResponse?.status === 200) {
          window.location.replace(checkoutPlanResponse?.data?.redirectURL);
        }
        setIsLoading({ id: null });
      } catch (err) {
        console.error(err);
        setIsLoading({ id: null });
      }
    } else {
      setShowIndividualError(true);
      setIsLoading({ id: null });
    }
  };

  return (
    <div>
      <div className="relative flex flex-col items-center lg:items-start lg:flex-row justify-between bg-secondary-blue w-[100%]  rounded-xl px-5 lg:px-10">
        <div className="sm:w-[40%] sm:text-center lg:text-left lg:max-w-md mt-20">
          <div className="text-white text-base sm:text-xl 2xl:text-[32px] font-semibold 2xl:leading-[48px]">
            <p>You’re just one step away from having fun learning with </p>
            <p className="text-primary-yellow">Krafternoon</p>
          </div>
          <img
            src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/Payment/dizzy-online-shopping_1_7TbAsU_XC.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654950055889"
            alt=""
            className="hidden lg:inline-flex absolute -bottom-2 w-[40%] 3xl:w-1/5"
          />
        </div>
        <div className="w-full sm:w-[70%] md:w-[55%] xl:w-[656px] bg-white border boder-primary-pink rounded-lg py-5 px-5 2xl:px-10 my-20">
          <div>
            <div className="mb-2 flex items-center justify-between">
              <h4 className="text-xs sm:text-sm xl:text-base font-medium leading-[24px] text-secondary-blue">
                Pricing plan
              </h4>
              <h4 className="text-xs sm:text-sm xl:text-base font-medium leading-[24px] text-secondary-blue">
                Price
              </h4>
            </div>
            <div className="mb-2 flex items-center justify-between">
              <p className="text-xs sm:text-sm xl:text-lg font-medium leading-[24px] text-secondary-blue">
                {selectedPlan?.paymentMode === "postpaid"
                  ? "Postpaid"
                  : "Prepaid"}
              </p>
              <div className="text-xs sm:text-sm xl:text-lg font-medium leading-[24px] text-secondary-blue">
                {selectedPlan?.paymentMode === "prepaid" && (
                  <strike className="xl:text-lg text-primary-purple font-medium mr-2">
                    $150.00
                  </strike>
                )}
                {selectedPlan?.paymentMode === "postpaid"
                  ? "$15/hr"
                  : `$${selectedPlan?.price - 20}`}
              </div>
            </div>
            {selectedPlan?.paymentMode === "postpaid" && (
              <div className="mb-7 flex items-center justify-between text-base xl:text-lg font-medium leading-[24px] text-secondary-blue">
                <p className="">Credit Limit</p>
                <p>100$</p>
              </div>
            )}
          </div>

          {selectedPlan?.paymentMode === "prepaid" && (
            <div className="bg-primary-pink w-full h-[1px] mt-10 mb-7"></div>
          )}

          {selectedPlan?.paymentMode === "prepaid" && (
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  type="text"
                  placeholder="Apply Promocode"
                  value={promocode}
                  onChange={(e) => {
                    if (isPromocodeApplied === false && e.target.value === "") {
                      setPromocodeApplied(null);
                    }
                    setPromocode(e.target.value);
                  }}
                  readOnly={isPromocodeApplied === true ? true : false}
                  className="pl-2 w-[100px] sm:w-[150px] 2xl:w-[200px] h-[31px] xl:h-[41px] bg-transparent border-t-2 border-b-2 border-l-2 rounded-tl-lg rounded-bl-lg borderborder-primary-purple text-[0.6rem] sm:text-sm xl:text-base placeholder:text-[0.6rem] sm:placeholder:text-xs xl:placeholder:text-sm text-secondary-blue font-normal placeholder:text-secondary-blue focus:outline-none"
                />
                <Button
                  innerText={
                    isPromocodeApplied === true && promocode !== ""
                      ? "Applied"
                      : isPromocodeApplied === false && promocode !== ""
                      ? "Invalid"
                      : "Apply"
                  }
                  Icon={
                    isPromocodeApplied === true && promocode !== ""
                      ? CircularTickBlue
                      : isPromocodeApplied === false && promocode !== ""
                      ? CircularCrossBlue
                      : null
                  }
                  type="button"
                  extraClass={`xl:-ml-3 h-[31px] xl:h-[41px] xl:w-[130px] text-[0.6rem] md:text-sm xl:text-lg text-secondary-blue font-normal ${
                    isPromocodeApplied === false
                      ? "bg-primary-yellow"
                      : "bg-ternary-pink"
                  } rounded-br-lg rounded-tl-none rounded-tr-lg rounded-bl-none`}
                  innerTextStyle="font-normal"
                  //onClick={validateCouponCodeAPI}
                  isLoading={isLoading.id === "validate-coupon"}
                  disabled={isLoading.id !== null}
                />
                {isPromocodeApplied === true && (
                  <span
                    onClick={() => applyRemoveCouponCodeAPI(false)}
                    className="cursor-pointer self-end text-[0.6rem] 2xl:text-sm ml-2 text-primary-red underline"
                  >
                    Remove Coupon
                  </span>
                )}
              </div>
              <div className="flex items-center space-x-3 ml-5">
                {isPromocodeApplied && (
                  <strike className="text-xs sm:text-base xl:text-base text-primary-purple font-medium">
                    {selectedPlan ? selectedPlan?.price : ""}
                  </strike>
                )}
                <p className="text-sm sm:text-base xl:text-2xl font-medium leading-[24px] text-secondary-blue">
                  {isPromocodeApplied === true
                    ? `$${selectedPlan.courseAmountAfterCoupon}`
                    : `$${selectedPlan.price - 20}`}
                </p>
              </div>
            </div>
          )}
          <div className="bg-primary-pink w-full h-[1px] my-5"></div>
          <div className="mb-3 flex items-center justify-between">
            <p className="text-base xl:text-2xl font-medium leading-[24px] text-secondary-blue">
              TOTAL
            </p>
            <p className="text-base xl:text-2xl font-medium leading-[24px] text-secondary-blue">
              {isPromocodeApplied === true
                ? `$${selectedPlan.courseAmountAfterCoupon}`
                : `$${selectedPlan.price - 20}`}
            </p>
          </div>
          <div className="bg-primary-pink w-full h-[1px] my-5"></div>
          <div className="flex flex-col space-y-3 lg:space-y-0 lg:flex-row md:items-center md:justify-between">
            <div className="mb-5 md:mb-0">
              <div className="flex items-center space-x-3">
                {!isChecked && (
                  <div
                    onClick={() => {
                      setShowIndividualError(false);
                      setIsChecked(true);
                    }}
                    className="cursor-pointer w-[16px] h-[16px] 2xl:w-[20px] 2xl:h-[20px] border-2 border-primary-purple rounded"
                  ></div>
                )}
                {isChecked && (
                  <CheckboxTick
                    className="w-[16px] h-[16px] 2xl:w-[20px] 2xl:h-[20px] cursor-pointer"
                    onClick={() => setIsChecked(false)}
                  />
                )}
                <p className="text-xs 2xl:text-sm text-secondary-blue font-normal">
                  I accept all the{" "}
                  <span className="text-xs 2xl:text-sm cursor-pointer text-primary-green font-semibold hover:underline">
                    Terms & Conditions
                  </span>
                </p>
              </div>

              {showIndividualError && (
                <p className="my-1 ml-9 text-xs text-primary-red">
                  Please accept all the Terms & Conditions!
                </p>
              )}
              {selectedPlan?.paymentMode === "postpaid" && (
                <p className="my-1 text-xs text-secondary-blue font-normal">
                  Note: Your credit limit is subject to payment history
                </p>
              )}
            </div>
            <div className="w-full lg:max-w-max flex flex-col space-y-3 md:space-y-0 md:flex-row items-center md:space-x-3">
              <Button
                isLoading={isLoading.id === "checkout-safely"}
                disabled={isLoading.id !== null}
                innerText={
                  selectedPlan?.paymentMode === "postpaid"
                    ? "Activate Postpaid"
                    : "Checkout safely"
                }
                onClick={checkoutPlanAPI}
                type="button"
                extraClass="h-[40px] w-full lg:max-w-max text-xs xl:text-sm text-white font-normal rounded-full bg-primary-green"
              />
              <Button
                innerText="Cancel"
                type="button"
                extraClass="h-[40px] w-full lg:max-w-max text-xs xl:text-sm text-primary-red font-normal rounded-full bg-transparent border border-primary-red"
                onClick={() => {
                  //localStorage.removeItem("bootcamp");
                  window.location.reload();
                }}
              />
            </div>
          </div>
        </div>
        <img
          src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/Payment/dizzy-online-shopping_1_7TbAsU_XC.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654950055889"
          alt=""
          className="sm:w-[60%] lg:hidden"
        />
      </div>
    </div>
  );
};

export default Summary;
