import React from "react";
import Button from "../../Button/Button";

const InfoCard = ({
  src,
  description,
  secondaryDescription,
  button,
  extraClass,
  fromStudentOf,
  buttonInnerText,
  extraElement,
  imageStyle,
  onClick,
  textStyle,
}) => {
  return (
    <div className={`relative ${extraClass}`}>
      {src && (
        <img
          src={src}
          alt=""
          className={imageStyle ? imageStyle : "w-full xl:h-[237px] rounded-xl"}
        />
      )}
      <p
        className={`text-secondary-blue text-sm xl:text-base ${
          textStyle ? textStyle : "font-bold"
        }`}
      >
        {description}
      </p>
      {secondaryDescription && (
        <p
          className={`text-secondary-blue text-sm xl:text-base ${
            textStyle ? textStyle : "font-bold"
          }`}
        >
          {secondaryDescription}
        </p>
      )}
      {button && (
        <Button
          onClick={onClick}
          innerText={buttonInnerText ? buttonInnerText : "Learn how"}
          type="button"
          extraClass="absolute bottom-3 xl:bottom-5 py-0.5 xl:py-1 max-w-max text-xs lg:text-base text-white font-normal rounded-full bg-primary-green"
        />
      )}
      {fromStudentOf && (
        <span
          className={`absolute right-5 bottom-2 font-normal text-secondary-blue text-sm ${textStyle}`}
        >
          {fromStudentOf}
        </span>
      )}
      {extraElement && extraElement}
    </div>
  );
};

export default InfoCard;
