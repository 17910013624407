import React, { useEffect, useState } from "react";
import DatePicker from "../../../components/DatePicker/DatePicker";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { toastNotification } from "../../../helpers/functions/toast-notification/toast-notification";
import { BACKEND_BASE_URL } from "../../../helpers/url/url";
import axios from "../../../helpers/axios/axios";
import Button from "../../../components/Button/Button";

const CreatePlan = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [parentCourse, setParentCourse] = useState([]);
  const [createPlanData, setCreatePlanData] = useState({
    mode: "single",
    courseId: "",
    courseType: "bootcamp",
    startDate: "",
    endDate: "",
    price: "",
    description: "",
    targetCountry: "us",
    currency: "USD",
    timezone: "PDT",
    timeslotFrom: "",
    timeslotTo: "",
  });

  useEffect(() => {
    const getCoursesAPI = async () => {
      try {
        const getCoursesResponse = await axios.get(
          `${BACKEND_BASE_URL}/admin/adminRoles/getCourses`
        );
        //console.log(getCoursesResponse?.data?.courses, "getCoursesResponses");

        let ALL_COURSES = [];
        const allCourses = getCoursesResponse?.data?.courses;

        for (let i = 0; i < allCourses.length; i++) {
          ALL_COURSES.push({
            id: allCourses[i]._id,
            name: allCourses[i].courseName,
            courseType: allCourses[i].courseType,
          });
        }
        setParentCourse([...ALL_COURSES]);
      } catch (err) {
        console.log(err);
      }
    };

    getCoursesAPI();
  }, []);

  const createPlanAPI = async () => {
    const createPlanPayload = {
      mode: createPlanData.mode,
      courseId: createPlanData.courseId,
      courseType: createPlanData.courseType,
      startDate: new Date(createPlanData.startDate).toISOString(),
      endDate: new Date(createPlanData.endDate).toISOString(),
      price: parseInt(createPlanData.price),
      currency: createPlanData.currency,
      targetCountry: createPlanData.targetCountry,
      description: createPlanData.description,
      duration:
        Date.parse(new Date(createPlanData.endDate).toISOString()) -
        Date.parse(new Date(createPlanData.startDate).toISOString()),
      timeslotFrom: createPlanData.timeslotFrom,
      timeslotTo: createPlanData.timeslotTo,
      timezone: createPlanData.timezone,
    };

    setIsLoading(true);
    try {
      const createPlanResponse = await axios.post(
        `${BACKEND_BASE_URL}/admin/adminRoles/createPlan`,
        createPlanPayload
      );

      if (createPlanResponse.status === 200) {
        //console.log(createPlanResponse, "createPlanResponse");
        toastNotification("success", "Plan Create successfully!");
        setIsLoading(false);
      }
    } catch (err) {
      //console.log(err);
      toastNotification("error", "Plan Create failed. Please try again!");
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-ternary-blue-ltr mt-10 w-[65%] rounded-lg">
      <h3 className="text-secondary-blue text-2xl font-extrabold p-5">
        CREATE PLAN
      </h3>
      <div className="border-t border-secondary-blue-lt p-5">
        <div className="flex items-center space-x-10 mt-10">
          <div className="flex items-center space-x-5">
            <p className="text-lg text-primary-blue font-normal">Mode</p>
            <Dropdown
              menuList={[
                { id: "batch", name: "Batch" },
                { id: "single", name: "Single" },
              ]}
              defaultValue={{ id: "single", name: "Single" }}
              dropdownStyle="w-[142px] h-[40px] border border-primary-blue flex items-center justify-around text-lg text-primary-blue font-normal"
              arrowStyle="w-[20px] h-[15px] cursor-pointer"
              dropdownMenuStyle="w-[142px] bg-white"
              dropdownHandler={(option) =>
                setCreatePlanData({
                  ...createPlanData,
                  mode: option.id,
                })
              }
            />
          </div>

          <div className="flex items-center space-x-5">
            <p className="text-lg text-primary-blue font-normal">Course Type</p>
            <Dropdown
              menuList={[
                { id: "bootcamp", name: "Bootcamp" },
                { id: "skill", name: "Skill" },
              ]}
              defaultValue={{ id: "bootcamp", name: "Bootcamp" }}
              dropdownStyle="w-[142px] h-[40px] border border-primary-blue flex items-center justify-around text-lg text-primary-blue font-normal"
              arrowStyle="w-[20px] h-[15px] cursor-pointer"
              dropdownMenuStyle="w-[142px] bg-white"
              dropdownHandler={(option) =>
                setCreatePlanData({
                  ...createPlanData,
                  courseType: option.id,
                })
              }
            />
          </div>
        </div>

        {createPlanData.courseType === "bootcamp" && (
          <div className="flex items-center space-x-5 mt-10">
            <p className="text-lg font-semibold text-primary-blue">
              Parent Course
            </p>
            <Dropdown
              menuList={[...parentCourse]}
              defaultValue={{ id: "none", name: "Enter Parent Course" }}
              dropdownStyle="px-5 w-[300px] h-[40px] border border-primary-blue flex items-center justify-between text-lg text-primary-blue font-normal"
              arrowStyle="w-[20px] h-[15px] cursor-pointer"
              dropdownMenuStyle="w-[300px] bg-white"
              dropdownHandler={(option) =>
                setCreatePlanData({
                  ...createPlanData,
                  courseId: option.id,
                })
              }
            />
          </div>
        )}

        <div className="flex items-center space-x-10 my-10">
          <div className="flex items-center space-x-5">
            <p className="text-lg text-primary-blue font-normal">Start Date</p>
            <DatePicker
              date={createPlanData.startDate}
              setDate={(date) =>
                setCreatePlanData({
                  ...createPlanData,
                  startDate: date,
                })
              }
            />
          </div>
          <div className="flex items-center space-x-5">
            <p className="text-lg text-primary-blue font-normal">End Date</p>
            <DatePicker
              date={createPlanData.endDate}
              setDate={(date) =>
                setCreatePlanData({
                  ...createPlanData,
                  endDate: date,
                })
              }
            />
          </div>
        </div>
        <div className="flex items-center space-x-10 mt-10">
          <div className="flex items-center space-x-5">
            <p className="text-lg text-primary-blue font-normal">
              Timeslot From
            </p>
            <input
              type="text"
              placeholder="00:00"
              className="w-[100px] text-secondary-blue focus:outline-none border-b border-primary-blue bg-transparent text-center placeholder:text-secondary-blue-lt text-lg"
              value={createPlanData.timeslotFrom}
              onChange={(e) =>
                setCreatePlanData({
                  ...createPlanData,
                  timeslotFrom: e.target.value,
                })
              }
            />
          </div>
          <div className="flex items-center space-x-5">
            <p className="text-lg text-primary-blue font-normal">Timeslot To</p>
            <input
              type="text"
              placeholder="00:00"
              className="w-[100px] text-secondary-blue focus:outline-none border-b border-primary-blue bg-transparent text-center placeholder:text-secondary-blue-lt text-lg"
              value={createPlanData.timeslotTo}
              onChange={(e) =>
                setCreatePlanData({
                  ...createPlanData,
                  timeslotTo: e.target.value,
                })
              }
            />
          </div>
          <div className="flex items-center space-x-5">
            <p className="text-lg text-primary-blue font-normal">Timezone</p>
            <Dropdown
              menuList={[
                { id: "PDT", name: "PDT" },
                { id: "MDT", name: "MDT" },
                { id: "PST", name: "PST" },
                { id: "CDT", name: "CDT" },
                { id: "EDT", name: "EDT" },
              ]}
              defaultValue={{ id: "PDT", name: "PDT" }}
              dropdownStyle="w-[142px] h-[40px] border border-primary-blue flex items-center justify-around text-lg text-primary-blue font-normal"
              arrowStyle="w-[20px] h-[15px] cursor-pointer"
              dropdownMenuStyle="w-[142px] bg-white"
              dropdownHandler={(option) =>
                setCreatePlanData({
                  ...createPlanData,
                  timezone: option.id,
                })
              }
            />
          </div>
        </div>

        <div className="flex items-center space-x-5 mt-10">
          <p className="text-lg text-primary-blue font-normal">Price</p>
          <input
            type="text"
            placeholder="$00:00"
            className="w-[100px] text-secondary-blue focus:outline-none border-b border-primary-blue bg-transparent text-center placeholder:text-secondary-blue-lt text-lg"
            value={createPlanData.price}
            onChange={(e) =>
              setCreatePlanData({ ...createPlanData, price: e.target.value })
            }
          />
        </div>

        <div className="my-10">
          <p className="text-lg font-semibold text-primary-blue mb-3">
            Description
          </p>
          <textarea
            style={{ resize: "none" }}
            placeholder="Provide a description"
            className="focus:outline-none placeholder:text-secondary-blue-lt text-secondary-blue text-base border border-primary-blue w-[365px] h-[90px] px-5 py-2 bg-transparent"
            value={createPlanData.description}
            onChange={(e) =>
              setCreatePlanData({
                ...createPlanData,
                description: e.target.value,
              })
            }
          ></textarea>
        </div>

        <Button
          onClick={createPlanAPI}
          isLoading={isLoading.isLoading && isLoading.type === "add-course"}
          disabled={isLoading.isLoading}
          innerText="Add Course"
          type="button"
          extraClass="py-1 px-5 max-w-max rounded-full bg-primary-green"
          innerTextStyle="text-xs md:text-base xl:text-lg font-normal text-white"
        />
      </div>
    </div>
  );
};

export default CreatePlan;
