import React, { useState } from "react";
import Button from "../../../components/Button/Button";
import Dropdown from "../../../components/Dropdown/Dropdown";
import axios from "../../../helpers/axios/axios";
import { toastNotification } from "../../../helpers/functions/toast-notification/toast-notification";
import { BACKEND_BASE_URL } from "../../../helpers/url/url";

const CreateDiscountCoupons = () => {
  const [createCouponData, setCreateCouponData] = useState({
    useType: "once",
    intendedUser: "",
    intent: "discount",
    discountType: "percentage",
    creditsToAdd: 0,
    discountValue: "",
    discountCurrency: "usd",
    planSpecific: false,
    codesCount: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  console.log(createCouponData, "createCouponData");

  const createCouponCodeAPI = async () => {
    setIsLoading(true);
    try {
      const createCouponResponse = await axios.post(
        `${BACKEND_BASE_URL}/admin/paymentElection/createCouponCodes`,
        {
          useType: createCouponData.useType,
          intendedUser: createCouponData.intendedUser,
          intent: createCouponData.intent,
          credits_to_add: createCouponData.creditsToAdd,
          discount_type: createCouponData.discountType,
          discount_value: createCouponData.discountValue,
          discount_currency: createCouponData.discountCurrency,
          planSpecific: createCouponData.planSpecific,
          codesCount: createCouponData.codesCount,
        }
      );
      if (createCouponResponse.status === 200) {
        console.log(createCouponResponse, "createCouponResponse");
        toastNotification("success", "Coupon code created successfully!");
        setIsLoading(false);
        setCreateCouponData({
          useType: "once",
          intendedUser: "",
          intent: "discount",
          discountType: "percentage",
          creditsToAdd: 0,
          discountValue: "",
          discountCurrency: "usd",
          planSpecific: false,
          codesCount: "",
        });
      }
    } catch (err) {
      console.log(err);
      toastNotification("error", "Coupon Creation failed. Please try again!");
      setIsLoading(false);
      setCreateCouponData({
        useType: "once",
        intendedUser: "",
        intent: "discount",
        discountType: "percentage",
        creditsToAdd: 0,
        discountValue: "",
        discountCurrency: "usd",
        planSpecific: false,
        codesCount: "",
      });
    }
  };

  return (
    <div className="h-full bg-ternary-blue-ltr mt-10 w-[50%] rounded-lg p-5">
      <h3 className="text-secondary-blue text-2xl font-extrabold mb-3">
        CREATE PLAN
      </h3>
      <div className="border-t border-secondary-blue-lt p-5"></div>

      <div className="flex items-center space-x-10">
        <div className="flex items-center space-x-5">
          <p className="text-lg text-primary-blue font-normal">Use Type</p>
          <Dropdown
            menuList={[
              { id: "once", name: "Once" },
              { id: "recurring", name: "Recurring" },
            ]}
            defaultValue={{ id: "once", name: "Once" }}
            dropdownStyle="w-[142px] h-[40px] border border-primary-blue flex items-center justify-around text-lg text-primary-blue font-normal"
            arrowStyle="w-[20px] h-[15px] cursor-pointer"
            dropdownMenuStyle="w-[142px] bg-white"
            dropdownHandler={(option) =>
              setCreateCouponData({
                ...createCouponData,
                useType: option.id,
              })
            }
          />
        </div>
        <div className="flex items-center space-x-5">
          <p className="text-lg text-primary-blue font-normal">Intented User</p>
          <input
            type="text"
            placeholder="Enter username"
            className="px-5 py-1 max-w-max text-secondary-blue focus:outline-none border border-primary-blue bg-transparent placeholder:text-secondary-blue-lt placeholder:text-sm text-lg"
            value={createCouponData.intendedUser}
            onChange={(e) =>
              setCreateCouponData({
                ...createCouponData,
                intendedUser: e.target.value,
              })
            }
          />
        </div>
      </div>

      <div className="flex items-center space-x-10 mt-10">
        <div className="flex items-center space-x-5">
          <p className="text-lg text-primary-blue font-normal">Discount Type</p>
          <Dropdown
            menuList={[
              { id: "percentage", name: "Percentage" },
              { id: "amount", name: "Amount" },
            ]}
            defaultValue={{ id: "percentage", name: "Percentage" }}
            dropdownStyle="w-[142px] h-[40px] border border-primary-blue flex items-center justify-around text-lg text-primary-blue font-normal"
            arrowStyle="w-[20px] h-[15px] cursor-pointer"
            dropdownMenuStyle="w-[142px] bg-white"
            dropdownHandler={(option) =>
              setCreateCouponData({
                ...createCouponData,
                discountType: option.id,
              })
            }
          />
        </div>

        <div className="flex items-center space-x-5">
          <p className="text-lg text-primary-blue font-normal">
            Discount Value
          </p>
          <input
            type="text"
            placeholder={
              createCouponData.discountType === "amount"
                ? "Enter discount value"
                : "Enter the discount percentage"
            }
            className="px-5 py-1 max-w-max text-secondary-blue focus:outline-none border border-primary-blue bg-transparent placeholder:text-secondary-blue-lt placeholder:text-sm text-lg"
            value={createCouponData.discountValue}
            onChange={(e) =>
              setCreateCouponData({
                ...createCouponData,
                discountValue: e.target.value,
              })
            }
          />
        </div>
      </div>

      <div className="flex items-center space-x-10 mt-10">
        <div className="flex items-center space-x-5">
          <p className="text-lg text-primary-blue font-normal">Intent</p>
          <Dropdown
            menuList={[
              { id: "discount", name: "Discount" },
              {
                id: "discount_and_credits_addition",
                name: "Discount and Credits Addition",
              },
            ]}
            defaultValue={{ id: "discount", name: "Discount" }}
            dropdownStyle="w-[280px] px-3 h-[40px] border border-primary-blue flex items-center justify-between text-lg text-primary-blue font-normal"
            arrowStyle="w-[20px] h-[15px] cursor-pointer"
            dropdownMenuStyle="w-full bg-white"
            dropdownHandler={(option) =>
              setCreateCouponData({
                ...createCouponData,
                intent: option.id,
              })
            }
          />
        </div>

        {createCouponData.intent === "discount_and_credits_addition" && (
          <div className="flex items-center space-x-5">
            <p className="text-lg text-primary-blue font-normal">
              Credits to Add
            </p>
            <input
              type="text"
              placeholder="Enter the credits you want to add"
              className="px-5 py-1 max-w-max text-secondary-blue focus:outline-none border border-primary-blue bg-transparent placeholder:text-secondary-blue-lt placeholder:text-sm text-lg"
              value={createCouponData.creditsToAdd}
              onChange={(e) =>
                setCreateCouponData({
                  ...createCouponData,
                  creditsToAdd: e.target.value,
                })
              }
            />
          </div>
        )}
      </div>

      <div className="flex items-center space-x-10 mt-10">
        <div className="flex items-center space-x-5">
          <p className="text-lg text-primary-blue font-normal">Currency</p>
          <Dropdown
            menuList={[
              { id: "USD", name: "USD" },
              { id: "INR", name: "INR" },
            ]}
            defaultValue={{ id: "usd", name: "USD" }}
            dropdownStyle="w-[142px] h-[40px] border border-primary-blue flex items-center justify-around text-lg text-primary-blue font-normal"
            arrowStyle="w-[20px] h-[15px] cursor-pointer"
            dropdownMenuStyle="w-[142px] bg-white"
            dropdownHandler={(option) =>
              setCreateCouponData({
                ...createCouponData,
                discountCurrency: option.id,
              })
            }
          />
        </div>

        <div className="flex items-center space-x-5">
          <p className="text-lg text-primary-blue font-normal">Codes Count</p>
          <input
            type="text"
            placeholder="Enter number of codes"
            className="px-5 py-1 max-w-max text-secondary-blue focus:outline-none border border-primary-blue bg-transparent  placeholder:text-secondary-blue-lt placeholder:text-sm placeholder:text-left text-lg"
            value={createCouponData.codesCount}
            onChange={(e) =>
              setCreateCouponData({
                ...createCouponData,
                codesCount: parseInt(e.target.value),
              })
            }
          />
        </div>
      </div>
      <Button
        onClick={createCouponCodeAPI}
        isLoading={isLoading}
        disabled={isLoading}
        innerText="Create Coupon Code"
        type="button"
        extraClass="my-10 py-1 px-5 max-w-max rounded-full bg-primary-green"
        innerTextStyle="text-xs md:text-base xl:text-lg font-normal text-white"
      />
    </div>
  );
};

export default CreateDiscountCoupons;
