import React from "react";

const Hamburger = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 35 24"
      {...rest}
    >
      <path
        fill="#434484"
        d="M33.198 10.111H1.45c-.813 0-1.45.637-1.45 1.45s.637 1.45 1.45 1.45h31.748c.813 0 1.45-.637 1.45-1.45s-.637-1.45-1.45-1.45zM1.45 2.9h27.434c.814 0 1.45-.636 1.45-1.45 0-.812-.636-1.449-1.45-1.449H1.45C.637 0 0 .636 0 1.45 0 2.263.637 2.9 1.45 2.9zm27.434 17.324H1.45c-.813 0-1.45.636-1.45 1.45 0 .812.636 1.449 1.45 1.449h27.434c.814 0 1.45-.636 1.45-1.45 0-.813-.671-1.45-1.45-1.45z"
      ></path>
    </svg>
  );
};

export default Hamburger;
