import React, { useState } from "react";
import Button from "../../components/Button/Button";
import { BACKEND_BASE_URL } from "../../helpers/url/url";
import axios from "../../helpers/axios/axios";
import { validateEmail } from "../../helpers/functions/email-validator";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(null);
  const [error, setError] = useState({ isError: false, message: "" });

  const contactUsAPI = async () => {
    setError({ isError: false, message: "" });
    if (validateEmail(userInfo.email) !== null) {
      setIsEmailValid(true);
      setIsLoading(true);
      try {
        const contactUsResponse = await axios.post(
          `${BACKEND_BASE_URL}/api/query`,
          {
            name: userInfo.name,
            email: userInfo.email,
            message: userInfo.message,
          }
        );
        if (contactUsResponse.status === 202) {
          setIsLoading(false);
          setError({
            isError: false,
            message:
              "We have received your request and will contact you shortly!",
          });
        }
        console.log(contactUsResponse, "contactUsResponse");
      } catch (err) {
        console.log(err);
        if (err.response.status === 500) {
          setIsLoading(false);
          setError({
            isError: true,
            message: "Something went wrong, please try again!",
          });
        }
      }
    } else {
      setIsEmailValid(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Krafternoon</title>
        <meta
          name="description"
          content="Personalized learning options in the comfort of your home."
        />
        <meta property="og:title" content="Krafternoon contacting page." />
      </Helmet>
      <div className="max-w-screen flex flex-col items-center md:items-start md:flex-row md:justify-around my-20">
        <div className="text-center">
          <h2 className="font-bold text-primary-background-blue text-xl lg:text-3xl">
            Feel free to ask anything.
          </h2>
          <h2 className="font-bold text-primary-background-blue text-xl lg:text-3xl">
            We're here to serve you!
          </h2>
          <img
            src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/dizzy-sending-a-letter_1_Bq32hmGOoA.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653667819777"
            alt=""
            className="hidden md:inline-flex mt-10 md:w-[385px] md:h-[216px] lg:w-[430px] lg:h-[250px] xl:w-[578px] xl:h-[325px]"
          />
        </div>

        <div className="mt-5 mb-10 md:mb-0 md:mt-0 w-[300px] md:w-[320px] md:h-full xl:w-[392px] xl:h-[472px] p-5 xl:px-5 xl:py-7 flex flex-col space-y-5 border border-secondary-blue rounded-2xl">
          {!error.isError && (
            <p
              className={`text-sm xl:text-lg font-medium text-center mt-3 text-primary-green`}
            >
              {error.message}
            </p>
          )}
          {error.isError && (
            <p
              className={`text-sm xl:text-lg font-medium text-center mt-3 text-primary-red`}
            >
              {error.message}
            </p>
          )}
          <div className="w-full">
            <p className="xl:mb-2 text-sm xl:text-[20px] text-secondary-blue font-normal">
              Full Name
            </p>
            <input
              className="w-full py-2 px-3 text-xs xl:text-base placeholder:text-xs xl:placeholder:text-sm focus:outline-none rounded-lg text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-ternary-blue"
              type="text"
              id="name"
              placeholder="Enter your full name"
              onChange={(e) =>
                setUserInfo({ ...userInfo, name: e.target.value })
              }
              value={userInfo.name}
            />
          </div>

          <div className="w-full">
            <p className="xl:mb-2 text-sm xl:text-[20px] text-secondary-blue font-normal">
              Email Address
            </p>
            <input
              className={`w-full py-2 px-3 text-xs xl:text-base placeholder:text-xs xl:placeholder:text-sm focus:outline-none rounded-lg text-primary-blue placeholder:text-secondary-blue-ltr-ltr border ${
                isEmailValid === false
                  ? "border-primary-red"
                  : "border-ternary-blue"
              }`}
              type="text"
              id="name"
              placeholder="Enter email id"
              onChange={(e) =>
                setUserInfo({ ...userInfo, email: e.target.value })
              }
              value={userInfo.email}
            />
            {isEmailValid === false && (
              <p
                className={`text-xs sm:text-sm font-normal ml-1 mt-1 sm:mt-2 text-primary-red`}
              >
                Invalid Email Address
              </p>
            )}
          </div>

          <div className="w-full">
            <p className="xl:mb-2 text-sm xl:text-[20px] text-secondary-blue font-normal">
              Message
            </p>
            <textarea
              style={{ resize: "none" }}
              className="w-full h-[70px] xl:h-[96px] py-2 px-3 text-xs xl:text-base placeholder:text-xs xl:placeholder:text-sm focus:outline-none rounded-lg text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-ternary-blue"
              id="message"
              placeholder="Write down your query"
              onChange={(e) =>
                setUserInfo({ ...userInfo, message: e.target.value })
              }
              value={userInfo.message}
            />
          </div>

          <Button
            onClick={contactUsAPI}
            innerText="Contact Us"
            type="submit"
            extraClass={`text-center py-1 text-sm sm:text-base xl:text-lg text-white font-medium  rounded-full bg-primary-green ${
              isLoading && "opacity-20"
            }`}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </div>
        <img
          src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/dizzy-sending-a-letter_1_Bq32hmGOoA.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653667819777"
          alt=""
          className="md:hidden"
        />
      </div>
    </>
  );
};

export default ContactUs;
