import React from "react";
import Clock from "../../../../../assets/SVG/clock";

const daysOfWeek = ["Monday", "Wednesday", "Friday"];

const ClassScheduleInfo = () => {
  return (
    <div className="flex flex-col space-y-5 w-[510px] px-10 py-5">
      <h3 className="text-xl text-secondary-blue font-bold">Batch-GD003</h3>
      <div className="flex items-center space-x-2">
        <p className="text-xl font-normal text-secondary-blue">Start Date:</p>
        <p className="text-lg font-normal text-primary-purple underline decoration-1 decoration-black underline-offset-4">
          dd/mm/yyyy
        </p>
      </div>
      <div className="flex items-center space-x-2">
        <p className="text-xl font-normal text-secondary-blue">No. of Days:</p>
        <p className="text-lg font-normal text-primary-purple underline decoration-1 decoration-black underline-offset-4">
          03
        </p>
      </div>
      <div className="w-[365px]">
        <table className="border border-secondary-blue">
          <thead>
            <tr className="text-xl font-semibold text-secondary-blue">
              <th className="py-2 px-5 border-r border-border-gray">
                Frequency:
              </th>
              <th className="py-2 px-5">Class Time</th>
            </tr>
          </thead>
          <tbody>
            {daysOfWeek.map((day, index) => (
              <tr key={day}>
                <td className="py-2 px-5 border-t border-r border-border-gray">
                  <span className="text-base font-normal text-secondary-blue">
                    {day}
                  </span>
                </td>
                <td className="py-2 px-5 border-t border-border-gray">
                  <div className="flex items-center space-x-2">
                    <Clock className="w-[24px] h-[24px]" />
                    <p className="text-lg font-normal text-primary-purple">
                      00:00
                    </p>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ClassScheduleInfo;
