import React from "react";
import { Link, useParams } from "react-router-dom";

const Navigator = ({ navigationOptions }) => {
  const { courseType, courseName } = useParams();
  console.log(navigationOptions, "navigationOptions");

  return (
    <div className="w-full">
      <div className="w-full bg-primary-background-blue flex items-center space-x-10 lg:space-x-16 px-10 sm:px-20 pt-10">
        <Link
          className={`text-lg pb-2 ${
            courseType === "all-courses"
              ? "font-semibold bg-white text-primary-background-blue px-3 pt-3 rounded-t-lg"
              : "text-white"
          }`}
          to="/student/my-learning/all-courses"
        >
          All courses
        </Link>
        <Link
          className={`text-lg pb-2 ${
            courseType === "bootcamp"
              ? "font-semibold bg-white text-primary-background-blue px-3 pt-3 rounded-t-lg"
              : "text-white"
          }`}
          to="/student/my-learning/bootcamp"
        >
          Bootcamp
        </Link>
      </div>
      {courseType === "all-courses" && (
        <div className="w-full pl-20 sm:px-[100px] pt-7 flex items-center space-x-20 border-b border-primary-purple">
          {navigationOptions.map((option, index) => (
            <Link
              key={index}
              className={`pb-1 text-sm sm:text-base lg:text-lg text-center ${
                courseName === option
                  ? "border-b-[3px] border-ternary-pink font-bold text-secondary-blue"
                  : "font-normal text-secondary-blue-lt "
              }`}
              to={`/student/my-learning/all-courses/${option}`}
            >
              {option}
            </Link>
          ))}
        </div>
      )}
      {courseType === "bootcamp" && (
        <div className="w-full px-[100px] pt-7 flex items-center space-x-20 border-b border-primary-purple">
          <Link
            className={`pb-1 text-lg text-center ${
              courseName === "game-design"
                ? "border-b-[3px] border-ternary-pink font-bold text-secondary-blue"
                : "font-normal text-secondary-blue-lt "
            }`}
            to="/student/my-learning/bootcamp/game-design"
          >
            Game Design
          </Link>
          <Link
            className={`pb-1 text-lg text-center ${
              courseName === "chess-fundamentals"
                ? "border-b-[3px] border-ternary-pink font-bold text-secondary-blue"
                : "font-normal text-secondary-blue-lt "
            }`}
            to="/student/my-learning/bootcamp/chess-fundamentals"
          >
            Chess Fundamentals
          </Link>
        </div>
      )}
    </div>
  );
};

export default Navigator;
