import React from "react";

const CircleRemove = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 25 25"
      {...rest}
    >
      <path
        fill="#F09920"
        d="M12.5 25A12.5 12.5 0 1125 12.5 12.513 12.513 0 0112.5 25zm0-23.077A10.577 10.577 0 1023.077 12.5 10.59 10.59 0 0012.5 1.923z"
      ></path>
      <path
        fill="#F09920"
        d="M17.308 13.462H7.692a.961.961 0 010-1.923h9.616a.961.961 0 110 1.923z"
      ></path>
    </svg>
  );
};

export default CircleRemove;
