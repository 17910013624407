import React from "react";

const LiveClasses = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 77 77"
    >
      <path
        fill="#FFE8F5"
        d="M69.021.752H7.486A7.328 7.328 0 00.16 8.078v49.815a7.329 7.329 0 007.326 7.325h16.116v8.791h-5.86a1.465 1.465 0 000 2.93h41.023a1.465 1.465 0 000-2.93h-5.86v-8.79H69.02a7.328 7.328 0 007.326-7.326V8.078A7.328 7.328 0 0069.021.752zM49.975 74.01H26.532v-8.79h23.443v8.79zm23.442-16.116a4.396 4.396 0 01-4.396 4.395H7.486a4.396 4.396 0 01-4.395-4.395v-4.396h55.675a1.465 1.465 0 000-2.93H3.09V8.078a4.396 4.396 0 014.395-4.395H69.02a4.396 4.396 0 014.396 4.395v42.489h-5.86a1.465 1.465 0 000 2.93h5.86v4.396z"
      ></path>
      <path
        fill="#FFE8F5"
        d="M25.069 30.758a9.142 9.142 0 100-18.285 9.142 9.142 0 000 18.285zm0-15.354a6.213 6.213 0 110 12.426 6.213 6.213 0 010-12.426zM25.065 32.43a13.772 13.772 0 00-12.996 9.244 4.398 4.398 0 00.586 4.044 4.602 4.602 0 003.62 1.92h1.465a1.465 1.465 0 000-2.93h-1.465a1.64 1.64 0 01-1.333-.69 1.464 1.464 0 01-.206-1.377 10.811 10.811 0 0120.512 0c.168.464.091.982-.205 1.377-.306.433-.803.69-1.333.69H23.6a1.465 1.465 0 000 2.93h10.095a4.6 4.6 0 003.721-1.92 4.395 4.395 0 00.587-4.044 13.74 13.74 0 00-12.938-9.245zM70.487 43.241V28.59a4.395 4.395 0 00-4.395-4.396H51.441a4.396 4.396 0 00-4.396 4.396V43.24a4.396 4.396 0 004.396 4.395h14.651a4.396 4.396 0 004.395-4.395zm-20.511 0V28.59c0-.81.656-1.465 1.465-1.465h14.65a1.465 1.465 0 011.466 1.465V43.24a1.465 1.465 0 01-1.465 1.465h-.747a2.953 2.953 0 00-.147-.85 7.005 7.005 0 00-4.498-4.395 4.968 4.968 0 10-4.395 0 7.003 7.003 0 00-4.498 4.395c-.09.274-.14.561-.147.85h-.22c-.808 0-1.464-.656-1.464-1.465zm8.556-6.197a2.036 2.036 0 110-4.073 2.036 2.036 0 010 4.073zm-3.868 7.662a4.163 4.163 0 013.868-2.667 4.31 4.31 0 013.956 2.667h-7.824z"
      ></path>
    </svg>
  );
};
export default LiveClasses;
