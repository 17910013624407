import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../../helpers/url/url";
import Navigator from "../../components/Navigator/Navigator";
import Sidebar from "../../components/Sidebar/Sidebar";
//import ClassRecordings from "./components/ClassRecordings/ClassRecordings";
import CourseDashboard from "./components/CourseDashboard/CourseDashboard";
//import EnrolledCamps from "./components/EnrolledCamps/EnrolledCamps";
import axios from "../../../helpers/axios/axios";
import LoadingSpinner from "../../../components/Spinners/LoadingSpinner/LoadingSpinner";
import Button from "../../../components/Button/Button";

// const classDetails = [
//   {
//     id: 1,
//     courseName: "Chess Fundamental",
//     date: "05/06/2022",
//     time: "12:30",
//     timezone: "PDT",
//     imageUrl:
//       "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Random/Rectangle_693_PnUL_Tnq_.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654871316235",
//   },
//   {
//     id: 2,
//     courseName: "Graphic Design",
//     date: "07/06/2022",
//     time: "10:30",
//     timezone: "PDT",
//     imageUrl:
//       "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Random/Rectangle_702_AKRTWqC8J.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654871316233",
//   },
//   {
//     id: 3,
//     courseName: "Chess Fundamental",
//     date: "05/06/2022",
//     time: "12:30",
//     timezone: "PDT",
//     imageUrl:
//       "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Random/Rectangle_693_PnUL_Tnq_.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654871316235",
//   },
//   {
//     id: 4,
//     courseName: "Graphic Design",
//     date: "07/06/2022",
//     time: "10:30",
//     timezone: "PDT",
//     imageUrl:
//       "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Random/Rectangle_702_AKRTWqC8J.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654871316233",
//   },
// ];

// const classRecordingDetails = [
//   {
//     id: 1,
//     courseName: "Introduction to Chess Fundamental",
//     date: "05/06/2022",
//     time: "12:30",
//     timezone: "PDT",
//     imageUrl:
//       "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/giphy__5__2_WUE3KzZ6s.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652622074743",
//   },
//   {
//     id: 2,
//     courseName: "Graphic Design",
//     date: "07/06/2022",
//     time: "10:30",
//     timezone: "PDT",
//     imageUrl:
//       "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/fxah_2_aBBdoMOMa.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652622074797",
//   },
// ];

// const enrolledCamps = [
//   {
//     id: 1,
//     courseName: "Logo Designing",
//     frequency: "Weekly",
//     day: "Thur",
//     time: "09:30 AM",
//   },
//   {
//     id: 2,
//     courseName: "Chess Basics",
//     frequency: "Weekly",
//     day: "Wed",
//     time: "04:30 PM",
//   },
// ];

const MyLearning = () => {
  const { courseType } = useParams();
  const [enrolledClasses, setEnrolledClasses] = useState([]);
  const [navigationOptions, setNavigationOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  console.log(enrolledClasses, "enrolledClasses");

  useEffect(() => {
    const getAllEnrolledCoursesAPI = async () => {
      setIsLoading(true);
      try {
        const allCoursesResponse = await axios.get(
          `${BACKEND_BASE_URL}/api/students/getClasses`
        );
        console.log(allCoursesResponse);
        if (allCoursesResponse?.status === 200) {
          setEnrolledClasses([...allCoursesResponse?.data?.studentClasses]);
          let ALL_COURSES = [...allCoursesResponse?.data?.studentClasses];
          let NAVIGATOR_FOUND = {};
          let NAVIGATORS = [];

          for (let i = 0; i < ALL_COURSES.length; i++) {
            const courseName = ALL_COURSES[i]?.courseInfo?.courseName;
            if (!NAVIGATOR_FOUND[courseName]) {
              NAVIGATORS.push(courseName);
              NAVIGATOR_FOUND[courseName] = true;
            }
          }

          setNavigationOptions([...NAVIGATORS]);
          setIsLoading(false);
        }
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };
    getAllEnrolledCoursesAPI();
  }, []);

  useEffect(() => {
    if (navigationOptions.length > 0 && courseType === "all-courses") {
      navigate(`/my-learning/all-courses/${navigationOptions[0]}`);
    }
  }, [navigationOptions, navigate, courseType]);

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <div className="max-w-screen min-h-screen">
          <Navigator navigationOptions={navigationOptions} />
          <div className="flex">
            <Sidebar />
            {navigationOptions.length === 0 && (
              <div className="flex flex-col items-center w-full text-sm sm:text-lg lg:text-2xl text-primary-purple text-center mt-5">
                <h1>You are not enrolled in any course!</h1>
                <Button
                  onClick={() => navigate("/student/dashboard")}
                  innerText="Go to Dashboard"
                  type="button"
                  extraClass="mt-5 max-w-max lg:py-2 lg:px-5 sm:text-lg text-white font-normal rounded-full bg-primary-green"
                />
              </div>
            )}
            {courseType === "all-courses" && navigationOptions.length > 0 && (
              <div className="m-5 sm:m-10 flex w-full">
                <CourseDashboard classDetails={enrolledClasses} />
                {/* <ClassRecordings
                  classRecordingDetails={classRecordingDetails}
                /> */}
              </div>
            )}
            {/* {courseType === "bootcamp" && (
              <div className="m-10 w-full relative">
                <CourseDashboard classDetails={classDetails.slice(0, 1)} />
                <EnrolledCamps enrolledCamps={enrolledCamps} />
              </div>
            )} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default MyLearning;
