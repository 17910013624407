import { createRoot } from "react-dom/client";
import "./index.css";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import store from "./redux/store/store";
import { Provider } from "react-redux";
//import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
if (process.env.NODE_ENV !== "development") console.log = () => {};
// ReactGA.initialize("G-50TD2VGTSE");

TagManager.initialize({
  gtmId: "GTM-KBLJFRJ",
});

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);
