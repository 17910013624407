import React from "react";

const CircularRight = () => {
  return (
    <div className="bg-primary-purple w-[34px] h-[34px] 2xl:w-[48px] 2xl:h-[48px] rounded-full flex items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="23"
        fill="none"
        viewBox="0 0 14 23"
      >
        <path
          fill="#434484"
          fillRule="evenodd"
          d="M13.552 10.013L2.651.395c-.635-.527-1.606-.527-2.203 0-.597.527-.597 1.384 0 1.944l9.819 8.663-9.819 8.662a1.278 1.278 0 000 1.944c.597.527 1.568.527 2.203 0l10.901-9.651c.597-.527.597-1.384 0-1.944z"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  );
};

export default CircularRight;
