import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const CarouselComponent = ({ carouselImages }) => {
  return (
    <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false}>
      {carouselImages.map((image) => (
        <div key={image.src}>
          <img src={image.src} alt="" />
        </div>
      ))}
    </Carousel>
  );
};

export default CarouselComponent;
