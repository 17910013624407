import React from "react";
import Logo from "../../assets/images/logo.png";
import Facebook from "../../assets/SVG/facebook";
import Instagram from "../../assets/SVG/instagram";
import Linkedin from "../../assets/SVG/linkedin";
import Email from "../../assets/SVG/email";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="w-full bg-white px-5 py-10 lg:p-10 shadow-sm">
      <div className="grid grid-cols-2 gap-y-10 lg:gap-y-0 place-items-center lg:flex lg:flex-row lg:items-start lg:justify-between">
        <div className="col-span-2 flex flex-col space-y-3 lg:space-y-0 lg:flex-row items-center lg:space-x-7">
          <div className="flex flex-col items-center">
            <img src={Logo} alt="Krafternoon" />
            <p className="font-bold  text-lg">KRAFTERNOON</p>
            <p className="font-normal  text-sm -mt-2">KRAFTING SKILLS</p>
          </div>
          <p className="text-center lg:text-left text-sm xl:text-base text-secondary-blue max-w-xs">
            Platform which focusses on futuristic Creative & Analytical skills
            through real-world applications for young minds.
          </p>
        </div>

        <div className="flex flex-col space-y-1 text-sm xl:text-lg text-secondary-blue">
          <Link to="/pricing/options">Pricing</Link>
          <Link to="/creative-champs">Creative Champs</Link>
          <Link to="/our-clients">Our Clients</Link>
          <Link to="/about-us">About Us</Link>
        </div>

        <div className="flex flex-col space-y-1 text-sm xl:text-lg text-secondary-blue">
          <Link to="/contact-us">Contact Us</Link>
          <Link to="/frequently-asked-questions">FAQs</Link>
          <Link to="/t&c">Terms & Conditions</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </div>

        <div className="col-span-2 text-sm xl:text-lg text-secondary-blue">
          <p>Follow us:</p>
          <div className="my-3 flex items-center space-x-3">
            <a
              href="https://www.facebook.com/Krafternoon_US-115579991145040"
              target="_blank"
              rel="noreferrer"
            >
              <Facebook />
            </a>
            <a
              href="https://www.instagram.com/krafternoon_us/?hl=en"
              target="_blank"
              rel="noreferrer"
            >
              <Instagram />
            </a>
            <a
              href="https://www.linkedin.com/company/krafternoon-com/"
              target="_blank"
              rel="noreferrer"
            >
              <Linkedin />
            </a>
          </div>
          <div className="flex items-center space-x-3">
            <p>Write us at:</p>
            <a href="mailto:tech@krafternoon.com">
              <Email />
            </a>
          </div>
        </div>
      </div>
      <div className="my-10 text-center text-sm text-secondary-blue font-normal ">
        <p>2021 Krafternoon</p>
        <div className="flex items-center justify-center space-x-2">
          <span>Terms Of Use</span>
          <span>|</span>
          <span>Privacy Policy</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
