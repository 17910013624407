import React from "react";

const CircularTick = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill="#FFE8F5"
        d="M15.315 7.084l-5.962 5.96-2.668-2.668a.885.885 0 00-1.248 0 .885.885 0 000 1.248l3.291 3.292a.879.879 0 001.249 0l6.586-6.584a.885.885 0 000-1.248.88.88 0 00-1.249 0z"
      ></path>
      <path
        fill="#FFE8F5"
        d="M11 0C4.933 0 0 4.933 0 11s4.933 11 11 11 11-4.933 11-11S17.067 0 11 0zm0 20.235c-5.09 0-9.235-4.142-9.235-9.235 0-5.093 4.145-9.235 9.235-9.235S20.235 5.907 20.235 11c0 5.093-4.145 9.235-9.235 9.235z"
      ></path>
    </svg>
  );
};

export default CircularTick;
