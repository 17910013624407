import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import ReactGA4 from "react-ga4";

import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import ContactUs from "./Pages/ContactUs/ContactUs";
import LandingPage from "./Pages/LandingPage/LandingPage";
import Signup from "./Pages/Signup/Signup";
import Signin from "./Pages/Signin/Signin";
import VerifyOtp from "./Pages/VerifyOtp/VerifyOtp";
import CreativeChamps from "./Pages/CreativeChamps/CreativeChamps";
import SummerBootcamp from "./Pages/SummerBootcamp/SummerBootcamp";
import BootcampRegister from "./Pages/BootcampRegister/BootcampRegister";
//import Dashboard from "./Pages/Dashboard/Dashboard";
import Instructor from "./Pages/Instructors/Instructor";
//import BootcampDashboard from "./Pages/Dashboard/BootcampDashboard";
import BootcampPaymentOutcome from "./Pages/BootcampRegister/components/BootcampPaymentOutcome";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import ProtectedRoute from "./helpers/protected-route/protected-route";
import NoMatch from "./Pages/NoMatch/NoMatch";
import FrequentlyAskedQuestions from "./Pages/FrequentlyAskedQuestions/FrequentlyAskedQuestions";
import OurClients from "./Pages/OurClients/OurClients";
import AboutUs from "./Pages/AboutUs/AboutUs";
import OauthRedirect from "./Pages/OauthRedirect/OauthRedirect";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "./redux/features/auth-slice";
import Admin from "./Admin/Admin";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide } from "react-toastify";
import Student from "./Student/Student";
import MyLearning from "./Student/Pages/MyLearning/MyLearning";
import Payment from "./Pages/Payment/Payment";
import Header from "./Student/components/Header/Header";
import LoadingSpinner from "./components/Spinners/LoadingSpinner/LoadingSpinner";
import WalletPlan from "./Student/Pages/WalletPlan/WalletPlan";
import ActivePricingPlan from "./Student/Pages/ActivePricingPlan/ActivePricingPlan";
import MyProfile from "./Student/Pages/MyProfile/MyProfile";
import { Helmet } from "react-helmet";

const App = () => {
  const [bootcamps, setBootcamps] = useState([]);

  const dispatch = useDispatch();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const redirectUrl = localStorage.getItem("redirectUrl");

  //console.log(auth, "auth");

  useEffect(() => {
    ReactGA4.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (
      userData &&
      userData.token &&
      userData.expirationDate &&
      new Date(userData.expirationDate) > new Date()
    ) {
      dispatch(
        authActions.login({
          token: userData.token,
          user: userData.user,
          expirationDate: userData.expirationDate,
        })
      );
    } else {
      dispatch(authActions.logout());
    }
  }, [dispatch]);

  return (
    <div className="relative min-h-full max-w-screen">
      {!auth.isLoggedIn &&
        !location.pathname.match("admin") &&
        !location.pathname.match("student") &&
        !location.pathname.match("pricing-plan") && <Navbar />}
      {auth.isLoggedIn && <Header />}
      <main>
        <ScrollToTop>
          <ToastContainer
            position="top-right"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            transition={Slide}
          />

          <Routes>
            <Route
              path="/"
              element={
                !auth.token ? (
                  <LandingPage />
                ) : (
                  <Navigate to="/student/dashboard" replace />
                )
              }
            />
            <Route
              path="/signup"
              element={
                !auth.token ? (
                  <Signup />
                ) : (
                  <Navigate
                    to={redirectUrl ? redirectUrl : "/student/dashboard"}
                    replace
                  />
                )
              }
            />
            <Route
              path="/signin"
              element={
                !auth.token ? (
                  <Signin />
                ) : (
                  <Navigate
                    to={redirectUrl ? redirectUrl : "/student/dashboard"}
                    replace
                  />
                )
              }
            />
            <Route
              path="/verify-otp"
              element={
                !auth.token ? (
                  <VerifyOtp />
                ) : (
                  <Navigate
                    to={redirectUrl ? redirectUrl : "/student/dashboard"}
                    replace
                  />
                )
              }
            />

            <Route path="/pricing/:type" element={<Payment />} />
            <Route path="/explore-skills/:skillType" element={<Instructor />} />
            <Route path="/creative-champs" element={<CreativeChamps />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/our-clients" element={<OurClients />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/oauth/:token" element={<OauthRedirect />} />
            <Route
              path="/frequently-asked-questions"
              element={<FrequentlyAskedQuestions />}
            />
            <Route
              path="/summer-bootcamp"
              element={
                <SummerBootcamp
                  bootcamps={bootcamps}
                  setBootcamps={setBootcamps}
                />
              }
            />

            <Route
              path="/payment/:result"
              element={<BootcampPaymentOutcome />}
            />

            <Route
              element={
                <ProtectedRoute token={auth.token} redirectPath="/signin" />
              }
            >
              <Route
                path="/bootcamp-register/:type"
                element={<BootcampRegister />}
              />
              {/* <Route path="/dashboard">
                <Route index element={<Dashboard />} />
                <Route path=":bootcamp" element={<BootcampDashboard />} />
              </Route> */}

              <Route path="/my-learning/:courseType">
                <Route index element={<MyLearning />} />
                <Route path=":courseName" element={<MyLearning />} />
              </Route>

              <Route path="/student/:type">
                <Route index element={<Student />} />
                <Route path=":subType" element={<Student />} />
              </Route>
            </Route>
            <Route path="my-pricing-plan" element={<ActivePricingPlan />} />
            <Route path="my-wallet" element={<WalletPlan />} />
            <Route path="my-profile" element={<MyProfile />} />

            <Route path="/admin/:type" element={<Admin />} />

            <Route path="*" element={<NoMatch />} />

            <Route path="/test" element={<LoadingSpinner />} />
          </Routes>
          <ToastContainer />
        </ScrollToTop>
      </main>

      {location.pathname !== "/verify-otp" &&
        location.pathname !== "/signup" &&
        location.pathname !== "/signin" &&
        location.pathname !== "/payment/success" &&
        location.pathname !== "/payment/failure" &&
        !location.pathname.match("admin") &&
        !location.pathname.match("student") &&
        !location.pathname.match("pricing-plan") &&
        !location.pathname.match("my-wallet") &&
        !location.pathname.match("my-profile") && <Footer />}
    </div>
  );
};

export default App;
