import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const Sidebar = ({ heading, menuList }) => {
  const navigate = useNavigate();
  const { type } = useParams();
  return (
    <div className="w-[300px] min-h-screen bg-primary-purple rounded-r-3xl">
      <h2 className="text-2xl text-secondary-blue font-bold pt-10 pb-5 p-16">
        {heading}
      </h2>
      {menuList.length > 0 && (
        <div className="">
          {menuList.map((option) => (
            <p
              key={option.id}
              className={`w-full pl-16 py-3 my-2 text-lg text-secondary-blue hover:font-bold hover:bg-primary-pink cursor-pointer ${
                type === option.id
                  ? "bg-primary-pink font-bold"
                  : "bg-transparent font-normal"
              }`}
              onClick={() => navigate(`${option.url}`)}
            >
              {option.name}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Sidebar;
