import React, { useEffect, useState } from "react";
import TransformCard from "../../components/Card/TransformCard/TransformCard";
import InfoCard from "../../components/Card/InfoCard/InfoCard";
import TypewriterEffect from "../../components/TypewriterEffect/TypewriterEffect";
//import WeTeachCreativeAnalyticalSkills from "../../components/WeTeachCreativeAnalyticalSkills/WeTeachCreativeAnalyticalSkills";
import MostInDemandFacilities from "../../components/MostInDemandFacilities/MostInDemandFacilities";
import PersonalizedCourses from "../../assets/SVG/personalized-courses";
import LiveClasses from "../../assets/SVG/live-classes";
import FunLearning from "../../assets/SVG/fun-learning";
import axios from "../../helpers/axios/axios";
import { BACKEND_BASE_URL } from "../../helpers/url/url";
import CircularArrowBack from "../../assets/SVG/circular-arrow-back";
import CircularArrowForward from "../../assets/SVG/circular-arrow-forward";
import { useNavigate } from "react-router-dom";
import BootcampCard from "../../components/Card/BootcampCard/BootcampCard";
import { isIOS } from "../../helpers/functions/is-ios";
import ReactPlayerComponent from "../../components/ReactPlayerComponent/ReactPlayerComponent";
import LandingPagePopup from "../../components/LandingPagePopup/LandingPagePopup";

import LearnAnySkill from "../../components/Card/LearnAnySkill/LearnAnySkill";
import { Helmet } from "react-helmet";

const allSkills = [
  {
    id: "graphic-designer",
    skillName: "Be a creative Graphic Designer",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/91aa3120ade4f94cedbc819b644230cb_6_WBPYBJeDa.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653587082234",
    redirectUrl: "/explore-skills/graphic-design",
  },
  {
    id: "chess-grandmaster",
    skillName: "Take the first step towards becoming Chess Grandmaster",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/giphy__5__1__1__w3IlPYYox.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581437258",
    redirectUrl: "/explore-skills/chess",
  },
  {
    id: "coding-languages",
    skillName: "Learn most-in-demand coding languages",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/fxah_1_OdiuzFueRb.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581436951",
    redirectUrl: "/explore-skills/coding",
  },
  {
    id: "web-design",
    skillName: "Create your own Website",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/91aa3120ade4f94cedbc819b644230cb_6__1__f-cvfjuql.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653587414387",
    redirectUrl: "/explore-skills/web-design",
  },
  {
    id: "video-editing",
    skillName: "Learn video editing like Pro!",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/giphy__5__3_n_UsDjsmo.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653587414892",
    redirectUrl: "/explore-skills/video-editing",
  },
  {
    id: "3d-model",
    skillName: "Turn your ideas into 3D Model",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/fxah_3_HE0YWbL99.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653587414952",
    redirectUrl: "/explore-skills/3D-modelling",
  },
];

const allBootcamps = {
  kn62c412206a5afca341b602c7: {
    courseName: "Learn Basics of Chess",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/giphy__5__1__1__w3IlPYYox.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581437258",
    minAge: 4,
    courseDescription:
      "This two-week Chess Summer Camp led by industry experts is an intensive introduction for those interested in learning chess. Through this course, your child will be learning the history of chess, the basics of chessboard and chess pieces, the unique powers of all pieces, various rules of chess, and special moves of chess. We will end this summer camp with an amazing chess tournament where your child will be able to showcase their learnings.",
    seatsAvailable: 5,
  },

  kn62c412fa6a5afca341b602d9: {
    courseName: "Logo Designing",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/91aa3120ade4f94cedbc819b644230cb_3__1__oHYJbpqPg.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581436735",
    minAge: 7,
    courseDescription:
      "This two-week Digital Arts Summer Camp led by industry experts is an intensive introduction for those interested in learning about digital arts. Through this course, your child will have the opportunity to sketch various logos and give them a digital effect. They will learn colour theory, typography, and Photoshop tools. Give your child a head starts into the magical world of design with Krafternoon!",
    seatsAvailable: 5,
  },

  kn628d237e28a1a61550c34d44: {
    courseName: "Musical Instruments with coding",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/fxah_1_OdiuzFueRb.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581436951",
    minAge: 7,
    courseDescription:
      "This two-week Coding Summer Camp led by industry experts is an intensive introduction for those interested in learning about coding and its applications. Through this course, your child will learn the basics of web development, web designing, and front-end development. Your child will develop a piano on a website that will produce the same sound effects as an original piano. Enhance your child’s analytical thinking with Krafternoon’s Coding Summer Bootcamp.",
    seatsAvailable: 5,
  },

  kn62c411c86a5afca341b602be: {
    courseName: "Develop your own Website",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/91aa3120ade4f94cedbc819b644230cb_4__1__TfsKzkwrN.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581436790",
    minAge: 11,
    courseDescription:
      "This two-week Coding Summer Camp led by industry experts is an intensive introduction for those interested in learning about coding and its applications. Through this course, your child will learn about website designing, front end and back-end development. They will be designing their own web portfolio and develop a front end to implement that. Enhance your child’s analytical thinking with Krafternoon’s Coding Summer Bootcamp.",
    seatsAvailable: 5,
  },

  kn62c4129e6a5afca341b602d0: {
    courseName: "Make your own youtube video",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/giphy__5__2__1__fZ_Ef0qHL.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581437408",
    minAge: 8,
    courseDescription:
      "This two-week Video Editing Summer Camp led by industry experts is an intensive introduction for those interested in learning the basics of Video Editing. First, your child will be shooting their own introductory video, then learn Premiere Pro Tool and edit their own video on Premiere Pro. They will also learn to add different animations to it. Enhance your child’s creativity with Krafternoon’s Video Editing Summer Bootcamp.",
    seatsAvailable: 5,
  },

  kn628d23d528a1a61550c34d4d: {
    courseName: "Learn interactive Game designing on Figma!",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/fxah_2__1__TZtKCiUpM.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581437132",
    minAge: 10,
    courseDescription:
      "This two-week UI UX design Summer Camp led by industry experts is an intensive introduction for those interested in learning the basics of UI UX design. Initially, your child will be introduced to UI and UX, and learn the basics of the two and the differences between the two. Through this course, your child will learn how to design a game. They will understand how a user thinks and design various gaming scenarios accordingly.",
    seatsAvailable: 5,
  },

  kn628d23eb28a1a61550c34d50: {
    courseName: "Learn the most-in-demand language-Python!",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/91aa3120ade4f94cedbc819b644230cb_5_Og8ohE6ze.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581436738",
    minAge: 9,
    courseDescription:
      "This two-week Basics of Python Summer Camp led by industry experts is an intensive introduction for those interested in learning Python. Your child will be learning python from scratch, getting familiar with Python’s environment and concepts of Python. In the end, your child will also be developing a game using Python.",
    seatsAvailable: 5,
  },
};

const LandingPage = () => {
  const [skillCount, setSkillCount] = useState({
    mobileScreen: 0,
    otherScreen: 0,
  });
  const [bootcamps, setBootcamps] = useState([]);
  const [closePopup, setClosePopup] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  const cmfvUrl =
    "https://d2z4maap0n2bq3.cloudfront.net/We%20know%20that%20you%20know%20the%20skills.cmfv";
  const hlsUrl =
    "https://d2z4maap0n2bq3.cloudfront.net/We%20know%20that%20you%20know%20the%20skills--hls.m3u8";

  const navigate = useNavigate();

  useEffect(() => {
    if (window.performance) {
      if (performance.getEntriesByType("navigation")[0].type === "reload") {
        localStorage.removeItem("closePopup");
      }
    }
  }, []);

  // useEffect(() => {
  //   let timer = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 500);
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  useEffect(() => {
    setClosePopup(true);
  }, []);

  useEffect(() => {
    let BOOTCAMP_TYPE = [];
    let SUMMER_BOOTCAMP = [];

    const getAllSummerBootcamps = async () => {
      try {
        const summerBootcampsResopnse = await axios.get(
          `${BACKEND_BASE_URL}/api/payment/getAllPlans?targetCountry=us&courseType=bootcamp`
        );
        //console.log(summerBootcampsResopnse, "summerBootcampsResopnse");
        let BOOTCAMPS = [...summerBootcampsResopnse?.data?.plans];
        for (let i = 0; i < BOOTCAMPS.length; i++) {
          if (
            !BOOTCAMP_TYPE.some(
              (bootcamp) =>
                bootcamp[BOOTCAMPS[i].courseData.courseName] === true
            )
          ) {
            const dummiyData = allBootcamps["kn" + BOOTCAMPS[i].courseId];

            SUMMER_BOOTCAMP.push({
              imageUrl: dummiyData.imageUrl,
              courseName: BOOTCAMPS[i].courseData.courseName,
              courseId: BOOTCAMPS[i].courseId,
              courseDescription: dummiyData.courseDescription,
              minAge: dummiyData.minAge,
              seatsAvailable: dummiyData.seatsAvailable,
              courseType: BOOTCAMPS[i].courseType,
              targetCountry: BOOTCAMPS[i].targetCountry,
              courseAmount: BOOTCAMPS[i].price,
              timezone: BOOTCAMPS[i].timezone,
              currency: BOOTCAMPS[i].currency,
              mode: BOOTCAMPS[i].mode,
              plans: [
                {
                  id: BOOTCAMPS[i]._id,
                  timeslot: { ...BOOTCAMPS[i].timeslot },
                  startDate: new Date(BOOTCAMPS[i].startDate),
                  endDate: new Date(BOOTCAMPS[i].endDate),
                },
              ],
            });
            BOOTCAMP_TYPE.push({ [BOOTCAMPS[i].courseData.courseName]: true });
          } else {
            for (let j = 0; j < SUMMER_BOOTCAMP.length; j++) {
              if (
                SUMMER_BOOTCAMP[j].courseName ===
                BOOTCAMPS[i].courseData.courseName
              ) {
                SUMMER_BOOTCAMP[j].plans.push({
                  id: BOOTCAMPS[i]._id,
                  timeslot: { ...BOOTCAMPS[i].timeslot },
                  startDate: new Date(BOOTCAMPS[i].startDate),
                  endDate: new Date(BOOTCAMPS[i].endDate),
                });
              }
            }
          }
        }
        setBootcamps([...SUMMER_BOOTCAMP]);
        //console.log(BOOTCAMP_TYPE, "BOOTCAMP_TYPE");
      } catch (err) {
        console.log(err);
      }
    };
    getAllSummerBootcamps();
  }, []);

  // useEffect(() => {
  //   setClosePopup(false);
  // }, []);

  // alert(isIOS(), "Test", navigator.platform);

  return (
    <div className="max-w-screen min-h-screen overflow-x-hidden">
      <Helmet>
        <title>Krafternoon</title>
        <meta
          name="description"
          content="Online global platform for kids. Offering industry ready top-most skills. Developing innovative solutions and enhancing thinking process of kids."
          data-react-helmet="true"
        />
        <meta
          property="og:title"
          content="2022 best adaptive virtual classroom offering technical skills."
          data-react-helmet="true"
        />
      </Helmet>
      <div>
        {/* <video
        loop
        muted
        autoPlay
        preload="auto"
        playsinline
        webkit-playsinline
        x5-playsinline
      >
        <source src={isIOS() ? hlsUrl : cmfvUrl} type="video/mp4" />
      </video> */}
        <LandingPagePopup setClosePopup={setClosePopup} />
        {closePopup && (
          <ReactPlayerComponent url={isIOS() ? hlsUrl : cmfvUrl} />
        )}

        <div className="py-20 w-full h-full bg-white flex flex-col space-y-5 xl:space-y-0 xl:flex-row items-center justify-center 2xl:px-40">
          <div className="lg:w-1/2 text-center">
            <h1 className="lg:max-w-lg text-secondary-blue text-2xl sm:text-3xl text-center xl:text-left xl:text-5xl font-bold">
              Explore new Generation skills!
            </h1>
            <div className="flex items-center justify-center xl:justify-start space-x-2 mt-2 xl:mt-5">
              <TypewriterEffect
                heading="Focusing on"
                dataText={[
                  "Creative Thinking",
                  "Analytical Power",
                  "Futuristic Learning",
                  "Real-World Projects",
                ]}
              />
            </div>
          </div>
          <div>
            <LearnAnySkill />
          </div>
          {/* <div className="mb-16">
            {/* <div className="flex flex-col items-center space-y-8 mt-16 relative">
              <div>
                {/* <div
                  className={`flex items-center space-x-3 border rounded-full ${
                    demoBookedSuccessfully && isEmailValid === true
                      ? "border-primary-green"
                      : isEmailValid === false
                      ? "border-primary-red"
                      : "border-secondary-blue"
                  } px-5 h-[42px]`}
                >
                  <input
                    type="text"
                    placeholder="Enter your email address"
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                    className="pr-7 w-[220px] xl:w-[270px] bg-transparent lg:text-lg text-secondary-blue font-normal placeholder:text-secondary-blue-lt-ltr focus:outline-none"
                  />
                </div> */}
          {/* {demoApiMessage && (
                  <p
                    className={`xl:hidden text-sm font-normal ml-2 mt-2 ${
                      isEmailValid ? "text-primary-green" : "text-primary-red"
                    }`}
                  >
                    {demoApiMessage}
                  </p>
                )} */}
          {/* </div> */}
          {/* <PhoneNumberInput value={phoneNumber} setValue={setPhoneNumber} />
              <Button
                innerText="Book your Free Spot!"
                type="button"
                extraClass={`w-full h-[42px] text-base lg:text-lg text-white font-normal rounded-lg bg-primary-green`}
                onClick={bookDemoClassAPI}
                isLoading={isLoading}
                disabled={isLoading}
                Icon={demoBookedSuccessfully ? CircularTick : null}
              />
            </div>
            {demoApiMessage && (
              <p
                className={`hidden xl:inline text-sm font-normal ml-2 mt-2 ${
                  isEmailValid ? "text-primary-green" : "text-primary-red"
                }`}
              >
                {demoApiMessage}
              </p>
            )} */}

          {/* </div> */}
        </div>
        <div className="bg-ternary-pink-ltr flex flex-col items-center justify-center">
          <div className="text-center max-w-2xl mt-28 px-5 xl:px-0">
            <div className="text-2xl xl:text-3xl font-extrabold mb-2 xl:mb-4">
              <p className="text-primary-green">
                PLAY-BASED
                <span className="text-secondary-blue ml-3">Learning</span>
              </p>
            </div>
            <p className="text-base md:text-lg xl:text-2xl font-normal  text-secondary-blue">
              Fuel your child’s interest in creativity, planning, managing
              skills and more with play-based learning!
            </p>
          </div>
          <div className="flex justify-center">
            <div className="relative hidden md:flex md:space-x-5 xl:space-x-10 2xl:space-x-20 justify-start items-center my-20 xl:mx-40 md:mx-16">
              <div
                className="absolute -left-3 2xl:left-6 cursor-pointer"
                onClick={() => {
                  if (
                    skillCount.otherScreen > 0 &&
                    skillCount.otherScreen < allSkills.length
                  ) {
                    setSkillCount((prevCount) => ({
                      ...prevCount,
                      otherScreen: prevCount.otherScreen - 3,
                    }));
                  }
                }}
              >
                <CircularArrowBack
                  className="h-7 w-7 xl:h-10 xl:w-10"
                  stroke={skillCount.otherScreen <= 0 ? "00FFFFFF" : null}
                  fill={skillCount.otherScreen <= 0 ? "00FFFFFF" : null}
                />
              </div>

              {allSkills
                .slice(skillCount.otherScreen, skillCount.otherScreen + 3)
                .map((skill) => (
                  <InfoCard
                    key={skill.id}
                    extraClass="bg-white w-[200px] h-[280px] lg:w-[280px] lg:h-[380px] xl:w-[328px] xl:h-[425px] text-center text-lg xl:text-2xl border-2 border-primary-orange rounded-3xl flex flex-col space-y-3 items-center p-3 lg:p-5"
                    src={skill.imageUrl}
                    button={true}
                    description={skill.skillName}
                    onClick={() => navigate(`${skill.redirectUrl}`)}
                  />
                ))}
              <div
                className="absolute -right-9 xl:-right-14 cursor-pointer"
                onClick={() => {
                  if (
                    skillCount.otherScreen >= 0 &&
                    skillCount.otherScreen < allSkills.length - 3
                  ) {
                    setSkillCount((prevCount) => ({
                      ...prevCount,
                      otherScreen: prevCount.otherScreen + 3,
                    }));
                  }
                }}
              >
                <CircularArrowForward
                  className="h-7 w-7 xl:h-10 xl:w-10"
                  stroke={
                    !allSkills.slice(skillCount.otherScreen + 3).length > 0
                      ? "00FFFFFF"
                      : null
                  }
                  fill={
                    !allSkills.slice(skillCount.otherScreen + 3).length > 0
                      ? "00FFFFFF"
                      : null
                  }
                />
              </div>
            </div>
            <div className="flex items-center justify-center space-x-1 md:hidden my-10">
              <div
                className="cursor-pointer"
                onClick={() => {
                  if (
                    skillCount.mobileScreen > 0 &&
                    skillCount.mobileScreen < allSkills.length
                  ) {
                    setSkillCount((prevCount) => ({
                      ...prevCount,
                      mobileScreen: prevCount.mobileScreen - 1,
                    }));
                  }
                }}
              >
                <CircularArrowBack
                  className="h-7 w-7"
                  stroke={skillCount.mobileScreen <= 0 ? "00FFFFFF" : null}
                  fill={skillCount.mobileScreen <= 0 ? "00FFFFFF" : null}
                />
              </div>
              {allSkills
                .slice(skillCount.mobileScreen, skillCount.mobileScreen + 1)
                .map((skill) => (
                  <InfoCard
                    key={skill.id}
                    extraClass="w-[250px] h-[330px] text-center text-lg border-2 border-primary-orange rounded-3xl flex flex-col space-y-7 items-center p-5"
                    src={skill.imageUrl}
                    button={true}
                    description={skill.skillName}
                    onClick={() => navigate(`${skill.redirectUrl}`)}
                  />
                ))}
              <div
                className="cursor-pointer"
                onClick={() => {
                  if (
                    skillCount.mobileScreen >= 0 &&
                    skillCount.mobileScreen < allSkills.length - 1
                  ) {
                    setSkillCount((prevCount) => ({
                      ...prevCount,
                      mobileScreen: prevCount.mobileScreen + 1,
                    }));
                  }
                }}
              >
                <CircularArrowForward
                  className="h-7 w-7"
                  stroke={
                    !allSkills.slice(skillCount.mobileScreen + 1).length > 0
                      ? "00FFFFFF"
                      : null
                  }
                  fill={
                    !allSkills.slice(skillCount.mobileScreen + 1).length > 0
                      ? "00FFFFFF"
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {/************************JOIN OUR SUMMER CAMP'22******************************/}
        <div className="bg-white max-w-screen pt-20 md:px-10 xl:px-32">
          <div className="flex items-center justify-center space-x-2 text-xl md:text-2xl xl:text-3xl font-extrabold mt-5">
            <p className="text-secondary-blue">Join our</p>
            <p className="text-primary-green">Summer Camp'22</p>
          </div>
          <p className="text-center text-secondary-blue text-base md:text-lg xl:text-2xl font-normal">
            Enroll into a fun ride this summer
          </p>
          <div className="w-full flex flex-col space-y-7 lg:space-y-0 lg:flex-row lg:justify-between items-center my-10">
            {bootcamps.slice(3, 6).map((course) => (
              <div key={course.courseId}>
                <BootcampCard
                  src={course.imageUrl}
                  title={course.courseName}
                  age={course.minAge}
                  seatsAvailable={5}
                  extraClass="bg-white w-[290px] h-[390px] xl:w-[328px] xl:h-[425px] text-center border-2 border-primary-orange rounded-3xl flex flex-col items-center justify-around px-5 py-1"
                  onClick={() => navigate("/summer-bootcamp")}
                />
              </div>
            ))}
          </div>
        </div>

        {/************************HEAR FROM THEM******************************/}
        <div className="bg-secondary-blue max-w-screen py-10 xl:py-20">
          <div className="flex items-center justify-center space-x-2 text-xl md:text-2xl xl:text-3xl font-extrabold mt-10">
            <p className="text-ternary-pink">Hear</p>
            <p className="text-white">from them</p>
          </div>
          <p className="text-center text-white text-base md:text-lg xl:text-2xl font-normal px-5">
            Look at how our kids are applying what they’ve learned in classes
          </p>
          <div className="flex flex-col space-y-7 lg:space-y-0 lg:flex-row justify-between items-center my-20 2xl:mx-20 lg:mx-10">
            <InfoCard
              extraClass="w-[280px] h-[470px] xl:w-[378px] xl:h-[600px] text-left text-xs sm:text-base 2xl:text-lg border-2 border-primary-orange rounded-3xl flex flex-col space-y-3 items-center pb-10 pt-5 px-3"
              src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/CreativeChamps/UX_1_JPQcjVhZs.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654025576319"
              button={false}
              description={`Our creative champ has designed her own bakery mobile application under the mentorship of Krafternoon.`}
              secondaryDescription={`Student word- "It's a bakery app, where anyone can learn how to bake stuffs just for learning purpose or even to make a career in it. I have designed this app under my Krafternoon mentor who helped me to come up with new ideas, and explained me how to design a good user experience."`}
              fromStudentOf="~UI/UX Student"
              textStyle="font-normal text-white"
            />
            <InfoCard
              extraClass="w-[280px] h-[470px] xl:w-[378px] xl:h-[600px] text-left text-xs sm:text-base xl:text-lg border-2 border-secondary-yellow rounded-3xl flex flex-col space-y-3 items-center pb-10 pt-5 px-3"
              src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/CreativeChamps/chess__3___DEQgn9bO.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654025575984"
              button={false}
              description={`Our chess champ under Krafternoon strategic guidance had showed significant growth.`}
              secondaryDescription={`Student word- "My rating significantly rose under Krafternoon mentorship."`}
              fromStudentOf="~Chess Student"
              textStyle="font-normal text-white"
            />
            <InfoCard
              extraClass="w-[280px] h-[470px] xl:w-[378px] xl:h-[600px] text-left text-xs sm:text-base xl:text-lg border-2 border-secondary-pink rounded-3xl flex flex-col space-y-3 items-center pb-10 pt-5 px-3"
              src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/CreativeChamps/Rectangle_343_rBtMIR6bP.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654025576000"
              button={false}
              description={`A 13 year old student establishes her own YouTube channel after getting exposed to Video Editing tool from Krafternoon.`}
              secondaryDescription={`Student word- " It was fun learning with Bhavik. Learning under his guidance motivated me to create my own YouTube channel."`}
              fromStudentOf="~Video Editing Student"
              textStyle="font-normal text-white"
            />
          </div>
        </div>

        {/* <WeTeachCreativeAnalyticalSkills
        firstPart="We build"
        secondPart="Creative & Analytical"
        thirdPart="approach"
        descriptionOne="With emerging job profiles and professional environment changes, it is essential to work from the grassroots level. The grassroot level means empowering individuals in refining & uplifting them in creative and analytical approach."
        descriptionTwo="It is well researched that, these soft skills lead to higher thinking patterns and help individuals to work effectively and efficiently in nonroutine cognitive jobs. With the cascade of events in learning process the learning power of individuals becomes high in due course of time. As to utilize the power of these skills, it is best to begin at an early stage of learning cum education process."
        descriptionThree="Being unaware of the skill sets which contribute towards enhancing creativity and analytical power, can create a point of hindrance. So, to leverage the current learning process we at Krafternoon have designed a platform with a humane centric approach that helps individuals to gain soft skills"
      /> */}
        <div className="relative py-20">
          <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-2 mx-auto text-center text-base md:text-xl xl:text-3xl font-[750] bg-white w-[65%] md:w-[40%] 2xl:w-[30%]">
            <span className="text-secondary-blue">
              Let your kid experience the
              <span className="ml-1 sm:ml-0 text-primary-green sm:inline-flex">
                MOST-IN-DEMAND
                <p className="sm:ml-2 text-secondary-blue">facilities</p>
              </span>
            </span>
          </div>
          <MostInDemandFacilities
            menuList={[
              {
                id: "tailored-courses",
                title: "Tailored Courses",
                Icon: PersonalizedCourses,
              },
              {
                id: "live-classes",
                title: "Live Classes",
                Icon: LiveClasses,
              },
              {
                id: "design-thinking-approach",
                title: "Design Thinking Approach",
                Icon: FunLearning,
              },
            ]}
          />
        </div>

        <TransformCard
          mainHeading="Exposure to learn with current industry experts"
          subHeading="Kids around the world are learning new skills and excelling in their field. Take a step towards an innovative future."
          buttonInnerText="Experience it for FREE"
        />
      </div>
    </div>
  );
};

export default LandingPage;
