import React, { useEffect, useState } from "react";
//import CircularCross from "../../../assets/SVG/circular-cross";
import Button from "../../../components/Button/Button";
import Checkbox from "../../../components/Checkbox/Checkbox";
import DatePicker from "../../../components/DatePicker/DatePicker";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { BACKEND_BASE_URL } from "../../../helpers/url/url";
import ClassFrequencySelector from "../../components/ClassFrequencySelector/ClassFrequencySelector";
import axios from "../../../helpers/axios/axios";
import SearchableDropdown from "../../../components/SearchableDropdown/SearchableDropdown";
import { formattedDate } from "../../../helpers/functions/date-format";
import { toastNotification } from "../../../helpers/functions/toast-notification/toast-notification";

const AssignClass = () => {
  //const [selectedStudents, setSelectedStudents] = useState([]);
  const [allStudents, setAllStudents] = useState([]);
  const [allTeachers, setAllTeachers] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [allBatches, setAllBatches] = useState([]);
  const [assignClassData, setAssignClassData] = useState({
    typeOfCourse: "bootcamp",
    typeOfClass: "one-on-one",
    courseId: "",
    assignedTeacher: "",
    studentName: "",
    classLink: "",
    startDate: "",
    endDate: "",
    classFrequency: "once",
    frequencyTimeline: [],
    batchName: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  // console.log(allStudents, "allStudents");
  //console.log(allTeachers, "allTeachers");

  useEffect(() => {
    const getAllStudentsAPI = async () => {
      try {
        const allStudentsResponse = await axios.get(
          `${BACKEND_BASE_URL}/admin/adminRoles/students`
        );
        let ALL_STUDENTS = [];
        allStudentsResponse?.data?.students.map((student) =>
          ALL_STUDENTS.push({
            id: student._id,
            name: student.name,
            email: student.email,
          })
        );
        setAllStudents([...ALL_STUDENTS]);
      } catch (err) {
        console.log(err);
      }
    };
    getAllStudentsAPI();
  }, []);

  useEffect(() => {
    const getAllTeachersAPI = async () => {
      try {
        const allTeachersResponse = await axios.get(
          `${BACKEND_BASE_URL}/admin/adminRoles/teachers`
        );
        let ALL_TEACHERS = [];
        allTeachersResponse?.data?.teachers.map((teacher) =>
          ALL_TEACHERS.push({
            id: teacher._id,
            name: teacher.name,
            email: teacher.email,
          })
        );
        setAllTeachers([...ALL_TEACHERS]);
      } catch (err) {
        console.log(err);
      }
    };
    getAllTeachersAPI();
  }, []);

  useEffect(() => {
    const getAllCoursesAPI = async () => {
      try {
        const allCoursesResponse = await axios.get(
          `${BACKEND_BASE_URL}/admin/adminRoles/getCourses?courseType=${assignClassData.typeOfCourse}`
        );
        //console.log(allCoursesResponse.data.courses, "allCoursesResponse");
        let ALL_COURSES = [];
        allCoursesResponse?.data?.courses.map((course) =>
          ALL_COURSES.push({
            id: course._id,
            name: course.courseName,
          })
        );
        setAllCourses([...ALL_COURSES]);
      } catch (err) {
        console.log(err);
      }
    };
    getAllCoursesAPI();
  }, [assignClassData.typeOfCourse]);

  useEffect(() => {
    const getAllBatchesAPI = async () => {
      try {
        const allBatchesResponse = await axios.get(
          `${BACKEND_BASE_URL}/admin/adminRoles/getBatches`
        );
        //console.log(allBatchesResponse, "allBatchesResponse");
        let ALL_BATCHES = [];
        if (allBatchesResponse.status === 200) {
          allBatchesResponse?.data?.batches.map((batch) =>
            ALL_BATCHES.push({ id: batch._id, name: batch.batchName })
          );
          setAllBatches([...ALL_BATCHES]);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getAllBatchesAPI();
  }, []);

  // const deleteSelectedStudentHandler = (id) => {
  //   const remainingStudents = selectedStudents.filter(
  //     (student) => student.id !== id
  //   );
  //   setSelectedStudents([...remainingStudents]);
  // };

  // const addStudentsHandler = (student) => {
  //   let currentStudents = selectedStudents;
  //   currentStudents.push(student);
  //   setSelectedStudents([...currentStudents]);
  // };

  const assignNewClassAPI = async () => {
    const singleClassPayload = {
      teacherEmail: assignClassData.assignedTeacher,
      studentEmail: assignClassData.studentName,
      courseId: assignClassData.courseId,
      classURL: assignClassData.classLink,
      frequency: assignClassData.classFrequency,
      frequencyTimeline: assignClassData.frequencyTimeline,
      classStartDate: formattedDate(assignClassData.startDate),
      classEndDate: formattedDate(assignClassData.endDate),
      classOriginTimeZone: "PST",
    };

    const batchClassPayload = {
      teacherEmail: assignClassData.assignedTeacher,
      courseId: assignClassData.courseId,
      classURL: assignClassData.classLink,
      frequency: assignClassData.classFrequency,
      frequencyTimeline: assignClassData.frequencyTimeline,
      classStartDate: formattedDate(assignClassData.startDate),
      classEndDate: formattedDate(assignClassData.endDate),
      batchName: assignClassData.batchName,
      classOriginTimeZone: "PST",
    };

    console.log(singleClassPayload, "singleClassPayload");
    console.log(batchClassPayload, "batchClassPayload");
    setIsLoading(true);
    try {
      const assignClassResponse = await axios.post(
        `${BACKEND_BASE_URL}/admin/teacherActions/${
          assignClassData.typeOfClass === "batch"
            ? "makeClassBatch"
            : "makeClassSingle"
        }`,
        assignClassData.typeOfClass === "batch"
          ? batchClassPayload
          : singleClassPayload
      );
      console.log(assignClassResponse, "assignClassResponse");
      if (assignClassResponse.status === 200) {
        toastNotification("success", "Class assigned successfully");
        setIsLoading(false);
        setAssignClassData({
          typeOfCourse: "bootcamp",
          typeOfClass: "one-on-one",
          courseId: "",
          assignedTeacher: "",
          studentName: "",
          classLink: "",
          startDate: "",
          endDate: "",
          classFrequency: "once",
          frequencyTimeline: [],
          batchName: "",
        });
        setTimeout(() => window.location.reload(), 2000);
      }
    } catch (err) {
      console.log(err);
      toastNotification("error", "Failed to assign successfully");
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-ternary-blue-ltr mt-10 w-[75%] rounded-lg">
      <h3 className="text-secondary-blue text-2xl font-extrabold p-5">
        NEW CLASS
      </h3>
      <div className="border-t border-secondary-blue-lt p-5">
        <h4 className="text-lg text-primary-blue font-extrabold mb-10">
          Assign the class:
        </h4>
        <div className="flex items-center space-x-10">
          <div className="flex items-center space-x-5">
            <p className="text-lg text-primary-blue font-normal">
              Type of course
            </p>
            <Dropdown
              menuList={[
                { id: "bootcamp", name: "Bootcamp" },
                { id: "regular", name: "Regular Class" },
              ]}
              defaultValue={{ id: "bootcamp", name: "Bootcamp" }}
              dropdownStyle="w-[142px] h-[40px] border border-primary-blue flex items-center justify-around text-lg text-primary-blue font-normal"
              arrowStyle="w-[20px] h-[15px] cursor-pointer"
              dropdownMenuStyle="w-[142px] bg-white"
              dropdownHandler={(option) =>
                setAssignClassData({
                  ...assignClassData,
                  typeOfCourse: option.id,
                })
              }
            />
          </div>

          <div className="flex items-center space-x-5">
            <p className="text-lg text-primary-blue font-normal">
              Type of class
            </p>
            <Checkbox
              optionsList={[
                { id: "one-on-one", name: "1:1" },
                { id: "batch", name: "Batch" },
              ]}
              defaultValue={{ id: "one-on-one", name: "1:1" }}
              checkboxHandler={(option) =>
                setAssignClassData({
                  ...assignClassData,
                  typeOfClass: option.id,
                })
              }
            />
          </div>
        </div>

        <div className="flex items-center space-x-10 mt-10">
          <div className="flex items-center space-x-5">
            <p className="text-lg text-primary-blue font-normal">Course Name</p>
            <SearchableDropdown
              menuList={[...allCourses]}
              searchbarStyle="w-[300px] h-[40px] border border-primary-blue flex items-center justify-between px-3 text-lg text-primary-blue font-normal "
              searchMenuStyle="w-[300px] bg-white absolute z-10 shadow-lg rounded-md"
              searchHandler={(option) =>
                setAssignClassData({
                  ...assignClassData,
                  courseId: option.id,
                })
              }
              placeholder="Enter Course Name"
            />
          </div>

          <div className="flex items-center space-x-5">
            <p className="text-lg text-primary-blue font-normal">
              Assign Teacher
            </p>
            <SearchableDropdown
              menuList={[...allTeachers]}
              searchbarStyle="w-[300px] h-[40px] border border-primary-blue flex items-center justify-between px-3 text-lg text-primary-blue font-normal "
              searchMenuStyle="w-[300px] bg-white absolute z-10 shadow-lg rounded-md"
              searchHandler={(option) =>
                setAssignClassData({
                  ...assignClassData,
                  assignedTeacher: option.email,
                })
              }
              placeholder="Enter Teacher's Name"
            />
          </div>
        </div>

        {assignClassData.typeOfClass === "one-on-one" && (
          <div className="flex items-center space-x-5 mt-10">
            <p className="text-lg text-primary-blue font-normal">Add Student</p>
            <SearchableDropdown
              menuList={[...allStudents]}
              searchbarStyle="w-[300px] h-[40px] border border-primary-blue flex items-center justify-between px-3 text-lg text-primary-blue font-normal "
              searchMenuStyle="w-[300px] bg-white absolute z-10 shadow-lg rounded-md"
              s
              searchHandler={(option) => {
                setAssignClassData({
                  ...assignClassData,
                  studentName: option.email,
                });
                //addStudentsHandler(option);
              }}
              placeholder="Enter Student's Name"
            />
            {assignClassData.studentName && (
              <div className="mt-5 max-w-max bg-ternary-blue-lt p-2 rounded mb-5">
                <p className="text-black text-sm">
                  {assignClassData.studentName}
                </p>
              </div>
            )}
          </div>
        )}

        {assignClassData.typeOfClass === "batch" && (
          <div className="flex items-center space-x-5 mt-10">
            <p className="text-lg font-semibold text-primary-blue">
              Batch Name
            </p>
            <SearchableDropdown
              menuList={[...allBatches]}
              searchbarStyle="w-[300px] h-[40px] border border-primary-blue flex items-center justify-between px-3 text-lg text-primary-blue font-normal "
              searchMenuStyle="w-[300px] bg-white absolute z-10 shadow-lg rounded-md"
              searchHandler={(option) =>
                setAssignClassData({
                  ...assignClassData,
                  batchName: option.name,
                })
              }
              placeholder="Enter Batch Name"
            />
          </div>
        )}

        {/* {selectedStudents.length > 0 && (
          <div className="border border-secondary-blue rounded-lg my-10 p-3 flex justify-between">
            <div className="flex flex-wrap items-center">
              {selectedStudents.map((student) => (
                <div
                  key={student.id}
                  className="mr-5 flex items-center space-x-2 max-w-max bg-ternary-blue-lt p-2 rounded mb-5"
                >
                  <p className="text-black text-sm">{student.name}</p>
                  <CircularCross
                    onClick={() => deleteSelectedStudentHandler(student.id)}
                    className="w-[13px] h-[13px] cursor-pointer"
                  />
                </div>
              ))}
            </div>
            <Button
              innerText="Add"
              type="button"
              extraClass="self-end py-0.5 px-3 max-w-max text-white rounded bg-primary-green"
              innerTextStyle="text-xs md:text-base xl:text-lg font-normal"
            />
          </div>
        )} */}

        <div className="flex items-center space-x-5 mt-10">
          <p className="text-lg text-primary-blue font-normal">Class Link</p>

          <input
            type="text"
            className="text-lg font-semibold text-secondary-blue placeholder:text-secondary-blue-lt bg-transparent focus:outline-none w-[60%] h-[40px] px-5 border border-secondary-blue"
            placeholder="Copy paste class link here"
            value={assignClassData.classLink}
            onChange={(e) =>
              setAssignClassData({
                ...assignClassData,
                classLink: e.target.value,
              })
            }
          />
        </div>
      </div>

      <div className="border-t border-secondary-blue-lt p-5">
        <h4 className="text-lg text-primary-blue font-extrabold mb-10">
          Schedule class:
        </h4>
        <div className="flex items-center space-x-10 mb-10">
          <div className="flex items-center space-x-5">
            <p className="text-lg text-primary-blue font-normal">Start Date</p>
            <DatePicker
              date={assignClassData.startDate}
              setDate={(date) =>
                setAssignClassData({
                  ...assignClassData,
                  startDate: date,
                })
              }
            />
          </div>
          <div className="flex items-center space-x-5">
            <p className="text-lg text-primary-blue font-normal">End Date</p>
            <DatePicker
              date={assignClassData.endDate}
              setDate={(date) =>
                setAssignClassData({
                  ...assignClassData,
                  endDate: date,
                })
              }
            />
          </div>
          <div className="flex items-center space-x-5">
            <p className="text-lg text-primary-blue font-normal">
              Class Frequency
            </p>
            <Dropdown
              menuList={[
                { id: "once", name: "Once" },
                { id: "weekly", name: "Weekly" },
              ]}
              defaultValue={{ id: "once", name: "Once" }}
              dropdownStyle="w-[142px] h-[40px] border border-primary-blue flex items-center justify-around text-lg text-primary-blue font-normal"
              arrowStyle="w-[20px] h-[15px] cursor-pointer"
              dropdownMenuStyle="w-[142px] bg-white"
              dropdownHandler={(option) =>
                setAssignClassData({
                  ...assignClassData,
                  classFrequency: option.id,
                })
              }
            />
          </div>
        </div>

        <ClassFrequencySelector
          frequencySelectHandler={(selectedOptions) =>
            setAssignClassData({
              ...assignClassData,
              frequencyTimeline: selectedOptions,
            })
          }
          startDate={assignClassData.startDate}
          endDate={assignClassData.endDate}
        />

        <Button
          isLoading={isLoading}
          disabled={isLoading}
          innerText="Save"
          type="button"
          extraClass="mt-10 py-1 px-3 max-w-max text-white font-normal rounded-full bg-primary-green"
          innerTextStyle="text-xs md:text-base xl:text-lg"
          onClick={assignNewClassAPI}
        />
      </div>
    </div>
  );
};

export default AssignClass;
