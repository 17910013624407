import React, { useEffect, useState } from "react";
import CustomModal from "../CustomModal/CustomModal";
//import ReactPlayerComponent from "../ReactPlayerComponent/ReactPlayerComponent";
import Signin from "./components/Signin";
//import bootcampAd from "../../assets/images/bootcamp-ad.png";

const LandingPagePopup = ({ setClosePopup }) => {
  //const [toggleView, setToggleView] = useState(false);
  //const [playVideo, setPlayVideo] = useState(false);
  const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   let timer = setTimeout(() => setToggleView(true), 5000);
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);
  useEffect(() => {
    let timer = setTimeout(() => {
      setClosePopup(false);
      setOpen(true);
      console.log("called");
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [setClosePopup]);

  // useEffect(() => {
  //   let timer = setTimeout(() => setPlayVideo(true), 1000);
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  const closeModalHandler = () => {
    localStorage.setItem("closePopup", true);
    setOpen(false);
    if (setClosePopup) {
      setClosePopup(true);
    }
  };

  return (
    <CustomModal open={open} closeModalHandler={closeModalHandler}>
      <div>
        {/* {!toggleView && (
          <div>
            {!playVideo && <img src={bootcampAd} alt="" />}
            {playVideo && (
              <ReactPlayerComponent url="https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/Definitely_Studying_JNAcnDo5K.mp4?ik-sdk-version=javascript-1.4.3&updatedAt=1654375606609" />
            )}
          </div>
        )} */}
        {/* {toggleView && ( */}
        <div className="flex flex-col md:flex-row">
          <img
            src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/Group_881_1__9lc-v39xU.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654377960063"
            alt=""
            className="hidden md:inline-flex w-[55%]"
          />
          <img
            src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/Group_882_5VFv9rxLI.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654379113203"
            alt=""
            className="md:hidden w-full"
          />
          <Signin />
        </div>
        {/* )} */}
      </div>
    </CustomModal>
  );
};

export default LandingPagePopup;
