import React from "react";

const CircularCross = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 14 13"
      {...rest}
    >
      <path
        fill="#3A1BA3"
        fillOpacity="0.65"
        d="M6.669 0C3.129 0 .25 2.916.25 6.5S3.13 13 6.669 13c3.539 0 6.419-2.916 6.419-6.5S10.207 0 6.668 0zm2.7 8.315l-.907.92-1.793-1.816-1.793 1.816-.908-.92L5.761 6.5 3.968 4.685l.908-.92 1.793 1.816 1.793-1.816.907.92L7.576 6.5 9.37 8.315z"
      ></path>
    </svg>
  );
};

export default CircularCross;
