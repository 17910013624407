import React from "react";

const Linkedin = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 26 25"
      {...rest}
    >
      <path
        fill="#434484"
        d="M24.21 0H1.989A1.389 1.389 0 00.6 1.389V23.61A1.389 1.389 0 001.989 25H24.21a1.389 1.389 0 001.389-1.389V1.39A1.389 1.389 0 0024.21 0zM8.016 21.301H4.304V9.371h3.71V21.3zM6.159 7.741a2.15 2.15 0 110-4.3 2.15 2.15 0 010 4.3zm15.743 13.56h-3.707V15.5c0-1.383-.025-3.162-1.927-3.162-1.93 0-2.224 1.508-2.224 3.065v5.9H10.34V9.373h3.555v1.63h.052c.493-.938 1.704-1.927 3.505-1.927 3.756 0 4.449 2.47 4.449 5.684V21.3h.001z"
      ></path>
    </svg>
  );
};

export default Linkedin;
