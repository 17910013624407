import React from "react";

const MostInDemandFacilities = ({ menuList }) => {
  return (
    <div>
      {/* <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-2 mx-auto text-center text-base md:text-xl xl:text-3xl font-bold  xl:leading-10 bg-white w-[65%] md:w-[40%] lg:w-[30%]">
        <span className="text-secondary-blue">
          Let your kid experience the
          <span className="text-primary-green mx-2">
            MOST-IN-DEMAND
            <p className=" text-secondary-blue">facilities</p>
          </span>
        </span>
      </div> */}

      <div className="mx-auto w-[85%] lg:w-[70%] xl:w-[65%] flex flex-col space-y-5 md:space-y-0 md:flex-row items-center justify-center pt-14 xl:pt-20 xl:pb-10 border-2 border-primary-blue rounded-3xl">
        {menuList.length > 0 &&
          menuList.map(({ id, title, Icon }) => (
            <div
              key={id}
              className="w-[80%] md:w-1/3 h-[200px] md:mx-3 flex flex-col space-y-3 items-center text-center"
            >
              <div className="bg-secondary-blue rounded-full px-5 py-7 xl:px-8 xl:py-10">
                <Icon className="w-16 h-12 xl:w-20 xl:h-16 " />
              </div>
              <p className="w-[80%] lg:w-[70%] text-xs xl:text-sm font-semibold text-secondary-blue">
                {title}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default MostInDemandFacilities;
