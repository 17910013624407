import React, { useState } from "react";
import { useParams } from "react-router-dom";
import EnrollCourse from "./Pages/EnrollCourse/EnrollCourse";
import StudentDashboard from "./Pages/StudentDashboard/StudentDashboard";

const Student = () => {
  const { type } = useParams();
  const [selectedCourse, setSelectedCourse] = useState();

  console.log(type, "type");

  return (
    <div>
      {type === "dashboard" && (
        <StudentDashboard setSelectedCourse={setSelectedCourse} />
      )}
      {type === "enroll" && (
        <EnrollCourse
          selectedCourse={selectedCourse}
          setSelectedCourse={setSelectedCourse}
        />
      )}
    </div>
  );
};

export default Student;
