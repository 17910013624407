import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import TransformCard from "../../components/Card/TransformCard/TransformCard";

const OurClients = () => {
  const navigate = useNavigate();
  return (
    <div className="max-w-screen px-5 py-10 md:px-10 xl:px-20 xl:py-20">
      <div className="w-full flex flex-col md:flex-row items-center md:justify-between">
        <div className="flex flex-col items-start">
          <h2 className="text-xl lg:text-3xl text-secondary-blue font-extrabold">
            We are a Customer Centric platform
          </h2>
          <p className="mt-2 mb-2 lg:mb-5 max-w-xs lg:max-w-md text-sm lg:text-base xl:text-lg text-secondary-blue font-normal">
            With a group of innovators, we design user-friendly products making
            our client's business life super easy. We are Gen Z designers,
            content creators, marketers & SEO professionals.
          </p>
          <Button
            onClick={() => navigate("/contact-us")}
            innerText="Let's Collaborate"
            type="button"
            extraClass="my-3 py-1 px-3 max-w-max text-white font-normal rounded-full bg-primary-green"
            innerTextStyle="text-sm md:text-base xl:text-lg"
          />
        </div>
        <div>
          <img
            src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/image_54_FLD-n4rpIm.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653667819747"
            alt=""
            className="mt-10 md:mt-0 md:w-[360px] md:h-[245px] xl:w-[569px] xl:h-[385px]"
          />
        </div>
      </div>

      <div className="flex flex-col items-center md:items-start">
        <h2 className="text-center md:text-left mt-16 lg:mt-28 mb-16 text-xl lg:text-3xl text-secondary-blue font-extrabold">
          A glimpse of our projects
        </h2>

        <div className="w-full flex flex-col md:flex-row items-center md:justify-between">
          <div>
            <h3 className="text-lg xl:text-2xl text-secondary-blue font-bold mb-2">
              ToroAlerts
            </h3>
            <p className="max-w-xs lg:max-w-md text-sm lg:text-base xl:text-lg text-secondary-blue font-normal">
              ToroAlerts provides AI based Portfolio alerts and pre-set smart
              performing Portfolio & Premium bundles.
            </p>
            <p className="max-w-xs lg:max-w-md mt-2 text-sm lg:text-base xl:text-lg text-secondary-blue font-normal">
              <span className="font-extrabold">Our role:</span> Redesigned iOS
              and Android application from scratch. Worked over their feature
              implementation & digital marketing (SEO).
            </p>
          </div>

          <div>
            <img
              src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/Step_3_1__2__O-W-jdoCA.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653667819430"
              alt=""
              className="mt-10 md:mt-0 md:w-[360px] md:h-[214px] xl:w-[543px] xl:h-[322px]"
            />
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row items-center md:space-x-16 lg:space-x-32 xl:space-x-48 mt-16 lg:mt-28">
          <div>
            <img
              src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/d1_1_19agT4VSM.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653667819782"
              alt=""
              className="mt-10 md:mt-0 md:w-[360px] md:h-[220px] xl:w-[540px] xl:h-[330px]"
            />
          </div>

          <div className="order-first md:order-none">
            <h3 className="text-lg xl:text-2xl text-secondary-blue font-bold mb-2">
              Cthreefund, LP&nbsp;
              <span className="text-base xl:text-lg font-normal underline">
                (cthreefund.com)
              </span>
            </h3>
            <p className="max-w-xs lg:max-w-md text-sm lg:text-base xl:text-lg text-secondary-blue font-normal">
              An innovative FinTech specialized in automated algorithmic
              trading.
            </p>
            <p className="max-w-xs lg:max-w-md mt-2 text-sm lg:text-base xl:text-lg text-secondary-blue font-normal">
              <span className="font-extrabold">Our role:</span> Re-designed
              their website, making it user-friendly and optimized its overall
              performance.
            </p>
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row items-center md:justify-between mt-16 xl:mt-28">
          <div>
            <h3 className="text-lg xl:text-2xl text-secondary-blue font-bold mb-2">
              Solana Grant Project
            </h3>
            <p className="max-w-xs lg:max-w-md text-sm lg:text-base xl:text-lg text-secondary-blue font-normal">
              Solana is one of the fastest blockchain and fastest crypto
              ecosystem flourishing in the world. As it has more than 400
              projects spanning from DeFi, NFTs, Web3 and many more.
            </p>
            <p className="max-w-xs lg:max-w-md mt-2 text-sm lg:text-base xl:text-lg text-secondary-blue font-normal">
              <span className="font-extrabold">Our role:</span> We made UI
              mockups and successfully accomplished the grant with designs for
              Kabilla, Kubera & Ellora.
            </p>
          </div>

          <div>
            <img
              src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/cabilla_2_KGBwxGGR1.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653667819619"
              alt=""
              className="mt-10 md:mt-0 md:w-[360px] md:h-[245px]  xl:w-[552px] xl:h-[393px]"
            />
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row items-center md:space-x-16 lg:space-x-32 xl:space-x-48 mt-16 lg:my-28">
          <div>
            <img
              src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/Rectangle_366_MV6FvianA.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653667821003"
              alt=""
              className="mt-10 md:mt-0 md:w-[360px] md:h-[256px] xl:w-[541px] xl:h-[356px]"
            />
          </div>

          <div className="order-first md:order-none">
            <h3 className="text-lg lg:text-2xl text-secondary-blue font-bold mb-2">
              Northwest Renovators
            </h3>
            <p className="max-w-xs lg:max-w-md text-sm lg:text-base xl:text-lg text-secondary-blue font-normal">
              Design-build & general contraction firm, Northwest Renovators is
              recognized as Manufacturing Corporation
            </p>
            <p className="max-w-xs lg:max-w-md mt-2 text-sm lg:text-base xl:text-lg text-secondary-blue font-normal">
              <span className="font-extrabold">Our role:</span> It's our ongoing
              project.
            </p>
          </div>
        </div>
      </div>

      <TransformCard
        mainHeading="Exposure to learn with current industry experts"
        subHeading="Kids around the world are learning new skills and excelling in their field. Take a step towards an innovative future."
        buttonInnerText="Contact Us"
        redirectPath="/contact-us"
      />
    </div>
  );
};

export default OurClients;
