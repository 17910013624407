import React from "react";

const CircularCrossBlue = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#434484"
        d="M9 0a9 9 0 109 9 9.01 9.01 0 00-9-9zm3.47 11.315a.818.818 0 11-1.156 1.157L9 10.157l-2.314 2.315a.818.818 0 01-1.157-1.157L7.843 9 5.53 6.685A.818.818 0 116.686 5.53L9 7.843l2.314-2.314a.818.818 0 111.157 1.156L10.157 9l2.314 2.315z"
      ></path>
    </svg>
  );
};

export default CircularCrossBlue;
