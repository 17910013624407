import React from "react";

const Thunder = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="19"
      fill="none"
      viewBox="0 0 15 19"
    >
      <path
        fill="#E8505B"
        d="M13.988 6.38L3.3 17.91a.281.281 0 01-.466-.299l3.213-7.768H.281a.281.281 0 01-.248-.414L4.996.15A.281.281 0 015.244 0h4.32a.282.282 0 01.25.41L6.974 5.906h6.807a.282.282 0 01.206.473z"
      ></path>
    </svg>
  );
};

export default Thunder;
