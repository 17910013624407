import React, { useState } from "react";
import Button from "../../../../../components/Button/Button";
import ClassFrequencySelector from "../../../../components/ClassFrequencySelector/ClassFrequencySelector";

const EditScheduledClass = () => {
  const [noOfDays, setNoOfDays] = useState(0);
  const [startDate, setStartDate] = useState(new Date());

  return (
    <div className="w-[800px] ">
      <h2 className="text-2xl font-extrabold text-primary-blue py-3 text-center">
        Edit: GD001
      </h2>
      <div className="bg-primary-purple h-[1px] w-full"></div>
      <div className="w-full flex flex-col space-y-10 px-10 pt-10">
        <div className="flex items-center space-x-5">
          <p className="text-lg text-secondary-blue font-normal">Start Date:</p>
          <input
            type="date"
            className="text-center w-[150px] focus:outline-none border-b border-black text-lg text-primary-purple"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="flex items-center space-x-3">
          <p className="text-lg text-secondary-blue font-normal">
            No. of Days:
          </p>
          <input
            type="number"
            className="text-center w-[50px] focus:outline-none border-b border-black text-lg text-primary-purple"
            value={noOfDays}
            onChange={(e) => setNoOfDays(e.target.value)}
          />
        </div>
        <ClassFrequencySelector />
        <div className="flex items-center space-x-5">
          <p className="text-lg text-secondary-blue font-normal">Class Link</p>

          <input
            type="text"
            className="text-lg font-semibold text-secondary-blue placeholder:text-secondary-blue-lt bg-transparent focus:outline-none w-[60%] h-[40px] px-5 border border-secondary-blue"
            placeholder="Copy paste class link here"
          />
        </div>
        <div className="flex items-center space-x-5 pt-5 pb-10">
          <Button
            innerText="Save & Publish"
            type="button"
            extraClass="h-[45px] w-[170px] text-white font-normal rounded-full bg-primary-green"
            innerTextStyle="text-xs md:text-base xl:text-lg font-normal"
          />
          <Button
            innerText="Delete Ad"
            type="button"
            extraClass="h-[45px] w-[170px] rounded-full bg-white border border-primary-red"
            innerTextStyle="text-xs md:text-base xl:text-lg text-primary-red font-normal"
          />
        </div>
      </div>
    </div>
  );
};

export default EditScheduledClass;
