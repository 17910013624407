import React from "react";

const Copy = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 24 27"
      {...rest}
    >
      <path
        fill="#434484"
        d="M17.897 5.216h4.795L17.897.424v4.792zM17.369 24.467V22.77H7.286a2.988 2.988 0 01-2.984-2.984V4.682H1.54A1.539 1.539 0 00-.001 6.22v18.246a1.539 1.539 0 001.54 1.54h14.29a1.539 1.539 0 001.54-1.54z"
      ></path>
      <path
        fill="#434484"
        d="M16.453 5.938V0H7.286a1.539 1.539 0 00-1.539 1.539v18.247a1.539 1.539 0 001.539 1.538h14.29a1.54 1.54 0 001.54-1.538V6.66h-5.94a.723.723 0 01-.723-.722z"
      ></path>
    </svg>
  );
};

export default Copy;
