import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../Button/Button";
import LearnAnySkill from "../LearnAnySkill/LearnAnySkill";

const TransformCard = ({
  signInForm = false,
  mainHeading,
  subHeading,
  buttonInnerText,
  redirectPath,
}) => {
  const navigate = useNavigate();
  return (
    <div className="my-28 xl:p mx-auto bg-primary-pink flex flex-col space-y-12 sm:space-y-0 items-center md:items-start md:flex-row md:justify-between w-[85%] p-5 lg:p-10 rounded-3xl">
      <div className="sm:w-[70%] lg:w-[45%] text-center sm:text-left">
        <h1 className="mb-3 font-[750] text-xl lg:text-2xl xl:text-4xl text-secondary-blue">
          {mainHeading}
        </h1>
        <p className="md:mb-5 lg:mb-10 md:w-[75%] text-base sm:text-sm xl:text-base text-primary-blue">
          {subHeading}
        </p>
        {!signInForm && (
          <Button
            innerText={buttonInnerText ? buttonInnerText : "Try it for FREE!"}
            type="button"
            extraClass="px-5 py-1 hidden md:inline-flex max-w-max text-sm xl:text-lg text-white font-[750] rounded-full bg-primary-green"
            onClick={() =>
              navigate(`${redirectPath ? redirectPath : "/signup"}`)
            }
          />
        )}
        {signInForm && (
          <div>
            <img
              src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/mom-daughter-homework_vcU27G63x.png?ik-sdk-version=javascript-1.4.3&updatedAt=1651849352227"
              alt=""
              className="hidden md:inline-flex md:-mb-12 -ml-5 md:w-[100%] md:h-[100%]"
            />
          </div>
        )}
      </div>

      {signInForm && (
        <div>
          <img
            src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/kid-mom-homework_0woQ3NDw5.png?ik-sdk-version=javascript-1.4.3&updatedAt=1651652977915"
            alt=""
            className="sm:hidden md:-mb-6 lg:-mb-12 md:ml-80 lg:ml-0 md:w-[50%] md:h-[50%] lg:w-[100%] lg:h-[100%]"
          />
        </div>
      )}

      {!signInForm && (
        <div>
          <img
            src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/kid-mom-homework_0woQ3NDw5.png?ik-sdk-version=javascript-1.4.3&updatedAt=1651652977915"
            alt=""
            className="md:-mb-6 lg:-mb-12 sm:w-[100%] sm:h-[100%]"
          />
        </div>
      )}

      {!signInForm && (
        <Button
          innerText={buttonInnerText ? buttonInnerText : "Try it for FREE!"}
          type="button"
          extraClass="md:hidden flex max-w-max text-sm xl:text-lg text-white font-[750] rounded-full bg-primary-green"
          onClick={() => navigate(`${redirectPath ? redirectPath : "/signup"}`)}
        />
      )}
      {signInForm && <LearnAnySkill />}
    </div>
  );
};

export default TransformCard;
