import React from "react";

const Paintbrush = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 122 71"
    >
      <path
        fill="#FFC6AA"
        d="M4.625 69.988c13.206.135 53.778-.409 53.778-.409l-4.765-1.907-29.952.273 28.863-.681-1.362-.544-33.628.272 32.403-.817-.545-.272-39.074-.273c0 .136-18.79 4.358-5.719 4.358zM84.951 50.927s10.211-6.263 17.019-15.112c6.807-8.85 17.154-24.507 18.924-28.863 1.77-4.493-3.812-8.441-7.216-6.4-3.131 1.771-15.792 13.751-22.6 23.281-6.807 9.53-12.525 22.464-12.525 22.464s0 1.634 2.179 3.268c2.313 1.634 4.22 1.362 4.22 1.362zM72.698 67.129c10.755-3.404 11.709-12.934 11.709-12.934s-2.723.273-5.446-1.906c-3.676-2.995-3.13-5.037-3.13-5.037s-5.446-.409-7.762 4.084c-2.178 4.493-1.906 9.395-6.943 11.3-4.629 1.77-7.216 1.09-7.216 1.09s8.169 6.67 18.788 3.403z"
      ></path>
    </svg>
  );
};

export default Paintbrush;
