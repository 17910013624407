import React from "react";

const FunLearning = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 68 77"
    >
      <path
        fill="#FFE8F5"
        d="M62.633 22.655H46.687C46.294 14.652 42.913 6.84 36.745.873a1.582 1.582 0 00-2.203 0c-6.17 5.966-9.551 13.778-9.944 21.782H5.47a4.762 4.762 0 00-4.763 4.763v38.11A4.762 4.762 0 005.47 70.29h15.22l-3.18 3.18h-2.512a1.583 1.583 0 100 3.168h38.11a1.583 1.583 0 100-3.168h-2.513l-3.18-3.18h15.22a4.762 4.762 0 004.764-4.763v-38.11a4.762 4.762 0 00-4.764-4.763h-.002zm-16.327 6.633c3.823 4.478 3.418 9.396 3.418 9.503a15.84 15.84 0 00-4.419-5.168c.441-1.43.763-2.87 1-4.335zM35.636 4.267a29.45 29.45 0 015.68 8.848H29.955a29.825 29.825 0 015.68-8.848zm-6.813 12.027h13.624c3.466 11.48-1.822 20.71-2.68 22.234h-8.252c-5.55-10.48-3.573-19.126-2.692-22.234zm-2.858 17.34a15.983 15.983 0 00-4.418 5.169c0-.107-.215-5.336 3.418-9.504.25 1.453.56 2.894 1 4.335zm-3.953 39.823l3.18-3.18h17.745l3.18 3.18H22.012zm42.205-7.931a1.59 1.59 0 01-1.584 1.584H5.47a1.583 1.583 0 01-1.584-1.584v-4.763H64.23v4.763h-.012zm0-7.943H3.887V27.417c0-.88.713-1.584 1.583-1.584H24c-3.561 3.264-5.621 7.765-5.621 12.648 0 2.441.512 4.81 1.513 7.05.262.583.833.964 1.453.94.964-.047 1.512-.714 1.584-1.429a12.677 12.677 0 014.215-8.24c.608 1.404 1.286 2.798 2.096 4.144.286.476.798.762 1.358.762h10.075c.56 0 1.071-.286 1.357-.762.822-1.346 1.5-2.74 2.096-4.145 2.382 2.12 3.74 5.05 4.216 8.241.214 1.43 1.5 1.43 1.584 1.43.62 0 1.19-.358 1.453-.941a17.066 17.066 0 001.512-7.05c0-4.883-2.048-9.384-5.62-12.648h15.361c.881 0 1.584.715 1.584 1.584v30.166z"
      ></path>
      <path
        fill="#FFE8F5"
        d="M35.636 43.293c-.87 0-1.584.715-1.584 1.584v4.763a1.583 1.583 0 103.168 0v-4.763a1.576 1.576 0 00-1.584-1.584zM29.289 46.472c-.881 0-1.584.714-1.584 1.584v4.763a1.583 1.583 0 103.168 0v-4.763c0-.881-.703-1.584-1.584-1.584zM41.993 46.472c-.881 0-1.584.714-1.584 1.584v4.763a1.583 1.583 0 103.168 0v-4.763c0-.881-.714-1.584-1.584-1.584z"
      ></path>
    </svg>
  );
};

export default FunLearning;
