import React, { useEffect, useState } from "react";
import CircularRight from "../../../assets/SVG/circular-right";
import Clock from "../../../assets/SVG/clock";
import Thunder from "../../../assets/SVG/thunder";
import Button from "../../../components/Button/Button";
import InfoCard from "../../../components/Card/InfoCard/InfoCard";
import {
  BACKEND_BASE_URL,
  CLOUDFRONT_AWS_S3_URL,
} from "../../../helpers/url/url";
import axios from "../../../helpers/axios/axios";
import { useNavigate } from "react-router-dom";
import CircularArrowBack from "../../../assets/SVG/circular-arrow-back";
import CircularArrowForward from "../../../assets/SVG/circular-arrow-forward";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../../components/Spinners/LoadingSpinner/LoadingSpinner";
import { bootcampActions } from "../../../redux/features/bootcamp-slice";

// const trendingSkills = [
//   {
//     id: "1",
//     title: "Build your own website. Learn from scratch!",
//     enrolled: "20",
//     imageUrl:
//       "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Dashboard/responsive-web-design_1__2__RzizL9Dgn.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652711757788",
//   },
//   {
//     id: "2",
//     title: "Turn your art into Digital art with Photoshop",
//     enrolled: "40",
//     imageUrl:
//       "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Dashboard/a114bcde3596d40684499375ee80eea3_3_7uxq4Hmn9.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652711757172",
//   },
//   {
//     id: "3",
//     title: "Learn fundamentals of coding. Start today!",
//     enrolled: "100",
//     imageUrl:
//       "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Dashboard/a114bcde3596d40684499375ee80eea3_4_2riz69BEwP.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652711756670",
//   },
// ];

const allBootcamps = {
  kn628d22e128a1a61550c34d3e: {
    courseName: "Learn Chess Fundamentals",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/giphy__5__1__1__w3IlPYYox.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581437258",
    minAge: 4,
    courseDescription:
      "This two-week Chess Summer Camp led by industry experts is an intensive introduction for those interested in learning chess. Through this course, your child will be learning the history of chess, the basics of chessboard and chess pieces, the unique powers of all pieces, various rules of chess, and special moves of chess. We will end this summer camp with an amazing chess tournament where your child will be able to showcase their learnings.",
    seatsAvailable: 5,
  },

  kn628d230e28a1a61550c34d41: {
    courseName: "Design your own logo",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/91aa3120ade4f94cedbc819b644230cb_3__1__oHYJbpqPg.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581436735",
    minAge: 7,
    courseDescription:
      "This two-week Digital Arts Summer Camp led by industry experts is an intensive introduction for those interested in learning about digital arts. Through this course, your child will have the opportunity to sketch various logos and give them a digital effect. They will learn colour theory, typography, and Photoshop tools. Give your child a head starts into the magical world of design with Krafternoon!",
    seatsAvailable: 5,
  },

  kn628d237e28a1a61550c34d44: {
    courseName: "Musical Instruments with coding",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/fxah_1_OdiuzFueRb.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581436951",
    minAge: 7,
    courseDescription:
      "This two-week Coding Summer Camp led by industry experts is an intensive introduction for those interested in learning about coding and its applications. Through this course, your child will learn the basics of web development, web designing, and front-end development. Your child will develop a piano on a website that will produce the same sound effects as an original piano. Enhance your child’s analytical thinking with Krafternoon’s Coding Summer Bootcamp.",
    seatsAvailable: 5,
  },

  kn628d239728a1a61550c34d47: {
    courseName: "Build your own Website",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/91aa3120ade4f94cedbc819b644230cb_4__1__TfsKzkwrN.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581436790",
    minAge: 11,
    courseDescription:
      "This two-week Coding Summer Camp led by industry experts is an intensive introduction for those interested in learning about coding and its applications. Through this course, your child will learn about website designing, front end and back-end development. They will be designing their own web portfolio and develop a front end to implement that. Enhance your child’s analytical thinking with Krafternoon’s Coding Summer Bootcamp.",
    seatsAvailable: 5,
  },

  kn628d23c128a1a61550c34d4a: {
    courseName: "Showcase yourself by making your own Introductory Video",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/giphy__5__2__1__fZ_Ef0qHL.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581437408",
    minAge: 8,
    courseDescription:
      "This two-week Video Editing Summer Camp led by industry experts is an intensive introduction for those interested in learning the basics of Video Editing. First, your child will be shooting their own introductory video, then learn Premiere Pro Tool and edit their own video on Premiere Pro. They will also learn to add different animations to it. Enhance your child’s creativity with Krafternoon’s Video Editing Summer Bootcamp.",
    seatsAvailable: 5,
  },

  kn628d23d528a1a61550c34d4d: {
    courseName: "Learn interactive Game designing on Figma!",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/fxah_2__1__TZtKCiUpM.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581437132",
    minAge: 10,
    courseDescription:
      "This two-week UI UX design Summer Camp led by industry experts is an intensive introduction for those interested in learning the basics of UI UX design. Initially, your child will be introduced to UI and UX, and learn the basics of the two and the differences between the two. Through this course, your child will learn how to design a game. They will understand how a user thinks and design various gaming scenarios accordingly.",
    seatsAvailable: 5,
  },

  kn628d23eb28a1a61550c34d50: {
    courseName: "Learn the most-in-demand language-Python!",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/91aa3120ade4f94cedbc819b644230cb_5_Og8ohE6ze.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581436738",
    minAge: 9,
    courseDescription:
      "This two-week Basics of Python Summer Camp led by industry experts is an intensive introduction for those interested in learning Python. Your child will be learning python from scratch, getting familiar with Python’s environment and concepts of Python. In the end, your child will also be developing a game using Python.",
    seatsAvailable: 5,
  },
};

const StudentDashboard = ({ setSelectedCourse }) => {
  const [bootcamps, setBootcamps] = useState([]);
  const [trendingSkills, setTrendingSkills] = useState([]);
  const [skillCount, setSkillCount] = useState({
    mobileScreen: 0,
    otherScreen: 0,
  });
  const [bootcampsCount, setBootcampsCount] = useState({
    mobileScreen: 0,
    otherScreen: 0,
  });

  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  console.log(auth, "auth");

  useEffect(() => {
    let BOOTCAMP_TYPE = [];
    let SUMMER_BOOTCAMP = [];

    const getAllSummerBootcamps = async () => {
      setIsLoading(true);
      try {
        const summerBootcampsResopnse = await axios.get(
          `${BACKEND_BASE_URL}/api/payment/getAllPlans?targetCountry=us&courseType=bootcamp`
        );
        console.log(summerBootcampsResopnse, "summerBootcampsResopnse");
        let TEMP_BOOTCAMPS = [...summerBootcampsResopnse?.data?.plans];
        let BOOTCAMPS = [];
        for (let i = 0; i < TEMP_BOOTCAMPS.length; i++) {
          if (TEMP_BOOTCAMPS[i]?.courseData?.courseName) {
            BOOTCAMPS.push(TEMP_BOOTCAMPS[i]);
          }
        }
        for (let i = 0; i < BOOTCAMPS.length; i++) {
          if (
            !BOOTCAMP_TYPE.some(
              (bootcamp) =>
                bootcamp[BOOTCAMPS[i]?.courseData?.courseName] === true
            )
          ) {
            const dummiyData = allBootcamps["kn" + BOOTCAMPS[i].courseId];

            SUMMER_BOOTCAMP.push({
              imageUrl: dummiyData.imageUrl,
              courseName: BOOTCAMPS[i]?.courseData?.courseName,
              courseId: BOOTCAMPS[i]?.courseId,
              courseDescription: dummiyData.courseDescription,
              minAge: dummiyData.minAge,
              seatsAvailable: dummiyData.seatsAvailable,
              courseType: BOOTCAMPS[i].courseType,
              targetCountry: BOOTCAMPS[i].targetCountry,
              courseAmount: BOOTCAMPS[i].price,
              timezone: BOOTCAMPS[i].timezone,
              currency: BOOTCAMPS[i].currency,
              mode: BOOTCAMPS[i].mode,
              plans: [
                {
                  id: BOOTCAMPS[i]._id,
                  timeslot: { ...BOOTCAMPS[i].timeslot },
                  startDate: new Date(BOOTCAMPS[i].startDate),
                  endDate: new Date(BOOTCAMPS[i].endDate),
                },
              ],
            });
            BOOTCAMP_TYPE.push({
              [BOOTCAMPS[i].courseData.courseName]: true,
            });
          } else {
            for (let j = 0; j < SUMMER_BOOTCAMP.length; j++) {
              if (
                SUMMER_BOOTCAMP[j].courseName ===
                BOOTCAMPS[i].courseData.courseName
              ) {
                SUMMER_BOOTCAMP[j].plans.push({
                  id: BOOTCAMPS[i]._id,
                  timeslot: { ...BOOTCAMPS[i].timeslot },
                  startDate: new Date(BOOTCAMPS[i].startDate),
                  endDate: new Date(BOOTCAMPS[i].endDate),
                });
              }
            }
          }
        }
        setBootcamps([...SUMMER_BOOTCAMP]);
        setIsLoading(false);
        //console.log(BOOTCAMP_TYPE, "BOOTCAMP_TYPE");
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };
    getAllSummerBootcamps();
  }, []);

  useEffect(() => {
    const getAllTrendingSkillsAPI = async () => {
      setIsLoading(true);
      try {
        const allSkillsResponse = await axios.get(
          `${BACKEND_BASE_URL}/api/course?courseType=skill`
        );
        console.log(allSkillsResponse, "allSkillsResponse");
        if (allSkillsResponse?.status === 200) {
          setTrendingSkills([...allSkillsResponse?.data?.courses]);
          setIsLoading(false);
        }
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };
    getAllTrendingSkillsAPI();
  }, []);

  const dispatch = useDispatch();

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <div className="max-w-screen py-10 px-0 2xl:px-16">
          <div className="px-5 2xl:px-0">
            <h1 className="text-lg 2xl:text-[32px]text-secondary-blue font-bold">
              Hey {auth?.username ? auth.username.split(" ")[0] : "User"},
            </h1>
            <h3 className="text-base 2xl:text-2xl font-normal text-secondary-blue mt-1">
              Let's learn new skills today!
            </h3>
          </div>
          <div className="md:px-5 2xl:px-0 w-full flex flex-col items-center md:items-start md:flex-row md:space-x-3 lg:justify-between mt-5">
            <div className="md:w-[50%] md:h-[280px] lg:w-[70%] ">
              <img
                src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/Dashboard/Rectangle_552_MkVNpKaMr.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652711758263"
                alt=""
                className="w-full h-full object-cover"
              />
            </div>
            <div
              style={{ boxShadow: "0px 6px 24px rgba(221, 194, 255, 0.5)" }}
              className="mt-10 md:mt-0 w-[300px] md:w-[50%] lg:w-[405px] h-full bg-white border border-ternary-blue-ltr-ltr rounded-xl pt-3 pb-5 2xl:pb-14"
            >
              <h2 className="text-base 2xl:text-xl font-bold text-secondary-blue text-center py-3">
                BOOTCAMP'22
              </h2>
              <div className="w-full h-[1px] bg-ternary-blue-ltr mb-8"></div>
              <div className="w-full flex items-center justify-between px-2 sm:px-5">
                <img
                  src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/91aa3120ade4f94cedbc819b644230cb_3_oTJ_nTur4.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652624183337"
                  alt=""
                  className="object-cover w-[100px] h-[100px] 2xl:w-[130px] 2xl:h-[130px] rounded-xl border border-primary-purple"
                />
                <div>
                  <h2 className="text-base 2xl:text-xl text-secondary-blue font-bold">
                    Logo Designing
                  </h2>
                  <p className="text-sm 2xl:text-lg font-normal text-secondary-blue">
                    Day: 05
                  </p>
                  <div className="flex items-center space-x-2">
                    <Clock className="w-[20px] h-[20px]" />
                    <p className="text-sm 2xl:text-lg font-normal text-secondary-blue">
                      9:30 AM
                    </p>
                  </div>
                  <Button
                    innerText="Join now!"
                    type="button"
                    disabled={true}
                    extraClass="mt-2 2xl:w-[140px] 2xl:h-[40px] text-sm 2xl:text-lg text-white font-normal rounded-full bg-primary-green"
                  />
                </div>
                <CircularRight />
              </div>
              <div className="ml-32 2xl:ml-48 mt-1">
                <Tooltip />
              </div>
            </div>
          </div>

          <div className="w-full px-5">
            <h2 className="text-center lg:text-left mt-16 mb-5 text-lg 2xl:text-2xl text-primary-background-blue font-bold">
              Summer Bootcamps
            </h2>

            <div className="hidden lg:flex items-center">
              {bootcampsCount.otherScreen > 0 && (
                <div
                  className={`cursor-pointer ${
                    bootcampsCount.otherScreen > 0 && "mr-5"
                  }`}
                  onClick={() => {
                    if (
                      bootcampsCount.otherScreen > 0 &&
                      bootcampsCount.otherScreen < bootcamps.length
                    ) {
                      setBootcampsCount((prevCount) => ({
                        ...prevCount,
                        otherScreen: prevCount.otherScreen - 3,
                      }));
                    }
                  }}
                >
                  <CircularArrowBack
                    className={`h-7 w-7 xl:h-10 xl:w-10`}
                    stroke={bootcampsCount.otherScreen <= 0 ? "00FFFFFF" : null}
                    fill={bootcampsCount.otherScreen <= 0 ? "00FFFFFF" : null}
                  />
                </div>
              )}
              <div className="flex items-center space-x-10">
                {bootcamps.length === 0 && (
                  <h1 className="text-base text-secondary-blue">
                    No Summer Bootcamps available!
                  </h1>
                )}
                {bootcamps
                  .slice(
                    bootcampsCount.otherScreen,
                    bootcampsCount.otherScreen + 3
                  )
                  .map((course, index) => (
                    <div key={course?.courseId}>
                      <InfoCard
                        extraClass="relative w-[285px] h-[340px] text-left text-lg font-bold border-2 border-primary-pink rounded-3xl flex flex-col items-center space-y-3 p-4"
                        src={`${CLOUDFRONT_AWS_S3_URL}/${
                          course?.mediaAttached[
                            bootcampsCount.otherScreen + index
                          ]
                        }`}
                        button={false}
                        description={course?.courseName}
                        imageStyle="w-full h-[167px] rounded-xl object-cover"
                        extraElement={
                          <div className="px-3 absolute bottom-5 w-full flex items-center justify-between">
                            <div>
                              <p className="text-xs text-secondary-blue">
                                Age: {course?.minAge}+
                              </p>
                              <p className="text-[0.7rem] text-primary-red">
                                10 seats available
                              </p>
                            </div>

                            <Button
                              onClick={() => {
                                dispatch(
                                  bootcampActions.addBatchInfo({
                                    ...course,
                                    plans: JSON.stringify(course?.plans),
                                  })
                                );
                                if (auth.isLoggedIn) {
                                  navigate("/bootcamp-register/batch-type");
                                } else {
                                  navigate("/bootcamp-register/signin");
                                }
                              }}
                              innerText="Book now!"
                              type="button"
                              extraClass="max-w-max text-sm text-white font-normal rounded-full bg-primary-green"
                            />
                          </div>
                        }
                      />
                    </div>
                  ))}
              </div>
              <div
                className="cursor-pointer ml-5"
                onClick={() => {
                  if (
                    bootcampsCount.otherScreen >= 0 &&
                    bootcampsCount.otherScreen < bootcamps.length - 3
                  ) {
                    setBootcampsCount((prevCount) => ({
                      ...prevCount,
                      otherScreen: prevCount.otherScreen + 3,
                    }));
                  }
                }}
              >
                <CircularArrowForward
                  className="h-7 w-7 xl:h-10 xl:w-10"
                  stroke={
                    !bootcamps.slice(bootcampsCount.otherScreen + 3).length > 0
                      ? "00FFFFFF"
                      : null
                  }
                  fill={
                    !bootcamps.slice(bootcampsCount.otherScreen + 3).length > 0
                      ? "00FFFFFF"
                      : null
                  }
                />
              </div>
            </div>

            {/************************SUMMER BOOTCAMP MOBILE SCREEN*************************************/}
            <div className="flex items-center justify-center space-x-1 lg:hidden my-10">
              <div
                className="cursor-pointer"
                onClick={() => {
                  if (
                    bootcampsCount.mobileScreen > 0 &&
                    bootcampsCount.mobileScreen < bootcamps.length
                  ) {
                    setBootcampsCount((prevCount) => ({
                      ...prevCount,
                      mobileScreen: prevCount.mobileScreen - 1,
                    }));
                  }
                }}
              >
                <CircularArrowBack
                  className="h-7 w-7"
                  stroke={bootcampsCount.mobileScreen <= 0 ? "00FFFFFF" : null}
                  fill={bootcampsCount.mobileScreen <= 0 ? "00FFFFFF" : null}
                />
              </div>
              {bootcamps.length === 0 && (
                <h1 className="text-sm text-secondary-blue">
                  No Summer Bootcamps available!
                </h1>
              )}
              {bootcamps
                .slice(
                  bootcampsCount.mobileScreen,
                  bootcampsCount.mobileScreen + 1
                )
                .map((course, index) => (
                  <div key={course.courseId}>
                    <InfoCard
                      extraClass="relative w-[250px] h-[340px] text-left text-lg font-bold border-2 border-primary-pink rounded-3xl flex flex-col items-center space-y-3 p-4"
                      src={`${CLOUDFRONT_AWS_S3_URL}/${
                        course?.mediaAttached[bootcampsCount.mobileScreen]
                      }`}
                      button={false}
                      description={course?.courseName}
                      imageStyle="w-full h-[159px] rounded-xl object-cover"
                      extraElement={
                        <div className="px-3 absolute bottom-5 w-full flex items-center justify-between">
                          <div>
                            <p className="text-xs text-secondary-blue">
                              Age: {course?.minAge}+
                            </p>
                            <p className="text-[0.7rem] text-primary-red">
                              10 seats available
                            </p>
                          </div>

                          <Button
                            onClick={() => {
                              dispatch(
                                bootcampActions.addBatchInfo({
                                  ...course,
                                  plans: JSON.stringify(course.plans),
                                })
                              );
                              if (auth.isLoggedIn) {
                                navigate("/bootcamp-register/batch-type");
                              } else {
                                navigate("/bootcamp-register/signin");
                              }
                            }}
                            innerText="Book now!"
                            type="button"
                            extraClass="max-w-max text-sm text-white font-normal rounded-full bg-primary-green"
                          />
                        </div>
                      }
                    />
                  </div>
                ))}
              <div
                className="cursor-pointer"
                onClick={() => {
                  if (
                    bootcampsCount.mobileScreen >= 0 &&
                    bootcampsCount.mobileScreen < bootcamps.length - 1
                  ) {
                    setBootcampsCount((prevCount) => ({
                      ...prevCount,
                      mobileScreen: prevCount.mobileScreen + 1,
                    }));
                  }
                }}
              >
                <CircularArrowForward
                  className="h-7 w-7"
                  stroke={
                    !bootcamps.slice(bootcampsCount.mobileScreen + 1).length > 0
                      ? "00FFFFFF"
                      : null
                  }
                  fill={
                    !bootcamps.slice(bootcampsCount.mobileScreen + 1).length > 0
                      ? "00FFFFFF"
                      : null
                  }
                />
              </div>
            </div>
          </div>

          <div className="w-full px-5">
            <h2 className="text-center lg:text-left mt-16 mb-5 text-lg 2xl:text-2xl text-primary-background-blue font-bold">
              Trending Skills
            </h2>

            <div className="hidden lg:flex items-center">
              {skillCount.otherScreen > 0 && (
                <div
                  className={`cursor-pointer ${
                    skillCount.otherScreen > 0 && "mr-5"
                  }`}
                  onClick={() => {
                    if (
                      skillCount.otherScreen > 0 &&
                      skillCount.otherScreen < trendingSkills.length
                    ) {
                      setSkillCount((prevCount) => ({
                        ...prevCount,
                        otherScreen: prevCount.otherScreen - 3,
                      }));
                    }
                  }}
                >
                  <CircularArrowBack
                    className={`h-7 w-7 xl:h-10 xl:w-10`}
                    stroke={skillCount.otherScreen <= 0 ? "00FFFFFF" : null}
                    fill={skillCount.otherScreen <= 0 ? "00FFFFFF" : null}
                  />
                </div>
              )}
              <div className="flex items-center space-x-10">
                {trendingSkills.length === 0 && (
                  <h1 className="text-base text-secondary-blue">
                    No Skills Available!
                  </h1>
                )}
                {trendingSkills
                  .slice(skillCount.otherScreen, skillCount.otherScreen + 3)
                  .map((skill, index) => (
                    <div key={skill._id}>
                      <InfoCard
                        extraClass="relative w-[285px] h-[340px] text-left text-lg font-bold border-2 border-primary-pink rounded-3xl flex flex-col items-center space-y-3 p-4"
                        src={`${CLOUDFRONT_AWS_S3_URL}/${
                          skill?.mediaAttached[skillCount.otherScreen + index]
                        }`}
                        button={false}
                        description={skill?.courseName}
                        imageStyle="w-[259px] h-[167px] rounded-xl object-cover"
                        extraElement={
                          <div className="px-3 absolute bottom-5 flex items-center justify-between w-full">
                            <div className="flex items-center space-x-1">
                              <Thunder />
                              <span className="text-[0.7rem] text-secondary-blue font-bold">
                                100+ enrolled
                              </span>
                            </div>
                            <Button
                              onClick={() => {
                                if (auth.isLoggedIn) {
                                  setSelectedCourse({
                                    ...skill,
                                    courseId: skill?._id,
                                  });
                                  navigate("/student/enroll/book-class");
                                } else {
                                  navigate("/signin");
                                }
                              }}
                              innerText="Book now!"
                              type="button"
                              extraClass="max-w-max text-sm text-white font-normal rounded-full bg-primary-green"
                            />
                          </div>
                        }
                      />
                    </div>
                  ))}
              </div>
              <div
                className="cursor-pointer ml-5"
                onClick={() => {
                  if (
                    skillCount.otherScreen >= 0 &&
                    skillCount.otherScreen < trendingSkills.length - 3
                  ) {
                    setSkillCount((prevCount) => ({
                      ...prevCount,
                      otherScreen: prevCount.otherScreen + 3,
                    }));
                  }
                }}
              >
                <CircularArrowForward
                  className="h-7 w-7 xl:h-10 xl:w-10"
                  stroke={
                    !trendingSkills.slice(skillCount.otherScreen + 3).length > 0
                      ? "00FFFFFF"
                      : null
                  }
                  fill={
                    !trendingSkills.slice(skillCount.otherScreen + 3).length > 0
                      ? "00FFFFFF"
                      : null
                  }
                />
              </div>
            </div>

            {/************************TRENDING SKILLS MOBILE SCREEN*************************************/}
            <div className="flex items-center justify-center space-x-1 lg:hidden my-10">
              <div
                className="cursor-pointer"
                onClick={() => {
                  if (
                    skillCount.mobileScreen > 0 &&
                    skillCount.mobileScreen < trendingSkills.length
                  ) {
                    setSkillCount((prevCount) => ({
                      ...prevCount,
                      mobileScreen: prevCount.mobileScreen - 1,
                    }));
                  }
                }}
              >
                <CircularArrowBack
                  className="h-7 w-7"
                  stroke={skillCount.mobileScreen <= 0 ? "00FFFFFF" : null}
                  fill={skillCount.mobileScreen <= 0 ? "00FFFFFF" : null}
                />
              </div>
              {trendingSkills.length === 0 && (
                <h1 className="text-sm text-secondary-blue">
                  No Skills Available!
                </h1>
              )}
              {trendingSkills
                .slice(skillCount.mobileScreen, skillCount.mobileScreen + 1)
                .map((skill, index) => (
                  <div key={skill._id}>
                    <InfoCard
                      extraClass="relative w-[250px] h-[340px] text-left text-lg font-bold border-2 border-primary-pink rounded-3xl flex flex-col items-center space-y-3 p-4"
                      src={`${CLOUDFRONT_AWS_S3_URL}/${
                        skill?.mediaAttached[skillCount.mobileScreen]
                      }`}
                      button={false}
                      description={skill?.courseName}
                      imageStyle="w-[250px] h-[167px] rounded-xl object-cover"
                      extraElement={
                        <div className="px-3 absolute bottom-5 flex items-center justify-between w-full">
                          <div className="flex items-center space-x-1">
                            <Thunder />
                            <span className="text-[0.7rem] text-secondary-blue font-bold">
                              100+ enrolled
                            </span>
                          </div>
                          <Button
                            onClick={() => {
                              if (auth.isLoggedIn) {
                                setSelectedCourse({
                                  ...skill,
                                  courseId: skill?._id,
                                });
                                navigate("/student/enroll/book-class");
                              } else {
                                navigate("/signin");
                              }
                            }}
                            innerText="Book now!"
                            type="button"
                            extraClass="max-w-max text-sm text-white font-normal rounded-full bg-primary-green"
                          />
                        </div>
                      }
                    />
                  </div>
                ))}
              <div
                className="cursor-pointer"
                onClick={() => {
                  if (
                    skillCount.mobileScreen >= 0 &&
                    skillCount.mobileScreen < trendingSkills.length - 1
                  ) {
                    setSkillCount((prevCount) => ({
                      ...prevCount,
                      mobileScreen: prevCount.mobileScreen + 1,
                    }));
                  }
                }}
              >
                <CircularArrowForward
                  className="h-7 w-7"
                  stroke={
                    !trendingSkills.slice(skillCount.mobileScreen + 1).length >
                    0
                      ? "00FFFFFF"
                      : null
                  }
                  fill={
                    !trendingSkills.slice(skillCount.mobileScreen + 1).length >
                    0
                      ? "00FFFFFF"
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentDashboard;
