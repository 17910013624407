import React, { useState } from "react";
import Edit from "../../../assets/SVG/edit";
import Export from "../../../assets/SVG/export";
import { stringTruncate } from "../../../helpers/functions/string-truncate";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Table from "../../../components/Table/Table";
import { batchAssignedClass } from "../../../components/Table/adapter/batchAssignedAdapter";
import { studentList } from "../../../components/Table/adapter/studentListAdapter";
import CustomModal from "../../../components/CustomModal/CustomModal";
import ClassScheduleInfo from "./components/ClassScheduleInfo/ClassScheduleInfo";
import EditScheduledClass from "./components/EditScheduledClass/EditScheduledClass";

const runningAds = [
  { id: "summer-camp", name: "Summer Camp'22", start: "31/05", end: "02/06" },
  {
    id: "digital-art-workshop",
    name: "Digital Art Workshop",
    start: "31/05",
    end: "02/06",
  },
  { id: "summer-camp$", name: "Summer Camp'22", start: "31/05", end: "02/06" },
  {
    id: "digital-art-workshop$",
    name: "Digital Art Workshop",
    start: "31/05",
    end: "02/06",
  },
];

const newRequests = [
  {
    id: "1",
    description: "Time shift to 4:30 PM as teacher was not available.",
    minsAgo: 10,
  },
  {
    id: "2",
    description: "Time shift to 4:30 PM as teacher was not available.",
    minsAgo: 10,
  },
];

const mainSkillsAdded = [
  { id: "chess", name: "Chess", numberOfSubSkills: 4 },
  { id: "graphic-design", name: "Graphic Design", numberOfSubSkills: 3 },
  { id: "3d-model", name: "3D Model", numberOfSubSkills: 1 },
  { id: "ux-design", name: "UX Design", numberOfSubSkills: 6 },
];

const AdminDashboard = () => {
  const [openModal, setOpenModal] = useState({ open: false, type: null });

  const cellClickHandler = (open, type) => {
    setOpenModal({ open: open, type: type });
  };

  const closeModalHandler = () => {
    setOpenModal({ open: false, type: null });
  };

  return (
    <div className="max-w-screen mt-24">
      <div className="flex items-center space-x-10">
        {/*****************RUNNING ADS*****************************/}
        <div className="bg-primary-pink rounded-xl w-[310px] h-[200px] overflow-y-auto">
          <h3 className="py-2 text-center text-base text-secondary-blue font-bold">
            Running Ads
          </h3>
          {runningAds.length > 0 &&
            runningAds.map((ads) => (
              <div
                key={ads.id}
                className="flex items-center justify-between border-t border-primary-purple p-3"
              >
                <div>
                  <p className="text-base text-secondary-blue font-bold">
                    {ads.name}
                  </p>
                  <div className="text-base text-secondary-blue font-normal flex items-center space-x-5">
                    <p>Start: {ads.start}</p>
                    <p>End: {ads.end}</p>
                  </div>
                </div>
                <Edit className="w-[25px] h-[25px]" />
              </div>
            ))}
          {runningAds.length === 0 && (
            <div className="border-t border-primary-purple p-3">
              <p className="text-center text-base text-secondary-blue font-bold">
                No Ads Found :)
              </p>
            </div>
          )}
        </div>

        {/*****************NEW REQUESTS*****************************/}
        <div className="bg-primary-pink rounded-xl w-[310px] h-[200px] overflow-y-auto">
          <h3 className="py-2 text-center text-base text-secondary-blue font-bold">
            New Requests (
            {newRequests.length > 9
              ? newRequests.length
              : `0${newRequests.length}`}
            )
          </h3>
          {newRequests.length > 0 &&
            newRequests.map((req) => (
              <div
                key={req.id}
                className="flex items-center justify-between border-t border-primary-purple p-3"
              >
                <div>
                  <p className="text-base text-secondary-blue font-bold">
                    {stringTruncate(req.description, 22)}
                  </p>
                  <p className="text-base text-secondary-blue font-normal">
                    {req.minsAgo} mins ago
                  </p>
                </div>
                <Export className="w-[24px] h-[24px]" />
              </div>
            ))}
          {newRequests.length === 0 && (
            <div className="border-t border-primary-purple p-3">
              <p className="text-center text-base text-secondary-blue font-bold">
                No Requests Found :)
              </p>
            </div>
          )}
        </div>

        {/*****************MAIN SKILLS ADDED*****************************/}
        <div className="bg-primary-pink rounded-xl w-[310px] h-[200px] overflow-y-auto">
          <h3 className="py-2 text-center text-base text-secondary-blue font-bold">
            Main Skills Added
          </h3>
          <div className="border-t border-primary-purple p-3">
            {mainSkillsAdded.length > 0 &&
              mainSkillsAdded.map((skill) => (
                <p
                  key={skill.id}
                  className="text-base text-secondary-blue font-bold my-1"
                >
                  {skill.name} -
                  <span className="font-normal">
                    {skill.numberOfSubSkills > 9
                      ? skill.numberOfSubSkills
                      : `0${skill.numberOfSubSkills}`}
                  </span>
                </p>
              ))}
            {mainSkillsAdded.length === 0 && (
              <p className="text-center text-base text-secondary-blue font-bold">
                No Skill Found :)
              </p>
            )}
          </div>
        </div>
      </div>

      {/**************************ASSIGNED CLASSES*********************************/}
      <div className="flex items-center space-x-10 mt-16">
        <h2 className="text-2xl font-bold text-secondary-blue">
          Assigned classes
        </h2>
        <Dropdown
          menuList={[
            { id: "batch", name: "Batch" },
            { id: "one-on-one", name: "1:1" },
          ]}
          defaultValue={{ id: "batch", name: "Batch" }}
          dropdownStyle="w-[116px] h-[45px] rounded-lg border border-primary-blue flex items-center justify-around text-lg text-primary-blue font-normal"
          arrowStyle="w-[20px] h-[15px] cursor-pointer"
          dropdownMenuStyle="w-[116px] bg-white"
        />
      </div>
      <div className="my-10">
        <Table
          headersData={batchAssignedClass.headers}
          rowsData={batchAssignedClass.rowData}
          columnsWidth={batchAssignedClass.columnsWidth}
          tableWidth={batchAssignedClass.tableWidth}
          cellClickHandler={cellClickHandler}
        />
      </div>
      {openModal.open && openModal.type === "studentList" && (
        <CustomModal
          open={openModal.open}
          closeModalHandler={closeModalHandler}
        >
          <div className="flex flex-col items-center p-5">
            <h2 className="text-2xl font-extrabold text-primary-blue text-center mb-5">
              Students List
            </h2>
            <Table
              headersData={studentList.headers}
              rowsData={studentList.rowData}
              columnsWidth={studentList.columnsWidth}
              tableWidth={studentList.tableWidth}
            />
          </div>
        </CustomModal>
      )}
      {openModal.open && openModal.type === "batchSchedule" && (
        <CustomModal
          open={openModal.open}
          closeModalHandler={closeModalHandler}
        >
          <ClassScheduleInfo />
        </CustomModal>
      )}
      {openModal.open && openModal.type === "editScheduledClass" && (
        <CustomModal
          open={openModal.open}
          closeModalHandler={closeModalHandler}
        >
          <EditScheduledClass />
        </CustomModal>
      )}
    </div>
  );
};

export default AdminDashboard;
