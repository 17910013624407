import React, { useEffect, useState } from "react";
import CheckboxTick from "../../../assets/SVG/checkbox-tick";
import Button from "../../../components/Button/Button";
import LoadingSpinner from "../../../components/Spinners/LoadingSpinner/LoadingSpinner";
import { BACKEND_BASE_URL } from "../../../helpers/url/url";
import axios from "../../../helpers/axios/axios";

const WalletPlan = () => {
  const [isLoading, setIsLoading] = useState({ id: null });
  const [isChecked, setIsChecked] = useState(false);
  const [showIndividualError, setShowIndividualError] = useState(false);
  const [addAmount, setAddAmount] = useState();

  const [planInfo, setPlanInfo] = useState({});

  const getWalletDetailsAPI = async () => {
    setIsLoading({ id: "main-page" });
    try {
      const getWalletDetailsResponse = await axios.get(
        `${BACKEND_BASE_URL}/api/students/getWalletDetails`
      );
      if (getWalletDetailsResponse?.status === 200) {
        console.log(getWalletDetailsResponse);
        setPlanInfo({
          paymentMode: getWalletDetailsResponse?.data?.wallet?.paymentMode,
          credits: getWalletDetailsResponse?.data?.wallet?.credits,
        });
        setAddAmount(getWalletDetailsResponse?.data?.wallet?.credits);
        setIsLoading({ id: null });
      }
    } catch (err) {
      console.log(err);
      setIsLoading({ id: null });
    }
  };

  useEffect(() => {
    getWalletDetailsAPI();
  }, []);

  const addAmountHandler = (e) => {
    if (e.target.value >= 0) {
      setAddAmount(e.target.value);
    }
  };

  return (
    <div>
      {isLoading.id === "main-page" && <LoadingSpinner />}
      {isLoading.id !== "main-page" && (
        <div className="max-w-screen p-5 lg:p-10 2xl:p-20">
          <div>
            <h1 className="text-center lg:text-left text-2xl xl:text-3xl text-secondary-blue font-bold xl:leading-[40px]">
              Wallet Plan
            </h1>
            <h3 className="text-center lg:text-left text-base xl:text-2xl text-secondary-blue font-normal xl:leading-[40px]">
              Top-up your wallet to get hassle-free learning experience!
            </h3>
          </div>
          <div className="mt-10 max-w-2xl bg-white border border-secondary-blue rounded-xl p-5 sm:p-10  flex flex-col justify-center">
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-secondary-blue font-semibold">
                  Current
                </p>
                <p className="text-base lg:text-xl text-secondary-blue font-semibold">
                  Wallet Balance
                </p>
              </div>
              <span className="text-lg lg:text-2xl font-semibold text-primary-green">
                ${planInfo?.credits ? planInfo?.credits : "Not Found"}
              </span>
            </div>
            <div className="my-5 w-full bg-secondary-blue h-[1px]"></div>
            <div>
              <p className="text-sm text-secondary-blue font-semibold">
                Top-up
              </p>
              <p className="text-base lg:text-xl text-secondary-blue font-semibold">
                Wallet Balance
              </p>
              <div className="my-5 sm:flex sm:items-center sm:space-x-5">
                <p className="text-sm lg:text-lg text-secondary-blue font-semibold">
                  Add Amount
                </p>
                <div className="mt-2 sm:mt-0 w-[250px] flex items-center justify-between border border-primary-purple rounded p-2">
                  <button
                    onClick={() => setAddAmount((prev) => prev - 1)}
                    className="bg-primary-purple text-secondary-blue rounded px-3 py-1"
                  >
                    -
                  </button>
                  <div className="mx-5 text-base lg:text-xl font-semibold text-secondary-blue flex items-center space-x-1 border-b border-secondary-blue">
                    <p>$</p>
                    <input
                      type="text"
                      value={addAmount}
                      onChange={(e) => addAmountHandler(e)}
                      className="w-full focus:outline-none"
                    />
                  </div>
                  <button
                    onClick={() => {
                      if (addAmount >= 1) {
                        setAddAmount((prev) => prev + 1);
                      }
                    }}
                    className="bg-primary-purple text-secondary-blue rounded px-3 py-1"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div className="my-5 w-full bg-primary-purple h-[1px]"></div>
              <div className="text-lg lg:text-2xl font-semibold text-secondary-blue flex items-center justify-between">
                <p>TOTAL</p>
                <p>${addAmount}</p>
              </div>
              <div className="my-5 w-full bg-primary-purple h-[1px]"></div>
              <div className="flex flex-col space-y-3 md:space-y-0 md:flex-row md:items-center md:justify-between">
                <div className="mb-5 md:mb-0">
                  <div className="flex items-center space-x-3">
                    {!isChecked && (
                      <div
                        onClick={() => {
                          setShowIndividualError(false);
                          setIsChecked(true);
                        }}
                        className="cursor-pointer w-[20px] h-[20px] xl:w-[24px] xl:h-[24px] border-2 border-secondary-blue rounded"
                      ></div>
                    )}
                    {isChecked && (
                      <CheckboxTick
                        fill="#434484"
                        className="w-[20px] h-[20px] xl:w-[24px] xl:h-[24px] cursor-pointer"
                        onClick={() => setIsChecked(false)}
                      />
                    )}
                    <p className="text-xs lg:text-lg text-secondary-blue font-normal">
                      I accept all the{" "}
                      <span className="text-sm lg:text-lg cursor-pointer text-primary-green font-medium hover:underline">
                        Terms & Conditions
                      </span>
                    </p>
                  </div>
                  {showIndividualError && (
                    <p className="mt-1 sm:ml-7 lg:ml-9 text-xs sm:text-sm font-semibold text-primary-red">
                      Please accept all the Terms & Conditions!
                    </p>
                  )}
                </div>
                <div className="flex flex-col space-y-3 md:space-y-0 md:flex-row items-center md:space-x-3">
                  <Button
                    isLoading={isLoading.id === "checkout-safely"}
                    disabled={isLoading.id !== null}
                    innerText="Checkout"
                    //onClick={checkoutPlanAPI}
                    type="button"
                    extraClass="py-2 px-5 w-full lg:max-w-max text-xs xl:text-lg text-white font-normal rounded-full bg-primary-green"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WalletPlan;
