import React from "react";
import "./typewriter.css";

const TYPING_SPEED = 100;
const DELETING_SPEED = 100;

class TypewriterEffect extends React.Component {
  state = {
    text: "",
    isDeleting: false,
    loopNum: 0,
    typingSpeed: TYPING_SPEED,
  };

  componentDidMount() {
    this.handleType();
  }

  handleType = () => {
    const { dataText } = this.props;
    const { isDeleting, loopNum, text, typingSpeed } = this.state;
    const i = loopNum % dataText.length;
    const fullText = dataText[i];

    this.setState({
      text: isDeleting
        ? fullText.substring(0, text.length - 1)
        : fullText.substring(0, text.length + 1),
      typingSpeed: isDeleting ? DELETING_SPEED : TYPING_SPEED,
    });

    if (!isDeleting && text === fullText) {
      setTimeout(() => this.setState({ isDeleting: true }), 500);
    } else if (isDeleting && text === "") {
      this.setState({
        isDeleting: false,
        loopNum: loopNum + 1,
      });
    }

    setTimeout(this.handleType, typingSpeed);
  };

  render() {
    return (
      <h1 className="text-base lg:text-lg xl:text-2xl font-normal text-secondary-blue">
        {this.props.heading}&nbsp;
        <span className="text-base md:text-lg xl:text-3xl font-bold text-primary-green">
          {this.state.text}
        </span>
        <span className="cursor"></span>
      </h1>
    );
  }
}

export default TypewriterEffect;
