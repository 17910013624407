import React from "react";

const GraphView = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 86 59"
    >
      <path
        fill="#FFE8F5"
        d="M82.358 45.459l-1.456.003V3.976A3.824 3.824 0 0077.256 0H8.61a3.825 3.825 0 00-3.646 3.976v41.482H3.505A3.439 3.439 0 000 48.818a9.791 9.791 0 0010.076 9.448H75.79a9.79 9.79 0 0010.077-9.447 3.438 3.438 0 00-3.508-3.36h-.001zM8.03 3.977c0-.52.305-.91.58-.91h68.645c.274 0 .58.39.58.91v41.49l-21.474.039a3.04 3.04 0 00-2.068.783 3.995 3.995 0 01-2.6.794H34.18a6.124 6.124 0 01-2.773-1.122 3.07 3.07 0 00-1.674-.502H8.03V3.977zM75.79 55.2H10.076a6.727 6.727 0 01-7.01-6.38c0-.139.187-.294.439-.294l26.233.007a8.884 8.884 0 004.441 1.617h17.515a6.852 6.852 0 004.627-1.572.155.155 0 01.046-.006l25.993-.046c.252 0 .44.154.44.293a6.727 6.727 0 01-7.01 6.381z"
      ></path>
      <path
        fill="#FFE8F5"
        d="M17.631 41.4h-4.6a2.302 2.302 0 01-2.3-2.3V28.367a2.302 2.302 0 012.3-2.3h4.6a2.302 2.302 0 012.3 2.3V39.1a2.302 2.302 0 01-2.3 2.3zm-3.833-3.066h3.067v-9.2h-3.067v9.2zM29.898 41.4h-4.6a2.302 2.302 0 01-2.3-2.3V19.167a2.302 2.302 0 012.3-2.3h4.6a2.302 2.302 0 012.3 2.3V39.1a2.302 2.302 0 01-2.3 2.3zm-3.833-3.066h3.066v-18.4h-3.066v18.4zM42.165 41.4h-4.6a2.303 2.303 0 01-2.3-2.3V9.967a2.302 2.302 0 012.3-2.3h4.6a2.302 2.302 0 012.3 2.3V39.1a2.302 2.302 0 01-2.3 2.3zm-3.834-3.066h3.067v-27.6h-3.067v27.6zM61.333 7.667a13.798 13.798 0 00-13.8 13.8 13.798 13.798 0 0013.8 13.8 13.799 13.799 0 0013.8-13.8 13.818 13.818 0 00-13.8-13.8zm-5.605 4.664l3.075 7.136-7.664-1.34a10.772 10.772 0 014.59-5.796zm5.605 19.87A10.744 10.744 0 0150.6 21.468c0-.108.013-.212.016-.319l9.825 1.718a1.878 1.878 0 002.048-2.591l-3.947-9.16A10.729 10.729 0 1161.333 32.2zM73.6 41.4H49.067a1.533 1.533 0 010-3.066H73.6a1.533 1.533 0 010 3.066z"
      ></path>
    </svg>
  );
};

export default GraphView;
