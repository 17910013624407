export const combineDateTime = (date, time) => {
  let timeSplit = time.split(":");
  date.setHours(timeSplit[0]);
  date.setMinutes(timeSplit[1]);
  date.setSeconds(timeSplit[2]);

  let utcDateTime =
    [date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate()].join(
      "-"
    ) +
    " " +
    [date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()].join(":") +
    "Z"; // eg "2020-5-24 11:31:59Z"
  //console.log(utcDateTime, "utcDateTime");
  const utcDateTimeISO = new Date(utcDateTime);
  //console.log(utcDate?.toISOString());
  return utcDateTimeISO?.toISOString();
};
