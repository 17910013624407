import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";

const ComingSoon = () => {
  const navigate = useNavigate();
  return (
    <div className="relative max-w-screen">
      <img
        src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/Mesa_de_trabajo_1_2_dt94GdTSd.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654158809221"
        alt=""
      />
      <div className="absolute top-[30%] left-1/2 sm:top-[40%] flex flex-col items-center">
        <div className="flex items-center space-x-1 sm:space-x-3 xl:space-x-10">
          <p className="md:tracking-[0.285rem] text-base sm:text-3xl lg:text-4xl xl:text-[60px] text-secondary-blue font-extrabold">
            COMING
          </p>
          <p className="md:tracking-[0.285rem] text-base sm:text-3xl lg:text-4xl xl:text-[60px] text-white font-extrabold">
            SOON
          </p>
        </div>
        <Button
          onClick={() => navigate("/explore-skills/chess")}
          innerText="Go Back"
          type="button"
          extraClass="mt-1 lg:mt-5 xl:py-1 xl:px-3 max-w-max text-white font-normal rounded-full bg-primary-green"
          innerTextStyle="text-xs md:text-base xl:text-lg"
        />
        <span
          onClick={() => navigate("/")}
          className="inline-flex mt-1 lg:mt-2 hover:underline text-xs md:text-base xl:text-lg font-bold text-secondary-blue"
        >
          Back to Home
        </span>
      </div>
    </div>
  );
};

export default ComingSoon;
