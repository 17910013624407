import React, { useState } from "react";

const Checkbox = ({ optionsList, checkboxHandler, defaultValue }) => {
  const [isChecked, setIsChecked] = useState({ ...defaultValue });
  return (
    <div className="flex items-center space-x-10">
      {optionsList.map((option) => (
        <div key={option.id} className="flex items-center space-x-3">
          <span
            onClick={() => {
              setIsChecked({ id: option.id });
              checkboxHandler(option);
            }}
            className={`border border-primary-blue px-1.5 py-1.5 rounded-full cursor-pointer ${
              isChecked.id === option.id ? "bg-primary-blue" : "bg-transparent"
            }`}
          ></span>
          <p className="text-lg font-normal text-primary-blue">{option.name}</p>
        </div>
      ))}
    </div>
  );
};

export default Checkbox;
