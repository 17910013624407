import React from "react";
import classes from "./loading-spinner.module.css";

const LoadingSpinner = () => {
  return (
    <div className="h-screen flex flex-col items-center justify-around">
      <div className={classes.spinner}>
        <div className={classes.spinner1}></div>
        <div className={classes.spinner2}></div>
        <div className={classes.spinner3}></div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
