import React, { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/Spinners/LoadingSpinner/LoadingSpinner";
import { authActions } from "../../redux/features/auth-slice";
import { useDispatch, useSelector } from "react-redux";

const OauthRedirect = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const decoded = jwt_decode(token);
  const bootcamp = useSelector((state) => state.bootcamp);
  const dispatch = useDispatch();

  console.log(token, decoded);

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      //localStorage.setItem("email", decoded.email);
      dispatch(authActions.login({ token: token }));
    }
  }, [token, dispatch]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    //const bootcamp = localStorage.getItem("bootcamp");

    if (token) {
      if (bootcamp && token) {
        navigate("/bootcamp-register/batch-type");
      } else if (token && !bootcamp) {
        navigate("/student/dashboard");
      }
    }
  }, [navigate, bootcamp]);

  return (
    <div className="max-w-screen h-screen bg-white flex space-x-2 mt-[10%] justify-center">
      <h1 className="text-4xl text-secondary-blue font-medium">
        Taking you in
      </h1>
      <div className="mt-4">
        <LoadingSpinner />
      </div>
    </div>
  );
};

export default OauthRedirect;
