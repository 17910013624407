import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "../features/auth-slice";
import { bootcampSlice } from "../features/bootcamp-slice";

export default configureStore({
  reducer: {
    auth: authSlice.reducer,
    bootcamp: bootcampSlice.reducer,
  },
});
