import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TaskTracker from "../../../components/TaskTracker/TaskTracker";
import BookClass from "../../components/BookClass/BookClass";
import BookingSummary from "../../components/BookingSummary/BookingSummary";
import BookingOutcome from "../../components/BookingOutcome/BookingOutcome";

const EnrollCourse = ({ selectedCourse, setSelectedCourse }) => {
  const [taskTracker, setTaskTracker] = useState(1);
  const { subType } = useParams();
  console.log(subType, "subType");

  console.log(selectedCourse, "selectedCourse");

  useEffect(() => {
    if (subType === "book-class") {
      setTaskTracker(1);
    } else if (subType === "summary") {
      setTaskTracker(2);
    } else if (subType === "success" || subType === "failure") {
      setTaskTracker(3);
    }
  }, [subType]);

  return (
    <div>
      {/* <Header /> */}
      <div className="max-w-screen py-10 px-16">
        {subType !== "success" && subType !== "failure" && (
          <div>
            <div>
              <h1 className="text-[32px] text-secondary-blue font-bold">
                Hey Emily,
              </h1>
              <h3 className="text-2xl font-normal text-secondary-blue mt-1">
                Let's learn new skills today!
              </h3>
            </div>

            <div className="my-10 mx-auto">
              <TaskTracker
                taskTracker={taskTracker}
                length={3}
                taskLabels={["Book Class", "Summary", "Confirmation"]}
              />
            </div>
          </div>
        )}
        <div>
          {subType === "book-class" && (
            <BookClass
              selectedCourse={selectedCourse}
              setSelectedCourse={setSelectedCourse}
            />
          )}
          {subType === "summary" && (
            <BookingSummary selectedCourse={selectedCourse} />
          )}
          {(subType === "success" || subType === "failure") && (
            <BookingOutcome />
          )}
        </div>
      </div>
    </div>
  );
};

export default EnrollCourse;
