import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../../../components/Button/Button";
import Dropdown from "../../../../../components/Dropdown/Dropdown";
import { BACKEND_BASE_URL } from "../../../../../helpers/url/url";
import axios from "../../../../../helpers/axios/axios";
import ChevronLeft from "../../../../../assets/SVG/chevron-left";
import ChevronRight from "../../../../../assets/SVG/chevron-right";
import CustomModal from "../../../../../components/CustomModal/CustomModal";

// const allSkills = [
//   {
//     id: "python-basic",
//     title: "Python Basic",
//     description:
//       "Learn basics of python with our industry expert. Get python ready in just 2 weeks. Enroll today!",
//     skillType: "Coding",
//     minAge: 12,
//     studentsLimit: 25,
//   },

//   {
//     id: "web-design",
//     title: "Web Design",
//     description:
//       "Learn basics of python with our industry expert. Get python ready in just 2 weeks. Enroll today!",
//     skillType: "Design",
//     minAge: 12,
//     studentsLimit: 25,
//   },

//   {
//     id: "web-development",
//     title: "Web Development",
//     description:
//       "Learn basics of python with our industry expert. Get python ready in just 2 weeks. Enroll today!",
//     skillType: "Coding",
//     minAge: 12,
//     studentsLimit: 25,
//   },
// ];

const AllCourse = () => {
  const navigate = useNavigate();
  const [allCourses, setAllCourses] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [openTopicsModal, setOpenTopicsModal] = useState(false);
  const [courseTopics, setCourseTopics] = useState([]);
  const [courseType, setCourseType] = useState("default");

  useEffect(() => {
    const getCoursesAPI = async () => {
      try {
        const getCoursesResponse = await axios.get(
          `${BACKEND_BASE_URL}/api/course?courseType=${
            courseType === "default" ? "" : courseType
          }`
        );
        console.log(getCoursesResponse?.data?.courses, "allCourses");
        setAllCourses([...getCoursesResponse?.data?.courses]);
      } catch (err) {
        console.log(err);
      }
    };

    getCoursesAPI();
  }, [courseType]);

  return (
    <div className="w-[45%]">
      <div className="bg-ternary-blue-ltr mt-10 rounded-lg pb-20">
        <div className="flex items-center space-x-10">
          <h3 className="text-secondary-blue text-2xl font-extrabold p-5">
            Add New Skill
          </h3>
          <Button
            onClick={() => navigate("/admin/add-course")}
            innerText="Create New"
            type="button"
            extraClass="py-1 px-5 max-w-max rounded-full bg-primary-green"
            innerTextStyle="text-xs md:text-base xl:text-lg font-normal text-white"
          />
        </div>
        <div className="border-t border-secondary-blue-lt p-5">
          <div className="flex items-center space-x-5 mb-10">
            <p className="text-base text-secondary-blue font-normal">FILTER:</p>
            {/* <Dropdown
              menuList={[
                { id: "regular-class", name: "Regular Class" },
                { id: "bootcamp", name: "bootcamp" },
                { id: "none", name: "Course Type" },
              ]}
              defaultValue={{ id: "none", name: "Course Type" }}
              dropdownStyle="rounded w-[150px] h-[33px] flex items-center justify-around text-lg text-primary-blue font-normal bg-secondary-purple"
              arrowStyle="w-[20px] h-[15px] cursor-pointer"
              dropdownMenuStyle="w-[142px] bg-white"
            /> */}
            <Dropdown
              menuList={[
                { id: "skill", name: "Skill" },
                { id: "bootcamp", name: "Bootcamp" },
                { id: "default", name: "All" },
              ]}
              defaultValue={{ id: "default", name: "Skill Type" }}
              dropdownStyle="rounded w-[150px] h-[33px] flex items-center justify-around text-lg text-primary-blue font-normal bg-secondary-purple"
              arrowStyle="w-[20px] h-[15px] cursor-pointer"
              dropdownMenuStyle="w-[142px] bg-white"
              dropdownHandler={(option) => setCourseType(option.id)}
            />
          </div>
          <div>
            {allCourses && allCourses?.length > 0 ? (
              allCourses.slice(pageCount, pageCount + 5).map((course) => (
                <div
                  key={course?._id}
                  className="bg-white w-[500px] rounded p-5 mb-5"
                >
                  <div className="flex items-center justify-between">
                    <h3 className="w-[70%] text-xl font-semibold text-primary-blue mb-1">
                      {course?.courseName ? course?.courseName : "Not Found"}
                    </h3>
                    <div className="flex items-center space-x-3">
                      <span
                        onClick={() => {
                          setOpenTopicsModal(true);
                          setCourseTopics([...course?.topicsData]);
                        }}
                        className="text-base cursor-pointer font-normal text-primary-red hover:underline"
                      >
                        View Topics
                      </span>
                      <span className="text-base cursor-pointer font-normal text-primary-green hover:underline">
                        Edit
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2 mb-1">
                    <p className="text-sm text-primary-blue font-normal">
                      {course?.courseType ? course?.courseType : "Not Found"}
                    </p>
                    <p className="flex items-center space-x-1">
                      <span className="text-primary-green">●</span>
                      <span className="text-sm text-primary-blue font-normal">
                        Age: {course?.minAge ? course?.minAge : "4"}+
                      </span>
                    </p>
                    <p className="flex items-center space-x-1">
                      <span className="text-primary-green">●</span>
                      <span className="text-sm text-primary-blue font-normal">
                        Limit:{" "}
                        {course?.maxEnrollmentAllowed
                          ? course?.maxEnrollmentAllowed
                          : "5"}
                      </span>
                    </p>
                  </div>
                  <p className="text-secondary-blue-ltr-ltr">
                    {course?.shortDescription
                      ? course?.shortDescription
                      : "Not Found"}
                  </p>
                </div>
              ))
            ) : (
              <p className="text-center text-base text-secondary-blue font-bold">
                No Skills Found :)
              </p>
            )}
          </div>
        </div>
        {allCourses?.length > 0 && (
          <div className="flex items-center justify-center space-x-5 text-2xl font-semibold">
            <span
              onClick={() => {
                if (pageCount - 5 >= 0) {
                  setPageCount((prev) => prev - 5);
                }
              }}
              className={`${
                pageCount - 5 < 0 && "opacity-30"
              } bg-ternary-blue rounded p-1`}
            >
              <ChevronLeft />
            </span>
            <span className="text-primary-blue">{pageCount / 5 + 1}</span>
            <span
              onClick={() => {
                if (pageCount + 5 < allCourses?.length) {
                  setPageCount((prev) => prev + 5);
                }
              }}
              className={`${
                pageCount + 5 >= allCourses?.length && "opacity-30"
              } bg-ternary-blue rounded p-1`}
            >
              <ChevronRight />
            </span>
          </div>
        )}
      </div>
      {openTopicsModal && (
        <CustomModal
          open={openTopicsModal}
          closeModalHandler={() => setOpenTopicsModal(false)}
        >
          <div className="min-w-[500px] min-h-[500px] p-10">
            <h1 className="text-primary-blue text-3xl font-bold text-center mb-10">
              Associated Topics
            </h1>
            {courseTopics?.length > 0 && (
              <div>
                {courseTopics.map((topic, index) => (
                  <div key={index} className="shadow-md w-full p-5 mb-3">
                    <h2 className="text-2xl font-semibold text-primary-blue mb-1">
                      {topic.title}
                    </h2>
                    <p className="text-secondary-blue-ltr-ltr text-xl">
                      {topic.shortDescription}
                    </p>
                  </div>
                ))}
              </div>
            )}
            {courseTopics?.length === 0 && (
              <p className="text-secondary-blue text-xl font-medium text-center">
                No Topics Found :)
              </p>
            )}
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default AllCourse;
