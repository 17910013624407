import { v4 as uuidv4 } from "uuid";
import CircleRemove from "../../../assets/SVG/circle-remove";

export const studentList = {
  tableWidth: "800px",
  columnsWidth: ["15%", "30%", "50%", "5%"],
  headers: [
    {
      accessor: "serialNumber",
      name: "Sr. No.",
      type: "normal",
      extraClass: "font-semibold text-xl text-secondary-blue",
    },
    {
      accessor: "studentName",
      name: "Student Name",
      type: "normal",
      extraClass: "font-semibold text-xl text-secondary-blue",
    },
    {
      accessor: "emailAddress",
      name: "Email Address",
      type: "normal",
      extraClass: "font-semibold text-xl text-secondary-blue",
    },
    {
      accessor: "remove",
      name: "Remove",
      type: "normal",
      extraClass: "font-semibold text-xl text-secondary-blue",
    },
  ],
  rowData: [
    [
      {
        id: uuidv4(),
        cellValue: "1",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue text-center",
      },
      {
        id: uuidv4(),
        cellValue: "Prisha Srivastava",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue",
      },
      {
        id: uuidv4(),
        cellValue: "prishasrivastava@gmail.com",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue",
      },
      {
        id: uuidv4(),
        cellValue: CircleRemove,
        type: "icon",
        cb: () => {
          console.log("clicked remove icon");
        },
        extraClass: "w-[24px] h-[24px]",
      },
    ],

    [
      {
        id: uuidv4(),
        cellValue: "2",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue text-center",
      },
      {
        id: uuidv4(),
        cellValue: "Anuja Gupta",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue",
      },
      {
        id: uuidv4(),
        cellValue: "anujagupta56@gmail.com",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue",
      },
      {
        id: uuidv4(),
        cellValue: CircleRemove,
        type: "icon",
        cb: () => {
          console.log("clicked remove icon");
        },
        extraClass: "w-[24px] h-[24px]",
      },
    ],

    [
      {
        id: uuidv4(),
        cellValue: "3",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue text-center",
      },
      {
        id: uuidv4(),
        cellValue: "Sumit Karande",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue",
      },
      {
        id: uuidv4(),
        cellValue: "sumitkarande16@gmail.com",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue",
      },
      {
        id: uuidv4(),
        cellValue: CircleRemove,
        type: "icon",
        cb: () => {
          console.log("clicked remove icon");
        },
        extraClass: "w-[24px] h-[24px]",
      },
    ],

    [
      {
        id: uuidv4(),
        cellValue: "4",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue text-center",
      },
      {
        id: uuidv4(),
        cellValue: "Sujith karande",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue",
      },
      {
        id: uuidv4(),
        cellValue: "sujithkarande26@gmail.com",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue",
      },
      {
        id: uuidv4(),
        cellValue: CircleRemove,
        type: "icon",
        cb: () => {
          console.log("clicked remove icon");
        },
        extraClass: "w-[24px] h-[24px]",
      },
    ],

    [
      {
        id: uuidv4(),
        cellValue: "5",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue text-center",
      },
      {
        id: uuidv4(),
        cellValue: "Anupama Srijan",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue",
      },
      {
        id: uuidv4(),
        cellValue: "anupamasri21@gmail.com",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue",
      },
      {
        id: uuidv4(),
        cellValue: CircleRemove,
        type: "icon",
        cb: () => {
          console.log("clicked remove icon");
        },
        extraClass: "w-[24px] h-[24px]",
      },
    ],
  ],
};
