import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";

const initialState = {
  isLoggedIn: false,
  token: null,
  expirationDate: null,
  user: null,
  username: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    login: (state, action) => {
      const decoded = jwt_decode(action.payload.token);
      const tokenExpirationDate = action.payload.expirationDate
        ? action.payload.expirationDate
        : Date.parse(new Date(new Date().getTime() + 60 * 60 * 24 * 1000));

      state.isLoggedIn = true;
      state.token = action.payload.token;
      state.user = action.payload.user ? action.payload.user : decoded.email;
      state.username = decoded.name;
      state.expirationDate = tokenExpirationDate;

      localStorage.setItem(
        "userData",
        JSON.stringify({
          token: action.payload.token,
          user: decoded.email,
          expirationDate: tokenExpirationDate,
        })
      );
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.token = null;
      state.user = null;
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
    },
  },
});

export const authActions = authSlice.actions;
