import React from "react";

const Google = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-7 w-7"}
      fill="none"
      viewBox="0 0 29 29"
      {...rest}
    >
      <path
        fill="#FFC107"
        d="M26.349 12.133h-.974v-.05H14.5v4.833h6.83c-.997 2.814-3.674 4.834-6.83 4.834a7.25 7.25 0 010-14.5c1.849 0 3.53.697 4.81 1.836l3.418-3.418A12.028 12.028 0 0014.5 2.416c-6.673 0-12.083 5.41-12.083 12.084 0 6.673 5.41 12.083 12.083 12.083s12.084-5.41 12.084-12.083c0-.81-.084-1.601-.235-2.367z"
      ></path>
      <path
        fill="#FF3D00"
        d="M3.81 8.876l3.97 2.911A7.247 7.247 0 0114.5 7.25c1.848 0 3.53.697 4.81 1.836l3.417-3.418A12.027 12.027 0 0014.5 2.416c-4.642 0-8.666 2.62-10.69 6.46z"
      ></path>
      <path
        fill="#4CAF50"
        d="M14.5 26.583c3.122 0 5.957-1.194 8.102-3.137l-3.74-3.164A7.195 7.195 0 0114.5 21.75c-3.143 0-5.811-2.004-6.816-4.8l-3.94 3.035c1.999 3.913 6.06 6.598 10.756 6.598z"
      ></path>
      <path
        fill="#1976D2"
        d="M26.348 12.134h-.973v-.05H14.5v4.833h6.829a7.274 7.274 0 01-2.47 3.366l.002-.002 3.74 3.165c-.264.24 3.982-2.904 3.982-8.946 0-.81-.083-1.6-.235-2.366z"
      ></path>
    </svg>
  );
};

export default Google;
