import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";

const NoMatch = () => {
  const navigate = useNavigate();

  return (
    <div className="relative flex flex-col items-center">
      <p className="mt-3 sm:mt-8 absolute text-base sm:text-2xl xl:text-4xl font-bold text-white">
        Oops...Page not found
      </p>
      <Button
        innerText="Go Back"
        type="button"
        extraClass="absolute bottom-7 sm:bottom-14 lg:bottom-20 flex max-w-max text-white rounded-full bg-primary-green"
        onClick={() => navigate(-1)}
        innerTextStyle="font-[750] text-xs sm:text-sm xl:text-lg"
      />
      <span className="absolute bottom-2 sm:bottom-5 lg:bottom-10 hover:underline text-xs sm:text-sm md:text-lg text-white font-[750]">
        Back to Home
      </span>
      <img
        src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/Artboard_2_1_t9NPdi_3w.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653667820719"
        alt=""
        className="max-w-screen h-[250px] sm:h-full"
      />
    </div>
  );
};

export default NoMatch;
