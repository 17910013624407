import React, { useEffect, useState } from "react";
import CheckboxTick from "../../../assets/SVG/checkbox-tick";
import Button from "../../../components/Button/Button";
import CircularTickBlue from "../../../assets/SVG/circular-tick-blue";
import CircularCrossBlue from "../../../assets/SVG/circular-cross-blue";
import { BACKEND_BASE_URL } from "../../../helpers/url/url";
import axios from "../../../helpers/axios/axios";
import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";

const BootcampPayment = ({ batchDetails, setBatchDetails }) => {
  const [promocode, setPromocode] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isPromocodeApplied, setPromocodeApplied] = useState(null);
  const [showIndividualError, setShowIndividualError] = useState(false);
  const [isLoading, setIsLoading] = useState({ id: null });

  //const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  // useEffect(() => {
  //   const selectedBootcamp = JSON.parse(localStorage.getItem("bootcamp"));
  //   let BATCH_DETAILS = {};
  //   const getPlanIdHandler = () => {
  //     for (let i = 0; i < selectedBootcamp?.plans.length; i++) {
  //       if (
  //         selectedBootcamp?.plans[i].startDate.slice(0, 11) ===
  //           batchDetails.batchStartDate.slice(0, 11) &&
  //         selectedBootcamp?.plans[i].endDate.slice(0, 11) ===
  //           batchDetails.batchEndDate.slice(0, 11) &&
  //         selectedBootcamp?.plans[i].timeslot.from ===
  //           batchDetails.timeslot.from &&
  //         selectedBootcamp?.plans[i].timeslot.to === batchDetails.timeslot.to
  //       ) {
  //         BATCH_DETAILS = {
  //           ...batchDetails,
  //           planId: selectedBootcamp?.plans[i].id,
  //           courseAmount: selectedBootcamp?.courseAmount,
  //           courseAmountAfterCoupon: selectedBootcamp?.courseAmount,
  //           courseName: selectedBootcamp?.courseName,
  //         };
  //       }
  //     }
  //   };
  //   getPlanIdHandler();
  //   if (BATCH_DETAILS) {
  //     setBatchDetails({ ...BATCH_DETAILS });
  //   }
  // }, [batchDetails, setBatchDetails]);

  // const bootcamp = useSelector((state) => state.bootcamp);
  // console.log(bootcamp, "bootcamp payment page");

  useEffect(() => {
    if (!batchDetails.planId && !batchDetails.checkoutId) {
      navigate("/summer-bootcamp");
    }
  }, [batchDetails.planId, batchDetails.checkoutId, navigate]);

  // useEffect(() => {
  //   const bootcamp = JSON.parse(localStorage.getItem("bootcamp"));
  //   if (bootcamp) {
  //     let timer = setTimeout(() => {
  //       localStorage.removeItem("bootcamp");
  //       navigate("/summer-bootcamp");
  //     }, 600000);
  //     return () => {
  //       clearTimeout(timer);
  //     };
  //   } else {
  //     navigate("/summer-bootcamp");
  //   }
  // }, [navigate]);

  const applyRemoveCouponCodeAPI = async (applied) => {
    setIsLoading({ id: "validate-coupon" });
    try {
      const applyCouponResponse = await axios.post(
        `${BACKEND_BASE_URL}/api/payment/applyCouponCode`,
        {
          checkoutId: batchDetails.checkoutId,
          couponCode: promocode,
          applied: applied,
        }
      );
      if (applyCouponResponse?.status === 200) {
        if (applied === false) {
          setPromocode("");
          setPromocodeApplied(null);
        } else {
          setPromocodeApplied(true);
        }

        setBatchDetails({
          ...batchDetails,
          courseAmountAfterCoupon: applyCouponResponse?.data?.finalAmount,
        });
      } else if (applyCouponResponse?.status === 404) {
        setPromocodeApplied(false);
        setPromocode("");
      }
      console.log("applyCouponResponse", applyCouponResponse);
      setIsLoading({ id: null });
    } catch (err) {
      console.error(err);
      setPromocodeApplied(false);
      setIsLoading({ id: null });
    }
  };

  const checkoutPlanAPI = async () => {
    if (isChecked) {
      setShowIndividualError(false);
      setIsLoading({ id: "checkout-safely" });
      try {
        const checkoutPlanResponse = await axios.post(
          `${BACKEND_BASE_URL}/api/payment/checkout`,
          {
            checkoutId: batchDetails.checkoutId,
          }
        );
        console.log("checkoutPlanResponse", checkoutPlanResponse);
        if (checkoutPlanResponse?.status === 200) {
          window.location.replace(checkoutPlanResponse?.data?.redirectURL);
        }
        setIsLoading({ id: null });
      } catch (err) {
        console.error(err);
        setIsLoading({ id: null });
      }
    } else {
      setShowIndividualError(true);
      setIsLoading({ id: null });
    }
  };

  return (
    <div className="w-[100%] flex flex-col items-center">
      <h1 className="text-xl xl:text-3xl text-white font-medium mb-10">
        Let's get you there!
      </h1>
      <div className="w-[90%] sm:w-[70%] lg:w-[50%] xl:w-[45%] 2xl:w-[40%] border boder-primary-pink rounded-[30px] p-7 xl:p-10">
        <div>
          <div className="mb-5 flex items-center justify-between">
            <h4 className="text-sm xl:text-base font-medium leading-[24px] text-primary-pink">
              Bootcamp Enrolled
            </h4>
            <h4 className="text-sm xl:text-base font-medium leading-[24px] text-primary-pink">
              Price
            </h4>
          </div>
          <div className="mb-7 flex items-center justify-between">
            <p className="text-base xl:text-xl font-medium leading-[24px] text-primary-pink">
              {batchDetails ? batchDetails?.courseName : ""}
            </p>
            <p className="text-base xl:text-xl font-medium leading-[24px] text-primary-pink">
              ${batchDetails ? batchDetails?.courseAmount : ""}
            </p>
          </div>
          <div className="mb-3 flex items-center justify-between">
            <p className="text-lg xl:text-2xl font-medium leading-[24px] text-primary-pink">
              TOTAL
            </p>
            <p className="text-lg xl:text-2xl font-medium leading-[24px] text-primary-pink">
              ${batchDetails ? batchDetails?.courseAmount : ""}
            </p>
          </div>
        </div>
        <div className="bg-primary-pink w-full h-[1px] mt-10 mb-7"></div>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <input
              type="text"
              placeholder="Apply Promocode"
              value={promocode}
              onChange={(e) => {
                if (isPromocodeApplied === false && e.target.value === "") {
                  setPromocodeApplied(null);
                }
                setPromocode(e.target.value);
              }}
              readOnly={isPromocodeApplied === true ? true : false}
              className="w-[120px] h-[31px] xl:w-[200px] xl:h-[42px] bg-transparent border-t-2 border-b-2 border-l-2 rounded-tl-lg rounded-bl-lg borderborder-primary-purple py-1.5 pl-2 xl:px-5 text-sm xl:text-lg placeholder:text-xs xl:placeholder:text-base text-primary-pink font-normal placeholder:text-primary-pink focus:outline-none"
            />
            <Button
              innerText={
                isPromocodeApplied === true && promocode !== ""
                  ? "Applied"
                  : isPromocodeApplied === false && promocode !== ""
                  ? "Invalid"
                  : "Apply"
              }
              Icon={
                isPromocodeApplied === true && promocode !== ""
                  ? CircularTickBlue
                  : isPromocodeApplied === false && promocode !== ""
                  ? CircularCrossBlue
                  : null
              }
              type="button"
              extraClass={`xl:-ml-3 h-[31px] xl:h-[41px] xl:w-[130px] text-xs md:text-sm xl:text-lg text-secondary-blue font-normal ${
                isPromocodeApplied === false && promocode !== ""
                  ? "bg-primary-yellow"
                  : "bg-ternary-pink"
              } rounded-br-lg rounded-tl-none rounded-tr-lg rounded-bl-none`}
              innerTextStyle="font-normal"
              onClick={() => applyRemoveCouponCodeAPI(true)}
              isLoading={isLoading.id === "validate-coupon"}
              disabled={isLoading.id !== null}
            />
            {isPromocodeApplied === true && (
              <span
                onClick={() => applyRemoveCouponCodeAPI(false)}
                className="cursor-pointer self-end text-sm ml-2 text-primary-red underline"
              >
                Remove Coupon
              </span>
            )}
          </div>
          <div className="flex items-center space-x-3">
            {isPromocodeApplied && (
              <strike className="text-sm xl:text-lg text-ternary-pink-lt font-medium">
                {batchDetails ? batchDetails?.courseAmount : ""}
              </strike>
            )}
            <p className="text-lg xl:text-2xl font-medium leading-[24px] text-primary-pink">
              {isPromocodeApplied === true
                ? `$$${batchDetails.courseAmountAfterCoupon}`
                : `$${batchDetails.courseAmount}`}
            </p>
          </div>
        </div>
        <div className="bg-primary-pink w-full h-[1px] mt-7 mb-10"></div>
        <div className="flex flex-col space-y-3 md:space-y-0 md:flex-row md:items-center md:justify-between">
          <div className="mb-5 md:mb-0">
            <div className="flex items-center space-x-3">
              {!isChecked && (
                <div
                  onClick={() => {
                    setShowIndividualError(false);
                    setIsChecked(true);
                  }}
                  className="cursor-pointer w-[20px] h-[20px] xl:w-[24px] xl:h-[24px] border-2 border-primary-purple rounded"
                ></div>
              )}
              {isChecked && (
                <CheckboxTick
                  className="w-[20px] h-[20px] xl:w-[24px] xl:h-[24px] cursor-pointer"
                  onClick={() => setIsChecked(false)}
                />
              )}
              <p className="text-[0.65rem] xl:text-sm text-primary-pink font-normal">
                I accept all the{" "}
                <span className="text-[0.75rem] cursor-pointer text-ternary-skyblue font-medium hover:underline">
                  Terms & Conditions
                </span>
              </p>
            </div>
            {showIndividualError && (
              <p className="mt-1 ml-9 text-xs text-primary-red">
                Please accept all the Terms & Conditions!
              </p>
            )}
          </div>
          <div className="flex flex-col space-y-3 md:space-y-0 md:flex-row items-center md:space-x-3">
            <Button
              isLoading={isLoading.id === "checkout-safely"}
              disabled={isLoading.id !== null}
              innerText="Checkout"
              onClick={checkoutPlanAPI}
              type="button"
              extraClass="py-1 max-w-max text-xs xl:text-lg text-white font-normal rounded-full bg-primary-green"
            />
            <Button
              innerText="Cancel"
              type="button"
              extraClass="py-1 w-[120px] text-xs xl:text-lg text-white font-normal rounded-full bg-transparent border border-primary-red"
              onClick={() => {
                //localStorage.removeItem("bootcamp");
                window.location.reload();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BootcampPayment;
