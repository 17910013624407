import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  imageUrl: "",
  planId: null,
  batchStartDate: "2022-06-12T18:30:00.000+00:00",
  batchEndDate: "2022-06-23T18:30:00.000+00:00",
  timeslot: { from: "09:30", to: "12:30" },
  batchId: "13-24",
  slotId: "09:30-12:30",
  courseAmount: null,
  courseDescription: "",
  courseName: "",
  timezone: "",
  currency: "",
  courseId: "",
  courseType: "",
  targetCountry: "",
  minAge: "",
  courseAmountAfterCoupon: null,
  plans: [],
};

export const bootcampSlice = createSlice({
  name: "bootcamp",
  initialState: initialState,
  reducers: {
    addBatchInfo: (state, action) => {
      state.plans = action.payload.plans;
      state.imageUrl = action.payload.imageUrl;
      state.courseAmount = action.payload.courseAmount;
      state.courseDescription = action.payload.courseDescription;
      state.courseName = action.payload.courseName;
      state.timezone = action.payload.timezone;
      state.currency = action.payload.currency;
      state.courseId = action.payload.courseId;
      state.courseType = action.payload.courseType;
      state.targetCountry = action.payload.targetCountry;
      state.minAge = action.payload.minAge;
      state.planId = action.payload.planId ? action.payload.planId : null;
      state.batchStartDate = action.payload.startDate
        ? action.payload.startDate
        : "2022-06-12T18:30:00.000+00:00";
      state.batchEndDate = action.payload.endDate
        ? action.payload.endDate
        : "2022-06-23T18:30:00.000+00:00";
      state.timeslot = { from: "09:30", to: "12:30" };
      state.batchId = "13-24";
      state.slotId = "09:30-12:30";
      state.courseAmountAfterCoupon = action.payload.courseAmountAfterCoupon
        ? action.payload.courseAmountAfterCoupon
        : null;
    },
  },
});

export const bootcampActions = bootcampSlice.actions;
