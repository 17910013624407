import View from "../../../assets/SVG/view";
import Export from "../../../assets/SVG/export";
import Copy from "../../../assets/SVG/copy";
import Edit from "../../../assets/SVG/edit";
import { v4 as uuidv4 } from "uuid";

export const batchAssignedClass = {
  tableWidth: "1100px",
  columnsWidth: ["20%", "15%", "20%", "10%", "20%", "15%"],
  headers: [
    {
      accessor: "batchName",
      name: "Batch Name/No.",
      type: "normal",
      extraClass: "font-semibold text-xl text-secondary-blue",
    },
    {
      accessor: "studentList",
      name: "Student List",
      type: "normal",
      extraClass: "font-semibold text-xl text-secondary-blue",
    },
    {
      accessor: "skill",
      name: "Skill",
      type: "normal",
      extraClass: "font-semibold text-xl text-secondary-blue",
    },
    {
      accessor: "schedule",
      name: "Schedule",
      type: "normal",
      extraClass: "font-semibold text-xl text-secondary-blue",
    },
    {
      accessor: "classLink",
      name: "Class Link",
      type: "normal",
      extraClass: "font-semibold text-xl text-secondary-blue",
    },
    {
      accessor: "edit",
      name: "Edit",
      type: "normal",
      extraClass: "font-semibold text-xl text-secondary-blue",
    },
  ],
  rowData: [
    [
      {
        id: uuidv4(),
        cellValue: "GD001",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue",
      },
      {
        id: uuidv4(),
        cellValue: View,
        type: "icon",
        isActionModal: true,
        cb: () => {
          console.log("clicked view icon");
          return "studentList";
        },
        extraClass: "w-[24px] h-[13px]",
      },
      {
        id: uuidv4(),
        cellValue: "Graphic Design",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue",
      },
      {
        id: uuidv4(),
        cellValue: Export,
        type: "icon",
        isActionModal: true,
        cb: () => {
          console.log("clicked schedule icon");
          return "batchSchedule";
        },
        extraClass: "w-[24px] h-[24px]",
      },
      {
        id: uuidv4(),
        cellValue: Copy,
        type: "icon",
        copyToClipboard: { isAllowed: true, text: "www.krafternoon.com" },
        cb: () => {
          console.log("clicked copy icon");
        },
        extraClass: "w-[23px] h-[26px]",
      },
      {
        id: uuidv4(),
        cellValue: Edit,
        type: "icon",
        isActionModal: true,
        cb: () => {
          console.log("clicked schedule icon");
          return "editScheduledClass";
        },
        extraClass: "w-[25px] h-[25px]",
      },
    ],

    [
      {
        id: uuidv4(),
        cellValue: "WD001",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue",
      },
      {
        id: uuidv4(),
        cellValue: View,
        type: "icon",
        isActionModal: true,
        cb: () => {
          console.log("clicked view icon");
          return "studentList";
        },
        extraClass: "w-[24px] h-[13px]",
      },
      {
        id: uuidv4(),
        cellValue: "Web Design",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue",
      },
      {
        id: uuidv4(),
        cellValue: Export,
        type: "icon",
        isActionModal: true,
        cb: () => {
          console.log("clicked schedule icon");
          return "batchSchedule";
        },
        extraClass: "w-[24px] h-[24px]",
      },
      {
        id: uuidv4(),
        cellValue: Copy,
        type: "icon",
        copyToClipboard: { isAllowed: true, text: "www.fontawesome.com" },
        cb: () => {
          console.log("clicked copy icon");
        },
        extraClass: "w-[23px] h-[26px]",
      },
      {
        id: uuidv4(),
        cellValue: Edit,
        type: "icon",
        isActionModal: true,
        cb: () => {
          console.log("clicked schedule icon");
          return "editScheduledClass";
        },
        extraClass: "w-[25px] h-[25px]",
      },
    ],

    [
      {
        id: uuidv4(),
        cellValue: "CD001",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue",
      },
      {
        id: uuidv4(),
        cellValue: View,
        type: "icon",
        isActionModal: true,
        cb: () => {
          console.log("clicked view icon");
          return "studentList";
        },
        extraClass: "w-[24px] h-[13px]",
      },
      {
        id: uuidv4(),
        cellValue: "Coding",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue",
      },
      {
        id: uuidv4(),
        cellValue: Export,
        type: "icon",
        isActionModal: true,
        cb: () => {
          console.log("clicked schedule icon");
          return "batchSchedule";
        },
        extraClass: "w-[24px] h-[24px]",
      },
      {
        id: uuidv4(),
        cellValue: Copy,
        type: "icon",
        copyToClipboard: { isAllowed: true, text: "www.figma.com" },
        cb: () => {
          console.log("clicked copy icon");
        },
        extraClass: "w-[23px] h-[26px]",
      },
      {
        id: uuidv4(),
        cellValue: Edit,
        type: "icon",
        isActionModal: true,
        cb: () => {
          console.log("clicked schedule icon");
          return "editScheduledClass";
        },
        extraClass: "w-[25px] h-[25px]",
      },
    ],

    [
      {
        id: uuidv4(),
        cellValue: "CH001",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue",
      },
      {
        id: uuidv4(),
        cellValue: View,
        type: "icon",
        isActionModal: true,
        cb: () => {
          console.log("clicked view icon");
          return "studentList";
        },
        extraClass: "w-[24px] h-[13px]",
      },
      {
        id: uuidv4(),
        cellValue: "Chess",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue",
      },
      {
        id: uuidv4(),
        cellValue: Export,
        type: "icon",
        isActionModal: true,
        cb: () => {
          console.log("clicked schedule icon");
          return "batchSchedule";
        },
        extraClass: "w-[24px] h-[24px]",
      },
      {
        id: uuidv4(),
        cellValue: Copy,
        type: "icon",
        copyToClipboard: { isAllowed: true, text: "www.tinder.com" },
        cb: () => {
          console.log("clicked copy icon");
        },
        extraClass: "w-[23px] h-[26px]",
      },
      {
        id: uuidv4(),
        cellValue: Edit,
        type: "icon",
        isActionModal: true,
        cb: () => {
          console.log("clicked schedule icon");
          return "editScheduledClass";
        },

        extraClass: "w-[25px] h-[25px]",
      },
    ],

    [
      {
        id: uuidv4(),
        cellValue: "VE001",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue",
      },
      {
        id: uuidv4(),
        cellValue: View,
        type: "icon",
        isActionModal: true,
        cb: () => {
          console.log("clicked view icon");
          return "studentList";
        },
        extraClass: "w-[24px] h-[13px]",
      },
      {
        id: uuidv4(),
        cellValue: "Video Editing",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue",
      },
      {
        id: uuidv4(),
        cellValue: Export,
        type: "icon",
        isActionModal: true,
        cb: () => {
          console.log("clicked schedule icon");
          return "batchSchedule";
        },
        extraClass: "w-[24px] h-[24px]",
      },
      {
        id: uuidv4(),
        cellValue: Copy,
        type: "icon",
        copyToClipboard: { isAllowed: true, text: "www.facebook.com" },
        cb: () => {
          console.log("clicked copy icon");
        },
        extraClass: "w-[23px] h-[26px]",
      },
      {
        id: uuidv4(),
        cellValue: Edit,
        type: "icon",
        isActionModal: true,
        cb: () => {
          console.log("clicked schedule icon");
          return "editScheduledClass";
        },
        extraClass: "w-[25px] h-[25px]",
      },
    ],

    [
      {
        id: uuidv4(),
        cellValue: "3D001",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue",
      },
      {
        id: uuidv4(),
        cellValue: View,
        type: "icon",
        isActionModal: true,
        cb: () => {
          console.log("clicked view icon");
          return "studentList";
        },
        extraClass: "w-[24px] h-[13px]",
      },
      {
        id: uuidv4(),
        cellValue: "3D Modelling",
        type: "normal",
        extraClass: "font-normal text-lg text-secondary-blue",
      },
      {
        id: uuidv4(),
        cellValue: Export,
        type: "icon",
        isActionModal: true,
        cb: () => {
          console.log("clicked schedule icon");
          return "batchSchedule";
        },
        extraClass: "w-[24px] h-[24px]",
      },
      {
        id: uuidv4(),
        cellValue: Copy,
        copyToClipboard: { isAllowed: true, text: "www.google.com" },
        type: "icon",
        cb: () => {
          console.log("clicked copy icon");
        },
        extraClass: "w-[23px] h-[26px]",
      },
      {
        id: uuidv4(),
        cellValue: Edit,
        type: "icon",
        isActionModal: true,
        cb: () => {
          console.log("clicked schedule icon");
          return "editScheduledClass";
        },
        extraClass: "w-[25px] h-[25px]",
      },
    ],
  ],
};
