import React from "react";

const Cross = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        fill="#434484"
        d="M.826.826a2.822 2.822 0 013.991 0L12 8.01 19.183.826a2.822 2.822 0 013.99 3.991L15.992 12l7.183 7.183a2.822 2.822 0 01-3.991 3.99L12 15.99l-7.183 7.183a2.822 2.822 0 01-3.99-3.99L8.008 12 .826 4.817a2.822 2.822 0 010-3.991z"
      ></path>
    </svg>
  );
};

export default Cross;
