import React, { useState } from "react";
import OtpInput from "react-otp-input";
import Button from "../../../components/Button/Button";
import { BACKEND_BASE_URL } from "../../../helpers/url/url";
import axios from "../../../helpers/axios/axios";
import { useNavigate } from "react-router-dom";

const BootcampVerifyOtp = () => {
  const [otpCode, setOtpCode] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ isError: false, message: "" });

  const navigate = useNavigate();
  // const handleOtpSubmit = (e) => {
  //   e.preventDefault();
  //   if (e.key === "Enter") {
  //     //onClick();
  //     console.log(otpCode, "otpCode");
  //   }
  // };

  const email = localStorage.getItem("email");

  const sendOtpAPI = async (email) => {
    setError({ isError: false, message: "" });
    try {
      const sendOtpResponse = await axios.post(
        `${BACKEND_BASE_URL}/api/otp-service/send`,
        { email: email }
      );
      if (sendOtpResponse?.data?.success) {
        navigate("bootcamp-register/verify-otp");
      }
      console.log(sendOtpResponse, "sendOtpResponse");
    } catch (err) {
      console.log(err);
      if (err) {
        setError({
          isError: true,
          message: "Oops something went wrong! Please try again.",
        });
      }
    }
  };

  const verifyOtpAPI = async () => {
    setIsLoading(true);
    try {
      const verifyOtpResponse = await axios.post(
        `${BACKEND_BASE_URL}/api/otp-service/verify`,
        { email: email, otp: otpCode }
      );

      console.log(verifyOtpResponse, "verifyOtpResponse");
      if (verifyOtpResponse?.data?.success) {
        navigate("/bootcamp-register/signin");
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      if (err.response.status >= 400 && err.response.status < 500) {
        setError({
          isError: true,
          message: "OTP didn't match! Please try again.",
        });
      } else {
        setError({
          isError: true,
          message: "Oops something went wrong! Please try again.",
        });
      }
      setIsLoading(false);
    }
  };

  const handleChange = (otp) => setOtpCode(otp);
  return (
    <div>
      <div className="flex flex-col items-center pb-20">
        <h1 className="mt-10 text-base xl:text-2xl font-extrabold text-primary-pink">
          Verify Email address
        </h1>
        {error.isError && (
          <p className="text-primary-red font-semibold">{error.message}</p>
        )}
        <p className="mt-3 text-center text-sm lg:text-lg font-normal text-primary-pink">
          Enter OTP sent to you email address
        </p>

        <form
          className="mt-10 flex flex-col items-center"
          //onSubmit={handleOtpSubmit}
        >
          <OtpInput
            value={otpCode}
            onChange={handleChange}
            numInputs={6}
            shouldAutoFocus={true}
            inputStyle="text-primary-pink border-b border-ternary-pink-lt mr-5 w-8 text-center bg-transparent text-xl"
            focusStyle="focus:outline-none"
          />
          <div className="flex flex-col lg:flex-row items-center lg:space-x-3 mt-10">
            <Button
              onClick={verifyOtpAPI}
              innerText="Verify"
              type="button"
              extraClass={`xl:mt-0 max-w-max px-5 text-center text-sm sm:text-base text-white rounded-full bg-primary-green ${
                isLoading && "opacity-20"
              }`}
              isLoading={isLoading}
              disabled={isLoading}
              innerTextStyle="font-normal"
            />
            <span
              onClick={sendOtpAPI}
              className="mb-10 lg:mb-0 mt-2 text-xs lg:text-sm text-primary-red hover:underline cursor-pointer"
            >
              Resend code
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BootcampVerifyOtp;
