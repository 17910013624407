import React, { useState } from "react";

const QuestionCard = ({ question, answer }) => {
  const [viewAnswer, setViewAnswer] = useState(false);

  return (
    <div className="w-[300px] sm:w-[500px] xl:w-[920px] border border-primary-purple rounded-xl p-3 sm:px-5 xl:p-8">
      <div className="flex items-center justify-between">
        <h2 className="font-bold text-sm xl:text-[20px] text-secondary-blue">
          {question}
        </h2>
        {viewAnswer && (
          <span
            onClick={() => setViewAnswer(false)}
            className="cursor-pointer text-2xl text-secondary-blue"
          >
            -
          </span>
        )}
        {!viewAnswer && (
          <span
            onClick={() => setViewAnswer(true)}
            className="cursor-pointer text-2xl text-secondary-blue"
          >
            +
          </span>
        )}
      </div>
      {viewAnswer && answer && answer}
    </div>
  );
};

export default QuestionCard;
