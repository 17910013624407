import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TaskTracker from "../../components/TaskTracker/TaskTracker";
import { BACKEND_BASE_URL } from "../../helpers/url/url";
//import Header from "../../Student/components/Header/Header";
import SelectPricingPlan from "./components/SelectPricingPlan";
import Summary from "./components/Summary";
import axios from "../../helpers/axios/axios";
import Pricing from "./components/Pricing";
import LoadingSpinner from "../../components/Spinners/LoadingSpinner/LoadingSpinner";

const Payment = () => {
  const [taskTracker, setTaskTracker] = useState(1);
  const { type } = useParams();

  const [prepaidPlan, setPrepaidPlan] = useState([]);
  const [postpaidPlan, setPostpaidPlan] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getPostpaidWalletPlansAPI = async () => {
      setIsLoading(true);
      try {
        const postpaidPlanResponse = await axios.get(
          `${BACKEND_BASE_URL}/api/payment/getAllWalletPlans?paymentMode=postpaid`
        );
        console.log(postpaidPlanResponse, "postpaidPlanResponse");
        if (postpaidPlanResponse.status === 200) {
          setPostpaidPlan([...postpaidPlanResponse?.data?.plans]);
          setIsLoading(false);
        }
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };

    getPostpaidWalletPlansAPI();
  }, []);

  useEffect(() => {
    const getPrepaidWalletPlansAPI = async () => {
      setIsLoading(true);
      try {
        const prepaidPlanResponse = await axios.get(
          `${BACKEND_BASE_URL}/api/payment/getAllWalletPlans?paymentMode=prepaid`
        );
        console.log(prepaidPlanResponse, "prepaidPlanResponse");
        if (prepaidPlanResponse.status === 200) {
          setPrepaidPlan([...prepaidPlanResponse?.data?.plans]);
          setIsLoading(false);
        }
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };

    getPrepaidWalletPlansAPI();
  }, []);

  console.log(postpaidPlan, "postpaidPlan");
  console.log(prepaidPlan, "prepaidPlan");

  useEffect(() => {
    if (type === "pricing-plan") {
      setTaskTracker(1);
    } else if (type === "summary") {
      setTaskTracker(2);
    } else if (type === "payment") {
      setTaskTracker(3);
    }
  }, [type]);

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <div className="max-w-screen py-10 px-5 2xl:px-16">
          {type !== "options" && (
            <div>
              <h1 className="text-lg 2xl:text-[32px] text-secondary-blue font-bold">
                Hey Emily,
              </h1>
              <h3 className="text-base 2xl:text-2xl font-normal text-secondary-blue mt-1">
                Let's learn new skills today!
              </h3>
            </div>
          )}
          {type !== "options" && (
            <div className="my-10 mx-auto">
              <TaskTracker
                taskTracker={taskTracker}
                length={3}
                taskLabels={["Plan", "Summary", "Payment"]}
              />
            </div>
          )}
          <div>
            {type === "options" && (
              <Pricing
                prepaidPlan={prepaidPlan}
                postpaidPlan={postpaidPlan}
                setSelectedPlan={setSelectedPlan}
              />
            )}
            {type === "pricing-plan" && (
              <SelectPricingPlan
                prepaidPlan={prepaidPlan}
                postpaidPlan={postpaidPlan}
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
              />
            )}
            {type === "summary" && (
              <Summary
                prepaidPlan={prepaidPlan}
                postpaidPlan={postpaidPlan}
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Payment;
