import React from "react";
import classes from "./date-picker.module.css";

const DatePicker = ({ label, date, setDate }) => {
  //console.log("date", date);
  return (
    <div className={classes.dateContainer}>
      {label && <label>{label}</label>}
      <input
        type="date"
        value={date}
        onChange={(e) => setDate(e.target.value)}
        required={true}
        pattern="\d{2}-\d{2}-\d{4}"
      />
    </div>
  );
};

export default DatePicker;
