import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Input from "../../../components/Input/Input";
import Button from "../../../components/Button/Button";
import Google from "../../../assets/SVG/google";
import { useNavigate } from "react-router-dom";
import axios from "../../../helpers/axios/axios";
import { BACKEND_BASE_URL, FALLBACK_URL } from "../../../helpers/url/url";

const BootcampSignUp = () => {
  const [showIndividualError, setShowIndividualError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ isError: false, message: "" });

  const navigate = useNavigate();

  let errorLength = 0;

  function checkErrors() {
    if (errorLength > 0) {
      setShowIndividualError(true);
    }
  }
  const sendOtpAPI = async (email) => {
    try {
      const sendOtpResponse = await axios.post(
        `${BACKEND_BASE_URL}/api/otp-service/send`,
        { email: email }
      );
      console.log(sendOtpResponse, "sendOtpResponse");

      if (sendOtpResponse?.data?.success) {
        navigate("/bootcamp-register/verify-otp");
      }
    } catch (err) {
      console.log(err);
      if (err) {
        setError({
          isError: true,
          message: "Oops something went wrong! Please try again.",
        });
      }
    }
  };

  const signUpAPI = async (name, email, password) => {
    setIsLoading(true);
    setError({ isError: false, message: "" });
    try {
      const signUpRespone = await axios.post(
        `${BACKEND_BASE_URL}/api/sign-up/student`,
        { name: name, email: email, password: password }
      );

      console.log(signUpRespone, "signUpRespone");

      if (signUpRespone?.data?.success) {
        sendOtpAPI(email);
        localStorage.setItem("email", email);
      }
      setIsLoading(false);
    } catch (err) {
      if (err.response.status === 304) {
        setError({
          isError: true,
          message: "User already exists! Please try again.",
        });
      } else {
        setError({
          isError: true,
          message: "Oops something went wrong! Please try again.",
        });
      }
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <div className="w-[100%] sm:w-[60%] lg:w-[70%] xl:w-[50%] xl:flex xl:flex-col items-center justify-center">
      {error.isError && (
        <p className="mt-3 text-xs sm:text-base text-center text-primary-red font-semibold">
          {error.message}
        </p>
      )}
      <Formik
        initialValues={{
          name: "",
          email: "",
          password: "",
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("Name is Required"),
          email: Yup.string()
            .email("Invalid email address")
            .required("Email is Required"),
          password: Yup.string()
            .required("Password is required")
            .min(8, "Password must contain atleast 8 characters"),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const { name, email, password } = values;
          //console.log(name, "name", email, "email", password, "password");

          signUpAPI(name, email, password);
          setSubmitting(false);
          resetForm({ name: "", email: "", password: "" });
        }}
      >
        {(formik) => {
          errorLength = Object.keys(formik.errors).length;
          return (
            <form onSubmit={formik.handleSubmit}>
              <div className="mt-10 mb-5">
                <p className="mb-1 sm:mb-3 text-base sm:text-xl text-primary-pink  leading-[27px] font-normal">
                  Full Name
                </p>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter full name"
                  autoComplete="name"
                  error={formik.errors.name}
                  showIndividualError={showIndividualError}
                  extraClass={
                    formik.touched.name && formik.errors.name
                      ? "text-primary-pink border border-primary-red bg-transparent placeholder:text-primary-pink"
                      : "text-primary-pink bg-transparent border border-primary-purple placeholder:text-primary-pink"
                  }
                  {...formik.getFieldProps("name")}
                />
              </div>
              <div className="mb-5">
                <p className="mb-1 sm:mb-3 text-base sm:text-xl text-primary-pink  leading-[27px] font-normal">
                  Email address
                </p>
                <Input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Enter email address"
                  autoComplete="email"
                  error={formik.errors.email}
                  showIndividualError={showIndividualError}
                  extraClass={
                    formik.touched.email && formik.errors.email
                      ? "text-primary-pink border border-primary-red bg-transparent placeholder:text-primary-pink"
                      : "text-primary-pink bg-transparent border border-primary-purple placeholder:text-primary-pink"
                  }
                  {...formik.getFieldProps("email")}
                />
              </div>

              <div>
                <p className="mb-1 sm:mb-3 text-base sm:text-xl text-primary-pink  leading-[27px] font-normal">
                  Password
                </p>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter your password"
                  error={formik.errors.password}
                  showIndividualError={showIndividualError}
                  extraClass={
                    formik.touched.password && formik.errors.password
                      ? "text-primary-pink border border-primary-red bg-transparent placeholder:text-primary-pink"
                      : "text-primary-pink bg-transparent border border-primary-purple placeholder:text-primary-pink"
                  }
                  {...formik.getFieldProps("password")}
                />
              </div>

              <div className="mt-12 w-full sm:w-[350px] flex flex-col space-y-7">
                <Button
                  onClick={() => checkErrors()}
                  innerText="Sign up for Free"
                  type="submit"
                  extraClass={`text-center h-[35px] lg:h-[45px] text-sm sm:text-base xl:text-lg text-white font-medium  rounded-full bg-primary-green  ${
                    isLoading && "opacity-20"
                  }`}
                  isLoading={isLoading}
                  disabled={isLoading}
                />
                <Button
                  onClick={() =>
                    window.location.replace(
                      `${BACKEND_BASE_URL}/api/oauth/google?fallbackURL=${FALLBACK_URL}`
                    )
                  }
                  Icon={Google}
                  innerText="Sign up with Google"
                  type="button"
                  disabled={true}
                  extraClass="text-center h-[35px] lg:h-[45px] text-sm sm:text-base xl:text-lg text-white font-medium  rounded-full bg-transparent border border-primary-purple"
                />
              </div>
            </form>
          );
        }}
      </Formik>
      <div>
        <p className="text-center mt-3 text-xs sm:text-sm text-primary-pink font-light">
          Already have an account?&nbsp;
          <span
            onClick={() => navigate("/bootcamp-register/signin")}
            className="font-bold cursor-pointer hover:underline"
          >
            Log in
          </span>
        </p>
      </div>
    </div>
  );
};

export default BootcampSignUp;
