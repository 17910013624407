import React, { useState } from "react";
import Profile from "../../../assets/images/profile.png";
import Cross from "../../../assets/SVG/cross";
import Edit from "../../../assets/SVG/edit";
import Button from "../../../components/Button/Button";
import CustomModal from "../../../components/CustomModal/CustomModal";
import MyDateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import Input from "../../../components/Input/Input";

const MyProfile = () => {
  const [viewCalendar, setViewCalendar] = useState(false);
  const [addedSkill, setAddedSkill] = useState([]);
  const [skill, setSkill] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const deleteAddedSkillHandler = (idx) => {
    const remainingSkills = addedSkill.filter((skill, index) => idx !== index);
    setAddedSkill([...remainingSkills]);
  };

  return (
    <div className="max-w-screen p-5 lg:p-10 2xl:p-20">
      <div>
        <h1 className="text-center lg:text-left text-2xl xl:text-3xl text-secondary-blue font-bold xl:leading-[40px]">
          My Profile
        </h1>
        <h3 className="text-center lg:text-left text-base xl:text-2xl text-secondary-blue font-normal xl:leading-[40px]">
          Keep your information up to date to help us serve you better!
        </h3>
      </div>
      <div className="mt-10">
        <div className="flex items-center space-x-10">
          <div className="w-[120px] h-[120px] border border-primary-purple">
            <img src={Profile} alt="Profile" />
          </div>
          <div>
            <Button
              innerText="Upload photo"
              type="button"
              extraClass="mb-1 py-2 px-3 w-full lg:max-w-max text-xs xl:text-lg text-white font-normal bg-primary-green"
            />
            <span className="text-lg font-normal text-black">
              Maximum size of 1MB. JPG, GIF, or PNG.
            </span>
          </div>
        </div>

        <div className="mt-10 mb-5 grid grid-cols-2 gap-10">
          <div>
            <p className="mb-1 sm:mb-3 text-base sm:text-xl text-primary-blue  leading-[27px] font-normal">
              First Name
            </p>
            <Input
              type="text"
              name="firstName"
              id="firstName"
              placeholder="Enter your first name"
              extraClass={
                "text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-ternary-blue"
              }
            />
          </div>
          <div>
            <p className="mb-1 sm:mb-3 text-base sm:text-xl text-primary-blue  leading-[27px] font-normal">
              Last Name
            </p>
            <Input
              type="text"
              name="lastName"
              id="lastName"
              placeholder="Enter your last name"
              extraClass={
                "text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-ternary-blue"
              }
            />
          </div>
          <div>
            <p className="mb-1 sm:mb-3 text-base sm:text-xl text-primary-blue  leading-[27px] font-normal">
              Email address
            </p>
            <Input
              type="text"
              name="email"
              id="email"
              placeholder="Email registered email id"
              extraClass={
                "text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-ternary-blue"
              }
            />
          </div>
          <div>
            <p className="mb-1 sm:mb-3 text-base sm:text-xl text-primary-blue  leading-[27px] font-normal">
              Password
            </p>
            <div className="flex items-center space-x-2">
              <span className="text-lg text-ternary-blue-lt">**********</span>
              <Edit
                className="cursor-pointer h-5 w-5 -mt-2"
                onClick={() => setOpenModal(true)}
              />
            </div>
          </div>
        </div>

        <div className="bg-primary-purple w-full h-[1px] my-20"></div>
        <div>
          <div>
            <h1 className="text-center lg:text-left text-2xl xl:text-3xl text-secondary-blue font-bold xl:leading-[40px]">
              About me
            </h1>
            <h3 className="text-center lg:text-left text-base xl:text-2xl text-secondary-blue font-normal xl:leading-[40px]">
              Introduce yourself to the Krafternoon community. Connect with
              learners like you to grow your network.
            </h3>
          </div>
          <div className="mt-10">
            <p className="mb-1 sm:mb-3 text-base sm:text-xl text-primary-blue  leading-[27px] font-normal">
              About me
            </p>
            <textarea
              style={{ resize: "none" }}
              className="w-[70%] h-[150px] py-2 px-3 text-xs xl:text-base placeholder:text-xs xl:placeholder:text-sm focus:outline-none rounded-lg text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-ternary-blue"
              id="message"
              type="text"
              placeholder="Tell us about what you do, what are your interests & hobbies, what you’d like to learn put of these courses"
            />
          </div>
          <div className="mt-10 mb-5 grid grid-cols-2 gap-10">
            <div>
              <p className="mb-1 sm:mb-3 text-base sm:text-xl text-primary-blue  leading-[27px] font-normal">
                Location
              </p>
              <Input
                type="text"
                name="location"
                id="location"
                placeholder="Enter city, state, country you live in"
                extraClass={
                  "text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-ternary-blue"
                }
              />
            </div>

            <div className="relative">
              <p className="mb-1 sm:mb-3 text-base sm:text-xl text-primary-blue  leading-[27px] font-normal">
                Birthday
              </p>
              <Input
                type="text"
                placeholder="dd-mm-yyyy"
                onFocus={() => setViewCalendar(true)}
                onBlur={() => setViewCalendar(false)}
                extraClass={
                  "mb-5 text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-ternary-blue"
                }
              />
              {viewCalendar && (
                <div className="absolute z-10">
                  <MyDateRangePicker />
                </div>
              )}
            </div>
            <div>
              <p className="mb-1 sm:mb-3 text-base sm:text-xl text-primary-blue  leading-[27px] font-normal">
                Age
              </p>

              <Input
                type="text"
                name="age"
                id="age"
                placeholder="Enter your age"
                extraClass={
                  "text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-ternary-blue"
                }
              />
            </div>
            <div>
              <p className="text-base sm:text-xl text-primary-blue  leading-[27px] font-normal">
                Skills
              </p>
              <p className="text-gray-300 text-sm mb-1 sm:mb-3 ">
                Add your top 5 skill
              </p>
              <div className="flex items-center space-x-3">
                <Input
                  type="text"
                  name="skill"
                  id="skill"
                  placeholder="Enter any skill"
                  extraClass={
                    "text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-ternary-blue"
                  }
                  value={skill}
                  onChange={(e) => setSkill(e.target.value)}
                />
                <Button
                  onClick={() => {
                    setSkill("");
                    setAddedSkill([...addedSkill, skill]);
                  }}
                  innerText="Add skill"
                  type="button"
                  extraClass="mb-1 max-w-max text-xs xl:text-lg rounded-sm text-white font-normal bg-primary-green"
                />
              </div>
              {addedSkill.length > 0 && (
                <div className="border border-ternary-blue rounded my-5 py-3 px-3">
                  {addedSkill.map((skill, index) => (
                    <div
                      key={index}
                      className="bg-primary-purple flex items-center space-x-2 rounded py-1 px-3 max-w-max"
                    >
                      <p className="capitalize text-secondary-blue text-sm font-semibold">
                        {skill}
                      </p>
                      <Cross
                        className="h-2 w-2 cursor-pointer"
                        onClick={() => deleteAddedSkillHandler(index)}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Button
        innerText="Save changes"
        type="button"
        extraClass="mb-1 py-2 px-5 w-full lg:max-w-max text-xs xl:text-lg text-white font-normal bg-primary-green"
      />
      {openModal && (
        <CustomModal
          open={openModal}
          closeModalHandler={() => setOpenModal(false)}
        >
          <div className="p-10 flex flex-col space-y-5">
            <div>
              <p className="mb-1 sm:mb-3 text-base sm:text-xl text-primary-blue  leading-[27px] font-normal">
                Current Password
              </p>
              <Input
                type="password"
                name="currentPassword"
                id="currentPassword"
                placeholder="Enter your current password"
                extraClass={
                  "text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-ternary-blue"
                }
              />
            </div>
            <div>
              <p className="mb-1 sm:mb-3 text-base sm:text-xl text-primary-blue  leading-[27px] font-normal">
                New Password
              </p>
              <Input
                type="password"
                name="newPassword"
                id="newPassword"
                placeholder="Enter your new password"
                extraClass={
                  "text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-ternary-blue"
                }
              />
            </div>
            <div>
              <p className="mb-1 sm:mb-3 text-base sm:text-xl text-primary-blue  leading-[27px] font-normal">
                Confirm Password
              </p>
              <Input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Enter your confirm password"
                extraClass={
                  "text-primary-blue placeholder:text-secondary-blue-ltr-ltr border border-ternary-blue"
                }
              />
            </div>
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default MyProfile;
