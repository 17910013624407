import React from "react";

const View = ({ className, ...rest }) => {
  return (
    <svg
      className={className ? className : "h-5 w-5"}
      viewBox="0 0 24 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.9393 10.1858C13.7692 10.1858 15.2393 8.54401 15.2393 6.50014C15.2393 4.45626 13.7693 2.81445 11.9393 2.81445C10.1094 2.81445 8.6394 4.45626 8.6394 6.50014C8.6394 8.54401 10.1094 10.1858 11.9393 10.1858Z"
        fill="#434484"
      />
      <path
        d="M11.9994 13C16.5891 13 20.7589 10.5206 23.8787 6.5C20.7289 2.47944 16.5591 0 11.9994 0C7.37972 0 3.14983 2.37899 0 6.5C3.14989 10.6211 7.34961 13 11.9994 13ZM11.9393 1.47423C14.4292 1.47423 16.4391 3.71907 16.4391 6.5C16.4391 9.28093 14.4292 11.5258 11.9393 11.5258C9.44944 11.5258 7.43955 9.28093 7.43955 6.5C7.43955 3.71907 9.44944 1.47423 11.9393 1.47423Z"
        fill="#434484"
      />
    </svg>
  );
};

export default View;
