import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import axios from "../../helpers/axios/axios";
import { BACKEND_BASE_URL } from "../../helpers/url/url";

const VerifyOtp = () => {
  const [otpCode, setOtpCode] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ isError: false, message: "" });

  const navigate = useNavigate();
  // const handleOtpSubmit = (e) => {
  //   e.preventDefault();
  //   if (e.key === "Enter") {
  //     //onClick();
  //     console.log(otpCode, "otpCode");
  //   }
  // };

  const email = localStorage.getItem("email");

  const sendOtpAPI = async (email) => {
    setError({ isError: false, message: "" });
    try {
      const sendOtpResponse = await axios.post(
        `${BACKEND_BASE_URL}/api/otp-service/send`,
        { email: email }
      );
      if (sendOtpResponse?.data?.success) {
        navigate("/verify-otp");
      }
      console.log(sendOtpResponse, "sendOtpResponse");
    } catch (err) {
      console.log(err);
      if (err) {
        setError({
          isError: true,
          message: "Oops something went wrong! Please try again.",
        });
      }
    }
  };

  const verifyOtpAPI = async () => {
    setIsLoading(true);
    setError({ isError: false, message: "" });

    try {
      const verifyOtpResponse = await axios.post(
        `${BACKEND_BASE_URL}/api/otp-service/verify`,
        { email: email, otp: otpCode }
      );
      console.log(verifyOtpResponse, "verifyOtpResponse");
      if (verifyOtpResponse?.data?.success) {
        navigate("/signin");
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response.status >= 400 && err.response.status < 500) {
        setError({
          isError: true,
          message: "OTP didn't match! Please try again.",
        });
      } else {
        setError({
          isError: true,
          message: "Oops something went wrong! Please try again.",
        });
      }
      setIsLoading(false);
    }
  };

  const handleChange = (otp) => setOtpCode(otp);

  return (
    <div className="max-w-screen">
      <div className="w-full flex flex-col xl:flex-row">
        <div className="order-2 xl:order-none rounded-xl hidden sm:inline-flex flex-col items-center bg-ternary-blue-ltr w-full xl:w-1/2">
          <h1 className="mt-10 w-[80%] text-2xl text-center xl:text-left xl:text-4xl xl:leading-[48px] font-semibold  text-secondary-blue">
            Sow the seeds of{" "}
            <i className="font-extrabold">Creativity & Analytical</i> approach
            with <i className="font-extrabold">Krafternoon</i>
          </h1>
          <img
            className="w-[700px] h-[430px]"
            src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/AuthPage/dizzy-girl-sitting-in-a-chair-with-a-notebook-and-watching-a-video-lecture_1__1__1FeWv7lnG.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652080713866"
            alt=""
          />
        </div>

        <div className="w-full xl:w-1/2 flex flex-col items-center">
          <h1 className="mt-10 text-2xl xl:text-4xl font-extrabold text-secondary-blue xl:leading-[62px]">
            Verify Email address
          </h1>
          {error.isError && (
            <p className="text-primary-red font-semibold">{error.message}</p>
          )}
          <p className=" mt-10 text-center xl:text-xl font-normal  text-primary-blue">
            Enter OTP sent to you email address
          </p>

          <form
            className="mt-10 flex flex-col xl:flex-row items-center xl:space-x-3"
            //onSubmit={handleOtpSubmit}
          >
            <OtpInput
              value={otpCode}
              onChange={handleChange}
              numInputs={6}
              shouldAutoFocus={true}
              inputStyle="text-secondary-blue border-b border-secondary-blue mr-5 w-8 text-center bg-transparent text-xl"
              focusStyle="focus:outline-none"
            />
            <Button
              onClick={verifyOtpAPI}
              innerText="Verify"
              type="button"
              extraClass={`mt-10 xl:mt-0 max-w-max text-center px-10 h-[45px] text-sm sm:text-base xl:text-lg text-white font-medium  rounded-full bg-primary-green ${
                isLoading && "opacity-20"
              }`}
              isLoading={isLoading}
              disabled={isLoading}
            />
            <span
              onClick={sendOtpAPI}
              className="mb-10 xl:mb-0 mt-1.5 text-base text-primary-red hover:underline cursor-pointer"
            >
              Resend code
            </span>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
