import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ChevronLeft from "../../../../../assets/SVG/chevron-left";
import ChevronRight from "../../../../../assets/SVG/chevron-right";
import Button from "../../../../../components/Button/Button";
import Dropdown from "../../../../../components/Dropdown/Dropdown";
import axios from "../../../../../helpers/axios/axios";
import { toastNotification } from "../../../../../helpers/functions/toast-notification/toast-notification";
import { BACKEND_BASE_URL } from "../../../../../helpers/url/url";

const AllAdPosts = () => {
  const [allAdPosts, setAllAdPosts] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [sortBy, setSortBy] = useState("default");

  const navigate = useNavigate();

  useEffect(() => {
    const getAllAdPosts = async () => {
      try {
        const allAdPostsResponse = await axios.get(
          `${BACKEND_BASE_URL}/admin/adminRoles/getAdPost/full`
        );
        console.log(allAdPostsResponse, "allAdPostsResponse");
        setAllAdPosts([...allAdPostsResponse?.data?.adPosts]);
      } catch (err) {
        console.log(err);
        toastNotification("error", "Something went wrong. Please try again.");
      }
    };

    getAllAdPosts();
  }, []);

  let AD_POSTS_SORTED = [...allAdPosts];
  if (allAdPosts.length > 0) {
    const todaysDate = Date.now();

    if (sortBy === "running-ads") {
      AD_POSTS_SORTED = allAdPosts.filter((ad) => ad.endDate >= todaysDate);
    } else if (sortBy === "scheduled-ads") {
      AD_POSTS_SORTED = allAdPosts.filter((ad) => ad.startDate > todaysDate);
    } else if (sortBy === "previous-ads") {
      AD_POSTS_SORTED = allAdPosts.filter((ad) => ad.endDate < todaysDate);
    } else if (sortBy === "default") {
      AD_POSTS_SORTED = allAdPosts;
    }
  }

  return (
    <div className="w-[35%]">
      <div className="bg-ternary-blue-ltr mt-10 rounded-lg pb-5">
        <div className="flex items-center justify-between p-5">
          <div className="flex items-center space-x-3">
            <h3 className="text-secondary-blue text-2xl font-extrabold">
              Ad Posts
            </h3>
            <Button
              onClick={() => navigate("/admin/ad-posts")}
              innerText="Create New"
              type="button"
              extraClass="py-1 px-5 max-w-max rounded-full bg-primary-green"
              innerTextStyle="text-xs md:text-base xl:text-sm font-normal text-white"
            />
          </div>
          <Dropdown
            menuList={[
              { id: "running-ads", name: "Running Ads" },
              { id: "scheduled-ads", name: "Scheduled Ads" },
              { id: "previous-ads", name: "Previous Ads" },
              { id: "default", name: "ALL" },
            ]}
            defaultValue={{ id: "default", name: "SORT BY" }}
            dropdownStyle="rounded w-[160px] h-[40px] flex items-center justify-around text-sm text-primary-blue font-normal bg-secondary-purple"
            arrowStyle="w-[20px] h-[15px] cursor-pointer"
            dropdownMenuStyle="w-[160px] bg-white"
            dropdownHandler={(option) => setSortBy(option.id)}
          />
        </div>
        <div className="border-t border-secondary-blue-lt p-5 h-full">
          {AD_POSTS_SORTED?.length > 0 ? (
            AD_POSTS_SORTED?.slice(pageCount, pageCount + 5)?.map(
              (ad, index) => (
                <div
                  key={ad._id ? ad._id : index}
                  className="bg-white px-5 mb-5"
                >
                  <h3 className="text-base text-primary-blue border-b border-primary-purple py-3">
                    {ad?.postName}
                  </h3>
                  <div className="flex justify-between  py-3">
                    <span className="flex items-center space-x-2">
                      <p className="text-primary-blue text-sm font-[750]">
                        Start Date :
                      </p>
                      <p className="text-primary-blue text-sm">
                        {new Date(ad?.startDate).toString().slice(4, 15)}
                      </p>
                    </span>
                    <span className="flex items-center space-x-2">
                      <p className="text-primary-blue text-sm font-[750]">
                        End Date :
                      </p>
                      <p className="text-primary-blue text-sm">
                        {new Date(ad?.endDate).toString().slice(4, 15)}
                      </p>
                    </span>
                    <span className="text-sm cursor-pointer font-normal text-primary-green hover:underline">
                      Edit
                    </span>
                  </div>
                </div>
              )
            )
          ) : (
            <p className="text-center text-base text-secondary-blue font-bold">
              No Ads Found :)
            </p>
          )}
        </div>
        {AD_POSTS_SORTED?.length > 0 && (
          <div className="flex items-center justify-center space-x-5 text-2xl font-semibold">
            <span
              onClick={() => {
                if (pageCount - 5 >= 0) {
                  setPageCount((prev) => prev - 5);
                }
              }}
              className={`${
                pageCount - 5 < 0 && "opacity-30"
              } bg-ternary-blue rounded p-1`}
            >
              <ChevronLeft />
            </span>
            <span className="text-primary-blue">{pageCount / 5 + 1}</span>
            <span
              onClick={() => {
                if (pageCount + 5 < AD_POSTS_SORTED?.length) {
                  setPageCount((prev) => prev + 5);
                }
              }}
              className={`${
                pageCount + 5 >= AD_POSTS_SORTED?.length && "opacity-30"
              } bg-ternary-blue rounded p-1`}
            >
              <ChevronRight />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllAdPosts;
