import React from "react";

const Clock = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 20 20"
      {...rest}
    >
      <path
        fill="#434484"
        d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm0 18.51c-4.693 0-8.511-3.817-8.511-8.51 0-4.694 3.818-8.512 8.511-8.512S18.511 5.306 18.511 10c0 4.694-3.818 8.512-8.511 8.512zm4.764-8.66H10.15V4.059a.744.744 0 10-1.489 0v6.537c0 .411.333.744.745.744h5.36a.744.744 0 100-1.488z"
      ></path>
    </svg>
  );
};

export default Clock;
