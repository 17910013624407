import React from "react";
import Button from "../../../components/Button/Button";
import successIcon from "../../../assets/gif/success.gif";
import { useNavigate, useParams } from "react-router-dom";
import errorIcon from "../../../assets/gif/failure.gif";

const BootcampPaymentOutcome = () => {
  const { subType } = useParams();
  const navigate = useNavigate();

  return (
    <div
      className={`flex flex-col items-center py-20 ${
        subType === "failure" ? "bg-primary-gray" : "bg-white"
      }`}
    >
      {subType === "success" && (
        <div className="text-2xl xl:text-3xl text-secondary-blue font-medium text-center mb-10">
          <p className="font-bold">Congratulations! </p>
          <p className="text-xl xl:text-2xl">
            You’re request for course enrollment has been received by us. Our
            team will contact you very soon.
          </p>
        </div>
      )}
      {subType === "failure" && (
        <div className="text-2xl xl:text-3xl text-secondary-blue font-medium text-center mb-10">
          <p className="font-bold">Process Failed!</p>
          <p className="text-xl xl:text-2xl">
            Please try again or contact our team in case you face any
            difficulties.
          </p>
        </div>
      )}
      {subType === "success" && (
        <img src={successIcon} alt="" className="md:w-[502px] md:h-[376px]" />
      )}
      {subType === "failure" && (
        <img
          src={errorIcon}
          alt=""
          className="md:w-[521px] md:h-[391px] bg-white"
        />
      )}
      <Button
        onClick={() => navigate("/student/dashboard")}
        innerText="Go to my Dashboard"
        type="button"
        extraClass="mt-10 mb-2 py-1 px-3 max-w-max text-lg text-white font-normal  rounded-full bg-primary-green"
        innerTextStyle="text-sm md:text-base xl:text-lg"
      />
      <span
        onClick={() => navigate("/")}
        className="cursor-pointer text-lg font-medium leading-[25px] text-secondary-green"
      >
        Explore more skills
      </span>
    </div>
  );
};

export default BootcampPaymentOutcome;
