import React, { useEffect, useState } from "react";
import CircularTickBlue from "../../../assets/SVG/circular-tick-blue";
import Button from "../../../components/Button/Button";
import Dropdown from "../../../components/Dropdown/Dropdown";
import FileUploader from "../../../components/FileUploader/FileUploader";
import { toastNotification } from "../../../helpers/functions/toast-notification/toast-notification";
import axios from "../../../helpers/axios/axios";
import { BACKEND_BASE_URL } from "../../../helpers/url/url";
import { fileExtension } from "../../../helpers/functions/file-extension";
import CircleSpinner from "../../../components/Spinners/CircleSpinner/CircleSpinner";
import { useNavigate } from "react-router-dom";
//import { useNavigate } from "react-router-dom";

let MEDIA_ATTACHED = [];

const AddCourse = () => {
  //console.log(MEDIA_ATTACHED, "MEDIA_ATTACHED");
  const [files, setFiles] = useState([]);
  const [parentCourse, setParentCourse] = useState([]);

  const [addNewCourse, setAddNewCourse] = useState({
    courseAdditionType: { id: "course", name: "Course" },
    courseType: { id: "skill", name: "Skill" },
    parentCourse: { id: "chess", name: "Chess" },
    courseName: "",
    shortDescription: "",
    description: "",
    certificateType: { id: "chess", name: "Chess" },
    minAge: 4,
    maxEnrollmentAllowed: 1000,
    mediaAttached: [],
  });
  const [isLoading, setIsLoading] = useState({
    isLoading: false,
    type: null,
  });

  useEffect(() => {
    const getCoursesAPI = async () => {
      try {
        const getCoursesResponse = await axios.get(
          `${BACKEND_BASE_URL}/admin/adminRoles/getCourses`
        );
        //console.log(getCoursesResponse?.data?.courses, "getCoursesResponses");

        let ALL_COURSES = [];
        const allCourses = getCoursesResponse?.data?.courses;

        for (let i = 0; i < allCourses.length; i++) {
          ALL_COURSES.push({
            id: allCourses[i]._id,
            name: allCourses[i].courseName,
            courseType: allCourses[i].courseType,
          });
        }
        setParentCourse([...ALL_COURSES]);
      } catch (err) {
        console.log(err);
      }
    };

    getCoursesAPI();
  }, []);

  const navigate = useNavigate();

  const addCourseAPI = async () => {
    setIsLoading({ isLoading: false, type: "add-course" });

    let addCourseData;
    addCourseData = {
      courseName: addNewCourse.courseName,
      courseType: addNewCourse.courseType.id,
      shortDescription: addNewCourse.shortDescription,
      courseDescription: addNewCourse.description,
      certificateType: addNewCourse.certificateType.id,
      minAge: addNewCourse.minAge,
      maxEnrollmentAllowed: addNewCourse.maxEnrollmentAllowed,
      mediaAttached: addNewCourse.mediaAttached,
    };

    console.log(addCourseData, "addCourseData");

    try {
      const addCourseResponse = await axios.post(
        `${BACKEND_BASE_URL}/admin/adminRoles/addCourse`,
        addCourseData
      );
      console.log(addCourseResponse, "addCourseResponse");
      if (addCourseResponse.status === 200) {
        setAddNewCourse({
          courseAdditionType: { id: "course", name: "Course" },
          courseType: { id: "skill", name: "Skill" },
          parentCourse: { id: "chess", name: "Chess" },
          courseName: "",
          shortDescription: "",
          description: "",
          certificateType: { id: "chess", name: "Chess" },
          minAge: 4,
          maxEnrollmentAllowed: 1000,
          mediaAttached: [],
        });
        //navigate("/admin/all-courses");
        toastNotification("success", "Course added successfully");
        navigate("/admin/all-courses");
      }
      setFiles([]);
      setIsLoading({ isLoading: false, type: null });
    } catch (err) {
      console.log(err);
      setIsLoading({ isLoading: false, type: null });
      toastNotification("error", "Something went wrong. Please try again!");
    }
  };

  const addTopicAPI = async () => {
    setIsLoading({ isLoading: false, type: "add-course" });
    try {
      const addTopicResponse = await axios.post(
        `${BACKEND_BASE_URL}/admin/adminRoles/addTopic`,
        {
          associatedCourseId: addNewCourse.parentCourse.id,
          title: addNewCourse.courseName,
          shortDescription: addNewCourse.shortDescription,
          description: addNewCourse.description,
          mediaAttached: addNewCourse.mediaAttached,
        }
      );
      console.log(addTopicResponse, "addTopicResponse");
      if (addTopicResponse.status === 200) {
        setAddNewCourse({
          courseAdditionType: { id: "course", name: "Course" },
          courseType: { id: "skill", name: "Skill" },
          parentCourse: { id: "chess", name: "Chess" },
          courseName: "",
          shortDescription: "",
          description: "",
          certificateType: { id: "chess", name: "Chess" },
          minAge: 4,
          maxEnrollmentAllowed: 1000,
          mediaAttached: [],
        });
        toastNotification("success", "Course topic added successfully");
        navigate("/admin/all-courses");
      }

      setFiles([]);
      setIsLoading({ isLoading: false, type: null });
    } catch (err) {
      console.log(err);
      setIsLoading({ isLoading: false, type: null });
      toastNotification("error", "Something went wrong. Please try again!");
    }
  };

  const uploadFilesAPI = async (file) => {
    setIsLoading({ isLoading: true, type: "file-upload" });
    try {
      const uploadUrlResponse = await axios.post(
        `${BACKEND_BASE_URL}/admin/mediaActions/uploadURL`,
        { fileType: fileExtension(file?.name), alias: file?.name }
      );
      console.log(uploadUrlResponse, "uploadUrlResponse");
      if (uploadUrlResponse?.status === 200) {
        const url = uploadUrlResponse?.data?.s3Payload?.url;
        const key = uploadUrlResponse?.data?.key;
        const fields = uploadUrlResponse?.data?.s3Payload?.fields;

        let formData = new FormData();
        for (let key in fields) {
          formData.append(key, fields[key]);
        }
        formData.append("file", file);

        try {
          const s3UploadResponse = await axios.post(url, formData);
          console.log(s3UploadResponse, "s3UploadResponse");
          if (s3UploadResponse.status === 204) {
            try {
              const ackUploadResponse = await axios.post(
                `${BACKEND_BASE_URL}/admin/mediaActions/ackUpload`,
                { key: key }
              );
              console.log(ackUploadResponse, "ackUploadResponse");
              if (ackUploadResponse.status === 204) {
                MEDIA_ATTACHED.push(key);
                toastNotification(
                  "success",
                  "File has been uploaded successfully."
                );
              }
            } catch (err) {
              console.log(err);
              setIsLoading({ isLoading: false, type: null });
              toastNotification(
                "error",
                "File could not be uploaded. Please try again!"
              );
            }
          }
        } catch (err) {
          setIsLoading({ isLoading: false, type: null });
          console.log(err);
          toastNotification(
            "error",
            "File could not be uploaded. Please try again!"
          );
        }
      }

      setAddNewCourse({ ...addNewCourse, mediaAttached: MEDIA_ATTACHED });
      setIsLoading({ isLoading: false, type: null });
    } catch (err) {
      console.log(err);
      setIsLoading({ isLoading: false, type: null });
      toastNotification(
        "error",
        "File could not be uploaded. Please try again!"
      );
    }
  };

  return (
    <div className="w-[50%]">
      <div className="bg-ternary-blue-ltr mt-10 rounded-lg pb-20">
        <h3 className="text-secondary-blue text-2xl font-extrabold p-5">
          Add New Course
        </h3>
        <div className="border-t border-secondary-blue-lt p-5">
          <div className="flex items-center space-x-5">
            <p className="text-lg font-semibold text-primary-blue">
              Course Addition Type
            </p>
            <Dropdown
              menuList={[
                { id: "course", name: "Course" },
                { id: "topic", name: "Topic" },
              ]}
              defaultValue={{ id: "course", name: "Course" }}
              dropdownStyle="w-[142px] h-[40px] border border-primary-blue flex items-center justify-around text-lg text-primary-blue font-normal"
              arrowStyle="w-[20px] h-[15px] cursor-pointer"
              dropdownMenuStyle="w-[142px] bg-white"
              dropdownHandler={(option) =>
                setAddNewCourse({ ...addNewCourse, courseAdditionType: option })
              }
            />
          </div>

          <div className="flex items-center space-x-5 mt-10">
            <p className="text-lg font-semibold text-primary-blue">
              Course Name
            </p>
            <input
              type="text"
              placeholder="Course Name"
              className="bg-transparent w-[70%] border border-secondary-blue py-1 px-2 text-lg text-secondary-blue placeholder:text-secondary-blue-lt focus:outline-none"
              value={addNewCourse.courseName}
              onChange={(e) =>
                setAddNewCourse({
                  ...addNewCourse,
                  courseName: e.target.value,
                })
              }
            />
          </div>

          {addNewCourse.courseAdditionType.id === "topic" && (
            <div className="flex items-center space-x-5 mt-10">
              <p className="text-lg font-semibold text-primary-blue">
                Parent Course
              </p>
              <Dropdown
                menuList={[...parentCourse]}
                defaultValue={parentCourse[0]}
                dropdownStyle="px-5 w-[300px] h-[40px] border border-primary-blue flex items-center justify-between text-lg text-primary-blue font-normal"
                arrowStyle="w-[20px] h-[15px] cursor-pointer"
                dropdownMenuStyle="w-[300px] bg-white"
                dropdownHandler={(option) =>
                  setAddNewCourse({
                    ...addNewCourse,
                    parentCourse: option,
                  })
                }
              />
            </div>
          )}

          <div className="flex items-center space-x-5 mt-10">
            <p className="text-lg font-semibold text-primary-blue">
              Short Description
            </p>
            <input
              type="text"
              placeholder="Enter short description"
              className="bg-transparent w-[70%] border border-secondary-blue px-2 py-1 text-lg text-secondary-blue placeholder:text-secondary-blue-lt focus:outline-none"
              value={addNewCourse.shortDescription}
              onChange={(e) =>
                setAddNewCourse({
                  ...addNewCourse,
                  shortDescription: e.target.value,
                })
              }
            />
          </div>

          <div className="mt-10">
            <p className="text-lg font-semibold text-primary-blue mb-3">
              Description
            </p>
            <textarea
              style={{ resize: "none" }}
              placeholder="Provide a name"
              className="focus:outline-none placeholder:text-secondary-blue-lt text-secondary-blue text-base border border-primary-blue w-[365px] h-[90px] px-5 py-2 bg-transparent"
              value={addNewCourse.description}
              onChange={(e) =>
                setAddNewCourse({
                  ...addNewCourse,
                  description: e.target.value,
                })
              }
            ></textarea>
          </div>

          {addNewCourse.courseAdditionType.id === "course" && (
            <div className="flex items-center space-x-5 mt-10">
              <p className="text-lg font-semibold text-primary-blue">
                Course Type
              </p>
              <Dropdown
                menuList={[
                  { id: "skill", name: "Skill" },
                  { id: "bootcamp", name: "Bootcamp" },
                ]}
                defaultValue={{ id: "skill", name: "Skill" }}
                dropdownStyle="w-[142px] h-[40px] border border-primary-blue flex items-center justify-around text-lg text-primary-blue font-normal"
                arrowStyle="w-[20px] h-[15px] cursor-pointer"
                dropdownMenuStyle="w-[142px] bg-white"
                dropdownHandler={(option) =>
                  setAddNewCourse({ ...addNewCourse, courseType: option })
                }
              />
            </div>
          )}

          {addNewCourse.courseType.id === "bootcamp" &&
            addNewCourse.courseAdditionType.id === "course" && (
              <div className="flex items-center space-x-10 mt-10">
                <div className="flex items-center space-x-5">
                  <p className="text-lg font-semibold text-primary-blue">Age</p>
                  <input
                    type="text"
                    placeholder="Enter Age"
                    className="bg-transparent w-[120px] border border-secondary-blue px-2 py-1 text-lg text-secondary-blue placeholder:text-secondary-blue-lt focus:outline-none"
                    value={addNewCourse.minAge}
                    onChange={(e) =>
                      setAddNewCourse({
                        ...addNewCourse,
                        minAge: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="flex items-center space-x-5">
                  <p className="text-lg font-semibold text-primary-blue">
                    Student Limit
                  </p>
                  <input
                    type="text"
                    placeholder="Student Limit"
                    className="bg-transparent w-[150px] border border-secondary-blue px-2 py-1 text-lg text-secondary-blue placeholder:text-secondary-blue-lt focus:outline-none"
                    value={addNewCourse.maxEnrollmentAllowed}
                    onChange={(e) =>
                      setAddNewCourse({
                        ...addNewCourse,
                        maxEnrollmentAllowed: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            )}

          <div className="flex items-center space-x-5 mt-10">
            <p className="text-lg font-semibold text-primary-blue">
              Add Media Files
            </p>
            <FileUploader
              uploadFilesAPI={uploadFilesAPI}
              label={
                <label
                  // onClick={() =>
                  //   setIsLoading({ isLoading: true, type: "file-upload" })
                  // }
                  htmlFor="dashboardData"
                  className="flex items-center space-x-2 text-lg text-white font-normal bg-secondary-blue-ltr rounded-full max-w-max px-10 py-2"
                >
                  <span>Upload</span>
                  {isLoading.type === "file-upload" && (
                    <span>
                      <CircleSpinner />
                    </span>
                  )}
                </label>
              }
              files={files}
              setFiles={setFiles}
            />
          </div>
          {files.length > 0 && (
            <div>
              {files.map((file) => (
                <p key={file.name} className="flex items-center space-x-3 mt-5">
                  <CircularTickBlue
                    fill="#549202"
                    className="w-[20px] h-[20px]"
                  />
                  <span className="text-secondary-blue">
                    {file && file?.name} successfully uploaded
                  </span>
                  <span
                    onClick={() =>
                      alert(
                        "A cron job will delete last accessed file a freq of 3 months"
                      )
                    }
                    className="text-base text-primary-yellow hover:underline"
                  >
                    Remove
                  </span>
                </p>
              ))}
            </div>
          )}

          {addNewCourse.courseAdditionType.id === "course" && (
            <div className="flex items-center space-x-5 mt-10">
              <p className="text-lg font-semibold text-primary-blue">
                Certificate Type
              </p>
              <Dropdown
                menuList={[
                  { id: "chess", name: "Chess" },
                  { id: "coding", name: "Coding" },
                  { id: "designing", name: "Designing" },
                  { id: "uiux", name: "UX Design" },
                ]}
                defaultValue={{ id: "chess", name: "Chess" }}
                dropdownStyle="w-[142px] h-[40px] border border-primary-blue flex items-center justify-around text-lg text-primary-blue font-normal"
                arrowStyle="w-[20px] h-[15px] cursor-pointer"
                dropdownMenuStyle="w-[142px] bg-white"
                dropdownHandler={(option) =>
                  setAddNewCourse({ ...addNewCourse, certificateType: option })
                }
              />
            </div>
          )}

          <div className="flex items-center space-x-5 mt-10">
            <Button
              onClick={() => {
                if (addNewCourse.courseAdditionType.id === "course") {
                  addCourseAPI();
                } else if (addNewCourse.courseAdditionType.id === "topic") {
                  addTopicAPI();
                }
              }}
              isLoading={isLoading.isLoading && isLoading.type === "add-course"}
              disabled={isLoading.isLoading}
              innerText="Add Course"
              type="button"
              extraClass="py-1 px-5 max-w-max rounded-full bg-primary-green"
              innerTextStyle="text-xs md:text-base xl:text-lg font-normal text-white"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCourse;
