import React, { useState } from "react";

const InstructorPageAnimation = ({
  mainHeading,
  mainImage,
  firstOption,
  secondOption,
  thirdOption,
  fourthOption,
  fifthOption,
}) => {
  const [isHovered, setIsHovered] = useState({ id: null });

  return (
    <div className="max-w-screen">
      <div className="relative lg:hidden flex flex-col items-center">
        <div className="grid grid-cols-1 place-items-center sm:place-items-start sm:grid-cols-2 sm:gap-x-20 sm:gap-y-5 bg-secondary-orange w-[95%] rounded-xl pt-24 pb-10 sm:px-12">
          <div className="mb-3 ml-7 sm:ml-0 sm:mb-0 cursor-pointer bg-white w-[250px] sm:w-[300px] flex items-center border border-primary-purple rounded-xl">
            <div className="flex items-center justify-center bg-primary-purple w-[46px] h-[46px] rounded-full -ml-7">
              <img
                src={firstOption.imageUrl}
                alt=""
                className="w-[46px] h-[46px] rounded-full"
              />
            </div>
            <p className="text-xs sm:text-sm text-primary-blue font-normal mx-3">
              {firstOption.title}
            </p>
          </div>
          <div className="mb-3 ml-7 sm:ml-0  sm:mb-0 cursor-pointer bg-white w-[250px] sm:w-[300px]  flex items-center border border-primary-purple rounded-xl">
            <div className="flex items-center justify-center bg-primary-purple w-[46px] h-[46px] rounded-full -ml-7">
              <img
                src={secondOption.imageUrl}
                alt=""
                className="w-[46px] h-[46px] rounded-full"
              />
            </div>
            <p className="text-xs sm:text-sm text-primary-blue font-normal mx-3">
              {secondOption.title}
            </p>
          </div>
          <div className="mb-3 ml-7 sm:ml-0  sm:mb-0 cursor-pointer bg-white w-[250px] sm:w-[300px]  flex items-center border border-primary-purple rounded-xl">
            <div className="flex items-center justify-center bg-primary-purple w-[46px] h-[46px] rounded-full -ml-7">
              <img
                src={thirdOption.imageUrl}
                alt=""
                className="w-[46px] h-[46px] rounded-full"
              />
            </div>
            <p className="text-xs sm:text-sm text-primary-blue font-normal mx-3">
              {thirdOption.title}
            </p>
          </div>
          <div className="mb-3 ml-7 sm:ml-0  cursor-pointer bg-white w-[250px] sm:w-[300px]  flex items-center border border-primary-purple rounded-xl">
            <div className="flex items-center justify-center bg-primary-purple w-[46px] h-[46px] rounded-full -ml-7">
              <img
                src={fourthOption.imageUrl}
                alt=""
                className="w-[46px] h-[46px] rounded-full"
              />
            </div>
            <p className="text-xs sm:text-sm text-primary-blue font-normal mx-3">
              {fourthOption.title}
            </p>
          </div>
          <div className="ml-7 sm:ml-0 sm:col-span-2 sm:place-self-center cursor-pointer bg-white w-[250px] sm:w-[300px]  flex items-center border border-primary-purple rounded-xl">
            <div className="flex items-center justify-center bg-primary-purple w-[46px] h-[46px] rounded-full -ml-7">
              <img
                src={fifthOption.imageUrl}
                alt=""
                className="w-[46px] h-[46px] rounded-full"
              />
            </div>
            <p className="text-xs sm:text-sm text-primary-blue font-normal mx-3">
              {fifthOption.title}
            </p>
          </div>
        </div>
        <img
          src={mainImage.imageUrl}
          alt=""
          className="w-[220px] h-[194px] absolute -top-36"
        />
      </div>
      <div className="relative hidden lg:inline-flex">
        <div className="flex">
          <div className="relative bg-secondary-orange w-[231px] h-[450px] rounded-xl pt-10">
            <h3 className="text-xl font-bold text-secondary-blue mx-10">
              {mainHeading}
            </h3>
          </div>
          <div className="relative">
            <div
              onMouseOver={() => setIsHovered({ id: 1 })}
              onMouseOut={() => setIsHovered({ id: null })}
              className="flex mb-7"
            >
              <div className="w-[160px] h-[1px] bg-primary-purple -rotate-[40deg] -ml-5"></div>
              <div
                className={
                  isHovered.id === 1
                    ? "cursor-pointer bg-primary-pink absolute -top-24 left-32 w-[510px] h-[102px] flex items-center border border-primary-purple rounded-xl py-3"
                    : "cursor-pointer bg-white absolute -top-20 left-32 w-[510px] h-[70px] flex items-center border border-primary-purple rounded-xl py-3"
                }
              >
                <div
                  className={
                    isHovered.id === 1
                      ? "flex items-center justify-center bg-primary-purple w-[124px] h-[124px] rounded-full -ml-7"
                      : "flex items-center justify-center bg-primary-purple w-[46px] h-[46px] rounded-full -ml-7"
                  }
                >
                  <img
                    src={firstOption.imageUrl}
                    alt=""
                    className={
                      isHovered.id === 1
                        ? "w-[68px] h-[68px] rounded-none"
                        : "w-[46px] h-[46px] rounded-full"
                    }
                  />
                </div>
                <p
                  className={
                    isHovered.id === 1
                      ? "text-xl font-semibold text-primary-blue mx-5"
                      : "text-xl text-primary-blue font-normal mx-5"
                  }
                >
                  {firstOption.title}
                </p>
              </div>
            </div>
            <div
              onMouseOver={() => setIsHovered({ id: 2 })}
              onMouseOut={() => setIsHovered({ id: null })}
              className="flex mb-7"
            >
              <div className="w-[150px] h-[1px] bg-primary-purple -rotate-[20deg] mt-20 -ml-1.5"></div>
              <div
                className={
                  isHovered.id === 2
                    ? "cursor-pointer bg-primary-pink absolute left-32 top-8 w-[510px] h-[102px] flex items-center border border-primary-purple rounded-xl py-3"
                    : "cursor-pointer bg-white absolute left-32 top-14  w-[510px] h-[70px] flex items-center border border-primary-purple rounded-xl py-3"
                }
              >
                <div
                  className={
                    isHovered.id === 2
                      ? "flex items-center justify-center bg-primary-purple w-[124px] h-[124px] rounded-full -ml-7"
                      : "flex items-center justify-center bg-primary-purple w-[46px] h-[46px] rounded-full -ml-7"
                  }
                >
                  <img
                    src={secondOption.imageUrl}
                    alt=""
                    className={
                      isHovered.id === 2
                        ? "w-[68px] h-[68px] rounded-none"
                        : "w-[46px] h-[46px] rounded-full"
                    }
                  />
                </div>
                <p
                  className={
                    isHovered.id === 2
                      ? "text-xl font-semibold text-primary-blue mx-5"
                      : "text-xl text-primary-blue font-normal mx-5"
                  }
                >
                  {secondOption.title}
                </p>
              </div>
            </div>
            <div
              onMouseOver={() => setIsHovered({ id: 3 })}
              onMouseOut={() => setIsHovered({ id: null })}
              className="flex mb-7"
            >
              <div className="w-[145px] h-[0.5px] bg-primary-purple rotate-[0deg] mt-20"></div>
              <div
                className={
                  isHovered.id === 3
                    ? "cursor-pointer bg-primary-pink absolute top-[170px] left-32 w-[510px] h-[102px] flex items-center border border-primary-purple rounded-xl py-3"
                    : "cursor-pointer bg-white absolute top-[180px] left-32 w-[510px] h-[70px] flex items-center border border-primary-purple rounded-xl py-3"
                }
              >
                <div
                  className={
                    isHovered.id === 3
                      ? "flex items-center justify-center bg-primary-purple w-[124px] h-[124px] rounded-full -ml-7"
                      : "flex items-center justify-center bg-primary-purple w-[46px] h-[46px] rounded-full -ml-7"
                  }
                >
                  <img
                    src={thirdOption.imageUrl}
                    alt=""
                    className={
                      isHovered.id === 3
                        ? "w-[68px] h-[68px] rounded-none"
                        : "w-[46px] h-[46px] rounded-full"
                    }
                  />
                </div>
                <p
                  className={
                    isHovered.id === 3
                      ? "text-xl font-semibold text-primary-blue mx-5"
                      : "text-xl text-primary-blue font-normal mx-5"
                  }
                >
                  {thirdOption.title}
                </p>
              </div>
            </div>
            <div
              onMouseOver={() => setIsHovered({ id: 4 })}
              onMouseOut={() => setIsHovered({ id: null })}
              className="flex mb-7"
            >
              <div className="w-[155px] h-[1px] bg-primary-purple rotate-[20deg] mt-20 -ml-1.5"></div>
              <div
                className={
                  isHovered.id === 4
                    ? "cursor-pointer bg-primary-pink absolute bottom-[50px] left-32 w-[510px] h-[102px] flex items-center border border-primary-purple rounded-xl py-3"
                    : "cursor-pointer bg-white absolute bottom-[70px] left-32 w-[510px] h-[70px] flex items-center border border-primary-purple rounded-xl py-3"
                }
              >
                <div
                  className={
                    isHovered.id === 4
                      ? "flex items-center justify-center bg-primary-purple w-[124px] h-[124px] rounded-full -ml-7"
                      : "flex items-center justify-center bg-primary-purple w-[46px] h-[46px] rounded-full -ml-7"
                  }
                >
                  <img
                    src={fourthOption.imageUrl}
                    alt=""
                    className={
                      isHovered.id === 4
                        ? "w-[68px] h-[68px] rounded-none"
                        : "w-[46px] h-[46px] rounded-full"
                    }
                  />
                </div>
                <p
                  className={
                    isHovered.id === 4
                      ? "text-xl font-semibold text-primary-blue mx-5"
                      : "text-xl text-primary-blue font-normal mx-5"
                  }
                >
                  {fourthOption.title}
                </p>
              </div>
            </div>
            <div
              onMouseOver={() => setIsHovered({ id: 5 })}
              onMouseOut={() => setIsHovered({ id: null })}
              className="flex"
            >
              <div className="w-[160px] h-[1px] bg-primary-purple rotate-[40deg] mt-20 -ml-5"></div>
              <div
                className={
                  isHovered.id === 5
                    ? "cursor-pointer bg-primary-pink absolute -bottom-[90px] left-32 w-[510px] h-[102px] flex items-center border border-primary-purple rounded-xl py-3"
                    : "cursor-pointer bg-white absolute -bottom-16 left-32 w-[510px] h-[70px] flex items-center border border-primary-purple rounded-xl py-3"
                }
              >
                <div
                  className={
                    isHovered.id === 5
                      ? "flex items-center justify-center bg-primary-purple w-[124px] h-[124px]  rounded-full -ml-7"
                      : "flex items-center justify-center bg-primary-purple w-[46px] h-[46px] rounded-full -ml-7"
                  }
                >
                  <img
                    src={fifthOption.imageUrl}
                    alt=""
                    className={
                      isHovered.id === 5
                        ? "w-[68px] h-[68px] rounded-none"
                        : "w-[46px] h-[46px] rounded-full"
                    }
                  />
                </div>
                <p
                  className={
                    isHovered.id === 5
                      ? "text-xl font-semibold text-primary-blue mx-5"
                      : "text-xl text-primary-blue font-normal mx-5"
                  }
                >
                  {fifthOption.title}
                </p>
              </div>
            </div>
          </div>
        </div>

        <img src={mainImage.imageUrl} alt="" className={mainImage.className} />
      </div>
    </div>
  );
};

export default InstructorPageAnimation;
