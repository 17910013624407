import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import { BACKEND_BASE_URL } from "../../../helpers/url/url";
import axios from "../../../helpers/axios/axios";

const BootcampBatchDetails = ({ batchDetails, setBatchDetails }) => {
  const navigate = useNavigate();
  const bootcamp = useSelector((state) => state.bootcamp);

  console.log(batchDetails, "batchDetails");

  useEffect(() => {
    if (!batchDetails?.courseId || !bootcamp.courseName) {
      navigate("/summer-bootcamp");
    }
  }, [navigate, batchDetails.courseId, bootcamp.courseName]);

  const createCheckoutAPI = async (planId) => {
    try {
      const createCheckoutResponse = await axios.post(
        `${BACKEND_BASE_URL}/api/payment/createCheckout`,
        {
          planType: "course_plan",
          planId: planId,
        }
      );
      console.log(createCheckoutResponse.data, "create-checkout");
      if (createCheckoutResponse?.status === 200) {
        setBatchDetails({
          ...batchDetails,
          checkoutId: createCheckoutResponse?.data?.checkoutId,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPlanIdHandler = () => {
    const availablePlans = JSON.parse(bootcamp?.plans);
    console.log(availablePlans, "availablePlans");

    for (let i = 0; i < availablePlans.length; i++) {
      if (
        availablePlans[i].startDate.slice(0, 11) ===
          batchDetails.batchStartDate.slice(0, 11) &&
        availablePlans[i].endDate.slice(0, 11) ===
          batchDetails.batchEndDate.slice(0, 11) &&
        availablePlans[i].timeslot.from === batchDetails.timeslot.from &&
        availablePlans[i].timeslot.to === batchDetails.timeslot.to
      ) {
        setBatchDetails({
          ...batchDetails,
          planId: availablePlans[i].id,
          courseAmount: bootcamp?.courseAmount,
          courseAmountAfterCoupon: bootcamp?.courseAmount,
          courseName: bootcamp?.courseName,
        });
        createCheckoutAPI(availablePlans[i].id);

        navigate("/bootcamp-register/payment");
      }
    }
  };

  return (
    <div className="w-[100%] flex flex-col items-center">
      <h1 className="text-xl lg:text-3xl text-white font-medium mb-10">
        Let's get you there!
      </h1>
      <div className="w-[90%] lg:w-[80%] xl:w-[60%] border boder-primary-pink rounded-[30px] p-10">
        <div className="mb-7">
          <h3 className="mb-3 text-base text-primary-pink font-medium">
            Skill
          </h3>
          <p className="text-lg lg:text-2xl text-primary-pink font-medium">
            {bootcamp && bootcamp?.courseName}
          </p>
        </div>
        <div className="mb-7">
          <p className="mb-3 text-lg lg:text-2xl text-primary-pink font-medium">
            Choose Batches
          </p>
          <div className="flex flex-col space-y-5 md:space-y-0 md:flex-row md:items-center md:space-x-5">
            <span
              onClick={() =>
                setBatchDetails({
                  ...batchDetails,
                  batchStartDate: "2022-06-12T18:30:00.000+00:00",
                  batchEndDate: "2022-06-23T18:30:00.000+00:00",
                  batchId: "13-24",
                })
              }
              className={`${
                batchDetails.batchId === "13-24"
                  ? "bg-primary-pink text-secondary-blue"
                  : "bg-transparent text-primary-pink"
              } w-[90%] md:w-full cursor-pointer text-xs lg:text-sm xl:text-lg font-medium border border-primary-pink rounded-lg hover:bg-primary-pink hover:text-secondary-blue px-5 py-1.5`}
            >
              13 June - 24 June
            </span>
            <span
              onClick={() =>
                setBatchDetails({
                  ...batchDetails,
                  batchStartDate: "2022-07-11T14:48:00.000Z",
                  batchEndDate: "2022-07-22T14:48:00.000Z",
                  batchId: "11-22",
                })
              }
              className={`${
                batchDetails.batchId === "11-22"
                  ? "bg-primary-pink text-secondary-blue"
                  : "bg-transparent text-primary-pink"
              } w-[90%] md:w-full cursor-pointer text-xs lg:text-sm xl:text-lg font-medium border border-primary-pink rounded-lg hover:bg-primary-pink hover:text-secondary-blue px-5 py-1.5`}
            >
              11 July - 22 July
            </span>
            <span
              onClick={() =>
                setBatchDetails({
                  ...batchDetails,
                  batchStartDate: "2022-08-07T18:30:00.000+00:00",
                  batchEndDate: "2022-08-19T18:30:00.000+00:00",
                  batchId: "08-20",
                })
              }
              className={`${
                batchDetails.batchId === "08-20"
                  ? "bg-primary-pink text-secondary-blue"
                  : "bg-transparent text-primary-pink"
              } w-[90%] md:w-full cursor-pointer text-xs lg:text-sm xl:text-lg font-medium border border-primary-pink rounded-lg hover:bg-primary-pink hover:text-secondary-blue px-5 py-1.5`}
            >
              08 August - 20 August
            </span>
          </div>
        </div>
        <div className="mb-7">
          <p className="mb-3 text-lg lg:text-2xl text-primary-pink font-medium">
            Slot
          </p>
          <div className="flex flex-col space-y-5 md:space-y-0 md:flex-row md:items-center md:space-x-5">
            <span
              onClick={() =>
                setBatchDetails({
                  ...batchDetails,
                  timeslot: { from: "09:30", to: "12:30" },
                  slotId: "09:30-12:30",
                })
              }
              className={`${
                batchDetails.slotId === "09:30-12:30"
                  ? "bg-primary-pink text-secondary-blue"
                  : "bg-transparent text-primary-pink"
              } w-[100%] lg:w-[30%] xl:w-[40%] md:w-full cursor-pointer text-xs lg:text-sm xl:text-lg font-medium border border-primary-pink rounded-lg hover:bg-primary-pink hover:text-secondary-blue px-5 py-1.5`}
            >
              09:30AM - 12:30PM (PDT)
            </span>
            <span
              onClick={() =>
                setBatchDetails({
                  ...batchDetails,
                  timeslot: { from: "16:30", to: "19:30" },
                  slotId: "16:30-19:30",
                })
              }
              className={`${
                batchDetails.slotId === "16:30-19:30"
                  ? "bg-primary-pink text-secondary-blue"
                  : "bg-transparent text-primary-pink"
              } w-[100%] lg:w-[30%] xl:w-[40%] md:w-full cursor-pointer text-xs lg:text-sm xl:text-lg font-medium border border-primary-pink rounded-lg hover:bg-primary-pink hover:text-secondary-blue px-5 py-1.5`}
            >
              04:30PM - 07:30PM (PDT)
            </span>
          </div>
        </div>
        <div className="flex items-center space-x-5">
          <Button
            innerText="Next"
            type="button"
            extraClass="px-3 w-[120px] text-sm lg:text-lg text-white font-normal rounded-full bg-primary-green"
            onClick={getPlanIdHandler}
          />
          <Button
            innerText="Cancel"
            type="button"
            extraClass="px-3 w-[120px] text-sm lg:text-lg text-white font-normal rounded-full bg-transparent border border-primary-red"
            onClick={() => {
              //localStorage.removeItem("bootcamp");
              //window.location.reload();
              navigate("/summer-bootcamp");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default BootcampBatchDetails;
