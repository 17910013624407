import React from "react";

const Search = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        fill="#434484"
        d="M9.666 0C4.34 0 0 4.34 0 9.666c0 5.327 4.34 9.667 9.666 9.667 2.304 0 4.42-.815 6.084-2.167l6.54 6.541c.391.39 1.027.39 1.417 0a.99.99 0 000-1.406l-6.54-6.541a9.623 9.623 0 002.166-6.094C19.333 4.34 14.993 0 9.666 0zm0 2a7.651 7.651 0 017.666 7.666 7.651 7.651 0 01-7.666 7.666A7.651 7.651 0 012 9.666 7.651 7.651 0 019.666 2z"
      ></path>
    </svg>
  );
};

export default Search;
