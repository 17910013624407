import React from "react";

const PeopleLife = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 74 75"
    >
      <path
        fill="#FFE8F5"
        d="M12.801 75l10.558-9.726 26.27 1.496a6.733 6.733 0 004.82-1.663L71.99 50.061a5.737 5.737 0 00.582-8.064h-.083a5.901 5.901 0 00-8.23-.665L50.791 52.555l-12.387.665-1.746-2.826 9.145-1.912a5.737 5.737 0 004.572-6.318v-.167a5.819 5.819 0 00-6.068-4.988l-19.453 1.33a6.734 6.734 0 00-3.906 1.414L0 56.462 12.801 75zm10.142-32.587a3.49 3.49 0 012.078-.748l19.536-1.247a2.408 2.408 0 012.577 2.078 2.41 2.41 0 01-1.912 2.743L31.34 48.066l5.238 8.562 15.462-.831.416-.333L66.42 43.826a2.494 2.494 0 013.575.333h.082a2.414 2.414 0 01-.249 3.408l-17.457 15.13a3.41 3.41 0 01-2.494.83l-27.682-1.662-8.812 8.064-8.895-12.802 18.455-14.714zM15.711 20.217v9.976h14.88V33.6h22.196v-3.408H67.75v-9.976a10.141 10.141 0 00-3.491-7.73 7.813 7.813 0 10-14.465-5.903 8.23 8.23 0 00-16.127 0A7.812 7.812 0 0023.401 2.1a7.814 7.814 0 00-4.198 10.386 10.141 10.141 0 00-3.492 7.731zm17.956-7.232l-.498-.499h-.001a7.792 7.792 0 00.665-1.662c.145.516.34 1.017.582 1.496l-.748.665zm15.795 17.29H33.916V20.55a7.481 7.481 0 012.577-5.652 8.23 8.23 0 0010.474 0 7.397 7.397 0 012.495 5.652v9.727zm.25-17.29l-.749-.665c.242-.479.437-.98.582-1.497.157.579.38 1.136.665 1.663l-.499.499zm14.713 13.883H52.787V20.55a10.802 10.802 0 00-1.08-4.738l.665-.666a7.815 7.815 0 009.726 0 6.818 6.818 0 012.328 5.071v6.65zM57.276 4.423a4.49 4.49 0 110 8.978 4.49 4.49 0 010-8.979zm-15.545-.915a4.988 4.988 0 11-.002 9.976 4.988 4.988 0 01.002-9.976zm-15.545.914a4.49 4.49 0 11-.001 8.979 4.49 4.49 0 010-8.979zm-7.15 15.795a6.818 6.818 0 012.328-5.154 7.815 7.815 0 009.726 0l.665.665a10.817 10.817 0 00-1.163 4.739v6.4H19.037v-6.65z"
      ></path>
    </svg>
  );
};

export default PeopleLife;
