import React from "react";
import ClassCalendar from "../../../../../assets/SVG/class-calendar";
import Clock from "../../../../../assets/SVG/clock";
import Button from "../../../../../components/Button/Button";
import InfoCard from "../../../../../components/Card/InfoCard/InfoCard";
import { CLOUDFRONT_AWS_S3_URL } from "../../../../../helpers/url/url";

const CourseDashboard = ({ classDetails }) => {
  console.log(classDetails);
  return (
    <div className="grid grid-cols-2 gap-20 w-[50%]">
      {classDetails
        // .slice(bootcampsCount.otherScreen, bootcampsCount.otherScreen + 3)
        .map((course) => (
          <div key={course?._id}>
            <InfoCard
              extraClass="w-[220px] sm:w-[230px] sm:h-[340px] text-left text-lg font-bold border-2 border-primary-pink rounded-3xl flex flex-col space-y-3 p-2 pb-5"
              src={`${CLOUDFRONT_AWS_S3_URL}/${course?.courseInfo?.mediaAttached[0]}`}
              button={false}
              description={course?.courseInfo?.courseName}
              imageStyle="w-full h-[160px] rounded-xl"
              textStyle="px-3 xl:text-lg"
              extraElement={
                <div className="px-3 flex flex-col space-y-3">
                  <div className="flex items-center space-x-2 text-sm text-secondary-blue font-semibold">
                    <ClassCalendar />
                    <p>{`${new Date(
                      course?.classStartDate
                    ).getDay()}/${new Date(
                      course?.classStartDate
                    ).getMonth()}/${new Date(
                      course?.classStartDate
                    ).getFullYear()}`}</p>
                  </div>
                  <div className="flex items-center space-x-2 text-sm text-secondary-blue font-semibold">
                    <Clock />
                    <p>
                      {`${new Date(
                        course?.classStartDate
                      ).getHours()}:${new Date(
                        course?.classStartDate
                      ).getMinutes()}`}
                    </p>
                  </div>

                  <Button
                    onClick={() => window.open(`${course?.classURL}`, "_blank")}
                    innerText="Join Class"
                    type="button"
                    extraClass="w-full text-lg text-white font-normal rounded-full bg-primary-green"
                  />
                </div>
              }
            />
          </div>
        ))}
    </div>
  );
};

export default CourseDashboard;
