import React from "react";
import Button from "../../Button/Button";

const BootcampCard = ({
  src,
  title,
  age,
  seatsAvailable,
  onClick,
  extraClass,
}) => {
  return (
    <div className={`${extraClass}`}>
      {src && (
        <img
          src={src}
          alt=""
          className="w-[290px] h-[230px] xl:w-[288px] xl:h-[237px] rounded-xl"
        />
      )}
      {title && (
        <h3 className="text-center text-base xl:text-lg xl:leading-[29px] text-secondary-blue font-bold ">
          {title}
        </h3>
      )}
      <div className="flex items-center justify-between w-full">
        <div>
          <p className="text-left text-base xl:text-xl xl:leading-[28px] font-normal text-secondary-blue ">
            Age: {age}+
          </p>
          <span className="text-xs xl:text-base font-normal  text-primary-red xl:leading-[24px]">
            {seatsAvailable} seats available
          </span>
        </div>
        <Button
          onClick={onClick}
          innerText="Learn how"
          type="button"
          extraClass="max-w-max text-sm xl:text-lg text-white font-semibold rounded-full bg-primary-green"
        />
      </div>
    </div>
  );
};

export default BootcampCard;
