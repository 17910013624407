import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "./custom-styling.css";

const CustomModal = ({ open, closeModalHandler, children }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={closeModalHandler}
        animationDuration={0}
        classNames={{
          modal: "customModal",
        }}
      >
        {children}
      </Modal>
    </div>
  );
};

export default CustomModal;
