import React from "react";

const Tick = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        fill="#434484"
        d="M10 15.586l-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414L10 15.586z"
      ></path>
    </svg>
  );
};

export default Tick;
