import React from "react";

const Facebook = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 25 25"
      {...rest}
    >
      <path
        fill="#434484"
        d="M23.62 0H1.38A1.38 1.38 0 000 1.38v22.24C0 24.381.618 25 1.38 25h11.973v-9.681h-3.258v-3.774h3.258V8.762c0-3.228 1.971-4.987 4.852-4.987 1.381 0 2.565.103 2.91.148v3.375h-1.997c-1.567 0-1.869.745-1.869 1.837v2.409h3.737l-.489 3.773H17.25v9.682h6.37c.762 0 1.381-.619 1.381-1.38V1.38A1.38 1.38 0 0023.62 0z"
      ></path>
    </svg>
  );
};

export default Facebook;
