import React from "react";
import ReactPlayer from "react-player";
import "./react-player.css";

const ReactPlayerComponent = ({ url }) => {
  return (
    <ReactPlayer
      width="100%"
      url={url}
      muted
      playing={true}
      loop={true}
      playsinline={true}
      controls={false}
      className="react-player"
    />
  );
};

export default ReactPlayerComponent;
