import React from "react";
import { useState } from "react";
import Schedule from "../../../assets/SVG/schedule";
import Videocam from "../../../assets/SVG/videocam";
import MyDateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import { BACKEND_BASE_URL } from "../../../helpers/url/url";
import axios from "../../../helpers/axios/axios";
import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { combineDateTime } from "../../../helpers/functions/combine-date-time";
import CheckboxTick from "../../../assets/SVG/checkbox-tick";

const timeSlots = [
  "12:00 AM",
  "12:30 AM",
  "01:00 AM",
  "01:30 AM",
  "02:00 AM",
  "02:30 AM",
  "03:00 AM",
  "03:30 AM",
  "04:00 AM",
  "04:30 AM",
  "05:00 AM",
  "05:30 AM",
  "06:00 AM",
  "06:30 AM",
  "07:00 AM",
  "07:30 AM",
  "08:00 AM",
  "08:30 AM",
  "09:00 AM",
  "09:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",

  "12:00 PM",
  "12:30 PM",
  "01:00 PM",
  "01:30 PM",
  "02:00 PM",
  "02:30 PM",
  "03:00 PM",
  "03:30 PM",
  "04:00 PM",
  "04:30 PM",
  "05:00 PM",
  "05:30 PM",
  "06:00 PM",
  "06:30 PM",
  "07:00 PM",
  "07:30 PM",
  "08:00 PM",
  "08:30 PM",
  "09:00 PM",
  "09:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM",
];

const BookClass = ({ selectedCourse, setSelectedCourse }) => {
  const [slotDetails, setSlotDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [recurringSlots, setRecurringSlots] = useState([]);
  const [showIndividualError, setShowIndividualError] = useState("");

  console.log(recurringSlots, "recurringSlots");

  console.log(slotDetails, "slotDetails", slotDetails?.date, slotDetails?.time);

  const navigate = useNavigate();

  const getWalletDetailsAPI = async () => {
    let combinedDateTime;
    if (slotDetails?.date && slotDetails?.time) {
      const date = new Date(slotDetails?.date);
      const timeString = slotDetails?.time + ":00";
      combinedDateTime = combineDateTime(date, timeString);
      console.log(combinedDateTime, "combined", Date.parse(combinedDateTime));
    }

    setIsLoading(true);
    try {
      const walletDetailsResponse = await axios.get(
        `${BACKEND_BASE_URL}/api/students/getWalletDetails`
      );
      if (walletDetailsResponse?.status === 200) {
        console.log(walletDetailsResponse?.data);
        setSelectedCourse({
          ...selectedCourse,
          walletDetails: walletDetailsResponse?.data?.wallet,
          requestedDateTime: combinedDateTime,
        });
        navigate("/student/enroll/summary");
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-screen">
      <div className="flex shadow-lg w-[85%] bg-white mx-auto my-20 py-10">
        <div className="flex flex-col space-y-2 w-[40%] px-10">
          <p className="text-xl font-medium text-secondary-blue">Krafternoon</p>
          <p className="font-bold text-xl text-secondary-blue">
            {selectedCourse?.courseName
              ? selectedCourse.courseName
              : selectedCourse?.title
              ? selectedCourse?.title
              : "Not Found"}
          </p>
          <div className="flex items-center space-x-2">
            <Schedule className="h-5 w-5 text-gray-500" />
            <p className="text-gray-500">1 hr</p>
          </div>
          <div className="flex space-x-2">
            <Videocam className="h-5 w-5 text-gray-500" />
            <p className="text-gray-500">
              Web conferencing details provided upon confirmation.
            </p>
          </div>
          <Button
            isLoading={isLoading}
            disabled={
              isChecked
                ? isLoading || recurringSlots.length === 0
                : isLoading || !slotDetails?.date || !slotDetails?.time
            }
            onClick={getWalletDetailsAPI}
            innerText="Next"
            type="button"
            extraClass="self-end max-w-max px-5 py-2 text-sm text-white font-normal rounded-full bg-primary-green"
          />
          {(!slotDetails?.date || !slotDetails?.time) &&
            recurringSlots.length === 0 && (
              <p className="text-primary-purple self-end">
                Please select the date and time
              </p>
            )}
          <div className="self-end flex items-center space-x-3">
            {!isChecked && (
              <div
                onClick={() => {
                  setIsChecked(true);
                }}
                className="cursor-pointer w-[20px] h-[20px] xl:w-[24px] xl:h-[24px] border-2 border-primary-purple rounded"
              ></div>
            )}
            {isChecked && (
              <CheckboxTick
                className="w-[20px] h-[20px] xl:w-[24px] xl:h-[24px] cursor-pointer"
                onClick={() => setIsChecked(false)}
              />
            )}
            <p className="text-sm xl:text-sm text-primary-blue font-normal">
              Do you want to add recurring classes ?
            </p>
          </div>
        </div>
        <div className=" w-[60%] flex justify-between border-l pl-20">
          <div className="flex flex-col space-y-5">
            <p className="font-bold text-xl text-secondary-blue">
              Select a Date & Time
            </p>
            <MyDateRangePicker
              slotDetails={slotDetails}
              setSlotDetails={setSlotDetails}
              setSelectedCourse={setSelectedCourse}
              selectedCourse={selectedCourse}
            />
            {isChecked && (
              <div>
                <Button
                  onClick={() => {
                    if (slotDetails?.date && slotDetails?.time) {
                      setShowIndividualError("");
                      const date = new Date(slotDetails?.date);
                      const timeString = slotDetails?.time + ":00";
                      const combinedDateTime = combineDateTime(
                        date,
                        timeString
                      );
                      setSlotDetails({});
                      setRecurringSlots([...recurringSlots, combinedDateTime]);
                    } else {
                      setShowIndividualError(
                        "Please select Both Date and Time"
                      );
                    }
                  }}
                  innerText="Add Recurring Slots"
                  type="button"
                  extraClass="max-w-max px-2 py-1 text-sm text-white font-normal rounded-full bg-primary-green"
                />
                {showIndividualError && (
                  <p className="text-xs text-primary-red font-semibold mt-2">
                    {showIndividualError}
                  </p>
                )}
              </div>
            )}
          </div>
          <div className="flex flex-col space-y-5 overflow-y-scroll h-[400px] pr-10">
            {timeSlots.map((slot, index) => (
              <div
                onClick={() => {
                  setSlotDetails({
                    ...slotDetails,
                    time: slot.match("PM")
                      ? parseInt(slot.slice(0, 2)) + 12 + slot.slice(2, 5)
                      : parseInt(slot.slice(0, 2)) + slot.slice(2, 5),
                  });
                  setSelectedCourse({ ...selectedCourse, time: slot });
                }}
                key={index}
                className="cursor-pointer bg-white hover:bg-secondary-blue hover:text-white px-10 py-2 border border-ternary-blue text-secondary-blue font-bold"
              >
                {slot}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookClass;
