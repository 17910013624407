import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CircularTickBlue from "../../../assets/SVG/circular-tick-blue";
import Button from "../../../components/Button/Button";
import DatePicker from "../../../components/DatePicker/DatePicker";
import FileUploader from "../../../components/FileUploader/FileUploader";
import CircleSpinner from "../../../components/Spinners/CircleSpinner/CircleSpinner";
import axios from "../../../helpers/axios/axios";
import { formattedDate } from "../../../helpers/functions/date-format";
import { fileExtension } from "../../../helpers/functions/file-extension";
import { toastNotification } from "../../../helpers/functions/toast-notification/toast-notification";
import { BACKEND_BASE_URL } from "../../../helpers/url/url";

let MEDIA_ATTACHED = [];

const AdPosts = () => {
  const [files, setFiles] = useState([]);
  const [adPostData, setAdPostData] = useState({
    adName: "",
    startDate: "",
    endDate: "",
    mediaAttached: [],
  });
  const [isLoading, setIsLoading] = useState({
    isLoading: false,
    type: null,
  });

  const navigate = useNavigate();

  const uploadFilesAPI = async (file) => {
    setIsLoading({ isLoading: true, type: "file-upload" });
    try {
      const uploadUrlResponse = await axios.post(
        `${BACKEND_BASE_URL}/admin/mediaActions/uploadURL`,
        { fileType: fileExtension(file?.name), alias: file?.name }
      );
      console.log(uploadUrlResponse, "uploadUrlResponse");
      if (uploadUrlResponse?.status === 200) {
        const url = uploadUrlResponse?.data?.s3Payload?.url;
        const key = uploadUrlResponse?.data?.key;
        const fields = uploadUrlResponse?.data?.s3Payload?.fields;

        let formData = new FormData();
        for (let key in fields) {
          formData.append(key, fields[key]);
        }
        formData.append("file", file);

        try {
          const s3UploadResponse = await axios.post(url, formData);
          console.log(s3UploadResponse, "s3UploadResponse");
          if (s3UploadResponse.status === 204) {
            try {
              const ackUploadResponse = await axios.post(
                `${BACKEND_BASE_URL}/admin/mediaActions/ackUpload`,
                { key: key }
              );
              console.log(ackUploadResponse, "ackUploadResponse");
              if (ackUploadResponse.status === 204) {
                MEDIA_ATTACHED.push(key);
                toastNotification(
                  "success",
                  "File has been uploaded successfully."
                );
              }
            } catch (err) {
              console.log(err);
              setIsLoading({ isLoading: false, type: null });
              toastNotification(
                "error",
                "File could not be uploaded. Please try again!"
              );
            }
          }
        } catch (err) {
          setIsLoading({ isLoading: false, type: null });
          console.log(err);
          toastNotification(
            "error",
            "File could not be uploaded. Please try again!"
          );
        }
      }

      setAdPostData({ ...adPostData, mediaAttached: MEDIA_ATTACHED });
      setIsLoading({ isLoading: false, type: null });
    } catch (err) {
      console.log(err);
      setIsLoading({ isLoading: false, type: null });
      toastNotification(
        "error",
        "File could not be uploaded. Please try again!"
      );
    }
  };

  const createAdPostAPI = async () => {
    try {
      const createAdPostResponse = await axios.post(
        `${BACKEND_BASE_URL}/admin/adminRoles/createAdPost`,
        {
          postName: adPostData.adName,
          startDate: formattedDate(adPostData.startDate),
          endDate: formattedDate(adPostData.endDate),
          mediaAttached: adPostData.mediaAttached,
        }
      );
      console.log(createAdPostResponse, "createAdPostResponse");
      if (createAdPostResponse.status === 200) {
        setAdPostData({
          adName: "",
          startDate: "",
          endDate: "",
          mediaAttached: [],
        });

        toastNotification("success", "Post added successfully.");
        navigate("/admin/all-ad-posts");
      }
      setFiles([]);
      setIsLoading({ isLoading: false, type: null });
    } catch (err) {
      console.log(err);
      setIsLoading({ isLoading: false, type: null });
      toastNotification(
        "error",
        "Failed to create the Ad Post. Please try again!"
      );
    }
  };

  return (
    <div className="w-[50%] h-full">
      <div className="h-full bg-ternary-blue-ltr mt-10 rounded-lg pb-5">
        <h3 className="text-secondary-blue text-2xl font-extrabold p-5">
          Ad Posts
        </h3>
        <div className="border-t border-secondary-blue-lt p-5 h-full">
          <div className="bg-white h-full pb-7">
            <h4 className="text-xl text-primary-blue px-5 py-3">
              Create New Post
            </h4>
            <div className="border-t border-primary-purple px-5">
              <div className="flex items-center space-x-5 mt-10">
                <p className="text-lg font-semibold text-primary-blue">
                  Ad Name
                </p>
                <input
                  type="text"
                  placeholder="Provide a name"
                  className="bg-transparent w-[70%] border border-secondary-blue px-2 py-1 text-lg text-secondary-blue placeholder:text-secondary-blue-lt focus:outline-none"
                  value={adPostData.adName}
                  onChange={(e) =>
                    setAdPostData({ ...adPostData, adName: e.target.value })
                  }
                />
              </div>

              <div className="flex items-center space-x-5 mt-10">
                <p className="text-lg font-semibold text-primary-blue">
                  Add Media Files
                </p>
                <FileUploader
                  uploadFilesAPI={uploadFilesAPI}
                  label={
                    <label
                      // onClick={() =>
                      //   setIsLoading({ isLoading: true, type: "file-upload" })
                      // }
                      htmlFor="dashboardData"
                      className="flex items-center space-x-2 text-lg text-white font-normal bg-secondary-blue-ltr rounded-full max-w-max px-10 py-2"
                    >
                      <span>Upload</span>
                      {isLoading.type === "file-upload" && (
                        <span>
                          <CircleSpinner />
                        </span>
                      )}
                    </label>
                  }
                  files={files}
                  setFiles={setFiles}
                />
              </div>
              {files.length > 0 &&
                files.map((file) => (
                  <div key={file.name} className="mt-5">
                    <p className="flex items-center space-x-3">
                      <CircularTickBlue
                        fill="#549202"
                        className="w-[20px] h-[20px]"
                      />
                      <span className="text-secondary-blue">
                        {file && file?.name} successfully uploaded
                      </span>
                      <span
                        onClick={() => setFiles([])}
                        className="text-base text-primary-yellow hover:underline"
                      >
                        Remove
                      </span>
                    </p>
                  </div>
                ))}

              <div className="flex items-center space-x-5 mt-10">
                <div className="flex items-center space-x-5">
                  <p className="text-lg text-primary-blue font-normal">
                    Start Date
                  </p>
                  <DatePicker
                    date={adPostData.startDate}
                    setDate={(date) =>
                      setAdPostData({
                        ...adPostData,
                        startDate: date,
                      })
                    }
                  />
                </div>
                <div className="flex items-center space-x-5">
                  <p className="text-lg text-primary-blue font-normal">
                    End Date
                  </p>
                  <DatePicker
                    date={adPostData.endDate}
                    setDate={(date) =>
                      setAdPostData({
                        ...adPostData,
                        endDate: date,
                      })
                    }
                  />
                </div>
              </div>
              <Button
                innerText="Save & Publish"
                type="button"
                extraClass="mt-24 h-[45px] w-[170px] text-white font-normal rounded-full bg-primary-green"
                innerTextStyle="text-xs md:text-base xl:text-lg font-normal"
                onClick={createAdPostAPI}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdPosts;
