export const toUTC = (time) => {
  const now = new Date();
  const timeOffset = now.getTimezoneOffset() * 1000 * 60;
  const hour = parseInt(time.slice(0, 2));
  const minute = parseInt(time.slice(3, 5));
  const timeInMilliseconds = (hour * 3600 + minute * 60) * 1000;

  let diff = timeInMilliseconds + timeOffset;

  if (diff < 0) {
    diff = 86400000 + diff;
  }

  const newDate = new Date(diff);
  let utcHours = newDate.getUTCHours();
  if (utcHours < 10) {
    utcHours = "0" + utcHours;
  }
  let utcMinutes = newDate.getUTCMinutes();
  if (utcMinutes < 10) {
    utcMinutes = "0" + utcMinutes;
  }
  return `${utcHours}:${utcMinutes}`;
};
