import React from "react";
import InfoCard from "../../components/Card/InfoCard/InfoCard";
import MostInDemandFacilities from "../../components/MostInDemandFacilities/MostInDemandFacilities";
import WeTeachCreativeAnalyticalSkills from "../../components/WeTeachCreativeAnalyticalSkills/WeTeachCreativeAnalyticalSkills";
import PersonalizedCourses from "../../assets/SVG/personalized-courses";
import LiveClasses from "../../assets/SVG/live-classes";
import FunLearning from "../../assets/SVG/fun-learning";
import TransformCard from "../../components/Card/TransformCard/TransformCard";
//import AutoCarousel from "../../components/AutoCarousel/AutoCarousel";
import { Helmet } from "react-helmet";

const CreativeChamps = () => {
  return (
    <>
      <Helmet>
        <title>Krafternoon</title>
        <meta
          name="description"
          content="Thinking about the future of your child by industry experts? Krafternoon's Innovative educational platform provides courses by industry experts."
        />
        <meta
          property="og:title"
          content="    Creating futuristic thinkers with refined creativity & analytical
          skills."
        />
      </Helmet>
      <div className="max-w-screen">
        <div className="text-center mt-20 mb-10">
          <h1 className="text-secondary-blue text-2xl sm:text-3xl text-center xl:text-5xl font-bold">
            Our creative Content Creators
          </h1>
          <p className="mt-2 text-base lg:text-lg px-10 xl:text-2xl xl:leading-[48px] text-secondary-blue font-normal ">
            Peek through next generation learning.
          </p>
        </div>

        <img
          src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/LandingPage/We_know_that_you_know_the_skills__1__fiz3Md84I3.gif?ik-sdk-version=javascript-1.4.3&updatedAt=1653755655899"
          alt=""
          className="w-screen"
        />
        <div className="text-center mt-20 mb-10">
          <p className="text-2xl xl:text-3xl font-extrabold mb-2 xl:mb-4 text-secondary-blue">
            <span className="text-primary-green">Hear </span>from them
          </p>
          <p className="text-base md:text-lg xl:text-2xl font-normal text-secondary-blue mx-10">
            Look at how our kids are applying what they’ve learned in classes
          </p>
          <div className="flex flex-col space-y-7 lg:space-y-0 lg:flex-row justify-between items-center my-20 2xl:mx-20 lg:mx-10">
            <InfoCard
              extraClass="w-[280px] h-[470px] xl:w-[378px] xl:h-[600px] text-left text-xs sm:text-base xl:text-lg border-2 border-primary-orange rounded-3xl flex flex-col space-y-3 items-center pb-10 pt-5 px-3"
              src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/CreativeChamps/UX_1_JPQcjVhZs.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654025576319"
              button={false}
              description={`Our creative champ has designed her own bakery mobile application under the mentorship of Krafternoon.`}
              secondaryDescription={`Student word- "It's a bakery app, where anyone can learn how to bake stuffs just for learning purpose or even to make a career in it. I have designed this app under my Krafternoon mentor who helped me to come up with new ideas, and explained me how to design a good user experience."`}
              fromStudentOf="~UI/UX Student"
              textStyle="font-normal"
            />
            <InfoCard
              extraClass="w-[280px] h-[470px] xl:w-[378px] xl:h-[600px] text-left text-xs sm:text-base xl:text-lg border-2 border-secondary-yellow rounded-3xl flex flex-col space-y-3 items-center pb-10 pt-5 px-3"
              src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/CreativeChamps/chess__3___DEQgn9bO.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654025575984"
              button={false}
              description={`Our chess champ under Krafternoon strategic guidance had showed significant growth.`}
              secondaryDescription={`Student word- "My rating significantly rose under Krafternoon mentorship."`}
              fromStudentOf="~Chess Student"
              textStyle="font-normal"
            />
            <InfoCard
              extraClass="w-[280px] h-[470px] xl:w-[378px] xl:h-[600px] text-left text-xs sm:text-base xl:text-lg border-2 border-secondary-pink rounded-3xl flex flex-col space-y-3 items-center pb-10 pt-5 px-3"
              src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/CreativeChamps/Rectangle_343_rBtMIR6bP.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654025576000"
              button={false}
              description={`A 13 year old student establishes her own YouTube channel after getting exposed to Video Editing tool from Krafternoon.`}
              secondaryDescription={`Student word- " It was fun learning with Bhavik. Learning under his guidance motivated me to create my own YouTube channel."`}
              fromStudentOf="~Video Editing Student"
              textStyle="font-normal"
            />
          </div>
        </div>
        <WeTeachCreativeAnalyticalSkills
          firstPart="We build"
          secondPart="Creative & Analytical"
          thirdPart="power"
          descriptionOne="With creativity (C) and analytical (A) skills being one of the most important aspects in the overall development of an individual, our team continuously monitors and includes skills over the platform with the same point of reference. For continuous and combined learning, we at Krafternoon want to create a difference in the concept of learning approach and pattern. With the digital background, we want to provide the comfort of distance learning with a humane touch while making it a personalized learning experience."
          descriptionTwo="Our hands-on learning approach & widen the scope of learning opportunities beyond schools and across different ages. Strengthen learning in existing and new systems. Integrate learning across educators, firms, and other stakeholders "
        />
        <div className="relative">
          <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-2 mx-auto text-center text-sm md:text-sm lg:text-lg xl:text-xl 2xl:text-2xl font-[750] bg-white w-[65%] md:w-[40%] lg:w-[40%]">
            <span className="text-secondary-blue">
              Hands-on learning with the Innovators extending
              <span className="ml-1 text-primary-green inline-flex">
                MOST-ESSENTIAL
                <p className="ml-1 text-secondary-blue">facilities</p>
              </span>
            </span>
          </div>
          <MostInDemandFacilities
            menuList={[
              {
                id: "personalized-creative-courses",
                title: "Personalized Creative Courses",
                Icon: PersonalizedCourses,
              },
              {
                id: "live-classes",
                title: "No Pre-Recorded class. Hands-on learning with experts.",
                Icon: LiveClasses,
              },
              {
                id: "fun-learning",
                title: "Interactive & Fun Learning",
                Icon: FunLearning,
              },
            ]}
          />
        </div>
        <TransformCard
          mainHeading="Exposure to learn with current industry experts"
          subHeading="Kids around the world are learning new skills and excelling in their field. Take a step towards an innovative future."
          buttonInnerText="Experience it for FREE"
        />
      </div>
    </>
  );
};

export default CreativeChamps;
