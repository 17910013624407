import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CircularTickBlue from "../../../../../assets/SVG/circular-tick-blue";
import Button from "../../../../../components/Button/Button";
import DatePicker from "../../../../../components/DatePicker/DatePicker";
import FileUploader from "../../../../../components/FileUploader/FileUploader";

const EditAdPost = () => {
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();

  return (
    <div className="w-[35%] h-[580px]">
      <div className="h-full bg-ternary-blue-ltr mt-10 rounded-lg pb-20">
        <div className="flex items-center space-x-3">
          <h3 className="text-secondary-blue text-2xl font-extrabold p-5">
            Edit Ad Posts
          </h3>
          <Button
            onClick={() => navigate("/admin/ad-posts")}
            innerText="Create New"
            type="button"
            extraClass="py-1 px-5 max-w-max rounded-full bg-primary-green"
            innerTextStyle="text-xs md:text-base xl:text-lg font-normal text-white"
          />
        </div>
        <div className="border-t border-secondary-blue-lt p-5 h-full">
          <div className="bg-white h-full">
            <h4 className="text-xl text-primary-blue px-5 py-3">
              Create New Post
            </h4>
            <div className="border-t border-primary-purple px-5">
              <div className="flex items-center space-x-5 mt-10">
                <p className="text-lg font-semibold text-primary-blue">
                  Ad Name
                </p>
                <input
                  type="text"
                  placeholder="Provide a name"
                  className="bg-transparent w-[70%] border border-secondary-blue px-2 py-1 text-lg text-secondary-blue placeholder:text-secondary-blue-lt focus:outline-none"
                />
              </div>

              <div className="flex items-center space-x-5 mt-10">
                <p className="text-lg font-semibold text-primary-blue">
                  Add Media Files
                </p>
                <FileUploader
                  label={
                    <label
                      htmlFor="dashboardData"
                      className="text-base text-white font-normal bg-secondary-blue-ltr rounded-full max-w-max px-10 py-2"
                    >
                      Upload
                    </label>
                  }
                  files={files}
                  setFiles={setFiles}
                />
              </div>
              {files.length > 0 &&
                files.map((file) => (
                  <p className="flex items-center space-x-3 mt-5">
                    <CircularTickBlue
                      fill="#549202"
                      className="w-[20px] h-[20px]"
                    />
                    <span className="text-secondary-blue">
                      {file && file?.name} successfully uploaded
                    </span>
                    <span
                      onClick={() => setFiles([])}
                      className="text-base text-primary-yellow hover:underline"
                    >
                      Remove
                    </span>
                  </p>
                ))}

              <div className="flex items-center space-x-5 mt-10">
                <p className="text-lg text-primary-blue font-normal">
                  Start Date
                </p>
                <DatePicker />
              </div>
              <div className="flex items-center space-x-5 mt-28">
                <Button
                  innerText="Save & Publish"
                  type="button"
                  extraClass="h-[45px] w-[170px] text-white font-normal rounded-full bg-primary-green"
                  innerTextStyle="text-xs md:text-base xl:text-lg font-normal"
                />
                <Button
                  innerText="Delete Ad"
                  type="button"
                  extraClass="h-[45px] w-[170px] rounded-full bg-white border border-primary-red"
                  innerTextStyle="text-xs md:text-base xl:text-lg text-primary-red font-normal"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAdPost;
