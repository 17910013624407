import React from "react";

const Laptop = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 33 22"
      {...rest}
    >
      <path
        fill="#434484"
        d="M32.1 20.517l-2.81-4.462V.775A.776.776 0 0028.513 0H3.706a.775.775 0 00-.775.775v15.28L.12 20.517a.775.775 0 00.654 1.19h30.67a.775.775 0 00.654-1.19zM4.48 1.55H27.74v13.955H4.48V1.55zM2.183 20.156l1.938-3.1h23.967l1.938 3.1H2.183z"
      ></path>
      <path
        fill="#434484"
        d="M19.308 7.856l-5.62-3.244a.776.776 0 00-1.164.67v6.49a.775.775 0 001.163.67l5.62-3.245a.776.776 0 000-1.341zm-5.233 2.574V6.623l3.295 1.904-3.295 1.903z"
      ></path>
    </svg>
  );
};

export default Laptop;
