import React from "react";

const TdData = ({ value, extraClass, style }) => {
  return (
    <td style={style} className={`${extraClass} py-3`}>
      <p className="w-[70%] mx-auto">{value}</p>
    </td>
  );
};

export default TdData;
