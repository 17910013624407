import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { getDaysOfWeekBetweenDates } from "../../../helpers/functions/days-between-dates";
import { toUTC } from "../../../helpers/functions/to-utc";
import classes from "./frequency.module.css";

let daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const defaultSelectedOptions = {
  Sunday: {
    weekDay: 0,
    selected: false,
    time: "00:00",
  },
  Monday: {
    weekDay: 1,
    selected: false,
    time: "00:00",
  },
  Tuesday: {
    weekDay: 2,
    selected: false,
    time: "00:00",
  },
  Wednesday: {
    weekDay: 3,
    selected: false,
    time: "00:00",
  },
  Thursday: {
    weekDay: 4,
    selected: false,
    time: "00:00",
  },
  Friday: {
    weekDay: 5,
    selected: false,
    time: "00:00",
  },
  Saturday: {
    weekDay: 6,
    selected: false,
    time: "00:00",
  },
};

const ClassFrequencySelector = ({
  frequencySelectHandler,
  startDate,
  endDate,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(
    defaultSelectedOptions
  );

  const updateOptionsHandler = useCallback(() => {
    if (startDate && endDate) {
      const days = getDaysOfWeekBetweenDates(startDate, endDate);
      console.log(days);
      setSelectedOptions({ ...days.daysOptions });
      daysOfWeek = days.weekdays;
    }
  }, [endDate, startDate]);

  useEffect(() => {
    updateOptionsHandler();
  }, [updateOptionsHandler]);

  //console.log("selectedOptions", selectedOptions);

  const frequencyChangeHandler = (e, day) => {
    const isSelected = e.target.checked;

    let updatedSelectedOptions = {};
    let selectedWeekdays = [];

    for (let key in selectedOptions) {
      if (key === day) {
        selectedOptions[key].selected = isSelected;
      }
      updatedSelectedOptions[key] = selectedOptions[key];
      if (updatedSelectedOptions[key].selected) {
        selectedWeekdays.push({
          weekDay: updatedSelectedOptions[key].weekDay,
          time: toUTC(updatedSelectedOptions[key].time),
        });
      }
    }
    setSelectedOptions({ ...updatedSelectedOptions });
    frequencySelectHandler(selectedWeekdays);
  };

  const timeChangeHandler = (e, day) => {
    const time = e.target.value;
    let updatedSelectedOptions = {};

    for (let key in selectedOptions) {
      if (key === day) {
        selectedOptions[key].time = time;
      }
      updatedSelectedOptions[key] = selectedOptions[key];
    }
    setSelectedOptions({ ...updatedSelectedOptions });
  };

  return (
    <div className="max-w-max">
      <table className="border-[0.5px] border-secondary-blue">
        <thead>
          <tr className="text-xl font-normal text-secondary-blue">
            <th className="py-5 px-10 border-r border-border-gray">
              Week Days
            </th>
            <th className="py-5 px-10">Class Time</th>
          </tr>
        </thead>
        <tbody>
          {daysOfWeek.map((day, index) => (
            <tr key={day}>
              <td className="py-2 px-10 border-t border-r border-border-gray">
                <input
                  type="checkbox"
                  className={classes.frequency}
                  checked={selectedOptions[day].selected}
                  onChange={(e) => frequencyChangeHandler(e, day)}
                />
                <span className="text-xl font-normal text-secondary-blue">
                  {day}
                </span>
              </td>
              <td className="py-2 px-10 border-t border-border-gray">
                <input
                  type="time"
                  name="time"
                  value={selectedOptions[day].time}
                  onChange={(e) => timeChangeHandler(e, day)}
                  className={classes.time}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClassFrequencySelector;
