import React from "react";

const InvertedCommas = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 52 42"
    >
      <path
        fill="#FFC6AA"
        d="M.973 41.397V28.115c0-5.114.811-9.853 2.432-14.218C5.151 9.53 8.082 5.229 12.198.988l7.296 5.426c-2.744 2.993-4.74 5.799-5.986 8.418-1.248 2.494-1.996 4.989-2.245 7.483h8.792v19.082H.973zm31.055 0V28.115c0-5.114.81-9.853 2.432-14.218C36.206 9.53 39.137 5.229 43.253.988l7.296 5.426c-2.744 2.993-4.74 5.799-5.987 8.418-1.247 2.494-1.995 4.989-2.245 7.483h8.793v19.082H32.028z"
      ></path>
    </svg>
  );
};

export default InvertedCommas;
