import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import DropdownArrow from "../../assets/SVG/dropdown-arrow";
import Logo from "../../assets/images/logo.png";
import Button from "../Button/Button";
//import { useClickOutside } from "../../CustomHook/use-click-outside";
import Hamburger from "../../assets/SVG/hamburger";
import Cross from "../../assets/SVG/cross";

const Navbar = () => {
  const navigate = useNavigate();
  const [isOpenDropdown, setIsOpenDropdown] = useState({ open: false });
  const [isOpenDropDownMobile, setIsOpenDropdownMobile] = useState({
    open: true,
  });
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

  // let domNode = useClickOutside(() => {
  //   setIsOpenDropdown({ open: false });
  // });

  return (
    <div className="relative max-w-screen bg-white flex items-center justify-between p-5 sm:px-10 sm:py-3 shadow-sm">
      <div className="flex items-center space-x-5 xl:space-x-10">
        <div className="lg:hidden">
          <Hamburger
            className="w-[35px] h-[24px]"
            onClick={() => setIsHamburgerOpen(true)}
          />
          {isHamburgerOpen && (
            <div
              style={{
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              }}
              className="flex flex-col space-y-3 absolute z-10 top-0 left-0 rounded-2xl w-[75%] h-screen bg-white py-3 px-7"
            >
              <div>
                <Cross
                  onClick={() => {
                    setIsHamburgerOpen(false);
                    setIsOpenDropdownMobile({ open: true });
                  }}
                />
                <div className="flex items-center space-x-2 mt-8">
                  <DropdownArrow
                    onClick={() => {
                      setIsOpenDropdownMobile((prevState) => {
                        return { open: !prevState.open };
                      });
                    }}
                    className="w-3 h-5 cursor-pointer"
                  />
                  <span className="text-base text-primary-blue font-medium">
                    Explore Skills
                  </span>
                </div>
                {isOpenDropDownMobile.open && (
                  <div className="flex flex-col">
                    <Link
                      to="/explore-skills/chess"
                      onClick={() => setIsHamburgerOpen(false)}
                      className="text-sm text-secondary-blue-lt block cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
                    >
                      Chess
                    </Link>
                    <Link
                      to="/explore-skills/web-design"
                      onClick={() => setIsHamburgerOpen(false)}
                      className="text-sm text-secondary-blue-lt cursor-pointer block hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
                    >
                      Web Design
                    </Link>
                    <Link
                      to="/explore-skills/coding"
                      onClick={() => setIsHamburgerOpen(false)}
                      className="text-sm text-secondary-blue-lt cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
                    >
                      Coding
                    </Link>
                    <Link
                      to="/explore-skills/graphic-design"
                      onClick={() => setIsHamburgerOpen(false)}
                      className="text-sm text-secondary-blue-lt cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
                    >
                      Graphic Design
                    </Link>
                    <Link
                      to="/explore-skills/video-editing"
                      onClick={() => setIsHamburgerOpen(false)}
                      className="text-sm text-secondary-blue-lt cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
                    >
                      Video Editing
                    </Link>
                    <Link
                      to="/explore-skills/3D-modelling"
                      onClick={() => setIsHamburgerOpen(false)}
                      className="text-sm text-secondary-blue-lt cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
                    >
                      3D Modelling
                    </Link>
                  </div>
                )}
              </div>

              <Link
                to="/summer-bootcamp"
                className="text-base text-secondary-blue font-normal"
                onClick={() => setIsHamburgerOpen(false)}
              >
                Summer Bootcamp 22
              </Link>
              <Link
                to="/pricing/options"
                className="text-base text-secondary-blue font-normal"
                onClick={() => setIsHamburgerOpen(false)}
              >
                Pricing Plans
              </Link>
              <Link
                to="/creative-champs"
                className="text-base text-secondary-blue font-normal"
                onClick={() => setIsHamburgerOpen(false)}
              >
                Creative Champs
              </Link>
              <Link
                to="/our-clients"
                className="text-base text-secondary-blue font-normal"
                onClick={() => setIsHamburgerOpen(false)}
              >
                Our Clients
              </Link>
              <Link
                to="/about-us"
                className="text-base text-secondary-blue font-normal"
                onClick={() => setIsHamburgerOpen(false)}
              >
                About Us
              </Link>
              <Link
                to="/contact-us"
                className="text-base text-secondary-blue font-normal"
                onClick={() => setIsHamburgerOpen(false)}
              >
                Contact Us
              </Link>
              {/* <span
                className="text-base text-secondary-blue font-normal"
                onClick={() => setIsHamburgerOpen(false)}
              >
                Settings
              </span>
              <span
                className="text-base text-secondary-blue font-normal"
                onClick={() => setIsHamburgerOpen(false)}
              >
                Logout
              </span> */}
            </div>
          )}
        </div>
        <div
          onClick={() => navigate("/")}
          className="lg:flex lg:flex-col sm:items-center cursor-pointer"
        >
          <img
            src={Logo}
            alt="Krafternoon"
            className="w-[53px] h-[43px] lg:w-[73px] lg:h-[63px]"
          />
          <p className="hidden lg:inline font-bold text-lg -mt-1">
            KRAFTERNOON
          </p>
          <p className="hidden lg:inline font-normal text-sm -mt-2">
            KRAFTING SKILLS
          </p>
        </div>

        <div
          onMouseOver={() => {
            setIsOpenDropdown((prevState) => {
              return { open: true };
            });
          }}
          onMouseOut={() => {
            setIsOpenDropdown((prevState) => {
              return { open: false };
            });
          }}
          className="bg-white z-20 relative"
        >
          <div className="hidden lg:inline-flex items-center space-x-2">
            <span className="text-sm 2xl:text-lg text-primary-blue font-normal ">
              Explore Skills
            </span>
            <DropdownArrow
              onClick={() => {
                setIsOpenDropdown((prevState) => {
                  return { open: !prevState.open };
                });
              }}
              className="w-3 h-5 cursor-pointer"
            />
          </div>
          {isOpenDropdown.open && (
            <div className="py-2 w-40 xl:w-44 hidden sm:flex sm:flex-col absolute 2xl:text-lg text-secondary-blue font-normal bg-white shadow-md rounded-md">
              <Link
                to="/explore-skills/chess"
                onClick={() => setIsOpenDropdown(false)}
                className="cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
              >
                Chess
              </Link>
              <Link
                to="/explore-skills/web-design"
                onClick={() => setIsOpenDropdown(false)}
                className="cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
              >
                Web Design
              </Link>
              <Link
                to="/explore-skills/coding"
                onClick={() => setIsOpenDropdown(false)}
                className="cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
              >
                Coding
              </Link>
              <Link
                to="/explore-skills/graphic-design"
                onClick={() => setIsOpenDropdown(false)}
                className="cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
              >
                Graphic Design
              </Link>
              <Link
                to="/explore-skills/video-editing"
                onClick={() => setIsOpenDropdown(false)}
                className="cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
              >
                Video Editing
              </Link>
              <Link
                to="/explore-skills/3D-modelling"
                onClick={() => setIsOpenDropdown(false)}
                className="cursor-pointer hover:bg-secondary-skyblue px-4 py-2 mx-1 rounded"
              >
                3D Modelling
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center space-x-4 xl:space-x-6 2xl:space-x-12">
        <div className="hidden lg:inline-flex items-center space-x-4 xl:space-x-6 2xl:space-x-12">
          <NavLink
            to="/summer-bootcamp"
            className={({ isActive }) =>
              isActive
                ? "text-primary-blue text-base 2xl:text-xl font-semibold "
                : "text-sm 2xl:text-lg font-normal  text-secondary-blue-lt"
            }
          >
            Summer Bootcamp 22
          </NavLink>
          <NavLink
            to="/pricing/options"
            className={({ isActive }) =>
              isActive
                ? "text-primary-blue text-base 2xl:text-xl font-semibold "
                : "text-sm 2xl:text-lg font-normal  text-secondary-blue-lt"
            }
          >
            Pricing
          </NavLink>
          <NavLink
            to="/creative-champs"
            className={({ isActive }) =>
              isActive
                ? "text-primary-blue lg:text-xl font-semibold "
                : "text-sm 2xl:text-lg font-normal text-secondary-blue-lt"
            }
          >
            Creative Champs
          </NavLink>
          <NavLink
            to="/about-us"
            className={({ isActive }) =>
              isActive
                ? "text-primary-blue text-base 2xl:text-xl font-semibold "
                : "text-sm 2xl:text-lg font-normal  text-secondary-blue-lt"
            }
          >
            About Us
          </NavLink>
          <NavLink
            to="/contact-us"
            className={({ isActive }) =>
              isActive
                ? "text-primary-blue text-base 2xl:text-xl font-semibold "
                : "text-sm 2xl:text-lg font-normal  text-secondary-blue-lt"
            }
          >
            Contact Us
          </NavLink>
        </div>
        <Button
          onClick={() => navigate("/signup")}
          innerText="Sign up"
          type="button"
          extraClass="rounded-lg max-w-max text-sm  2xl:text-lg font-normal text-secondary-blue bg-white border border-primary-yellow"
          innerTextStyle="font-normal"
        />
      </div>
    </div>
  );
};

export default Navbar;
