import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tick from "../../../assets/SVG/tick";
import Button from "../../../components/Button/Button";
import { BACKEND_BASE_URL } from "../../../helpers/url/url";
import axios from "../../../helpers/axios/axios";

const pricingPlans = {
  postpaid: {
    actualPrice: 15,
    discountedPrice: 15,
    shortText: "Pay as you learn",
    benefits: [
      "Learn any skill",
      "1:1 Mentoring",
      "Real-world projects",
      "Capstone Project",
      "Personalized Teaching",
    ],
  },
  prepaid: {
    actualPrice: 150,
    discountedPrice: 130,
    shortText: "Get instant $20 OFF",
    benefits: [
      "10 hrs Bundle",
      "Learn any skill",
      "1:1 Mentoring",
      "Real-world projects",
      "Capstone Project",
      "Personalized Teaching",
    ],
  },
};

const SelectPricingPlan = ({
  prepaidPlan,
  postpaidPlan,
  selectedPlan,
  setSelectedPlan,
}) => {
  const [planType, setPlanType] = useState(selectedPlan?.paymentMode);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  console.log(selectedPlan, "selectedPlan");

  // useEffect(() => {
  //   const redirectUrl = localStorage.getItem("redirectUrl");
  //   const planTypeSelected = localStorage.getItem("planType");

  //   if (redirectUrl) {
  //     localStorage.removeItem("redirectUrl");
  //   }
  //   if (planTypeSelected) {
  //     setPlanType(planTypeSelected?.toLowerCase());
  //     localStorage.removeItem("planType");
  //   }
  // }, []);

  const createCheckoutAPI = async (planId) => {
    setIsLoading(true);
    try {
      const createCheckoutResponse = await axios.post(
        `${BACKEND_BASE_URL}/api/payment/createCheckout`,
        {
          planType: "wallet_plan",
          planId: planId,
        }
      );
      console.log(createCheckoutResponse.data, "create-checkout");
      if (createCheckoutResponse?.status === 200) {
        setSelectedPlan({
          ...selectedPlan,
          checkoutId: createCheckoutResponse?.data?.checkoutId,
        });
        setIsLoading(false);
        navigate("/pricing/summary");
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  return (
    <div className="relative flex flex-col items-center lg:items-start lg:flex-row justify-between bg-secondary-blue w-[100%]  rounded-xl px-5 lg:px-10">
      <div className="sm:w-[40%] sm:text-center lg:text-left lg:max-w-md mt-20">
        <div className="text-white text-base sm:text-xl 2xl:text-[32px] font-semibold 2xl:leading-[48px]">
          <p>You’re just one step away from having fun learning with </p>
          <p className="text-primary-yellow">Krafternoon</p>
        </div>
        <img
          src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/Payment/dizzy-online-shopping_1_7TbAsU_XC.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654950055889"
          alt=""
          className="hidden lg:inline-flex absolute -bottom-2 w-1/2 2xl:w-1/3 3xl:w-1/5"
        />
      </div>
      <div className="bg-white rounded p-5 sm:w-[45%] lg:w-[30%] 3xl:w-[20%] my-10">
        <div className="flex items-center">
          <span
            onClick={() => setPlanType("prepaid")}
            className={`${
              planType === "prepaid"
                ? "bg-secondary-blue text-white text-sm 2xl:text-xl font-semibold w-1/2 py-2 px-5 cursor-pointer rounded-lg text-center"
                : "text-secondary-blue-lt-lt bg-primary-skyblue-lt text-sm 2xl:text-xl font-semibold w-1/2 py-2 px-5 cursor-pointer rounded-l-lg text-center"
            }`}
          >
            Prepaid
          </span>
          <span
            onClick={() => setPlanType("postpaid")}
            className={`${
              planType === "postpaid"
                ? "bg-secondary-blue text-white text-sm 2xl:text-xl font-semibold w-1/2 py-2 px-5 cursor-pointer rounded-lg text-center"
                : "text-secondary-blue-lt-lt bg-primary-skyblue-lt text-sm 2xl:text-xl font-semibold w-1/2 py-2 px-5 cursor-pointer rounded-r-lg text-center "
            }`}
          >
            Postpaid
          </span>
        </div>
        <div className="text-secondary-blue border-b border-primary-purple">
          <div className="flex items-center justify-center pt-10 pb-5">
            {planType === "prepaid" && (
              <span className="text-3xl 2xl:text-[48px]">
                ${prepaidPlan[0]?.price ? prepaidPlan[0]?.price - 20 : 150 - 20}
              </span>
            )}
            {planType === "postpaid" && (
              <span className="text-3xl 2xl:text-[48px]">
                ${postpaidPlan?.price ? postpaidPlan?.price : 15}
              </span>
            )}
            {planType === "prepaid" && (
              <strike className="text-base 2xl:text-[24px] text-secondary-blue-ltr-ltr ml-1">
                ${prepaidPlan[0]?.price ? prepaidPlan[0]?.price : 150}
              </strike>
            )}
            {planType === "postpaid" && (
              <span className="mt-1.5 2xl:mt-3 text-lg 2xl:text-2xl">/hr</span>
            )}
          </div>
          <p className="text-center text-xs 2xl:text-base pb-3">
            {pricingPlans[planType]?.shortText}
          </p>
        </div>
        <div className="mx-auto max-w-max mt-5">
          {pricingPlans[planType]?.benefits.map((option, index) => (
            <div
              className="flex items-center space-x-2 text-sm sm:text-base xl:text-lg text-secondary-blue mb-1"
              key={index}
            >
              <Tick />
              <span>{option}</span>
            </div>
          ))}
        </div>
        <Button
          isLoading={isLoading}
          disabled={isLoading}
          onClick={() => createCheckoutAPI(selectedPlan._id)}
          //onClick={() => navigate("/pricing/summary")}
          innerText="Choose Plan"
          type="button"
          extraClass="mt-5 mx-auto w-[70%] text-base xl:text-lg text-white font-normal  rounded-full bg-primary-green"
        />
      </div>
      <img
        src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/Payment/dizzy-online-shopping_1_7TbAsU_XC.png?ik-sdk-version=javascript-1.4.3&updatedAt=1654950055889"
        alt=""
        className="sm:w-[60%] lg:hidden"
      />
    </div>
  );
};

export default SelectPricingPlan;
