import React from "react";
import MostInDemandFacilities from "../../components/MostInDemandFacilities/MostInDemandFacilities";
import TechReady from "../../assets/SVG/tech-ready";
import ProfessionalProjects from "../../assets/SVG/professional-projects";
import WorkingProfessionals from "../../assets/SVG/working-professionals";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Krafternoon</title>
        <meta
          name="description"
          content="Meet our creative and analytical design thinkers."
        />
        <meta
          property="og:title"
          content="Powering futuristic innovative solutions"
        />
      </Helmet>
      <div className="max-w-screen">
        <div className="bg-primary-background-blue">
          <video
            className="object-cover w-full lg:h-[600px]"
            loop
            muted
            autoPlay
          >
            <source
              src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/OurTeam/our-team_ur4zRiY-_.mp4?ik-sdk-version=javascript-1.4.3&updatedAt=1652113710729"
              type="video/mp4"
            />
          </video>
        </div>

        <div className="max-w-4xl mx-auto my-20">
          <p className="text-center text-2xl xl:text-4xl  font-bold text-secondary-blue mb-5">
            Our <span className="text-primary-green">Story</span>
          </p>
          <div className="mx-10 text-center md:text-left flex flex-col space-y-5 text-sm sm:text-base lg:text-lg font-normal  text-secondary-blue">
            <p>
              With ideas sprouting in the mind of young entrepreneur, Mr.
              Devarsh Srivastava planned to create platform which extends an
              approach towards Creative (C) & Analytical (A)thinking skill.
              Synonyms for innovative Krafternoon (KN) is rooted in the
              institution of building & strengthening up Creativity & Analytical
              approach in the young minds.
            </p>
            <p>
              With offering just one skill, KN has expanded it over 5 CA skills
              and has expanded its team which comprise of innovative
              individuals.
            </p>
            <p>
              With the current growth rate team KN believes to make
              little-hearts, enthusiastic teens as well as growth-mindset adults
              skill ready under Creative & Analytical domain.
            </p>
            <p>
              Covering 80+ students around 5 countries, we serve learners from
              diverse backgrounds. We welcome individuals who love to learn &
              grow.
            </p>
          </div>
        </div>

        <div className="relative my-40">
          <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-2 mx-auto text-center text-2xl xl:text-3xl font-bold  xl:leading-10 bg-white w-[55%] sm:w-[23%] lg:w-[18%] 2xl:w-[15%]">
            <div className="flex items-center justify-center space-x-1">
              <p className="text-secondary-blue">Our</p>
              <p className="text-primary-green">Speciality</p>
            </div>
          </div>
          <MostInDemandFacilities
            menuList={[
              {
                id: "tech-ready",
                title: "We mould individuals to be Tech-Ready",
                Icon: TechReady,
              },
              {
                id: "professtional-projects",
                title:
                  "We provide opportunities to work on professional projects",
                Icon: ProfessionalProjects,
              },
              {
                id: "industry-experts",
                title: "Hands on teaching from Industry experts",
                Icon: WorkingProfessionals,
              },
            ]}
          />
        </div>

        <div className="max-w-3xl mx-auto my-20">
          <p className="text-center text-2xl xl:text-4xl  font-bold text-secondary-blue mb-10 xl:mb-20">
            Our <span className="text-primary-green">Clients</span>
          </p>
          <div className="flex items-center justify-around sm:justify-center sm:space-x-10 xl:justify-between xl:space-x-0 mx-2">
            <img
              src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/OurTeam/toro-alerts_v9lQCxQxva.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652113745723"
              alt=""
              className="w-[70px] h-[70px] lg:w-[140px] lg:h-[140px]"
            />
            <img
              src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/OurTeam/c3fund_iSXVhk0AY.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652113779286"
              alt=""
              className="w-[70px] h-[70px] lg:w-[140px] lg:h-[140px]"
            />
            <img
              src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/OurTeam/northwest-renovatorspng_TARiuSITZ?ik-sdk-version=javascript-1.4.3&updatedAt=1652113775682"
              alt=""
              className="w-[70px] h-[70px] lg:w-[140px] lg:h-[140px]"
            />
            <img
              src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/OurTeam/kabilla_sanyYNHY1.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652113749442"
              alt=""
              className="w-[70px] h-[70px] lg:w-[140px] lg:h-[140px]"
            />
          </div>
        </div>

        <div className="bg-primary-pink max-w-screen flex flex-col items-center py-10 lg:py-32">
          <h2 className="text-center lg:text-left text-2xl xl:text-4xl font-[750] text-secondary-blue mb-3">
            Want to join innovative team?
          </h2>
          <p className="mx-10 lg:max-w-2xl text-center text-base xl:text-lg font-normal  text-secondary-blue mb-10 xl:mb-16">
            Hey Innovators, we welcome you on board.
          </p>
          <Button
            innerText="Join us"
            type="button"
            extraClass="max-w-max px-5 xl:px-8 xl:py-1 text-lg text-white font-normal rounded-full bg-primary-green"
            onClick={() => navigate("/signup")}
          />
        </div>
      </div>
    </>
  );
};

export default AboutUs;
