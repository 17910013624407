import React from "react";

const TdIcon = ({ Icon, extraClass, style, ...rest }) => {
  return (
    <td style={style} className={`${extraClass}`} {...rest}>
      <Icon className="mx-auto w-[25px] h-[25px] cursor-pointer" />
    </td>
  );
};

export default TdIcon;
