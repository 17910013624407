import React from "react";

const ClassCalendar = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 25 25"
      {...rest}
    >
      <path
        fill="#434484"
        d="M22.005 1.796h-1.348V.45a.45.45 0 00-.898 0v1.347H4.491V.45a.45.45 0 00-.898 0v1.347H2.245A2.25 2.25 0 000 4.042v17.963a2.25 2.25 0 002.245 2.245h19.76a2.25 2.25 0 002.245-2.245V4.042a2.25 2.25 0 00-2.245-2.246zm1.347 20.209a1.35 1.35 0 01-1.347 1.347H2.245a1.35 1.35 0 01-1.347-1.347V8.083h22.454v13.922zm0-14.82H.898V4.042a1.35 1.35 0 011.347-1.348h1.348v1.348a.45.45 0 00.898 0V2.694h15.268v1.348a.45.45 0 00.898 0V2.694h1.348a1.35 1.35 0 011.347 1.348v3.143z"
      ></path>
      <path
        fill="#434484"
        d="M18.411 11.677h1.796a.45.45 0 100-.898h-1.796a.45.45 0 000 .898zM18.411 16.166h1.796a.45.45 0 000-.898h-1.796a.45.45 0 000 .898zM18.411 20.658h1.796a.45.45 0 000-.898h-1.796a.45.45 0 100 .898zM11.226 11.677h1.797a.45.45 0 100-.898h-1.797a.45.45 0 000 .898zM11.226 16.166h1.797a.45.45 0 000-.898h-1.797a.45.45 0 000 .898zM11.226 20.658h1.797a.45.45 0 000-.898h-1.797a.45.45 0 100 .898zM4.042 11.677h1.796a.449.449 0 100-.898H4.042a.45.45 0 000 .898zM4.042 16.166h1.796a.45.45 0 000-.898H4.042a.45.45 0 000 .898zM4.042 20.658h1.796a.45.45 0 000-.898H4.042a.449.449 0 100 .898z"
      ></path>
    </svg>
  );
};

export default ClassCalendar;
