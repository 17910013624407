import React from "react";

const PersonalizedCourses = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 82 66"
    >
      <path
        fill="#FFE8F5"
        d="M2.208.895a1.394 1.394 0 00-1.4 1.388V52.49c.004.77.63 1.392 1.4 1.388h27.785l-3.698 8.747h-6.282a1.398 1.398 0 100 2.791H62.48a1.396 1.396 0 000-2.79h-6.29l-3.698-8.748h27.792a1.394 1.394 0 001.391-1.388V2.283A1.394 1.394 0 0080.284.895H2.208zm1.389 2.788h75.285v34.344H3.597V3.683zm23.753 6.421a1.395 1.395 0 00-1.256 1.392v18.72a1.394 1.394 0 001.39 1.39H38.58l1.584 1.944h.001a1.393 1.393 0 002.16 0l1.576-1.945h11.094a1.394 1.394 0 001.4-1.388V11.496a1.393 1.393 0 00-1.4-1.392H27.483a1.557 1.557 0 00-.133 0zm1.533 2.791h10.963v16.058a1.396 1.396 0 00-.599-.136H28.883V12.895zm13.752 0h10.971v15.922H43.242c-.21 0-.418.05-.607.142V12.895zM3.596 40.818h75.286V51.09H3.596V40.817zm41.909 3.741c-.05-.002-.102 0-.153.003H37.13a1.571 1.571 0 00-.134 0 1.395 1.395 0 00.134 2.78h8.224a1.394 1.394 0 10.152-2.783zm-12.483 9.318h16.436l3.703 8.747H29.316l3.706-8.747z"
      ></path>
    </svg>
  );
};

export default PersonalizedCourses;
