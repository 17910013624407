import React from "react";
import PaymentCard from "../../../components/Card/PaymentCard/PaymentCard";
import TransformCard from "../../../components/Card/TransformCard/TransformCard";

import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Pricing = ({ prepaidPlan, postpaidPlan, setSelectedPlan }) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  return (
    <>
      <Helmet>
        <title>Krafternoon</title>
        <meta
          name="description"
          content="Providing innovative hands-on interactive learning to young minds around the world with real-world projects"
        />
        <meta
          property="og:title"
          content="Design thinking approach via online classes for kids."
        />
      </Helmet>
      <div className="max-w-screen flex flex-col items-center mt-20">
        <h1 className="max-w-max border-b-4 border-secondary-orange text-2xl xl:text-4xl pb-1 text-secondary-blue font-normal ">
          Learn As Per Your Choice
        </h1>
        <div className="flex flex-col space-y-5 md:space-y-0 md:flex-row mt-20 xl:my-20">
          <PaymentCard
            heading="Prepaid"
            bestSeller={true}
            description="Utilize 10 hrs into any skill"
            price={prepaidPlan[0]?.price ? prepaidPlan[0]?.price : 150}
            priceDescription="Get instant $20 OFF"
            optionsList={[
              { id: "10-hrs-bundle", title: "10 hrs Bundle" },
              { id: "learn-any-skill", title: "Learn any skill" },
              { id: "1:1-mentoring", title: "1:1 Mentoring" },
              { id: "real-wrold-project", title: "Real-world Project" },
              { id: "capstone-project", title: "Capstone Project" },
              { id: "personalized-teaching", title: "Personalized Teaching" },
            ]}
            onClick={() => {
              if (auth.isLoggedIn) {
                setSelectedPlan(prepaidPlan[0]);
                navigate("/pricing/pricing-plan");
              } else {
                navigate("/signin");
              }
            }}
          />
          <PaymentCard
            heading="Postpaid"
            bestSeller={false}
            description="Learn now & pay later"
            price={postpaidPlan?.price ? postpaidPlan?.price : 15}
            priceDescription="Pay as you learn"
            optionsList={[
              { id: "learn-any-skill", title: "Learn any skill" },
              { id: "1:1-mentoring", title: "1:1 Mentoring" },
              { id: "real-wrold-project", title: "Real-world Project" },
              { id: "capstone-project", title: "Capstone Project" },
              { id: "personalized-teaching", title: "Personalized Teaching" },
            ]}
            onClick={() => {
              if (auth.isLoggedIn) {
                setSelectedPlan(postpaidPlan[0]);
                navigate("/pricing/pricing-plan");
              } else {
                navigate("/signin");
              }
            }}
          />
        </div>
        <TransformCard
          signInForm={true}
          mainHeading="Exposure to learn with current industry experts"
          subHeading="Kids around the world are learning new skills and excelling in their field. Take a step towards an innovative future."
        />
      </div>
    </>
  );
};

export default Pricing;
