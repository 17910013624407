import React, { useState } from "react";
import { BACKEND_BASE_URL } from "../../../helpers/url/url";
import Button from "../../Button/Button";
import axios from "../../../helpers/axios/axios";
import CircularTick from "../../../assets/SVG/circular-tick";
import { validateEmail } from "../../../helpers/functions/email-validator";
import PhoneNumberInput from "../../PhoneNumberInput/PhoneNumberInput";
//import PhoneNumberInput from "../../PhoneNumberInput/PhoneNumberInput";

const LearnAnySkill = () => {
  //const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [demoBookedSuccessfully, setDemoBookedSuccessfully] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [demoApiMessage, setDemoApiMessage] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);

  console.log(phoneNumber, "phoneNumber");

  const bookDemoClassAPI = async () => {
    if (validateEmail(emailAddress) !== null) {
      setIsEmailValid(true);
      setIsLoading(true);
      try {
        const bookDemoResponse = await axios.post(
          `${BACKEND_BASE_URL}/api/demo/book`,
          { email: emailAddress, name: name, phone: phoneNumber }
        );
        console.log(bookDemoResponse);
        if (bookDemoResponse.statusText === "OK") {
          setDemoBookedSuccessfully(true);
          setDemoApiMessage(
            "We’ve send the details straight to your mail inbox"
          );
        }
        setIsLoading(false);
        setEmailAddress("");
        setName("");
      } catch (err) {
        console.log(err);
        setIsLoading(false);
        setEmailAddress("");
        setName("");
      }
      setTimeout(() => {
        setDemoBookedSuccessfully(false);
        setDemoApiMessage("");
      }, 50000);
    } else {
      setIsEmailValid(false);
      setDemoApiMessage("Invalid email address");
    }
  };

  return (
    <div className="bg-white shadow-sm w-[100%] sm:w-[300px] lg:w-[350px] xl:w-[500px] rounded-xl border border-ternary-blue-lt">
      <div className="py-2 rounded-t-xl text-center bg-secondary-blue text-white  font-normal text-2xl xl:text-4xl xl:py-5">
        <h1>Explore any skill!</h1>
      </div>
      {demoApiMessage && isEmailValid === true && (
        <p
          className={`px-5 text-sm lg:text-lg font-medium text-center mt-3 text-primary-green`}
        >
          {demoApiMessage}
        </p>
      )}
      <div className="px-3 sm:px-5 pt-5 pb-10 sm:py-10 xl:px-10 xl:py-16 flex flex-col items-center space-y-5 xl:space-y-10">
        <div className="w-full flex items-center">
          <label
            className="text-sm sm:text-base lg:text-xl xl:text-2xl font-normal text-primary-blue"
            htmlFor="name"
          >
            Name
          </label>
          <input
            className="font-semibold xl:text-lg text-primary-blue ml-2 lg:ml-5 w-full h-[35px] lg:h-[38px] xl:h-[42px] px-5 py-1.5 rounded-lg placeholder:text-xs xl:placeholder:text-lg placeholder:text-secondary-blue-ltr border border-ternary-blue focus:outline-none"
            type="text"
            placeholder="Full name"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="w-full">
          <div className="flex items-center">
            <label
              className="text-sm sm:text-base lg:text-xl xl:text-2xl font-normal text-primary-blue"
              htmlFor="name"
            >
              Email
            </label>
            <input
              className={`font-semibold xl:text-lg text-primary-blue ml-2 lg:ml-5 w-full h-[35px]  lg:h-[38px] xl:h-[42px] pl-5 py-1.5 rounded-lg placeholder:text-xs xl:placeholder:text-lg placeholder:text-secondary-blue-ltr border ${
                isEmailValid === false
                  ? "border-primary-red"
                  : "border-ternary-blue"
              } focus:outline-none`}
              type="text"
              placeholder="Enter your email address"
              id="email-address"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
          </div>
          {demoApiMessage && isEmailValid === false && (
            <p
              className={`text-xs sm:text-sm font-normal ml-[50px] xl:ml-[90px] mt-1 sm:mt-2 text-primary-red`}
            >
              {demoApiMessage}
            </p>
          )}
        </div>
        <div className="flex items-center w-full">
          <p className="text-sm sm:text-base lg:text-xl xl:text-2xl font-normal text-primary-blue mr-2 lg:mr-5">
            Phone
          </p>
          <PhoneNumberInput value={phoneNumber} setValue={setPhoneNumber} />
        </div>
      </div>
      <Button
        innerText="Book your FREE experience!"
        type="button"
        extraClass={`mx-auto sm:py-1 w-[90%] h-[35px] xl:h-[55px]  text-white font-normal rounded-full bg-primary-green ${
          isLoading && "opacity-20"
        }`}
        onClick={bookDemoClassAPI}
        isLoading={isLoading}
        disabled={isLoading}
        Icon={demoBookedSuccessfully ? CircularTick : null}
        innerTextStyle="text-xs lg:text-sm 2xl:text-base"
      />
      <p className="mt-2 mb-5 text-xs sm:text-base text-primary-blue text-center font-light">
        Earn instant 10% discount
      </p>
    </div>
  );
};

export default LearnAnySkill;
