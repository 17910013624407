import React from "react";

const Export = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-5 w-5"}
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        fill="#434484"
        d="M0 4.35v17.603A2.047 2.047 0 002.021 24h17.604a2.049 2.049 0 002.047-2.047V11.207a1.28 1.28 0 10-2.56 0v10.235H2.56V4.887h10.234a1.279 1.279 0 100-2.559H2.021A2.021 2.021 0 000 4.35z"
      ></path>
      <path
        fill="#434484"
        d="M22.72 8.009A1.28 1.28 0 0024 6.729V0h-6.755a1.28 1.28 0 000 2.559h2.38l-3.66 3.659a1.23 1.23 0 000 1.79c.237.242.559.38.896.385a1.304 1.304 0 001.05-.384l3.53-3.634V6.73a1.28 1.28 0 001.28 1.279z"
      ></path>
    </svg>
  );
};

export default Export;
