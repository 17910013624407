import React, { useEffect, useState } from "react";
import Paintbrush from "../../assets/SVG/paintbrush";
import Button from "../../components/Button/Button";
import BootcampCard from "../../components/Card/BootcampCard/BootcampCard";
import TransformCard from "../../components/Card/TransformCard/TransformCard";
import InvertedCommas from "../../assets/SVG/inverted-commas";
import CustomModal from "../../components/CustomModal/CustomModal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND_BASE_URL } from "../../helpers/url/url";
import CarouselComponent from "../../components/Carousel/Carousel";
import LandingPagePopup from "../../components/LandingPagePopup/LandingPagePopup";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { bootcampActions } from "../../redux/features/bootcamp-slice";

const allBootcamps = {
  kn62c412206a5afca341b602c7: {
    courseName: "Learn Basics of Chess",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/giphy__5__1__1__w3IlPYYox.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581437258",
    minAge: 4,
    courseDescription:
      "This two-week Chess Summer Camp led by industry experts is an intensive introduction for those interested in learning chess. Through this course, your child will be learning the history of chess, the basics of chessboard and chess pieces, the unique powers of all pieces, various rules of chess, and special moves of chess. We will end this summer camp with an amazing chess tournament where your child will be able to showcase their learnings.",
    seatsAvailable: 5,
  },

  kn62c412fa6a5afca341b602d9: {
    courseName: "Logo Designing",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/91aa3120ade4f94cedbc819b644230cb_3__1__oHYJbpqPg.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581436735",
    minAge: 7,
    courseDescription:
      "This two-week Digital Arts Summer Camp led by industry experts is an intensive introduction for those interested in learning about digital arts. Through this course, your child will have the opportunity to sketch various logos and give them a digital effect. They will learn colour theory, typography, and Photoshop tools. Give your child a head starts into the magical world of design with Krafternoon!",
    seatsAvailable: 5,
  },

  kn628d237e28a1a61550c34d44: {
    courseName: "Musical Instruments with coding",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/fxah_1_OdiuzFueRb.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581436951",
    minAge: 7,
    courseDescription:
      "This two-week Coding Summer Camp led by industry experts is an intensive introduction for those interested in learning about coding and its applications. Through this course, your child will learn the basics of web development, web designing, and front-end development. Your child will develop a piano on a website that will produce the same sound effects as an original piano. Enhance your child’s analytical thinking with Krafternoon’s Coding Summer Bootcamp.",
    seatsAvailable: 5,
  },

  kn62c411c86a5afca341b602be: {
    courseName: "Develop your own Website",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/91aa3120ade4f94cedbc819b644230cb_4__1__TfsKzkwrN.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581436790",
    minAge: 11,
    courseDescription:
      "This two-week Coding Summer Camp led by industry experts is an intensive introduction for those interested in learning about coding and its applications. Through this course, your child will learn about website designing, front end and back-end development. They will be designing their own web portfolio and develop a front end to implement that. Enhance your child’s analytical thinking with Krafternoon’s Coding Summer Bootcamp.",
    seatsAvailable: 5,
  },

  kn62c4129e6a5afca341b602d0: {
    courseName: "Make your own youtube video",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/giphy__5__2__1__fZ_Ef0qHL.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581437408",
    minAge: 8,
    courseDescription:
      "This two-week Video Editing Summer Camp led by industry experts is an intensive introduction for those interested in learning the basics of Video Editing. First, your child will be shooting their own introductory video, then learn Premiere Pro Tool and edit their own video on Premiere Pro. They will also learn to add different animations to it. Enhance your child’s creativity with Krafternoon’s Video Editing Summer Bootcamp.",
    seatsAvailable: 5,
  },

  kn628d23d528a1a61550c34d4d: {
    courseName: "Learn interactive Game designing on Figma!",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/fxah_2__1__TZtKCiUpM.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581437132",
    minAge: 10,
    courseDescription:
      "This two-week UI UX design Summer Camp led by industry experts is an intensive introduction for those interested in learning the basics of UI UX design. Initially, your child will be introduced to UI and UX, and learn the basics of the two and the differences between the two. Through this course, your child will learn how to design a game. They will understand how a user thinks and design various gaming scenarios accordingly.",
    seatsAvailable: 5,
  },

  kn628d23eb28a1a61550c34d50: {
    courseName: "Learn the most-in-demand language-Python!",
    imageUrl:
      "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/91aa3120ade4f94cedbc819b644230cb_5_Og8ohE6ze.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581436738",
    minAge: 9,
    courseDescription:
      "This two-week Basics of Python Summer Camp led by industry experts is an intensive introduction for those interested in learning Python. Your child will be learning python from scratch, getting familiar with Python’s environment and concepts of Python. In the end, your child will also be developing a game using Python.",
    seatsAvailable: 5,
  },
};

const SummerBootcamp = ({ bootcamps, setBootcamps }) => {
  const [openLearnHowModal, setOpenLearnHowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    imageUrl: "",
    courseTitle: "",
    courseAmount: "",
    classDescription: "",
    minAge: null,
  });
  const navigate = useNavigate();

  const closeLearnHowModalHandler = () => {
    setOpenLearnHowModal(false);
  };

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    let BOOTCAMP_TYPE = [];
    let SUMMER_BOOTCAMP = [];

    const getAllSummerBootcamps = async () => {
      // try {
      const summerBootcampsResopnse = await axios.get(
        `${BACKEND_BASE_URL}/api/payment/getAllPlans?targetCountry=us&courseType=bootcamp`
      );
      console.log(summerBootcampsResopnse, "summerBootcampsResopnse");
      //let TEMP_BOOTCAMPS = [...summerBootcampsResopnse?.data?.plans];
      let BOOTCAMPS = [...summerBootcampsResopnse?.data?.plans];
      // for (let i = 0; i < TEMP_BOOTCAMPS.length; i++) {
      //   if (TEMP_BOOTCAMPS[i]?.courseData?.courseName) {
      //     BOOTCAMPS.push(TEMP_BOOTCAMPS[i]);
      //   }
      // }
      for (let i = 0; i < BOOTCAMPS.length; i++) {
        if (
          !BOOTCAMP_TYPE.some(
            (bootcamp) =>
              bootcamp[BOOTCAMPS[i]?.courseData?.courseName] === true
          )
        ) {
          const dummiyData = allBootcamps["kn" + BOOTCAMPS[i].courseId];

          SUMMER_BOOTCAMP.push({
            imageUrl: dummiyData.imageUrl,
            courseName: BOOTCAMPS[i]?.courseData?.courseName,
            courseId: BOOTCAMPS[i].courseId,
            courseDescription: BOOTCAMPS[i].description,
            minAge: dummiyData.minAge,
            seatsAvailable: 5,
            courseType: BOOTCAMPS[i].courseType,
            targetCountry: BOOTCAMPS[i].targetCountry,
            courseAmount: BOOTCAMPS[i].price,
            timezone: BOOTCAMPS[i].timezone,
            currency: BOOTCAMPS[i].currency,
            mode: BOOTCAMPS[i].mode,
            plans: [
              {
                id: BOOTCAMPS[i]._id,
                timeslot: { ...BOOTCAMPS[i].timeslot },
                startDate: new Date(BOOTCAMPS[i].startDate),
                endDate: new Date(BOOTCAMPS[i].endDate),
              },
            ],
          });
          BOOTCAMP_TYPE.push({ [BOOTCAMPS[i]?.courseData?.courseName]: true });
        } else {
          for (let j = 0; j < SUMMER_BOOTCAMP.length; j++) {
            if (
              SUMMER_BOOTCAMP[j]?.courseName ===
              BOOTCAMPS[i]?.courseData?.courseName
            ) {
              SUMMER_BOOTCAMP[j].plans.push({
                id: BOOTCAMPS[i]._id,
                timeslot: { ...BOOTCAMPS[i].timeslot },
                startDate: new Date(BOOTCAMPS[i].startDate),
                endDate: new Date(BOOTCAMPS[i].endDate),
              });
            }
          }
        }
      }
      console.log("SUMMER_BOOTCAMP", SUMMER_BOOTCAMP);
      setBootcamps([...SUMMER_BOOTCAMP]);
      //console.log(BOOTCAMP_TYPE, "BOOTCAMP_TYPE");
      // } catch (err) {
      //   console.log(err);
      // }
    };
    getAllSummerBootcamps();
  }, [setBootcamps]);

  console.log(bootcamps, "bootcamps");
  return (
    <>
      <Helmet>
        <title>Krafternoon</title>
        <meta
          name="description"
          content="Best online summer camps 2022|Coding, Python, UI UX, Chess, Graphic Design, Video Editing, Game Design, Logo Design, Digital Art, 3D modelling"
        />
        <meta property="og:title" content="Summer camps in USA." />
      </Helmet>
      <div className="max-w-screen">
        <div className="bg-primary-background-blue w-full py-10 flex items-center justify-center">
          <h1 className="-mr-10 text-white text-2xl sm:text-3xl xl:text-5xl leading-[54px] font-extrabold ">
            Summer Bootcamp'22
          </h1>
          <Paintbrush className="h-20 w-20 xl:h-28 xl:w-28" />
        </div>

        {/* <img
        src="https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/WhatsApp_Image_2022-05-13_at_10.30_1__1__EXlQZk_hg.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581438414"
        alt=""
      /> */}
        {!localStorage.getItem("closePopup") && <LandingPagePopup />}
        <CarouselComponent
          carouselImages={[
            {
              src: "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/WhatsApp_Image_2022-05-13_at_10.30_1__1__EXlQZk_hg.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653581438414",
            },
            {
              src: "https://ik.imagekit.io/b3i1ietvz/Krafternoon/Bootcamp/Website_spanish_VnS5VFYoZ.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1654022705293",
            },
          ]}
        />

        <div className="w-full mt-24 xl:mt-20">
          <div className="px-5 sm:px-10 xl:px-28">
            <h3 className="text-center text-lg sm:text-2xl xl:text-3xl text-black font-bold xl:leading-[54px]">
              Learn Creative & Analytical skills
            </h3>
            <div className="bg-primary-purple h-[2px] w-full mt-3 mb-10 xl:mb-16"></div>
            <div className="grid grid-cols-1 sm:grid-cols-2 place-items-center gap-y-10 lg:place-items-start lg:grid-cols-3 xl:gap-16 my-10 xl:my-20">
              {bootcamps?.length > 0 &&
                bootcamps?.map((course) => (
                  <div key={course.courseId}>
                    <BootcampCard
                      src={course.imageUrl}
                      title={course.courseName}
                      age={course.minAge}
                      seatsAvailable={5}
                      extraClass="w-[290px] h-[390px] xl:w-[328px] xl:h-[425px] text-center border-2 border-primary-orange rounded-3xl flex flex-col items-center justify-around px-5 py-1"
                      onClick={() => {
                        setOpenLearnHowModal(true);
                        setModalInfo({
                          imageUrl: course.imageUrl,
                          courseAmount: `$${course.courseAmount}`,
                          courseDescription: course.courseDescription,
                          courseName: course.courseName,
                          plans: JSON.stringify(course.plans),
                          timezone: course.timezone,
                          currency: course.currency,
                          courseId: course.courseId,
                          courseType: course.courseType,
                          targetCountry: course.targetCountry,
                          minAge: course.minAge,
                        });
                      }}
                    />
                  </div>
                ))}
              {bootcamps?.length === 0 && (
                <h2 className="text-center text-base text-secondary-blue my-5">
                  No Summer Bootcamps found!
                </h2>
              )}
            </div>
          </div>
          <div className="w-full flex  justify-center space-x-5 xl:space-x-10 pt-20 px-5 xl:px-20">
            <div className="relative lg:w-1/3  border border-primary-purple rounded-[24px] py-5 xl:py-10 px-5">
              <div className="absolute -top-6 left-6">
                <InvertedCommas className="h-10 w-10" />
              </div>
              <p className="text-xs xl:text-sm 2xl:text-base text-secondary-blue">
                Instructor makes the game learning super fun and exciting! He
                comes up with new challenges with every session keeping me
                interested. Also he keeps the kids super positive throughout the
                process which makes it an enjoyable experience for my age kids.
              </p>
            </div>
            <div className="hidden sm:inline-flex relative lg:w-1/3 border border-primary-purple rounded-[24px] py-5 xl:py-10 px-5">
              <div className="absolute -top-6 left-6">
                <InvertedCommas className="h-10 w-10" />
              </div>
              <p className="text-xs xl:text-sm 2xl:text-base text-secondary-blue">
                My kids have been learning chess from Devarsh since the past 4
                months.They have made a lot of progress.Devarsh is very patient
                with the kids.He is a lot of fun to learn with.He also hosts
                tournaments on a bi weekly basis which helps build kid’s
                confidence.
              </p>
            </div>
            <div className="hidden lg:inline-flex relative lg:w-1/3 border border-primary-purple rounded-[24px] py-5 xl:py-10 px-5">
              <div className="absolute -top-6 left-6">
                <InvertedCommas className="h-10 w-10" />
              </div>
              <p className="text-xs xl:text-sm 2xl:text-base text-secondary-blue">
                My son is learning chess from last 4 months and the improvements
                in his thinking ability and reasoning skills are evident. He
                eagerly waits for the class every week. A big thanks to Devarsh
                for adopting innovative techniques for Chess training and for
                systematically identifying his weak points in game play to
                provide the necessary help.
              </p>
            </div>
          </div>
          <div>
            <TransformCard
              mainHeading="Exposure to learn with current industry experts"
              subHeading="Kids around the world are learning new skills and excelling in their field. Take a step towards an innovative future."
              buttonInnerText="Experience it for FREE"
            />
          </div>
        </div>

        {openLearnHowModal && (
          <CustomModal
            open={openLearnHowModal}
            closeModalHandler={closeLearnHowModalHandler}
          >
            <div className="py-10 px-5 lg:px-10 md:py-20 rounded-3xl">
              <div className="flex flex-col space-y-3 items-center md:space-y-0 md:flex-row md:justify-between md:items-start">
                <div className="flex flex-col items-center text-center md:text-left md:items-start md:flex-row md:space-x-5">
                  <div>
                    <img
                      src={modalInfo.imageUrl}
                      alt=""
                      className="w-[112px] h-[112px] xl:w-[173px] xl:h-[173px] rounded-full"
                    />
                  </div>
                  <div>
                    <h2 className="text-base xl:text-2xl font-bold text-secondary-blue">
                      {modalInfo.courseName}
                    </h2>
                    <p className="text-sm xl:text-xl text-secondary-blue font-normal xl:leading-[28px]">
                      Age: {modalInfo.minAge}+
                    </p>
                    {/* <p className="text-xl text-secondary-blue font-normal  leading-[28px]">
                  Language: C++
                </p>
                <p className="text-xl text-secondary-blue font-normal  leading-[28px]">
                  Coding Experience: Beginner
                </p> */}
                  </div>
                </div>
                <Button
                  innerText={`${modalInfo.courseAmount} - Register now!`}
                  type="button"
                  extraClass="p-1 max-w-max text-lg text-white font-normal rounded-full bg-primary-green"
                  onClick={() => {
                    //setSelectedBootcamp({ ...modalInfo });
                    dispatch(bootcampActions.addBatchInfo({ ...modalInfo }));
                    //localStorage.setItem("bootcamp", JSON.stringify(modalInfo));
                    if (auth.isLoggedIn) {
                      navigate("/bootcamp-register/batch-type");
                    } else {
                      navigate("/bootcamp-register/signin");
                    }
                  }}
                  innerTextStyle="text-sm xl:text-lg"
                />
              </div>

              <div className="text-center md:text-left my-10">
                <p className="text-base xl:text-xl text-black font-bold xl:leading-[28px]">
                  Class description:
                </p>
                <p className="mt-2 mb-5 text-sm xl:text-xl text-black font-normal leading-[24px] xl:leading-[28px]">
                  {modalInfo.courseDescription}
                </p>
              </div>

              <div className="flex flex-col items-center md:items-start">
                <Button
                  innerText="Register now!"
                  type="button"
                  extraClass="my-3 py-1 px-3 max-w-max text-lg text-white font-normal  rounded-full bg-primary-green"
                  onClick={() => {
                    //setSelectedBootcamp({ ...modalInfo });
                    dispatch(bootcampActions.addBatchInfo({ ...modalInfo }));
                    //localStorage.setItem("bootcamp", JSON.stringify(modalInfo));
                    if (auth.isLoggedIn) {
                      navigate("/bootcamp-register/batch-type");
                    } else {
                      navigate("/bootcamp-register/signin");
                    }
                  }}
                  innerTextStyle="text-sm xl:text-lg"
                />
                <a
                  href="mailto:tech@krafternoon.com"
                  className="text-xs xl:text-lg font-medium text-primary-skyblue hover:underline"
                >
                  Still have any questions? Feel free to ask
                </a>
              </div>
            </div>
          </CustomModal>
        )}
      </div>
    </>
  );
};

export default SummerBootcamp;
